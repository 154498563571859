import { Navigate, useParams } from "react-router-dom";
import { EmissionFactorSourceDetails } from "./AllSources";


export function EmissionFactorSourceTypes(){
	const {source}=useParams();
	const validSources = ["DEFRA", "CBAM", "EPA"];
  	const isValidSource =	!source ? false : validSources.includes(source);
	
	return (
		<div>	
			{
				isValidSource ? <EmissionFactorSourceDetails/>: <Navigate to={'/404'}/>				
			}
		</div>
	)
}