import * as React from 'react';
import './style.scss';
import { BETabs } from '../../../../../../../Components/BETabs';
import { AddCommaToNumber } from '../../../../../../../Config/Functions/UsefullFunctions';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { getBusinessStaySpendCategories, getScope3TypeCategories, getScope3TypeData } from '../../../../../Actions';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { Scope3TypeSettings } from './TypeSettings';
import { useLocation, useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../Redux/Types/scope3Types';
import { Scope3TypeDataSpendBased } from './BasedOnTab/SpendBased';
import { Scope3TypeDataQuantityBased } from './BasedOnTab/QunatityBased';
import { Scope3TypeDataPassengerDistanceBased } from './BasedOnTab/PassengerDistanceBased';
import { Scope3TypeDataFreightDistanceBased } from './BasedOnTab/FreightDistanceBased';
import { setScope3BasedOn } from '../../../../../../../Redux/Scope3';
import EmissionCard from '../../../../../../../Components/Cards/EmissionCard';
import { getScope3EmissionCalculations } from '../../../../../../Dashboard/Actions';
import { 
  QUANTITY_BASED, 
  SPEND_BASED,
  PASSENGER_DISTANCE_BASED,
  FREIGHT_DISTANCE_BASED
 } from '../../../../../Data/constants';
import { BasedOnData, TypeData, TypeSpendData } from '../../../../../Data/data';
import { DashboardDataScreen } from './Dashboard';
import LockIcon from '../../../../../../../Components/BEIcons/LockIcon';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';

export interface IScope3TypeDataScreenProps {
  setOpenUpload: Function;
}


export function Scope3TypeDataScreen(props: IScope3TypeDataScreenProps) {
  const procurementSpendCategories = useTypedSelector(state => state.scope3.catagoryConfigurations);
  const typeData = useTypedSelector(state => state.scope3.typeData);
  const { type } = useParams<{ type: Scope3TypeType }>();
  const year = useTypedSelector(state => state.common.date.currentYear);
  const basedOn = useTypedSelector(state => state.scope3.basedOn);
  const dispatch = useAppDispatch();
  const [inSettings, setInSettings] = React.useState(false);
  const emissionsCalculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
  const methods = useTypedSelector(state => state.scope3.methods);
  const selectedBasedOn = useTypedSelector(state => state.scope3.selectedBasedOnForType);
  const [activeTab, setActiveTab] = React.useState('DASHBOARD');

  React.useEffect(() => {
    if (!year || !basedOn) return;
    dispatch(getScope3TypeCategories(type as Scope3TypeType));
    // dispatch(getScope3TypeData(type as Scope3TypeType));
  }, [type, year, basedOn]);

  // React.useEffect(() => {
  //     dispatch(getScope3EmissionCalculations(year));
  // }, [year]);

  React.useEffect(() => {
    setActiveTab('DASHBOARD');
  }, [type]);


  return (
    <div className='scope3-type-data-screen'>      
      <div className='calculations'>
        {/* <DashboardDataScreen/> */}
        <BETabs
          activeKey={activeTab}
          destroyInactiveTabPane
          defaultActiveKey={'DASHBOARD'}
          tabBarStyle={{ marginLeft: '1.5rem' ,marginTop:'2rem'}}
          onChange={(key: string) => {
            switch(key){
              case 'settings':
                setInSettings(true);
                break;
              case 'DASHBOARD': 
                break;
              default:
                setInSettings(false);
                dispatch(setScope3BasedOn(key))
            }
            setActiveTab(key);
          }}
          type='card'
          items={
            [{
              label:"Dashboard",key:'DASHBOARD',children:<DashboardDataScreen setOpenUpload={props.setOpenUpload}/>
            }
          ,
          ...(
             [
              ...(
                Object.keys(TypeSpendData[type as Scope3TypeType]).includes(SPEND_BASED) ?
                [{
                label: TypeSpendData[type as Scope3TypeType][SPEND_BASED]?.changeCalculationMethod?.name 
                ? TypeSpendData[type as Scope3TypeType][SPEND_BASED]?.changeCalculationMethod?.name + ' Calculations' :
                'Spend-based Calculations', key: SPEND_BASED, children:
                  <Scope3TypeDataSpendBased setOpenUpload={props.setOpenUpload} />,
                  ...(!selectedBasedOn.includes(SPEND_BASED) && 
                  {
                    icon:<span style={{height:'1rem'}}><LockIcon inheritSize fill={PrimaryTheme.primaryGreyDark}/></span>,
                    disabled:true
                  }
                )
              }]:[]
            )
              ,
              ...(
                Object.keys(TypeSpendData[type as Scope3TypeType]).includes(QUANTITY_BASED) ?
                [{
                label: TypeSpendData[type as Scope3TypeType][QUANTITY_BASED]?.
                changeCalculationMethod?.name  ?
                TypeSpendData[type as Scope3TypeType][QUANTITY_BASED]?.changeCalculationMethod?.name + ' Calculations' :
                'Average-data Calculations', key: QUANTITY_BASED, children:
                  <Scope3TypeDataQuantityBased setOpenUpload={props.setOpenUpload} />,
                  ...(!selectedBasedOn.includes(QUANTITY_BASED) && 
                  {
                    icon:<div style={{height:'1rem',width:'1rem'}}><LockIcon inheritSize fill={PrimaryTheme.primaryGreyDark}/></div>,
                    disabled:true
                  }
                )
              }]:[]
            ),
            ...(
              Object.keys(TypeSpendData[type as Scope3TypeType]).includes(PASSENGER_DISTANCE_BASED) ?
              [{
              label: TypeSpendData[type as Scope3TypeType][PASSENGER_DISTANCE_BASED]?.
              changeCalculationMethod?.name  ?
              TypeSpendData[type as Scope3TypeType][PASSENGER_DISTANCE_BASED]?.changeCalculationMethod?.name + ' Calculations' :
              'Passenger-distance-based Calculations', key: PASSENGER_DISTANCE_BASED, children:
                <Scope3TypeDataPassengerDistanceBased setOpenUpload={props.setOpenUpload} />,
                ...(!selectedBasedOn.includes(PASSENGER_DISTANCE_BASED) && 
                {
                  icon:<div style={{height:'1rem',width:'1rem'}}><LockIcon inheritSize fill={PrimaryTheme.primaryGreyDark}/></div>,
                  disabled:true
                }
              )
            }]:[]
          ),
          
          ...(
            Object.keys(TypeSpendData[type as Scope3TypeType]).includes(FREIGHT_DISTANCE_BASED) ?
            [{
            label: 
            TypeSpendData[type as Scope3TypeType][FREIGHT_DISTANCE_BASED]?.
            changeCalculationMethod?.name  ?
            TypeSpendData[type as Scope3TypeType][FREIGHT_DISTANCE_BASED]?.changeCalculationMethod?.name + ' Calculations' :
            'Freight-distance-based Calculations', key: FREIGHT_DISTANCE_BASED, children:
              <Scope3TypeDataFreightDistanceBased setOpenUpload={props.setOpenUpload} />,
              ...(!selectedBasedOn.includes(FREIGHT_DISTANCE_BASED) && 
              {
                icon:<div style={{height:'1rem',width:'1rem'}}><LockIcon inheritSize fill={PrimaryTheme.primaryGreyDark}/></div>,
                disabled:true
              }
            )
          }]:[]
        ),

            ]
          ),
          {
            label: 'Settings', key: 'settings', children:
              <Scope3TypeSettings inSettings={inSettings} />
          }
        ] 
          } />


      </div>

    </div>
  );
}
