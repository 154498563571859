import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Scope3Type } from './ALLTypes/TypeScreen';
import { BUSINESS_STAY, BUSINESS_TRANSPORT, DOWNSTREAM_LOGISTICS, END_OF_LIFE_WASTE_TREATMENT, PROCUREMENT, UPSTREAM_LOGISTICS, WASTE_GENERATED } from '../../Data/constants';

export interface IScope3typesProps {
}

export function Scope3types (props: IScope3typesProps) {
  let {type} = useParams();
  return (
    <div>
        {
          type === PROCUREMENT || type === BUSINESS_TRANSPORT || type === UPSTREAM_LOGISTICS || type === BUSINESS_STAY || type ===DOWNSTREAM_LOGISTICS || type===WASTE_GENERATED || type===END_OF_LIFE_WASTE_TREATMENT ?
           <Scope3Type/>
          :
           <Navigate to='/404'/>
        }
    </div>
  );
}
