import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import SettingsIcon from '../../../../Components/BEIcons/SettingsIcon';
import Layers from '../../../../Components/BEIcons/Layers';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import IntegrationIcon from '../../../../Components/BEIcons/IntegrationIcon';
import NotificationIcon from '../../../../Components/BEIcons/NotificationIcon';
import LabelIcon from '../../../../Components/BEIcons/MarketPlaceIcon';
import DownloadIcon from '../../../../Components/BEIcons/DownloadIcon';
import { useLocation } from 'react-router-dom';

export interface ITopSectionProps {
}

export function TopSection(props: ITopSectionProps) {
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const location = useLocation();
    const settingsTabs = [
        {
            name: "General Settings",
            icon: (
                <SettingsIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/general-settings'
        },
        {
            name: "Users",
            icon: (
                <UsersIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/users'
        },
        {
            name: "Emission factor sources",
            icon: (
                <IntegrationIcon
                fill={PrimaryTheme.primaryGreen}
                inheritSize
              />
            ),
            path: '/home/settings/emission-factor-sources'
        },
        // {
        //     name: "Logs",
        //     icon: (
        //         <IntegrationIcon
        //             fill={PrimaryTheme.primaryGreen}
        //             inheritSize
        //         />
        //     ),
        //     path: '/home/settings/logs'
        // },
        // {
        //     name: "Notifications",
        //     icon: (
        //         <NotificationIcon
        //             fill={PrimaryTheme.primaryGreen}
        //             inheritSize
        //         />
        //     ),
        //     path: '/home/settings/notifications'
        // },
        // {
        //     name: "Permissions",
        //     icon: (
        //         <IntegrationIcon
        //             fill={PrimaryTheme.primaryGreen}
        //             inheritSize
        //         />
        //     ),
        //     path: '/home/settings/permissions'
        // },
        // {
        //     name: "Labels",
        //     icon: (
        //         <LabelIcon
        //             fill={PrimaryTheme.primaryGreen}
        //             inheritSize
        //         />
        //     ),
        //     path: '/home/settings/labels'
        // },
        // {
        //     name: "Data Download",
        //     icon: (
        //         <DownloadIcon
        //             fill={PrimaryTheme.primaryGreen}
        //             inheritSize
        //         />
        //     ),
        //     path: '/home/settings/data-download'
        // },
        {
            name: "Overview",
            icon: (
                <Layers
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings'
        },
    ];

    React.useEffect(() => {
        const path = location.pathname;
        const tab = settingsTabs.find(tab => path.includes(tab.path));
        if (tab) {
            setActiveTab(settingsTabs.indexOf(tab));
        }
    }, [location]);

    return (
        <div className='global-top-section' style={{borderBottom:`1px solid ${PrimaryTheme.primaryGreyLight} `,display:'flex',gap:'1rem'}}>
            <div
                style={{
                    fontSize: "1rem",
                    color: PrimaryTheme.primaryGreen,
                    paddingLeft: "1.5rem",
                    height: "1.25rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem"
                }}>
                <span>
                    {settingsTabs[activeTab].icon}
                </span>
                <span>
                    {settingsTabs[activeTab].name.toUpperCase()}
                </span>
            </div>
        </div>
    );
}
