import React from 'react'
import IconProps from './Interface/IconProps';

const TreeIcon= (props:IconProps) => {
  return (
    <svg  width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19.5927 17.5397L15.2802 12H17.5002C17.6403 12 17.7776 11.9608 17.8966 11.8868C18.0155 11.8127 18.1113 11.7069 18.1732 11.5812C18.2351 11.4555 18.2605 11.315 18.2466 11.1756C18.2327 11.0362 18.1801 10.9035 18.0946 10.7925L10.5946 1.04247C10.5246 0.951445 10.4345 0.877722 10.3315 0.826991C10.2284 0.776261 10.1151 0.749878 10.0002 0.749878C9.88536 0.749878 9.77204 0.776261 9.66898 0.826991C9.56593 0.877722 9.47591 0.951445 9.40585 1.04247L1.90585 10.7925C1.8204 10.9035 1.76774 11.0362 1.75385 11.1756C1.73996 11.315 1.76539 11.4555 1.82726 11.5812C1.88913 11.7069 1.98496 11.8127 2.1039 11.8868C2.22284 11.9608 2.36014 12 2.50023 12H4.71648L0.403978 17.5397C0.317597 17.6508 0.264223 17.7839 0.249957 17.924C0.235691 18.064 0.261109 18.2052 0.323305 18.3314C0.385501 18.4577 0.481965 18.5639 0.601671 18.6379C0.721376 18.7119 0.859491 18.7507 1.00023 18.75H9.25023V22.5C9.25023 22.6989 9.32925 22.8897 9.4699 23.0303C9.61055 23.171 9.80132 23.25 10.0002 23.25C10.1991 23.25 10.3899 23.171 10.5306 23.0303C10.6712 22.8897 10.7502 22.6989 10.7502 22.5V18.75H19.0002C19.1406 18.75 19.2783 18.7107 19.3974 18.6364C19.5166 18.5621 19.6125 18.4559 19.6742 18.3297C19.736 18.2036 19.7611 18.0627 19.7466 17.923C19.7322 17.7833 19.6789 17.6505 19.5927 17.5397ZM2.53398 17.25L6.84648 11.7103C6.93286 11.5992 6.98623 11.466 7.0005 11.326C7.01476 11.186 6.98935 11.0448 6.92715 10.9185C6.86495 10.7923 6.76849 10.6861 6.64878 10.6121C6.52908 10.538 6.39096 10.4992 6.25023 10.5H4.02366L10.0002 2.72997L15.9768 10.5H13.7502C13.6099 10.5001 13.4724 10.5396 13.3534 10.6139C13.2344 10.6883 13.1386 10.7945 13.077 10.9206C13.0153 11.0467 12.9903 11.1876 13.0048 11.3272C13.0192 11.4667 13.0725 11.5995 13.1587 11.7103L17.4665 17.25H2.53398Z" fill={props.fill ? props.fill:'black'}/>
	</svg>

  );
}

export default TreeIcon