import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../Redux/Types/scope3Types';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BUSINESS_STAY, BUSINESS_TRANSPORT, DOWNSTREAM_LOGISTICS, END_OF_LIFE_WASTE_TREATMENT, FREIGHT_DISTANCE_BASED, PASSENGER_DISTANCE_BASED, PROCUREMENT, QUANTITY_BASED, SPEND_BASED, UPSTREAM_LOGISTICS, WASTE_GENERATED } from '../../Data/constants';
import { title } from 'process';
import { BEInfoBox } from '../../../../Components/BEEyeButton/BEInfoBox';

export interface IScope3DashboardInfoBoxProps {
}

export function Scope3DashboardInfoBox (props: IScope3DashboardInfoBoxProps) {
  const { type } = useParams<{ type: Scope3TypeType }>();
  const basedOn = useTypedSelector(state => state.scope3.basedOn);

  const infoData:any = {
    [PROCUREMENT]:{
      [SPEND_BASED]:{
        title:'Please visit “Settings --> Configure Spend-data Calculations” to:',
        description:[
          'Select/update emission factor for a Procurement Category',
          'Update expenditure type for a Procurement Category',
        ]
      },
      [QUANTITY_BASED]:{
        title:'Please visit “Settings --> Configure Average-data Calculations” to:',
        description:[
          'Select/update emission factor for a Material Category',
          'Update expenditure type for a Material Category',
          'Update unit of measurement for a Material Category',
          'Select/update quantity conversion formula for a Material Category',
        ]
      }
    },
    [BUSINESS_TRANSPORT]:{
      [SPEND_BASED]:{
        title:'Please visit “Settings --> Configure Spend-data Calculations” to:',
        description:[
          'Select/update emission factor for a Transport Category',
        ]
      },
      [PASSENGER_DISTANCE_BASED]:{
        title:'Please visit “Settings --> Configure Distance-data Calculations” to:',
        description:[
          'Select/update emission factor for a Transport Category',
        ]
      },
    },
    [BUSINESS_STAY]:{
      [SPEND_BASED]:{
        title:'Please visit “Settings --> Configure Spend-data Calculations” to:',
        description:[
          'Select/update emission factor for an Accommodation Category',
        ]
      }
    },
    [WASTE_GENERATED]:{
      [QUANTITY_BASED]:{
        title:'Please visit “Settings --> Configure Waste-type-specific Calculations” to:',
        description:[
          'Select/update emission factor for a Waste Category',
          'Update unit of measurement for a Waste Category',
          'Select/update quantity conversion formula for a Waste Category',
        ]
      }
    },
    [END_OF_LIFE_WASTE_TREATMENT]:{
      [QUANTITY_BASED]:{
        title:'Please visit “Settings --> Configure Waste-type-specific Calculations” to:',
        description:[
          'Select/update emission factor for a Product Category',
          'Update unit of measurement for a Product Category',
          'Select/update quantity conversion formula for a Product Category',
        ]
      }
    },
    [UPSTREAM_LOGISTICS]:{
      [SPEND_BASED]:{
        title:'Please visit “Settings --> Configure Spend-data Calculations” to:',
        description:[
          'Select/update emission factor for a Logistics Category',
        ]
      },
      [FREIGHT_DISTANCE_BASED]:{
        title:'Please visit “Settings --> Configure Distance-data Calculations” to:',
        description:[
          'Select/update emission factor for a Logistics Category',
        ]
      }
    },
    [DOWNSTREAM_LOGISTICS]:{
      [SPEND_BASED]:{
        title:'Please visit “Settings --> Configure Spend-data Calculations” to:',
        description:[
          'Select/update emission factor for a Logistics Category',
        ]
      },
      [FREIGHT_DISTANCE_BASED]:{
        title:'Please visit “Settings --> Configure Distance-data Calculations” to:',
        description:[
          'Select/update emission factor for a Logistics Category',
        ]
      }
    }
  }

  if(!infoData[type as Scope3TypeType] || !infoData[type as Scope3TypeType][basedOn as string]) return null;
  return (
      <BEInfoBox 
        title={infoData[type as Scope3TypeType][basedOn as string].title} 
        description={
          <div>
            {
              infoData[type as Scope3TypeType][basedOn as string].description.map((item:string,index:number)=>
                <p key={index}>{index+1}. {item}</p>
              )
            }
          </div>
        }
      />
  );
}
