import * as React from 'react';
import { useTypedSelector } from '../../../../../../../../../Config/Hooks/useTypedSelector';
import { BUSINESS_STAY, BUSINESS_TRANSPORT, DOWNSTREAM_LOGISTICS, END_OF_LIFE_WASTE_TREATMENT, FREIGHT_DISTANCE_BASED, PASSENGER_DISTANCE_BASED, PROCUREMENT, QUANTITY_BASED, SPEND_BASED, UPSTREAM_LOGISTICS, WASTE_GENERATED } from '../../../../../../../Data/constants';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import { title } from 'process';
import { Checkbox, Form, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { Scope3BasedOnType, Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { BEDrawer } from '../../../../../../../../../Components/BEDrawer';
import { patchScope3TypeCategory } from '../../../../../../../Actions';
import { BEMessage } from '../../../../../../../../../Components/BEMessage';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../../../../../Components/BEFormItems/LableRequired';
import { BEEyeButton } from '../../../../../../../../../Components/BEEyeButton';
import { BEInput } from '../../../../../../../../../Components/BEFormItems/BEInput';
import { all } from 'axios';
import { CatagoryMap } from '../../../../../../../Data/data';

export interface IEditEmissionFactorDrawerProps {
  open: boolean;
  setOpen: Function;
  data: any;
}

// these are the fields that are required to be filled in order to get the emission factor


export function EditEmissionFactorDrawer(props: IEditEmissionFactorDrawerProps) {
  const dispatch = useAppDispatch();
  const basedOn = useTypedSelector(state => state.scope3.basedOn);
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [feilds, setFeilds] = React.useState<any>({})
  const [step, setStep] = React.useState<number>(1);
  const emissionFactors = useTypedSelector(state => state.scope3.emissionFactors)
  const [emissionFactor, setEmissionFactor] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false)
  const { type } = useParams<{ type: Scope3TypeType }>()
  const [feildOptions, setFeildOptions] = React.useState<any>({})
  const [typeBasedOnFeilds, setTypeBasedOnFeilds] = React.useState<any>(null)
  const [typeBasedOnResultFeilds, setTypeBasedOnResultFeilds] = React.useState<any>(null)
  const [ignoreFeilds, setIgnoreFeilds] = React.useState<any>({})



  const SPEND_BASED_FIELDS = [
    { title: 'Source', key: 'source', type: 'select' },
    { title: 'Version', key: 'version', type: 'select' },
    { title: 'Sector', key: 'sector_l1', type: 'select' },
    { title: 'Industry', key: 'industry_l2', type: 'select' },
    { title: 'Activity (NAICS)', key: 'naics_l3', type: 'select' },
    { title: 'Include Margins for Emission Factor', key: 'ef_margins', type: 'boolean', options: ['Without Margins', 'With Margins'] },
  ]

  const FREIGHT_DISTANCE_BASED_FIELDS = [
    { title: 'Source', key: 'ef_source', type: 'select' },
    { title: 'Version', key: 'ef_version', type: 'select' },
    { title: 'Logistics Category', key: 'logistics_category', type: 'select' ,dependencyFor: { ef_specification:{ values:['Rail'], valid:false }} },
    { title: 'Logistics Subcategory', key: 'logistics_subcategory', type: 'select' },
    { title: 'Specification', key: 'ef_specification', type: 'select' },
    { title: 'Input Unit', key: 'ef_input_unit', type: 'select' },
  ]

  const emissionsFeilds: any = {
    [SPEND_BASED]: {
      [PROCUREMENT]: SPEND_BASED_FIELDS,
      [BUSINESS_STAY]: SPEND_BASED_FIELDS,
      [BUSINESS_TRANSPORT]: SPEND_BASED_FIELDS,
      [UPSTREAM_LOGISTICS]: SPEND_BASED_FIELDS,
      [DOWNSTREAM_LOGISTICS]: SPEND_BASED_FIELDS
    },
    [QUANTITY_BASED]: {
      // dependencyFor strores key value pair where: key = dependent feild, 
      // in value if valid is true then the dependent feild is only valid for the value in values array
      // else it is invalid only for the values in values array

      // ******** WARNING:in dependencyFor one feild should only be added once *********
      [PROCUREMENT]: [
        { title: 'Source', key: 'ef_source', type: 'select', dependencyFor: { ef_coverage:{ values:['CBAM'], valid:true }} },
        { title: 'Version', key: 'ef_version', type: 'select' },
        { title: 'Material Category', key: 'material_category', type: 'select' },
        { title: 'Material Subcategory', key: 'material_subcategory', type: 'select' },
        { title: 'Material Name', key: 'material_name', type: 'select' },
        { title: 'Coverage', key: 'ef_coverage', type: 'select' },
      ],
      [WASTE_GENERATED]: [
        { title: 'Source', key: 'ef_source', type: 'select' },
        { title: 'Version', key: 'ef_version', type: 'select' },
        { title: 'Waste Category', key: 'waste_category', type: 'select' },
        { title: 'Waste Subcategory', key: 'waste_subcategory', type: 'select' },
        { title: 'Waste Name', key: 'waste_name', type: 'select' },
      ],
      [END_OF_LIFE_WASTE_TREATMENT]: [
        { title: 'Source', key: 'ef_source', type: 'select' },
        { title: 'Version', key: 'ef_version', type: 'select' },
        { title: 'Waste Category', key: 'waste_category', type: 'select' },
        { title: 'Waste Subcategory', key: 'waste_subcategory', type: 'select' },
        { title: 'Waste Name', key: 'waste_name', type: 'select' },
      ]

    },
    [PASSENGER_DISTANCE_BASED]:{
      [BUSINESS_TRANSPORT]: [
        { title: 'Source', key: 'ef_source', type: 'select' },
        { title: 'Version', key: 'ef_version', type: 'select' },
        { title: 'Transport Category', key: 'transport_category', type: 'select', dependencyFor: { 
          passenger_class:{ values:['Flights'], valid:true },
          ef_specification:{ values:['Flights','Cars (by size)','Cars (by market segment)'], valid:true }
        }},
        { title: 'Transport Subcategory', key: 'transport_subcategory', type: 'select' },
        { title: 'Specification', key: 'ef_specification', type: 'select' },
        { title: 'Passenger Class', key: 'passenger_class', type: 'select' },
      ]
    },
    [FREIGHT_DISTANCE_BASED]: {
      [UPSTREAM_LOGISTICS]: FREIGHT_DISTANCE_BASED_FIELDS,
      [DOWNSTREAM_LOGISTICS]: FREIGHT_DISTANCE_BASED_FIELDS
    }


  }

  // these are the feilds to show  after the emission factor is selected

  const SPEND_BASED_RESULT_FIELDS = [
    { title: 'description', key: 'description', type: 'description' },
    { title: 'Value', key: 'ef_value', type: 'text' },
    { title: 'Unit', key: 'ef_unit', type: 'text' },
  ]

  const emissionsResultFeilds: any = {
    [SPEND_BASED]: {
      [PROCUREMENT]: SPEND_BASED_RESULT_FIELDS,
      [BUSINESS_STAY]: SPEND_BASED_RESULT_FIELDS,
      [BUSINESS_TRANSPORT]: SPEND_BASED_RESULT_FIELDS,
      [UPSTREAM_LOGISTICS]: SPEND_BASED_RESULT_FIELDS,
      [DOWNSTREAM_LOGISTICS]: SPEND_BASED_RESULT_FIELDS
    },
    [QUANTITY_BASED]: {
      [PROCUREMENT]: [
        { title: 'description', key: 'material_description', type: 'description' },
        { title: 'Value', key: 'ef_value', type: 'text' },
        { title: 'Input Unit', key: 'ef_input_unit', type: 'text' },
        { title: 'Output Unit', key: 'ef_output_unit', type: 'text' },
      ],
      [WASTE_GENERATED]: [
        { title: 'description', key: 'ef_description', type: 'description' },
        { title: 'Value', key: 'ef_value', type: 'text' },
        { title: 'Input Unit', key: 'ef_input_unit', type: 'text' },
        { title: 'Output Unit', key: 'ef_output_unit', type: 'text' },
      ],
      [END_OF_LIFE_WASTE_TREATMENT]: [
        { title: 'description', key: 'ef_description', type: 'description' },
        { title: 'Value', key: 'ef_value', type: 'text' },
        { title: 'Input Unit', key: 'ef_input_unit', type: 'text' },
        { title: 'Output Unit', key: 'ef_output_unit', type: 'text' },
      ]
    },
    [PASSENGER_DISTANCE_BASED]:{
      [BUSINESS_TRANSPORT]: [
        { title: 'description', key: 'ef_description', type: 'description' },
        { title: 'Value', key: 'ef_value', type: 'text' },
        { title: 'Input Unit', key: 'ef_input_unit', type: 'text' },
        { title: 'Output Unit', key: 'ef_output_unit', type: 'text' },
      ]
    },
    [FREIGHT_DISTANCE_BASED]: {
      [UPSTREAM_LOGISTICS]: [
        { title: 'description', key: 'ef_description', type: 'description' },
        { title: 'Value', key: 'ef_value', type: 'text' },
        { title: 'Input Unit', key: 'ef_input_unit', type: 'text' },
        { title: 'Output Unit', key: 'ef_output_unit', type: 'text' },
      ],
      [DOWNSTREAM_LOGISTICS]: [
        { title: 'description', key: 'ef_description', type: 'description' },
        { title: 'Value', key: 'ef_value', type: 'text' },
        { title: 'Input Unit', key: 'ef_input_unit', type: 'text' },
        { title: 'Output Unit', key: 'ef_output_unit', type: 'text' },
      ]
    }

  }

  const onSubmit = async () => {
    if (!emissionFactor) {
      BEMessage.error('Please select an emission factor');
      return;
    }
    setLoading(true)
    await dispatch(patchScope3TypeCategory(type as Scope3TypeType, props.data.id,
      {
        emission_factor: emissionFactor.id,
        configured: true,
        ef_value: emissionFactor.ef_value
      }));
    setLoading(false)
    props.setOpen(false)
  }

  const DeleteConfiguration = async () => {
    setLoadingDelete(true)
    await dispatch(patchScope3TypeCategory(type as Scope3TypeType, props.data.id,
      {
        emission_factor: null,
        configured: false,
        ef_value: 0
      }));
    setLoadingDelete(false)
    props.setOpen(false)
  }

  const onSubmitForm1 = async () => {
    setStep(2)
  }

  React.useEffect(() => {
    if (!basedOn || !type || !emissionsFeilds[basedOn] || !emissionsFeilds[basedOn][type]) return;
    setTypeBasedOnFeilds(emissionsFeilds[basedOn][type])
    setTypeBasedOnResultFeilds(emissionsResultFeilds[basedOn][type])
  }, [basedOn, type])

  React.useEffect(() => {
    if (!typeBasedOnFeilds) return;
    //check if all feilds are filled
    if (typeBasedOnFeilds.every((field: any) => feilds[field.key] || ignoreFeilds[field.key])) {
      const ef = emissionFactors.data.find((item: any) =>
        typeBasedOnFeilds.every((field2: any) => (item[field2.key] === feilds[field2.key]) || ignoreFeilds[field2.key])
      ) 
      console.log(typeBasedOnFeilds,feilds,ignoreFeilds)
      console.log(ef, 'ef')
      if (ef) setEmissionFactor(ef)
      return;
    }
    setEmissionFactor(null)
  }, [feilds, emissionFactors, ignoreFeilds])

  React.useEffect(() => {
    //if the emission factor is already selected
    // then set the feilds to the selected emission factor
    if (!typeBasedOnFeilds) return;
    if (emissionFactors.status === 'success') {
      if (props.data.emission_factor) {
        let thisEf = emissionFactors.data.find((factor: any) => factor.id === props.data.emission_factor)
        if (thisEf) {
          let temp: any = {}
          let form1Values: any = {}
          let form2Values: any = {}
          let form1keys = new Set(typeBasedOnFeilds.slice(0, 2).map((field: any) => field.key))
          let form2keys = new Set(typeBasedOnFeilds.slice(2).map((field: any) => field.key))
          //append all the feilds to the temp object
          let feildsToIgnore:any = {};
          typeBasedOnFeilds
            .forEach((field: any) => {
              temp[field.key] = thisEf[field.key]
              if (form1keys.has(field.key)) form1Values[field.key] = thisEf[field.key]
              if (form2keys.has(field.key)) form2Values[field.key] = thisEf[field.key]
              feildsToIgnore = {...feildsToIgnore,...getFeildsToIgnore(field, thisEf[field.key])}
            })
          setIgnoreFeilds(feildsToIgnore)
          setFeilds(temp)
          form1.setFieldsValue(form1Values)
          form2.setFieldsValue(form2Values)
        }
      }
      else {
        form1.resetFields()
        form2.resetFields()
        //set the check box to false
        let temp: any = {}
        //append all the feilds to the temp object
        typeBasedOnFeilds
          .forEach((field: any) => {
            temp[field.key] = field.type === 'boolean' ? field.options[0] : ''
          })
        setFeilds(temp)
        setIgnoreFeilds({})
      }
      setStep(1)
    }
  }, [emissionFactors, props.open])

  React.useEffect(() => {
    if (!typeBasedOnFeilds) return;
    let temp: any = {}
    typeBasedOnFeilds.forEach((field: any, index: number) => {
      if (index === 0) temp[field.key] = Array.from(new Set(emissionFactors.data.map((item: any) => item[field.key])))
      else {
        // filter the emission factors by the last selected feilds
        let _key:any= null;
        for(let i=index-1;i>=0;i--){
          if(!ignoreFeilds[typeBasedOnFeilds[i].key]){
            _key = typeBasedOnFeilds[i].key
            break;
          }
        }
        if (feilds[_key])
          temp[field.key] = Array.from(new Set(
            // emissionFactors.data.filter((item: any) => item[_key] === feilds[_key])
            //   .map((item: any) => item[field.key])

            // filter the options based on all the feild values before this feild and the current feild
            emissionFactors.data.filter((item: any) => typeBasedOnFeilds.slice(0, index).every((field2: any) => item[field2.key] === feilds[field2.key] || ignoreFeilds[field2.key]))
              .map((item: any) => item[field.key])
          ))
        else temp[field.key] = []
      }
    })
    setFeildOptions(temp)
  }, [feilds, typeBasedOnFeilds, ignoreFeilds])

  React.useEffect(() => {
    setStep(1)
  }, [props.open, basedOn, type])

  const setFeildFun = (value: any, index: number) => {
    let temp: any = {}
    let tempForm1: any = {}
    let tempForm2: any = {}
    typeBasedOnFeilds.forEach((field: any, i: number) => {
      if (i > index) {
        const _value = field.type === 'boolean' ? field.options[0] : ''
        temp[field.key] = _value
        if (i < 2) tempForm1[field.key] = _value
        else tempForm2[field.key] = _value
      }
      else if (i === index) {
        temp[field.key] = value
        getFeildsToIgnore(field, value, true)
      }
      else temp[field.key] = feilds[field.key]
    })
    setFeilds(temp)
    form1.setFieldsValue(tempForm1)
    form2.setFieldsValue(tempForm2)
  }

  const getFeildsToIgnore = (field: any, value: any, setValues?:boolean) => {
    if (field.hasOwnProperty('dependencyFor')) {
      let valuesToRemove: any = {}
      let allRemovedValues: any = ignoreFeilds
      Object.keys(field.dependencyFor).forEach((dependentFeild: any) => {
        if(field.dependencyFor[dependentFeild].valid){
          const validValues = field.dependencyFor[dependentFeild]?.values
          if (!validValues.includes(value)) {
            valuesToRemove[dependentFeild] = true
            allRemovedValues[dependentFeild] = true
          }
          else delete allRemovedValues[dependentFeild]
        }
        else{
          const invalidValues = field.dependencyFor[dependentFeild]?.values
          if (invalidValues.includes(value)) {
            valuesToRemove[dependentFeild] = true
            allRemovedValues[dependentFeild] = true
          }
          else delete allRemovedValues[dependentFeild]
        }
      })
      console.log(valuesToRemove, 'valuesToRemove')
      if(setValues) setIgnoreFeilds(allRemovedValues)
      return valuesToRemove
    }
  }

  if (!typeBasedOnFeilds || !typeBasedOnResultFeilds) return null;


  return (
    <div>
      <BEDrawer
        heading='Select Emission Factor'
        secondryHeading={{
          heading:`${basedOn && props.data[CatagoryMap[type as Scope3TypeType][basedOn].dataIndex]}`,
        }}
        open={props.open}
        setOpen={props.setOpen}
        width='500px'
        footer={
          <>
            <BEButton className='primary' size='large' loading={loading} onClick={
              async () => {
                if (step === 1) form1.submit()
                if (step === 2) {
                  await onSubmit()
                }
              }
            }>
              Save & Next
            </BEButton>
            {
              props.data.emission_factor ?
                <BEButton className='secondry-red' size='large' loading={loadingDelete} onClick={DeleteConfiguration}>
                  Delete Configuration
                </BEButton>
                :
                <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
            }
          </>
        }
      >
        <div>
          {
            step === 1 &&
            <Form form={form1}
              onFinish={onSubmitForm1}
            >

              {
                typeBasedOnFeilds.slice(0, 2).map((field: any, index: number) => {
                  return <>
                    <LableRequired>{field.title}</LableRequired>
                    <Form.Item name={field.key} rules={[{ required: true, message: 'This field is required' }]}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        value={feilds[field.key]}
                        onChange={(value) => {
                          setFeildFun(value, index)
                        }}
                      >
                        {
                          feildOptions[field.key]?.sort()?.map((item: any) => <Select.Option value={item}>{item}</Select.Option>)
                        }
                      </Select>
                    </Form.Item>
                  </>
                })
              }
            </Form>
          }

          {
            step === 2 &&
            <Form form={form2}
              onFinish={onSubmit}
            >
              {
                typeBasedOnFeilds.slice(2).map((field: any, index: number) => {
                  return <>
                    {
                      ignoreFeilds[field.key] ? null :
                      field.type === 'select' ?
                        <>
                          <LableRequired>{field.title}</LableRequired>
                          <Form.Item name={field.key} rules={[{ required: true, message: 'This field is required' }]}>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              allowClear
                              value={feilds[field.key]}
                              onChange={(value) => {
                                setFeildFun(value, index + 2)
                              }}
                            >
                              {
                                feildOptions[field.key]?.sort()?.map((item: any) => <Select.Option value={item}>{item}</Select.Option>)
                              }
                            </Select>
                          </Form.Item>
                        </>
                        :
                        <Checkbox
                          checked={feilds[field.key] === field.options[1]}
                          onChange={(e) => {
                            setFeildFun(e.target.checked ? field.options[1] : field.options[0], index + 2)
                          }}
                        >
                          {field.title}
                        </Checkbox>
                    }
                  </>
                })
              }
              <br /><br /><br />
              {
                <p>Emission Factor Value & Unit<br /> <strong>
                  {emissionFactor &&
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                      {
                        typeBasedOnResultFeilds?.map((field: any) => {
                          return (
                            <>
                              {
                                field.type === 'description' ?
                                  <BEEyeButton
                                    title={'Description'}
                                    discription={emissionFactor[field.key]}
                                  /> :
                                  <BEInput disabled value={emissionFactor[field.key]} style={{ width: '150px' }} />

                              }
                            </>
                          )
                        })
                      }
                    </div>}
                </strong></p>
              }
            </Form>
          }
        </div>

      </BEDrawer>
    </div>
  )
}
