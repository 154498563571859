import * as React from 'react';
import { useTypedSelector } from '../../../../../../../../../Config/Hooks/useTypedSelector';
import { BEInfoBox } from '../../../../../../../../../Components/BEEyeButton/BEInfoBox';
import { NewBETable } from '../../../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
// import { CatagoryMap, SpendTextboxData } from '../../../../../../../../Config/Data/Scope3Type';
import { CatagoryMap} from '../../../../../../../Data/data';
import { Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { useParams } from 'react-router-dom';
import { AddCommaToNumber } from '../../../../../../../../../Config/Functions/UsefullFunctions';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import { navigateTo } from '../../../../../../../../../Navigation/NavigationManager';
import EmissionCard from '../../../../../../../../../Components/Cards/EmissionCard';
import { PASSENGER_DISTANCE_BASED } from '../../../../../../../Data/constants';
import { Scope3DashboardInfoBox } from '../../../../../../../Components/Scop3DashboardInfoBox';
import BEStatusTag from '../../../../../../../../../Components/BEStatusTag';

export interface IScope3TypeDataPassengerDistanceBasedProps {
  setOpenUpload: Function;
}


export function Scope3TypeDataPassengerDistanceBased(props: IScope3TypeDataPassengerDistanceBasedProps) {
  const typePassengerDistanceCategories = useTypedSelector(state => state.scope3.catagoryConfigurations);
  const { type } = useParams<{ type: Scope3TypeType }>();

  const columns: ColumnsType<any> = [
    {
      title: CatagoryMap[type as Scope3TypeType][PASSENGER_DISTANCE_BASED].title,
      dataIndex: CatagoryMap[type as Scope3TypeType][PASSENGER_DISTANCE_BASED].dataIndex,
    },
    {
      title: 'Total Emissions (tCO2e)',
      dataIndex: 'total_co2e',
      render: (text: any) => AddCommaToNumber(text, false, true),
      sorter: (a: any, b: any) => a.total_co2e - b.total_co2e,
    }, {
      title: '% of Emissions',
      dataIndex: 'percentage_co2e',
      render: (text: any, record: any) => AddCommaToNumber(record.percentage_co2e, false, true, true, 2) + '%',
    },
    {
      title: 'Passenger-km',
      dataIndex: 'passenger_km',
      render: (text: any) => AddCommaToNumber(text, false, true, true, 2)
    },
    {
      title: '% of Passenger-km',
      dataIndex: 'percentage_passenger_km',
      render: (text: any) => text && AddCommaToNumber(text, false, true, true, 2) + '%'
    },
    {
      title: 'Emission Factor Status',
      render: (text: any, record: any) => <BEStatusTag status={record.configured ? 'success' : 'error'}>
      <span>{record.configured ? 'COMPLETED' : 'PENDING'}</span>
    </BEStatusTag> 
    },
  ]

  return (
    <div>
      <div className='calculations-box'>
        <EmissionCard loading={typePassengerDistanceCategories.status === 'loading'} description='TOTAL EMISSIONS ' value={
          AddCommaToNumber(typePassengerDistanceCategories.data.reduce((acc: number, item: any) => acc + item.total_co2e, 0), false, true)
        } unit='tCO2e' />
  
      </div>
      <div className='info-container'>
          <Scope3DashboardInfoBox/>
      </div>
      <div className='catagory-table'>
        <NewBETable
          pagination
          columns={columns}
          loading={typePassengerDistanceCategories.status === 'loading'}
          data={[...typePassengerDistanceCategories.data].sort((a, b) => a.id - b.id)}
          search
          TableTopRightComponents={
            <>
              <BEButton size='large'
                onClick={() => navigateTo('/home/scope-3/' + type + '/view-data')}
              >View Data</BEButton>
              <BEButton size='large' className='primary'
                onClick={() => props.setOpenUpload(true)}
              >Add Data</BEButton>
            </>
          }
        />
      </div>
    </div>
  );
}
