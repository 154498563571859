import React from 'react'
import IconProps from './Interface/IconProps'

const ArrowLeft = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 14}
      height={props.inheritSize ? "100%" : 14}
	  viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
	  <path d="M6.99996 13.6668L8.17496 12.4918L3.52496 7.8335L13.6666 7.8335L13.6666 6.16683L3.52496 6.16683L8.18329 1.51683L6.99996 0.333496L0.333293 7.00016L6.99996 13.6668Z" fill="black"/>
	  </svg>
	  
  );
}

export default ArrowLeft