import * as React from 'react';
import { BEDrawer } from '../../../../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import { Select } from 'antd';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import { patchTypeFile } from '../../../../../../../Actions';
import { Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { useParams } from 'react-router-dom';
import { BEWarning } from '../../../../../../../../../Components/BEWarning';

export interface IEditFileProps {
  open: boolean;
  setOpen: Function;
  data: any;
}

export function EditFile (props: IEditFileProps) {
  const dispatch = useAppDispatch();
  const [loadingSelect, setLoadingSelect] = React.useState<boolean>(false);
  const [selectValue, setSelectValue] = React.useState<any>(null);
  const { type } = useParams<{ type: Scope3TypeType }>();

  React.useEffect(() => {
    setSelectValue(props?.data?.active);
  }, [props.data]);

  return (
      <BEDrawer open={props.open} setOpen={props.setOpen} heading='Edit File' width='528px' secondryHeading={{
        heading:`${props?.data?.name}`,
      }}
          footer={
          <>
            <BEButton className='primary' size='large'
              onClick={async () => {
                setLoadingSelect(true);
                await dispatch(patchTypeFile(type as Scope3TypeType,props.data.id, {active: selectValue}));
                setLoadingSelect(false);
                props.setOpen(false);
              }}
              loading={loadingSelect}
            >Save</BEButton>
            <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
          </>
        }
        >
           <div>
            <BEWarning description={"Once a file is set as inactive, it cannot be reactivated, and all file data will be permanently deleted from emission calculations. Inactive files can still be downloaded and uploaded again as required."}/>
            <p>Status</p>
              <Select value={selectValue} onChange={(value) => setSelectValue(value)} loading={loadingSelect}
                  style={{width:'100%'}}>
                  <Select.Option value={true}>Active</Select.Option>
                  <Select.Option value={false}>Inactive</Select.Option>
              </Select>
           </div>
        </BEDrawer>
  );
}
