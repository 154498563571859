export const PrimaryTheme = {
  primaryGreen: "#2e9844",
  primaryGreenDark: "#21453c",
  primaryGreenDisabled: "rgb(46,152,68,0.5)",
  primaryGreenLight: "#e2f0eb",
  primaryGreenLightest: '#F3F3F3',
  secondaryGreen: "rgb(0,184,118)",
  secondaryGreenLight: "#4FA556",
  primaryGray: 'rgba(102, 102, 102, 1)',
  primaryGreyLight: "rgba(243, 243, 243, 1)",
  primaryGrayInputBorder:"rgb(217,217,217)",
  primaryGreyDark:'rgb(159,159,159)',
  primaryBlack: "#232323",
  primaryBlackLight: '#101010',
  secondaryBlack: "#181818",
  primaryRedError: "#f04f6d",
  secondaryRedError: "#ED6262",
  primaryBlueInfo: "#3382de",
  primaryBlueLight: "#f5f9fa",
  secondaryGray: "#9F9F9F",
  amber: "#F09948",
  secondryBlueLight: "rgba(46, 152, 68, 0.1)",
};
