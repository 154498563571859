import * as React from 'react';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import Emisions from '../../../../Components/BEIcons/Emissions';
import './style.scss';
import CartIcon from '../../../../Components/BEIcons/Scope3/CartIcon';
import AeroplaneIcon from '../../../../Components/BEIcons/Scope3/AeroplaneIcon';
import BedIcon from '../../../../Components/BEIcons/Scope3/BedIcon';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import ReviewIcon from '../../../../Components/BEIcons/ReviewIcon';
import ChocolateIcon from '../../../../Components/BEIcons/ChocolateIcon';
import { BUSINESS_STAY, BUSINESS_TRANSPORT, PROCUREMENT, UPSTREAM_LOGISTICS, CAPITAL_GOODS,PURCHASED_GOODS_AND_SERVICES, DOWNSTREAM_LOGISTICS, WASTE_GENERATED, END_OF_LIFE_WASTE_TREATMENT } from '../../../../Features/Scope3/Data/constants';
import { filterDataByMonthOrBU, getAllEmissionCalculations, getScope3EmissionCalculations } from '../../../../Features/Dashboard/Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useEffect } from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import CapitalGoods from '../../../../Components/BEIcons/CapitalGoodsIcon';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';

export interface ICatagoriesMenuProps {
}

let Scope3Items = [
  // {
  //   name:"Total Emission",
  //   icon:<CartIcon inheritSize fill='black' />,
  //   href:"home/dashboard",
  //   emissioncategory:"Total Emission"
  // },
  {
    name: "Purchased goods and services",
    icon: <CartIcon inheritSize fill='black' />,
    href: "home/scope-3/"+PROCUREMENT,
    emissioncategory:PURCHASED_GOODS_AND_SERVICES
  },
  {
    name: "Capital goods",
    icon: <CapitalGoods inheritSize fill='black' />,
    href: "home/scope-3/"+ PROCUREMENT,
    emissioncategory:CAPITAL_GOODS
  },
  {
    name: "Business Travel (Transport)",
    icon: <AeroplaneIcon inheritSize fill='black' />,
    href: "home/scope-3/"+ BUSINESS_TRANSPORT,
    emissioncategory:BUSINESS_TRANSPORT
  },
  {
    name: "Business Travel (Accommodation)",
    icon: <BedIcon inheritSize fill='black' />,
    href: "home/scope-3/"+ BUSINESS_STAY,
    emissioncategory:BUSINESS_STAY
  },
  // {
  //   name: "Waste Generated in Operations",
  //   icon: <DeleteIcon inheritSize stroke='black' />,
  //   // href: "home/scope-3/waste-generated-in-operations",
  //   emissioncategory:'WASTE_GENERATED'
  // },
  {
    name: "Upstream Transportation",
    icon: <ReviewIcon inheritSize stroke='black' />,
    href: "home/scope-3/"+ UPSTREAM_LOGISTICS,
    emissioncategory:UPSTREAM_LOGISTICS
  },
  {
    name: "Downstream Transportation",
    icon: <ReviewIcon inheritSize stroke='black' />,
    href: "home/scope-3/"+ DOWNSTREAM_LOGISTICS,
    emissioncategory:DOWNSTREAM_LOGISTICS
  },
  {
    name: "Waste Generated in Operations",
    icon: <ReviewIcon inheritSize stroke='black' />,
    href: "home/scope-3/"+ WASTE_GENERATED,
    emissioncategory:WASTE_GENERATED
  },
  {
    name: "End-of-Life Treatment of Sold Products",
    icon: <ReviewIcon inheritSize stroke='black' />,
    href: "home/scope-3/"+ END_OF_LIFE_WASTE_TREATMENT,
    emissioncategory:END_OF_LIFE_WASTE_TREATMENT
  }
]

export function CatagoriesMenu(props: ICatagoriesMenuProps) {
  const dispatch=useAppDispatch()
  const [showMenu, setShowMenu] = React.useState(false);
  const [disabledTotalEmission,setDisabledTotalEmission]=React.useState(false);
  const [totalEmission,setTotalEmission]=React.useState(0)
  const emissionsCalculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
  const scope1Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope1Calculations);
  const scope2Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope2Calculations);
  const scope3Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
  const currentYear=useTypedSelector(state=>state.common.date.currentYear)
  const yearEnd = useTypedSelector((state) => state.companies.yearEnd);
  
  
  useEffect(() => {
    if(!currentYear) return
        dispatch(getAllEmissionCalculations(currentYear))
        dispatch(getScope3EmissionCalculations(currentYear))
    }, [currentYear, yearEnd])

  useEffect(()=>{
    if(window.location.pathname.includes('/home/dashboard')){
      setDisabledTotalEmission(true)
    }else{
      setDisabledTotalEmission(false)
    }
  },[window.location.pathname,showMenu])


  useEffect(() => {
    let total1 = scope1Calculations.data.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    let total2 = scope2Calculations.data.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    let total3 = scope3Calculations.data.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    setTotalEmission(total1+total2+total3)
  }, [ scope3Calculations])

  const chunkScopeItem = (array:any, chunkSize:number) => {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
      }
    return result;
  };

  const scope3ItemsGroup=chunkScopeItem(Scope3Items,4)

  return (
    <div className='catagories-menu'
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <BEButton className='primary' icon={
        <div style={{ height: '0.8rem', width: '1rem' }}><Emisions inheritSize stroke='white' /></div>
      }
      >
        Scope 3
      </BEButton>
      <div className='scope3-icon'>
        {/* <ChocolateIcon/> */}
      </div>
      <div className={`hover-menu ${showMenu ? 'show' : ''}`}>
        <div className='hover-menu-box'>
          <div>
            {scope3ItemsGroup.map((scope3Rows,index)=>{
             return <div className='scope3-itm-grp' style={{display:'flex', borderBottom: index !== scope3ItemsGroup.length-1 ? `1px solid ${PrimaryTheme.primaryGreyLight}` :'none', paddingBottom:'5px'}}> 
                {
                  scope3Rows.map((item:any, index:number) => (
                      <div className={`scope3-item ${disabledTotalEmission && item.name === "Total Emission" ? 'disabled' :''}`}
                        onClick={()=> navigateTo(item.href)}
                      >
                          <div className='scope3-item-icon'>{item.icon}</div>
                          <div>
                            <p className='scope3-item-name'>{item.name}</p>

                            <p 
                            style={{fontSize:'1.8rem', marginTop:'0.8rem',color:PrimaryTheme.primaryGreen, fontWeight:500, position:'absolute',top:'3rem'}}>
                              {item.emissioncategory == "Total Emission" ?
                                AddCommaToNumber(totalEmission,false,true) :
                                AddCommaToNumber(emissionsCalculations.data.reduce((acc:any,data:any)=> data.emission_category === item.emissioncategory ? acc+ data.tco2e :acc,0),false,true)}
                              <span style={{fontSize:'1rem',marginLeft:'0.5rem'}}>tCO2e</span>
                            </p>
                            
                          </div>
                      </div>
                  ))
                }
                </div>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
