import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    //methods
    GET_MY_METHODS,
    POST_MY_METHODS,
    // method graph data
    GET_SCOPE3_GRAPH_DATA,
    //files
    GET_TYPE_FILES,
    PATCH_TYPE_FILE,
    DELETE_TYPE_FILE,
    //spend
    GET_SPEND_EMISION_FACTORS,

    //Quantity
    GET_QUANTITY_FORMULAS,
    POST_QUANTITY_FORMULAS,
    GET_QUANTITY_FORMULA_VARIABLES,
    POST_QUANTITY_FORMULA_VARIABLES,
    PATCH_QUANTITY_FORMULAS,
    PATCH_QUANTITY_FORMULA_VARIABLES,

    // Passanger Distance
    GET_PASSENGER_DISTANCE_EMISION_FACTORS,

    // Freight Distance
    GET_FREIGHT_DISTANCE_EMISION_FACTORS,

    // Procurement Spend
    POST_PROCUREMENT_FILE,
    GET_PROCUREMENT_DATA_FEILDS,
    GET_PROCUREMENT_SPEND_CATEGORIES,
    GET_PROCUREMENT_EXPENDITURE_TYPES_REFRENCE,
    PATCH_PROCUREMENT_SPEND_CATEGORY,
    PATCH_PROCUREMENT_SPEND_CALCULATIONS,
    GET_PROCUREMENT_SPEND_DATA,
    // Business Transport Spend
    POST_BUSINESS_TRANSPORT_SPEND_BASED_DATA,
    GET_BUSINESS_TRANSPORT_DATA_FEILDS,
    GET_BUSINESS_TRANSPORT_DATA,
    GET_BUSINESS_TRANSPORT_SPEND_CATEGORIES,
    PATCH_BUSINESS_TRANSPORT_SPEND_CATEGORY,
    PATCH_BUSINESS_TRANSPORT_SPEND_CALCULATIONS,
    // Upstream Logistics Spend
    GET_UPSTREAM_LOGISTICS_DATA,
    GET_UPSTREAM_LOGISTICS_DATA_FEILDS,
    GET_UPSTREAM_LOGISTICS_SPEND_CATEGORIES,
    PATCH_UPSTREAM_LOGISTICS_SPEND_CATEGORY,
    PATCH_UPSTREAM_LOGISTICS_SPEND_CALCULATIONS,
    POST_UPSTREAM_LOGISTICS_SPEND_BASED_DATA,
    //business stay spend
    GET_BUSINESS_STAY_DATA,
    GET_BUSINESS_STAY_DATA_FEILDS,
    GET_BUSINESS_STAY_SPEND_CATEGORIES,
    PATCH_BUSINESS_STAY_SPEND_CATEGORY,
    PATCH_BUSINESS_STAY_SPEND_CALCULATIONS,
    POST_BUSINESS_STAY_SPEND_BASED_DATA,
    GET_TYPE_FILE,
    //downstream logistics
    GET_DOWNSTREAM_LOGISTICS_DATA,
    GET_DOWNSTREAM_LOGISTICS_DATA_FEILDS,
    GET_DOWNSTREAM_LOGISTICS_SPEND_CATEGORIES,
    PATCH_DOWNSTREAM_LOGISTICS_SPEND_CATEGORY,
    PATCH_DOWNSTREAM_LOGISTICS_SPEND_CALCULATIONS,
    POST_DOWNSTREAM_LOGISTICS_SPEND_BASED_DATA,


    //procurement quantity
    GET_PROCUREMENT_QUANTITY_DATA,
    POST_PROCUREMENT_QUANTITY_FILE,
    GET_PROCUREMENT_QUANTITY_CATEGORIES,
    PATCH_PROCUREMENT_QUANTITY_CATEGORY,
    PATCH_PROCUREMENT_QUANTITY_CALCULATIONS,
    GET_PROCUREMENT_QUANTITY_DATA_FEILDS,
    GET_PROCUREMENT_QUANTITY_EMISION_FACTORS,
    //Waste Generated quantity
    GET_WASTE_GENERATED_QUANTITY_DATA,
    POST_WASTE_GENERATED_QUANTITY_DATA,
    GET_WASTE_GENERATED_QUANTITY_CATEGORIES,
    PATCH_WASTE_GENERATED_QUANTITY_CATEGORY,
    PATCH_WASTE_GENERATED_QUANTITY_CALCULATIONS,
    GET_WASTE_GENERATED_QUANTITY_DATA_FEILDS,
    GET_WASTE_GENERATED_QUANTITY_EMISION_FACTORS,
    //end of life waste treatment quantity
    GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA,
    POST_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA,
    GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORIES,
    PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORY,
    PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CALCULATIONS,
    GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA_FEILDS,
    GET_END_OF_LIFE_WASTE_TREATMENT__QUANTITY_EMISION_FACTORS,

    //business transport passanger distance
    GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA,
    GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA_FEILDS,
    GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORIES,
    PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORY,
    PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CALCULATIONS,
    POST_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA,
    //upsream logistics freight distance
    GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA,
    GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FEILDS,
    GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES,
    PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY,
    PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS,
    POST_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA,
    //downstream logistics freight distance
    GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA,
    GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FEILDS,
    GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES,
    PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY,
    PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS,
    POST_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA,
    
} from "../../../Utils/Routes/Scope3Routes";
import {
    setMethods,
    setCatagoryConfigurations,
    setEmissionFactors,
    setExpenditureTypesReference,
    setSystemFeilds,
    setTypeData,
    setTypeFiles,
    setFormulas,
    setVariables,
    setCurrentFormula,
    setScope3DashboardGraphData
} from "../../../Redux/Scope3";
import type { Scope3BasedOnType, Scope3TypeType } from "../../../Redux/Types/scope3Types";
import { getScope3EmissionCalculations } from "../../Dashboard/Actions";
import {
    BUSINESS_STAY,
    BUSINESS_TRANSPORT,
    PROCUREMENT,
    SPEND_BASED,
    UPSTREAM_LOGISTICS,
    WASTE_GENERATED,
    END_OF_LIFE_WASTE_TREATMENT,
    DOWNSTREAM_LOGISTICS,
    QUANTITY_BASED,
    PASSENGER_DISTANCE_BASED,
    FREIGHT_DISTANCE_BASED,
} from "../Data/constants";
import { trackTaskStatus } from "../../../views/Actions/HomeActions";
import { TypeData } from "../Data/data";

// *************** Methods Actions  ***************** //
export const getMethods = (noLoad?:boolean) => async (dispatch: AppDispatch) => {
    if (!noLoad)
        dispatch(setMethods({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_MY_METHODS());
    if (data) {
        dispatch(setMethods({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMethods({
            status: "error",
            data: []
        }));
    }
}

export const postMethods = (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(POST_MY_METHODS(data));
    if (res) {
        dispatch(getMethods(true));
        dispatch(getScope3EmissionCalculations(year));
        BEMessage.success("Method Added Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error(error?.msg);
        return null;
    }
}

// *************** Graph Data Actions  ***************** //
export const getGraphData =(year: number,filter_by: string,methods:number[]) => async (dispatch: AppDispatch) => {
    dispatch(setScope3DashboardGraphData({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_SCOPE3_GRAPH_DATA(year,filter_by,methods));
    if (data) {
        dispatch(setScope3DashboardGraphData({
            status: "success",
            data: data.data
        }))
    }
    else {
        console.log(error);
        dispatch(setScope3DashboardGraphData({
            status: "error",
            data: []
        }))
    }
}


//*************  Combine Actions ******************** */

export const getScope3TypeData = (type: Scope3TypeType,query?:string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const methodDataRouteMap:any = {
        [SPEND_BASED]: {
            [PROCUREMENT]: GET_PROCUREMENT_SPEND_DATA,
            [BUSINESS_TRANSPORT]: GET_BUSINESS_TRANSPORT_DATA,
            [BUSINESS_STAY]: GET_BUSINESS_STAY_DATA,
            [UPSTREAM_LOGISTICS]: GET_UPSTREAM_LOGISTICS_DATA,
            [DOWNSTREAM_LOGISTICS]: GET_DOWNSTREAM_LOGISTICS_DATA
        },
        [QUANTITY_BASED]: {
            [PROCUREMENT]: GET_PROCUREMENT_QUANTITY_DATA,
            [WASTE_GENERATED]: GET_WASTE_GENERATED_QUANTITY_DATA,
            [END_OF_LIFE_WASTE_TREATMENT]: GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA
        },
        [PASSENGER_DISTANCE_BASED]: {
            [BUSINESS_TRANSPORT]: GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA
        },
        [FREIGHT_DISTANCE_BASED]: {
            [UPSTREAM_LOGISTICS]: GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA,
            [DOWNSTREAM_LOGISTICS]: GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA
        }
    }
    
    const basedOn = getState().scope3.basedOn;
    const year = getState().common.date.currentYear;
    if(!methodDataRouteMap[basedOn as Scope3BasedOnType] || !methodDataRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType]) return;

    dispatch(setTypeData({
        status: "loading",
        data: [],
        filterValues: {},
        totalPages: 0
    }));

    const queryToSend = 'year=' + year+ '&' + (query || '');

    const [data, error] = await handleAPICall(methodDataRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType](queryToSend));

    if (data) {
        dispatch(setTypeData({
            status: "success",
            data: data.data.data,
            filterValues: data.data.filter_values,
            totalPages: data.data.total_pages
        }));
    }
    else {
        console.log(error);
        dispatch(setTypeData({
            status: "error",
            data: [],
            filterValues: {},
            totalPages: 0
        }));
    }
}

export const getScope3TypeCategories = (type: Scope3TypeType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    const year = getState().common.date.currentYear;
    const methodCategoryRouteMap:any = {
        [SPEND_BASED]: {
            [PROCUREMENT]: GET_PROCUREMENT_SPEND_CATEGORIES,
            [BUSINESS_TRANSPORT]: GET_BUSINESS_TRANSPORT_SPEND_CATEGORIES,
            [BUSINESS_STAY]: GET_BUSINESS_STAY_SPEND_CATEGORIES,
            [UPSTREAM_LOGISTICS]: GET_UPSTREAM_LOGISTICS_SPEND_CATEGORIES,
            [DOWNSTREAM_LOGISTICS]: GET_DOWNSTREAM_LOGISTICS_SPEND_CATEGORIES
        },
        [QUANTITY_BASED]: {
            [PROCUREMENT]: GET_PROCUREMENT_QUANTITY_CATEGORIES,
            [WASTE_GENERATED]: GET_WASTE_GENERATED_QUANTITY_CATEGORIES,
            [END_OF_LIFE_WASTE_TREATMENT]: GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORIES
        },
        [PASSENGER_DISTANCE_BASED]: {
            [BUSINESS_TRANSPORT]: GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORIES
        },
        [FREIGHT_DISTANCE_BASED]: {
            [UPSTREAM_LOGISTICS]: GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES,
            [DOWNSTREAM_LOGISTICS]: GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES
        }
    }
    if(!methodCategoryRouteMap[basedOn as Scope3BasedOnType] || !methodCategoryRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType]) return;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(methodCategoryRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType](year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchScope3TypeCategory = (type: Scope3TypeType, id: any, data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    const year = getState().common.date.currentYear;
    const methodCategoryRouteMap:any = {
        [SPEND_BASED]: {
            [PROCUREMENT]: PATCH_PROCUREMENT_SPEND_CATEGORY,
            [BUSINESS_TRANSPORT]: PATCH_BUSINESS_TRANSPORT_SPEND_CATEGORY,
            [BUSINESS_STAY]: PATCH_BUSINESS_STAY_SPEND_CATEGORY,
            [UPSTREAM_LOGISTICS]: PATCH_UPSTREAM_LOGISTICS_SPEND_CATEGORY,
            [DOWNSTREAM_LOGISTICS]: PATCH_DOWNSTREAM_LOGISTICS_SPEND_CATEGORY
        },
        [QUANTITY_BASED]: {
            [PROCUREMENT]: PATCH_PROCUREMENT_QUANTITY_CATEGORY,
            [WASTE_GENERATED]: PATCH_WASTE_GENERATED_QUANTITY_CATEGORY,
            [END_OF_LIFE_WASTE_TREATMENT]: PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORY
        },
        [PASSENGER_DISTANCE_BASED]: {
            [BUSINESS_TRANSPORT]: PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORY
        },
        [FREIGHT_DISTANCE_BASED]: {
            [UPSTREAM_LOGISTICS]: PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY,
            [DOWNSTREAM_LOGISTICS]: PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY
        }
    }
    if(!methodCategoryRouteMap[basedOn as Scope3BasedOnType] || !methodCategoryRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType]) return;
    const [res, error] = await handleAPICall(methodCategoryRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType](id, data));
    if (res) {
        dispatch(getScope3TypeCategories(type));
        BEMessage.success("Category Updated Successfully");
        dispatch(getScope3EmissionCalculations(year));
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchScope3TypeCalculations = (type: Scope3TypeType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    const year = getState().common.date.currentYear;
    const methodCalculationsRouteMap:any = {
        [SPEND_BASED]: {
            [PROCUREMENT]: PATCH_PROCUREMENT_SPEND_CALCULATIONS,
            [BUSINESS_TRANSPORT]: PATCH_BUSINESS_TRANSPORT_SPEND_CALCULATIONS,
            [BUSINESS_STAY]: PATCH_BUSINESS_STAY_SPEND_CALCULATIONS,
            [UPSTREAM_LOGISTICS]: PATCH_UPSTREAM_LOGISTICS_SPEND_CALCULATIONS,
            [DOWNSTREAM_LOGISTICS]: PATCH_DOWNSTREAM_LOGISTICS_SPEND_CALCULATIONS
        },
        [QUANTITY_BASED]: {
            [PROCUREMENT]: PATCH_PROCUREMENT_QUANTITY_CALCULATIONS,
            [WASTE_GENERATED]: PATCH_WASTE_GENERATED_QUANTITY_CALCULATIONS,
            [END_OF_LIFE_WASTE_TREATMENT]: PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CALCULATIONS
        },
        [PASSENGER_DISTANCE_BASED]: {
            [BUSINESS_TRANSPORT]: PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CALCULATIONS
        },
        [FREIGHT_DISTANCE_BASED]: {
            [UPSTREAM_LOGISTICS]: PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS,
            [DOWNSTREAM_LOGISTICS]: PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS
        }
    }
    if(!methodCalculationsRouteMap[basedOn as Scope3BasedOnType] || !methodCalculationsRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType]) return;
    const [res, error] = await handleAPICall(methodCalculationsRouteMap[basedOn as Scope3BasedOnType][type as Scope3TypeType](year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            dispatch(getScope3EmissionCalculations(year));
            dispatch(getScope3TypeCategories(type));
            BEMessage.success("Calculations Updated Successfully");
        }
        else BEMessage.error("Calculations Updated Failed");
    }
    else {
        console.log(error);
        BEMessage.error("Calculations Updated Failed");
    }

}

export const postScope3TypeFile = (type: Scope3TypeType, data: any, fieldMap: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    let res = null;
    if (basedOn === SPEND_BASED){
        switch (type) {
            case PROCUREMENT:
                res = await dispatch(postProcurementFile(data, fieldMap));
                break;
            case BUSINESS_TRANSPORT:
                res = await dispatch(postBusinessTransportSpendBasedFile(data, fieldMap));
                break;
            case BUSINESS_STAY:
                res = await dispatch(postBusinessStaySpendBasedFile(data, fieldMap));
                break;
            case UPSTREAM_LOGISTICS:
                res = await dispatch(postUpstreamLogisticsSpendBasedFile(data, fieldMap));
                break;
            case DOWNSTREAM_LOGISTICS:
                res = await dispatch(postDownstreamLogisticsSpendBasedFile(data,fieldMap))
                break;
        }
    }
    else if (basedOn === QUANTITY_BASED){
        switch (type) {
            case PROCUREMENT:
                res = await dispatch(postProcurementQuantityData(data, fieldMap));
                break;
            case WASTE_GENERATED:
                console.log(data,fieldMap,"HEY")
                res = await dispatch(postWasteGeneratedQuantityData(data,fieldMap));
                console.log(res)
                break;
            case END_OF_LIFE_WASTE_TREATMENT:
                res = await dispatch(postEndOfLifeWasteTreatmentQuantityData(data,fieldMap));
                break;
        }
    }
    else if (basedOn === PASSENGER_DISTANCE_BASED)
        switch (type) {
            case BUSINESS_TRANSPORT:
                res = await dispatch(postBusinessTransportPassengerDistanceData(data, fieldMap));
                break;
        }
    else if (basedOn === FREIGHT_DISTANCE_BASED)
        switch (type) {
            case UPSTREAM_LOGISTICS:
                res = await dispatch(postUpstreamLogisticsFreightDistanceData(data, fieldMap));
                break;
            case DOWNSTREAM_LOGISTICS:
                res = await dispatch(postDownstreamLogisticsFreightDistanceData(data, fieldMap));
                break;
        }
    if(res) return await dispatch(getTypeFile(res));
    return null;
}

export const getScope3TypeFeilds = (type: Scope3TypeType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    if (basedOn === SPEND_BASED)
        switch (type) {
            case PROCUREMENT:
                dispatch(getProcurementDataFields());
                break;
            case BUSINESS_TRANSPORT:
                dispatch(getBusinessTransportSpendFeilds());
                break;
            case BUSINESS_STAY:
                dispatch(getBusinessStaySpendFeilds());
                break;
            case UPSTREAM_LOGISTICS:
                dispatch(getUpstreamLogisticsSpendFeilds());
                break;
            case DOWNSTREAM_LOGISTICS:
                dispatch(getDownstreamLogisticsSpendFeilds());
                break;
        }
    else if (basedOn === QUANTITY_BASED)
        switch (type) {
            case PROCUREMENT:
                dispatch(getProcurementQuantityDataFields());
                break;
            case WASTE_GENERATED:
                dispatch(getWasteGeneratedQuantityFeilds());
                break;
            case END_OF_LIFE_WASTE_TREATMENT:
                dispatch(getEndOfLifeWasteTreatmentQuantityFeilds());
                break;
        }
    else if (basedOn === PASSENGER_DISTANCE_BASED)
        switch (type) {
            case BUSINESS_TRANSPORT:
                dispatch(getBusinessTransportPassengerDistanceFeilds());
                break;
        }
    else if (basedOn === FREIGHT_DISTANCE_BASED)
        switch (type) {
            case UPSTREAM_LOGISTICS:
                dispatch(getUpstreamLogisticsFreightDistanceFeilds());
                break;
            case DOWNSTREAM_LOGISTICS:
                dispatch(getDownstreamLogisticsFreightDistanceFeilds());
                break;
        }
}

export const getScope3TypeEmissionFactors = (type: Scope3TypeType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    if (basedOn === SPEND_BASED)
        switch (type) {
            case PROCUREMENT:
                dispatch(getSpendEmissionFactors(PROCUREMENT));
                break;
            case BUSINESS_TRANSPORT:
                dispatch(getSpendEmissionFactors(BUSINESS_TRANSPORT));
                break;
            case BUSINESS_STAY:
                dispatch(getSpendEmissionFactors(BUSINESS_STAY));
                break;
            case UPSTREAM_LOGISTICS:
                dispatch(getSpendEmissionFactors(UPSTREAM_LOGISTICS));
                break;
            case DOWNSTREAM_LOGISTICS:
                dispatch(getSpendEmissionFactors(UPSTREAM_LOGISTICS)); // **** for now both have same emission factors
                break;
        }
    else if (basedOn === QUANTITY_BASED)                            // for quantity based all types have same emission factors 
          switch (type) {
            case PROCUREMENT:
                dispatch(getProcurementQuantityEmissionFactors());
                break;
            case WASTE_GENERATED:
                dispatch(getWasteGeneratedQuantityEmissionFactors());
                break;
            case END_OF_LIFE_WASTE_TREATMENT:
                dispatch(getEndOfLifeWasteTreatmentQuantityEmissionFactors());
                break;
          }
    else if (basedOn === PASSENGER_DISTANCE_BASED)    
        switch (type) {
            case BUSINESS_TRANSPORT:
                dispatch(getPassengerDistanceEmissionFactors(BUSINESS_TRANSPORT));
                break;
        }
    else if (basedOn === FREIGHT_DISTANCE_BASED)    
        switch (type) {
            case UPSTREAM_LOGISTICS:
                dispatch(getFreightDistanceEmissionFactors(UPSTREAM_LOGISTICS));
                break;
            case DOWNSTREAM_LOGISTICS:
                dispatch(getFreightDistanceEmissionFactors(UPSTREAM_LOGISTICS)); // **** for now both have same emission factors
                break;
        }

}

export const getTypeFiles = (noLoad?: true) => async (dispatch: AppDispatch) => {
    if (!noLoad)
        dispatch(setTypeFiles({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_TYPE_FILES());
    if (data) {
        dispatch(setTypeFiles({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setTypeFiles({
            status: "error",
            data: []
        }));
    }
}

export const getTypeFile = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_TYPE_FILE(id));
    if (data) {
        return data.data;
    }
    else {
        console.log(error);
        return null;
    }
}

export const patchTypeFile = (type: Scope3TypeType, id: any, data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const basedOn = getState().scope3.basedOn;
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_TYPE_FILE(id, data));

    if (res) {
        dispatch(getTypeFiles(true));
        dispatch(getScope3EmissionCalculations(year))
        dispatch(getScope3TypeCategories(type));
        BEMessage.success("File Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("File Updated Failed");
        return null;
    }
}

export const deleteTypeFile = (type: Scope3TypeType, id: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(DELETE_TYPE_FILE(id));
    if (res) {
        dispatch(getTypeFiles(true));
        dispatch(getScope3TypeCategories(type));
        dispatch(getScope3EmissionCalculations(year));
        BEMessage.success("File Deleted Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("File Deleted Failed");
        return null;
    }
}


// ************ Sprnd Actions ************ //

export const getSpendEmissionFactors = (type: Scope3TypeType) => async (dispatch: AppDispatch) => {

    const [data, error] = await handleAPICall(GET_SPEND_EMISION_FACTORS());
    if (data) {
        const typeName = TypeData[type]?.feildName;
        dispatch(setEmissionFactors({
            status: "success",
            data: data.data.filter((item: any) => item.scope_3_module === typeName)
        }));
    }
    else {
        console.log(error);
        dispatch(setEmissionFactors({
            status: "error",
            data: []
        }));
    }
}


// ************ Quantity Actions ************ //

export const processFormulaStringToArray = (input: string) => async (dispatch: AppDispatch, getState: () => RootState) => {

    input = input.slice(1, -1);
    const result: { type: string, value: string ,id?:number}[] = [];
    const regex = /\(\d+\)|[\+\-×÷\(\)]/g;
    let match;

    while ((match = regex.exec(input)) !== null) {
        if (match[0].startsWith('(') && match[0].endsWith(')') && match[0].length > 2) { // This is an ID (e.g., (123))
            const id = match[0].slice(1, -1); // Remove the surrounding parentheses
            const variable = getState().scope3.formulaCalculation.variables.data.find(v => v.id === parseInt(id, 10));
            if (variable) {
                result.push({ type: 'tag', value: variable.title, id: variable.id });
            }
        } else { // This is a sign (e.g., +, -, ×, ÷, ), ()
            result.push({ type: 'sign', value: match[0] });
        }
    }

    return result;
};

export const getQuantityFormulas = () => async (dispatch: AppDispatch) => {
    dispatch(setFormulas({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_QUANTITY_FORMULAS());
    if (data) {
        dispatch(setFormulas({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setFormulas({
            status: "error",
            data: []
        }));
    }
}

export const postQuantityFormulas = (data: any,noMessage?:boolean) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(POST_QUANTITY_FORMULAS(data));
    if (res) {
        dispatch(setCurrentFormula(res.data))
        dispatch(getQuantityFormulas());
        if(!noMessage) BEMessage.success("Formula Added Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error(error?.msg);
        return null;
    }
}

export const patchQuantityFormulas = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_QUANTITY_FORMULAS(id, data));
    if (res) {
        dispatch(getQuantityFormulas());
        BEMessage.success("Formula Updated Successfully");
        console.log('res', res)
        return true;
    }
    else {
        console.log(error);
        BEMessage.error(error?.msg)
        return false;
    }
}

export const getQuantityFormulaVariables = () => async (dispatch: AppDispatch) => {
    dispatch(setVariables({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_QUANTITY_FORMULA_VARIABLES());
    if (data) {
        dispatch(setVariables({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setVariables({
            status: "error",
            data: []
        }));
    }
}

export const postQuantityFormulaVariables = (data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(POST_QUANTITY_FORMULA_VARIABLES(data));
    if (res) {
        dispatch(getQuantityFormulaVariables());
        BEMessage.success("Variable Added Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error(error?.msg)
        return null;
    }
}

export const patchQuantityFormulaVariables = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_QUANTITY_FORMULA_VARIABLES(id, data));
    if (res) {
        dispatch(getQuantityFormulaVariables());
        BEMessage.success("Variable Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error(error?.msg)
        return null;
    }
}



//************  Passenger Distance Actions  ************ //

export const getPassengerDistanceEmissionFactors = (type: Scope3TypeType) => async (dispatch: AppDispatch) => {
    
        const [data, error] = await handleAPICall(GET_PASSENGER_DISTANCE_EMISION_FACTORS());
        if (data) {
            const typeName = TypeData[type]?.feildName;
            dispatch(setEmissionFactors({
                status: "success",
                data: data.data.filter((item: any) => item.scope_3_module === typeName)
            }));
        }
        else {
            console.log(error);
            dispatch(setEmissionFactors({
                status: "error",
                data: []
            }));
        }
    }



//************  Freight Distance Actions  ************ //

export const getFreightDistanceEmissionFactors = (type: Scope3TypeType) => async (dispatch: AppDispatch) => {
    
        const [data, error] = await handleAPICall(GET_FREIGHT_DISTANCE_EMISION_FACTORS());
        if (data) {
            const typeName = TypeData[type]?.feildName;
            dispatch(setEmissionFactors({
                status: "success",
                data: data.data.filter((item: any) => item.scope_3_module === typeName)
            }));
        }
        else {
            console.log(error);
            dispatch(setEmissionFactors({
                status: "error",
                data: []
            }));
        }
    }



//************  Procurement Spend Actions  ************ //



export const patchProcurementSpendCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_PROCUREMENT_SPEND_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}

export const patchProcurementSpendCategory = (id: any, data: any, noMessage?: boolean) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_PROCUREMENT_SPEND_CATEGORY(id, data));
    if (res) {
        dispatch(getProcurementSpendCategories());
        if (!noMessage) {
            BEMessage.success("Configuration Updated Successfully");
        }
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const getProcurementExpenditureTypesReference = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_PROCUREMENT_EXPENDITURE_TYPES_REFRENCE());
    if (data) {
        dispatch(setExpenditureTypesReference({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setExpenditureTypesReference({
            status: "error",
            data: []
        }));
    }
}

export const postProcurementFile = (data: any, feildMap: any) => async (dispatch: AppDispatch) => {
    let dataToSend: any = { ...data, ...feildMap };
    dataToSend['PROCUREMENT_SPENT_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_PROCUREMENT_FILE(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getProcurementSpendCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return null;
    }
}

export const getProcurementDataFields = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_PROCUREMENT_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getProcurementSpendCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_PROCUREMENT_SPEND_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}


// ************  Procurement Quantity Actions  ************ //

export const postProcurementQuantityData = (data: any, feildMap: any) => async (dispatch: AppDispatch) => {
    let dataToSend: any = { ...data, ...feildMap };
    dataToSend['QUANTITY_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_PROCUREMENT_QUANTITY_FILE(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getProcurementQuantityData(true));
            dispatch(getTypeFiles(true));
            dispatch(getProcurementQuantityCategories());
            return taskRes;
        }
    }
    else {
        console.log(error);
        return null;
    }
}

export const getProcurementQuantityData = (noLoad?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    if (!noLoad)
        dispatch(setTypeData({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_PROCUREMENT_QUANTITY_DATA('year'));
    if (data) {
        dispatch(setTypeData({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setTypeData({
            status: "error",
            data: []
        }));
    }
}

export const getProcurementQuantityDataFields = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_PROCUREMENT_QUANTITY_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getProcurementQuantityCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_PROCUREMENT_QUANTITY_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchProcurementQuantityCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_PROCUREMENT_QUANTITY_CATEGORY(id, data));
    if (res) {
        dispatch(getProcurementQuantityCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchProcurementQuantityCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_PROCUREMENT_QUANTITY_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            dispatch(getProcurementQuantityData(true));
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}

export const getProcurementQuantityEmissionFactors = () => async (dispatch: AppDispatch) => {

    const [data, error] = await handleAPICall(GET_PROCUREMENT_QUANTITY_EMISION_FACTORS());
    if (data) {
        dispatch(setEmissionFactors({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setEmissionFactors({
            status: "error",
            data: []
        }));
    }
}


//************  Business Transport Spend ****************** //

export const postBusinessTransportSpendBasedFile = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['BUSINESS_TRANSPORT_SPENT_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_BUSINESS_TRANSPORT_SPEND_BASED_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getBusinessTransportSpendCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return null;
    }

}



export const getBusinessTransportSpendFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_TRANSPORT_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getBusinessTransportSpendCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_TRANSPORT_SPEND_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchBusinessTransportSpendCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_BUSINESS_TRANSPORT_SPEND_CATEGORY(id, data));
    if (res) {
        dispatch(getBusinessTransportSpendCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchBusinessTransportSpendCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_BUSINESS_TRANSPORT_SPEND_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


//************  Upstream Logistics Spend ****************** //

export const postUpstreamLogisticsSpendBasedFile = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['UPSTREAM_LOGISTICS_SPENT_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_UPSTREAM_LOGISTICS_SPEND_BASED_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getUpstreamLogisticsSpendCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return null;
    }
}



export const getUpstreamLogisticsSpendFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_UPSTREAM_LOGISTICS_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getUpstreamLogisticsSpendCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_UPSTREAM_LOGISTICS_SPEND_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchUpstreamLogisticsSpendCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_UPSTREAM_LOGISTICS_SPEND_CATEGORY(id, data));
    if (res) {
        dispatch(getUpstreamLogisticsSpendCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchUpstreamLogisticsSpendCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_UPSTREAM_LOGISTICS_SPEND_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


//************  Downstream Logistics Spend ****************** //

export const postDownstreamLogisticsSpendBasedFile = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['DOWNSTREAM_LOGISTICS_SPEND_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_DOWNSTREAM_LOGISTICS_SPEND_BASED_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getDownstreamLogisticsSpendCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return null;
    }
}



export const getDownstreamLogisticsSpendFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DOWNSTREAM_LOGISTICS_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getDownstreamLogisticsSpendCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DOWNSTREAM_LOGISTICS_SPEND_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchDownstreamLogisticsSpendCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_DOWNSTREAM_LOGISTICS_SPEND_CATEGORY(id, data));
    if (res) {
        dispatch(getDownstreamLogisticsSpendCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchDownstreamLogisticsSpendCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_DOWNSTREAM_LOGISTICS_SPEND_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


//************  Business Stay Spend****************** //

export const postBusinessStaySpendBasedFile = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['BUSINESS_STAY_SPENT_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_BUSINESS_STAY_SPEND_BASED_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getBusinessStaySpendCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return
    }

}


export const getBusinessStaySpendFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_STAY_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getBusinessStaySpendCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_STAY_SPEND_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchBusinessStaySpendCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_BUSINESS_STAY_SPEND_CATEGORY(id, data));
    if (res) {
        dispatch(getBusinessStaySpendCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchBusinessStaySpendCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_BUSINESS_STAY_SPEND_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


//************  Waste Generated Quantity ****************** //

export const postWasteGeneratedQuantityData = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['WASTE_GENERATED_QUANTITY_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_WASTE_GENERATED_QUANTITY_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));
        console.log(taskRes,"taskres")
        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getWasteGeneratedQuantityCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return
    }

}

export const getWasteGeneratedQuantityFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_WASTE_GENERATED_QUANTITY_DATA_FEILDS());
    if (data) {
        console.log(data)
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getWasteGeneratedQuantityCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_WASTE_GENERATED_QUANTITY_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchWasteGeneratedQuantityCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_WASTE_GENERATED_QUANTITY_CATEGORY(id, data));
    if (res) {
        dispatch(getWasteGeneratedQuantityCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchWasteGeneratedQuantityCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_WASTE_GENERATED_QUANTITY_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}

export const getWasteGeneratedQuantityEmissionFactors = () => async (dispatch: AppDispatch) => {
        const [data, error] = await handleAPICall(GET_WASTE_GENERATED_QUANTITY_EMISION_FACTORS());
        if (data) {
            dispatch(setEmissionFactors({
                status: "success",
                data: data.data
            }));
        }
        else {
            console.log(error);
            dispatch(setEmissionFactors({
                status: "error",
                data: []
            }));
        }
    }


//************  End of Life Waste Treatment Quantity ****************** //

export const postEndOfLifeWasteTreatmentQuantityData = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getEndOfLifeWasteTreatmentQuantityCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return
    }

}


export const getEndOfLifeWasteTreatmentQuantityFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getEndOfLifeWasteTreatmentQuantityCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchEndOfLifeWasteTreatmentQuantityCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORY(id, data));
    if (res) {
        dispatch(getEndOfLifeWasteTreatmentQuantityCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchEndOfLifeWasteTreatmentQuantityCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}

export const getEndOfLifeWasteTreatmentQuantityEmissionFactors = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_END_OF_LIFE_WASTE_TREATMENT__QUANTITY_EMISION_FACTORS());
    if (data) {
        dispatch(setEmissionFactors({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setEmissionFactors({
            status: "error",
            data: []
        }));
    }
}


//************  Business Tranaport Passenger Distance ****************** //

export const postBusinessTransportPassengerDistanceData = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getBusinessTransportPassengerDistanceCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return
    }

}

export const getBusinessTransportPassengerDistanceFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getBusinessTransportPassengerDistanceCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchBusinessTransportPassengerDistanceCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORY(id, data));
    if (res) {
        dispatch(getBusinessTransportPassengerDistanceCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchBusinessTransportPassengerDistanceCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


//************  Upsream Logistics Freight Distance ****************** //

export const postUpstreamLogisticsFreightDistanceData = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getUpstreamLogisticsFreightDistanceCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return
    }

}


export const getUpstreamLogisticsFreightDistanceFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getUpstreamLogisticsFreightDistanceCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchUpstreamLogisticsFreightDistanceCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY(id, data));
    if (res) {
        dispatch(getUpstreamLogisticsFreightDistanceCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchUpstreamLogisticsFreightDistanceCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


//************  Downstream Logistics Freight Distance ****************** //

export const postDownstreamLogisticsFreightDistanceData = (data: any, fieldMap: any) => async (dispatch: AppDispatch) => {

    let dataToSend: any = { ...data, ...fieldMap };
    dataToSend['DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FILE'] = data.file;
    delete dataToSend.file;
    const [res, error] = await handleAPICall(POST_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA(dataToSend));
    if (res) {
        const taskRes = await dispatch(trackTaskStatus(res.data.task_id));

        if(taskRes){
            BEMessage.success("File Uploaded Successfully");
            dispatch(getTypeFiles(true));
            dispatch(getDownstreamLogisticsFreightDistanceCategories());
            return taskRes;
        }
        // return res;
    }
    else {
        console.log(error);
        return
    }

}


export const getDownstreamLogisticsFreightDistanceFeilds = () => async (dispatch: AppDispatch) => {
    dispatch(setSystemFeilds({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FEILDS());
    if (data) {
        dispatch(setSystemFeilds({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSystemFeilds({
            status: "error",
            data: []
        }));
    }
}

export const getDownstreamLogisticsFreightDistanceCategories = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    dispatch(setCatagoryConfigurations({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES(year));
    if (data) {
        dispatch(setCatagoryConfigurations({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setCatagoryConfigurations({
            status: "error",
            data: []
        }));
    }
}

export const patchDownstreamLogisticsFreightDistanceCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY(id, data));
    if (res) {
        dispatch(getDownstreamLogisticsFreightDistanceCategories());
        BEMessage.success("Category Updated Successfully");
        return res.data;
    }
    else {
        console.log(error);
        BEMessage.error("Category Updated Failed");
        return null;
    }
}

export const patchDownstreamLogisticsFreightDistanceCalculations = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const year = getState().common.date.currentYear;
    const [res, error] = await handleAPICall(PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS(year));
    if (res) {
        let task = await dispatch(trackTaskStatus(res.data.task_id));
        if(task){
            return task;
        }
        else return null;
    }
    else {
        console.log(error);
        return null;
    }
}


