import * as React from 'react';
import EditIcon from '../../../../../../../../../../Components/BEIcons/EditIcon';
import { NewBETable } from '../../../../../../../../../../Components/BETable/newBETable';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import {
  patchScope3TypeCalculations,
  patchScope3TypeCategory
} from '../../../../../../../../Actions';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../../../../../../Config/Theme/theames';
import { EditEmissionFactorDrawer } from '../../EditEmissionFactorDrawer';
import { Select } from 'antd';
import { AddCommaToNumber } from '../../../../../../../../../../Config/Functions/UsefullFunctions';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../../../Redux/Types/scope3Types';
import { CatagoryMap } from '../../../../../../../../Data/data';
import { Scope3TypeEditMeasurement } from '../../EditMeasurement/EditMeasurement';
import { setCurrentCategory } from '../../../../../../../../../../Redux/Scope3';
import { PROCUREMENT, QUANTITY_BASED } from '../../../../../../../../Data/constants';
import BEStatusTag from '../../../../../../../../../../Components/BEStatusTag';
import { EditExpenditureTypeDrawer } from '../SpendBased/EditExpenditureTypeDrawer';
import { EditUnitDrawer } from './EditUnitDrawer';

export interface IDataUploadStep4Props {
  calculate?: boolean
}



export function Scope3TypeQuantityBasedConfiguration(props: IDataUploadStep4Props) {
  const dispatch = useAppDispatch();
  const [fullDetails, setFullDetails] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [currentRow, setCurrentRow] = React.useState<any>({});
  const procurementSpendCategories = useTypedSelector(state => state.scope3.catagoryConfigurations)
  const procurementExpenditureTypes = useTypedSelector(state => state.scope3.expenditureTypesReference)
  const [loadingSelect, setLoadingSelect] = React.useState<boolean>(false)
  const [openExpenditureEditDrawer,setOpenExpenditureEditDrawer]=React.useState<boolean>(false)
  const [openUnitOfMeasurementDrawer,setOpenUnitOfMeasurementDrawer]=React.useState<boolean>(false)
  const [openEditDrawer, setOpenEditDrawer] = React.useState<boolean>(false)
  const [openEditMesurementDrawer, setOpenEditMesurementDrawer] = React.useState<boolean>(false)
  const [loadingCalculate, setLoadingCalculate] = React.useState<boolean>(false)
  const { type } = useParams<{ type: Scope3TypeType }>()

  console.log(currentRow)
  const columns: ColumnsType<any> = [
    {
      title: CatagoryMap[type as Scope3TypeType][QUANTITY_BASED].title,
      dataIndex: CatagoryMap[type as Scope3TypeType][QUANTITY_BASED].dataIndex,
    },
    // {
    //   title: 'Total Quantity',
    //   dataIndex: 'quantity',
    //   render: (text: any, record: any) => text && AddCommaToNumber(text, false, true, true)
    // },
    // {
    //   title: '% of Quantity',
    //   dataIndex: 'percentage_quantity',
    //   render: (text: any, record: any) => text && text.toFixed(2) + '%',
    //   sorter: (a: any, b: any) => (a.percentage_spend > b.percentage_spend) ? 1 : -1
    // },
    {
      title: 'Unit of Measurement',
      dataIndex: 'unit',
      render: (text: any, record: any) => {
        return (
          <div style={{display:'flex',justifyContent:'space-between', width:'60%'}}>
            <div>{text==="other" ? "custom": text}</div>
            <div style={{ height: '1rem', cursor: 'pointer' }}
             onMouseEnter={() => {
              setCurrentRow(record)
              dispatch(setCurrentCategory(record))
             }}  onClick={() => setOpenUnitOfMeasurementDrawer(true)}><EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
          </div>
        )
      }
    },{
      title:'Formula',
      render:(text:any,record:any)=>
        record.unit !== 'other' ? '-':
        record.formula
    },
    {
      title: 'Formula Selection',
      dataIndex: 'unit',
      render: (text: any, record: any) => <div
        onMouseEnter={() => dispatch(setCurrentCategory(record))}
        style={{ height: '1rem',width:'max-content', cursor: `${record.unit === 'other' ? 'pointer' : 'not-allowed'}` }}
        onClick={() => {
          if (record.unit === 'other') {
            setOpenEditMesurementDrawer(true)
          }
        }}
      ><EditIcon inheritSize stroke={record.unit !== 'other' ? PrimaryTheme.secondaryGray : PrimaryTheme.primaryGray} /></div>
    },{
      title:'Quantity Conversion Status',
      render:(text:any,record:any)=>{
        return <>
         {record.unit !== 'other' ?
           <BEStatusTag status={ 'success' }>
             <span>CONFIGURED</span>
           </BEStatusTag>
         :
           <BEStatusTag status={ record.formula ? 'success' : 'error' }>
           <span>{ record.formula ? 'CONFIGURED' : 'PENDING'}</span>
         </BEStatusTag>
         }
         </>
     }
    },
    {
      title: 'Expenditure Type',
      dataIndex: 'expenditure_type',
      render: (text: any, record: any) => {
        return (
          <div style={{display:'flex',justifyContent:'space-between', width:'90%'}}>
            <div>{text}</div>
            <div style={{ height: '1rem', cursor: 'pointer' }}
             onMouseEnter={() => setCurrentRow(record)}  onClick={() => setOpenExpenditureEditDrawer(true)}><EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
          </div>
        )
      }
    },
    {
      title: 'Emission Factor Status',
      render: (text: any, record: any) =>  <BEStatusTag status={record.configured ? 'success' : 'error'}>
        <span>{record.configured ? 'COMPLETED' : 'PENDING'}</span>
      </BEStatusTag> 
      
    },
    {
      title: 'Emission Factor Selection',
      dataIndex: 'emission_factor',
      render: (text: any, record: any) => {
        return <div style={{ height: '1rem',display:'flex',width:'max-content', cursor: 'pointer' }}
          onMouseEnter={() => setCurrentRow(record)} onClick={() => setOpenEditDrawer(true)}
        ><EditIcon inheritSize stroke={PrimaryTheme.primaryGray} /></div>
      }
    }
  ]



  React.useEffect(() => {
    if (procurementSpendCategories.status === 'success') {
      setTableData(procurementSpendCategories.data);
    }
  }, [procurementSpendCategories]);

  return (
    <div className={'scope3-type-configuration-settings'}>
      <NewBETable
        pagination
        loading={procurementSpendCategories.status === 'loading'}
        TableTopRightComponents={
          <>
            {
              props.calculate &&
              <BEButton className='primary' size='large'
                loading={loadingCalculate}
                onClick={async () => {
                  setLoadingCalculate(true);
                  await dispatch(patchScope3TypeCalculations(type as Scope3TypeType));
                  setLoadingCalculate(false);
                }}
              >Calculate</BEButton>
            }
          </>
        }
        search
        data={[...tableData].sort((a: any, b: any) => a.id - b.id)}
        columns={type !== PROCUREMENT ? [...columns.slice(0, 5), ...columns.slice(6)] : columns}
      />
      <EditEmissionFactorDrawer open={openEditDrawer} setOpen={setOpenEditDrawer} data={currentRow} />
      <Scope3TypeEditMeasurement open={openEditMesurementDrawer} setOpen={setOpenEditMesurementDrawer} data={currentRow} />
      <EditExpenditureTypeDrawer open={openExpenditureEditDrawer} setOpen={setOpenExpenditureEditDrawer} data={currentRow}/>
      <EditUnitDrawer open={openUnitOfMeasurementDrawer} setOpen={setOpenUnitOfMeasurementDrawer} setOpenFormulaSelection={setOpenEditMesurementDrawer} data={currentRow}/>
    </div>
  );
}


