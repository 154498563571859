import { clearStore } from "../ExtraReducrActions";
import { userManagementTypes } from "../Types/userManagementTypes";

import { Reducer, createSlice } from "@reduxjs/toolkit";

const initialState: userManagementTypes = {
  status: 'idle',
  addStatus: 'idle',
  data: []
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setUsers: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status
    },
    setAddUser: (state, action) => {
      state.addStatus = action.payload.addStatus
    }
  },
});

export const { setUsers, setAddUser } = userManagementSlice.actions;

const userManagementReducer: Reducer<userManagementTypes> =
  userManagementSlice.reducer;

export default userManagementReducer;
