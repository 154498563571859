import {
  ONBOARDING_STEP_1,
  GET_INDUSTRIES,
  GET_COMPANY_DETAILS,
  GET_STAGE,
  UPDATE_STAGE,
  UPDATE_COMPANY_DETAILS,
} from "../../Utils/Routes/Onboarding";
import { GET_ALL_COMPANIES,PATCH_INTERNAL_USER_COMPANY } from "../../Utils/Routes/UserRouts";
import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import {
  setIndustries,
  setOnBoardingStep1,
} from "../../Redux/OnboardingReducer";
import { setCompanies,setCompanyDetails,setYearEnd } from "../../Redux/CompaniesReducer";
import { setOnboardingStage } from "../../Redux/CommonReducer";
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import dayjs from "dayjs";


export const getAllIndustries = () => async (dispatch: AppDispatch) => {
    dispatch(
      setIndustries({
        status: "loading",
        data: [],
      })
    );
  const [data, error] = await handleAPICall(GET_INDUSTRIES());

  if (data) {
    console.log(data);
    dispatch(
      setIndustries({
        status: "success",
        data: data.data,
      })
    );
  } else {
    console.log(error);
    dispatch(
      setIndustries({
        status: "error",
        data: [],
      })
    );
  }
};


export const completeOnboardingStep1 = (body: any) => async (dispatch: AppDispatch) => {
    dispatch(
        setOnBoardingStep1({
            status: "loading",
            data: null,
        })
    );
    
    const [data, error] = await handleAPICall(ONBOARDING_STEP_1(body));

    if (data) {
        console.log(data);
        dispatch(
            setOnBoardingStep1({
                status: "success",
                data: data.data,
            })
        );
      BEMessage.success('Successfully added company details!')
      // await dispatch(updateOnboardingStage(0));
      navigateTo("/onboarding/step2");
    } else {
      console.log(error);
      if (error.response.data?.error) {
        BEMessage.error(error.response.data.error)
      }
      else if (error.response.data?.detail) {
        BEMessage.error(error.response.data.details)
      }
      else  BEMessage.error("Couldn't save company details")
        dispatch(
            setOnBoardingStep1({
                status: "error",
                data: null,
            })
        );
    }
}


export const getAllCompanies = () => async (dispatch:AppDispatch) => {
    dispatch(setCompanies({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_COMPANIES());
    if (data) {
        console.log(data);
        dispatch(setCompanies({
            status: "success",
            data: data.data
        }));
    }
    else{
        console.log(error);
        dispatch(setCompanies({
            status: "error",
            data: []
        }));
    }
}


export const getCompanyDetails = () => async (dispatch:AppDispatch) => {
    dispatch(setCompanyDetails({
            status: "loading",
            data: null
        }));
    const [data, error] = await handleAPICall(GET_COMPANY_DETAILS());
    if (data) {
        console.log(data);
        dispatch(setYearEnd(
          Number(dayjs(data.company_profile.reporting_year_end).format('MM'))
        ));
        dispatch(setCompanyDetails({
            status: "success",
            data: data.company_profile
        }));
    }
    else{
        console.log(error);
        dispatch(setCompanyDetails({
            status: "error",
            data: null
        }));
    }
}

export const updateCompanyDetails = (body:any,id:number,isFormData:boolean) => async (dispatch: AppDispatch) => {
  dispatch(
    setOnBoardingStep1({
      status: "loading",
      data: null,
    })
  );

  const [data, error] = await handleAPICall(UPDATE_COMPANY_DETAILS(body,id,isFormData));

  if (data) {
    console.log(data);
    dispatch(
      setOnBoardingStep1({
        status: "success",
        data: data.data,
      })
    );
    dispatch(getCompanyDetails());
    BEMessage.success("Successfully updated company details!");
  } else {
    console.log(error);
    BEMessage.error(error?.response?.data?.detail);
    dispatch(
      setOnBoardingStep1({
        status: "error",
        data: null,
      })
    );
  }
};

export const getOnboardingStage = () => async (dispatch: AppDispatch) => {
  dispatch(
    setOnboardingStage({
      status: "loading",
      stage: 0,
    })
  );
  const [data, error] = await handleAPICall(GET_STAGE());
  console.log('Inside handler')
  if (data) {
    console.log(data)
    dispatch(setOnboardingStage({
      status: 'success',
      stage: data.data.stage
    }))
  }
  else {
    console.log(data);
    dispatch(
      setOnboardingStage({
        status: "error",
        stage: 0,
      })
    );
    BEMessage.error("Couldn't fetch account stage for onboarding")
  }
};

export const updateOnboardingStage = (stage:number) => async (dispatch: AppDispatch) => { 
  const [data, error] = await handleAPICall(UPDATE_STAGE({stage:stage+1}));
  // console.log("Inside handler");
  if (data) {
    if(stage===3) BEMessage.success('Onboarding completed successfully!')
    dispatch(
      setOnboardingStage({
        status: "success",
        stage: stage+1,
      })
    );
  } else {
    console.log(error);
    dispatch(
      setOnboardingStage({
        status: "error",
        stage: stage,
      })
    );
  }
}
