import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAppDispatch } from "../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../Config/Hooks/useTypedSelector";
import useScrollToTop from "../Config/Hooks/scrollToTop";
import { GlobalLoader } from "../Components/GlobalLoader";
import { Suspense } from "react";
import PrivateRoute from "../Config/PrivateRoute";
import { Login } from "../views/Login";
import { useEffect } from "react";
import { AutoLogin } from "../Config/Functions/Autologin";
import { setNavigateFunction } from "./NavigationManager";
import { useNavigate, useLocation } from "react-router-dom";
import { ChooseCompany } from "../views/Login/ChooseCompany";
import { OnboardingView } from "../views/Onboarding/OnboardingView";
import Step1 from "../views/Onboarding/Step1";
import { Step2 } from "../views/Onboarding/Step2";
import Step3 from "../views/Onboarding/Step3";
import HomeLayout from "../views/Home/Layout";
import { TestComponent } from "../views/Test";

import { NoPermission } from "../views/Miscellaneous/NoPermission";
import DashboardLayout from "../Features/Dashboard/Components/DashboardLayout";
import Scope3Layout from "../Features/Scope3/Components/Scope3Layout";
import { Scope3View } from "../Features/Scope3/Views/Scope3View";
import { Scope3TypeViewData } from "../Features/Scope3/Views/Scope3Type/ViewData";
import { Scope3types } from "../Features/Scope3/Views/Scope3Type";

import { MarketplaceLayout } from "../Features/Marketplace/Components/MarketPlaceLayout";
import { MarketPlaceDashbord } from "../Features/Marketplace/Views/MarketPlaceDashbord";
import Settings from "../Features/Settings";
import Users from "../Features/Settings/Views/Users";
import { Logs } from "../Features/Settings/Views/Logs";
import GeneralSettings from "../Features/Settings/Views/GeneralSettings";
import Overview from "../Features/Dashboard/Views/DashboardView";
import { EmissionFactorSources } from "../Features/Settings/Views/EmissionFactorSources";
import { EmissionFactorSourceTypes } from "../Features/Settings/Views/EmissionFactorSources/EmissionFactorSourcesType";

function RootRoutes() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    dispatch(AutoLogin(navigate, location));
    setNavigateFunction(navigate);
  }, []);
  useScrollToTop();
  return (
    //fallback page to be added
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" />
        <Route path="/login" element={<Login />} />

        {/********* ESG_CONSULTANT routes ***********/}
        <Route path="/choose-company" element={<PrivateRoute roles={["ESG_CONSULTANT"]}><ChooseCompany /></PrivateRoute>} />

        {/* onboarding routes ************************/}
        <Route
          path="/onboarding"
          element={
            <PrivateRoute roles={["SUPER_ADMIN", "ADMIN", "ESG_CONSULTANT"]}>
              <OnboardingView />
            </PrivateRoute>
          }
        >
          <Route path="step1" element={<Step1 />} />
          <Route path="step2" element={<Step3 />} />
          <Route path="step3" element={<Step2 />} />
        </Route>

        {/* redirect routes ********************** use this to rediret if not logged in **/}
        <Route element={<PrivateRoute roles={["SUPER_ADMIN", "ADMIN", "ESG_CONSULTANT"]} rediectIfNotLoggedIn />}>

        </Route>

        {/* home routes ************************/}

        <Route element={<PrivateRoute roles={["ADMIN", "SUPER_ADMIN", "ESG_CONSULTANT"]} />}>
        {/* results page ***********************/}

          <Route element={<PrivateRoute roles={["ADMIN", "SUPER_ADMIN", "ESG_CONSULTANT"]} />}>
            <Route path='/results'>

            </Route>
          </Route>

          <Route path="/home" element={<HomeLayout />}>

            {/* dashboard routes */}
            <Route path="dashboard" element={<DashboardLayout />} >
              <Route path="" element={< Overview/>} />
            </Route>

            {/* scope3 routes */}
            <Route path="scope-3" element={<Scope3Layout />} >
              <Route path="" element={<Scope3View/>} />
              <Route path=":type" >
                <Route path="" element={<Scope3types />} />
                <Route path="view-data" element={<Scope3TypeViewData/>} />
              </Route>
            </Route>

            {/* marketplace routes */}
            <Route path="marketplace" element={<MarketplaceLayout />} >
              <Route path="" element={<MarketPlaceDashbord />} />
            </Route>

            {/* settings routes */}
            <Route element={<PrivateRoute roles={['ADMIN','SUPER_ADMIN']} />}>
            <Route path="settings" element={<Settings />} >
              <Route path="users" element={<Users />} />
              <Route path="logs" element={<Logs />} />
              <Route path="general-settings" element={<GeneralSettings />} />
              <Route path="emission-factor-sources">
                <Route path="" element={<EmissionFactorSources />} />
                <Route path=":source" element={<EmissionFactorSourceTypes/>}/>
              </Route>
            </Route>
          </Route>

          </Route>
        </Route>

        {/* ***************open routes***************/}

        <Route path="/test" element={<TestComponent />} />
        {/* ***********************************/}
        <Route path="/no-permission" element={<NoPermission />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Suspense>
  );
}

export default RootRoutes;
