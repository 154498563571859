import React from "react";
import IconProps from "../Interface/IconProps";

const BedIcon = (props: IconProps) => {
  return (
    <svg width={props.inheritSize ? "100%" : 18} height={props.inheritSize ? "100%" : 18}
      viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0316 2.87109H1.65662V0.996094C1.65662 0.830333 1.59077 0.671362 1.47356 0.554152C1.35635 0.436942 1.19738 0.371094 1.03162 0.371094C0.865856 0.371094 0.706885 0.436942 0.589674 0.554152C0.472464 0.671362 0.406616 0.830333 0.406616 0.996094V13.4961C0.406616 13.6619 0.472464 13.8208 0.589674 13.938C0.706885 14.0552 0.865856 14.1211 1.03162 14.1211C1.19738 14.1211 1.35635 14.0552 1.47356 13.938C1.59077 13.8208 1.65662 13.6619 1.65662 13.4961V10.9961H17.9066V13.4961C17.9066 13.6619 17.9725 13.8208 18.0897 13.938C18.2069 14.0552 18.3659 14.1211 18.5316 14.1211C18.6974 14.1211 18.8563 14.0552 18.9736 13.938C19.0908 13.8208 19.1566 13.6619 19.1566 13.4961V5.99609C19.1566 5.16729 18.8274 4.37244 18.2413 3.78639C17.6553 3.20033 16.8604 2.87109 16.0316 2.87109ZM1.65662 4.12109H7.28162V9.74609H1.65662V4.12109ZM8.53162 9.74609V4.12109H16.0316C16.5289 4.12109 17.0058 4.31864 17.3574 4.67027C17.7091 5.0219 17.9066 5.49881 17.9066 5.99609V9.74609H8.53162Z"
        fill={props.fill ? props.fill : "gray"}
      />
    </svg>
  );
};

export default BedIcon;