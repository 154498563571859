import * as React from 'react';
import './style.scss';
import Logo from '../../../assets/images/Company/Logo.png'
import { EntitySelectCascader } from './EntitySelectCascader';
import { Notification } from './Notification';
import { UserTopNav } from './UserTopNav';
import { useLocation } from 'react-router-dom';
import { CatagoriesMenu } from './CatagoriesMenu';

export interface ITopMenubarProps {
}


export function TopMenubar (props: ITopMenubarProps) {
  const location = useLocation();
  const [activeFeature, setActiveFeature] = React.useState('Dashboard');

  React.useEffect(() => {
    const path = location.pathname.split('/')[2];
    const name=path?.replace(/-/g, ' ')
    setActiveFeature(name?.charAt(0).toUpperCase()+ name?.slice(1));
  }, [location.pathname])
  return (
    <div className='top-menu-bar'>
        <img className='logo' src={Logo} alt="Logo" />
        <p className='name'>{activeFeature}</p>
        <EntitySelectCascader />
        <CatagoriesMenu/>
        <Notification/>
        <UserTopNav/>
    </div>
  );
}
