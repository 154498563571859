import { Progress } from 'antd'
import React from 'react'
import { PrimaryTheme } from '../../../Config/Theme/theames'
import { navigateTo } from '../../../Navigation/NavigationManager';
import ToolTip from '../../BEToolTip';
import { AddCommaToNumber } from '../../../Config/Functions/UsefullFunctions';


export interface IDashboardCardProps {
    metricName: string;
    metricValue: number;
    metricUnit: string;
    targetPercent?: number;
}

export function DashboardCard(props: IDashboardCardProps) {
    return (
        <div style={{ padding: '1rem', width: "calc(100% - 2rem)",
            border: `1px solid ${PrimaryTheme.primaryGreyLight}`,
            borderRadius: '6px', display: "flex",
            // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            justifyContent: "center", alignItems: "center"
        }}>
            <div style={{ borderRadius: '6px', width: "100%", backgroundColor: "white", display: "flex", gap: "1rem", flexDirection: "column" }}>
                <span style={{ fontSize: "14px", lineHeight: "12px", color: "#101010", fontWeight: "600", opacity: "50%" }}>
                <ToolTip title={props.metricName}>  {props.metricName.slice(0, 26)?.toUpperCase()}{Number(props.metricName?.length) > 26 && " ..."}</ToolTip>
                </span>
                <span style={{ fontSize: "20px", lineHeight: "32px", color: "#101010", fontWeight: "400" }}>{AddCommaToNumber(props.metricValue, false, true)}{" "}{props.metricUnit}</span>
                {
                    props.targetPercent !== undefined &&
                    (props.targetPercent === -1 ?
                        <div style={{fontSize: "small",
                            lineHeight: "18px", color: PrimaryTheme.primaryGreen
                        }}>
                            <div>Please set target from{" "}</div>
                            <div
                                style={{ textDecoration: "underline", color: PrimaryTheme.primaryGreenDark, cursor: "pointer"}}
                                onClick={() => navigateTo("/home/targets")}
                            >
                                Targets and limits
                            </div>
                        </div> :
                        <div style={{ display: "flex", flexDirection: "column", width: 180 }}>
                            <div style={{ display: "flex", flexDirection: "row", fontSize: "12px", lineHeight: "15px", fontWeight: "400" }}>
                                Target: {props.targetPercent}{" "}%
                            </div>
                            <Progress
                                strokeColor={PrimaryTheme.primaryGreen}
                                percent={props.targetPercent}
                                showInfo={false}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}
