import * as React from 'react';
import './style.scss';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';

export interface IMarketPlaceDashbordProps {
}

export function MarketPlaceDashbord (props: IMarketPlaceDashbordProps) {
  return (
    <div className='marketplace-dashbord' >
      <div className='search-bar'>
        <BEInput placeholder='Search here' searchForColumns={{dis:'sss'}} size='large' style={{width:'313px'}} columnSearchValue='sss'/>
      </div>
      <div className='listed-items'>

      </div>
    </div>
  );
}
