import * as React from 'react';
import { useAppDispatch } from '../Config/Hooks/useAppDispatch';
import EditableTable, { EditableColumnTypes } from '../Components/BETable/ExcelLikeTable';
import { AddCommaToNumber } from '../Config/Functions/UsefullFunctions';
import { BEMessage } from '../Components/BEMessage';

export interface ITestComponentProps {
}

export function TestComponent(props: ITestComponentProps) {
  const dispatch = useAppDispatch();



  return (
    <div>
    </div>
  );
}
