import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { BETable } from "../../../../Components/BETable";
import "../styles.scss";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import { Col, Row } from "antd";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { deleteUser } from "../../../Actions/UsersManagementActions";
import { BEMessage } from "../../../../Components/BEMessage";
import { BEInput } from "../../../../Components/BEFormItems/BEInput";
import { DELETE_USER } from "../../../../Utils/Routes/UserManagementRoutes";
import BEConfirmModal from "../../../../Components/BEConfirmModal";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { roleMap } from "../../../../Config/Data/UsersMap";
import type { Role } from "../../../../Redux/Types/userTypes";
import DownloadTemplate from "../../../../Utils/Download/Excell/DownloadTemplate";
import Upload from "antd/es/upload/Upload";
import { bulkUploadUsers } from "../../../Actions/UsersManagementActions";
import { BEBulkUploadDrawer } from "../../../../Components/BEDrawer/BEBulkUploadDrawer";
import { BEWrapper } from "../../../../Components/BEWrapper";

interface BEUserListTableProps {
  users: any[];
  setAddUser: Function;
  setEditUser: Function;
  loading?: boolean;
}

const columns: ColumnsType<any> = [
  {
    title: "NAME",
    dataIndex: "name",
    sorter: (a, b) => (a < b ? -1 : 1),
    render: (value, record) => {
      return `${record.first_name} ${record.last_name}`;
    },
  },
  {
    title: "EMAIL",
    dataIndex: "email",
  },
  {
    title: "ROLE",
    dataIndex: "role",
    sorter: (a, b) => (a < b ? -1 : 1),
    render: (value : Role , record) => {
      return value ? roleMap[value] : "";
    }
  },
  {
    title: "DEPARTMENT",
    dataIndex: "department",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (value: boolean) => {
      return value?'Active':'Inactisve'
    },
  },
];

const UserListTable = (props: BEUserListTableProps) => {
  const [showDelete, setShowDelete] = useState(false);
  const currentUser = useTypedSelector((state) => state.common.selectedRow);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    await dispatch(deleteUser(currentUser.id));
  };

  const bulkUpload = async (file: any) => {
    const formData = {
      User_bulk_upload_template: file,
    }
    await dispatch(bulkUploadUsers(formData));
  }



  return (
    <BEWrapper style={{marginTop:'1rem',border:'none'}}>
      <div className="list-table">
        <BEConfirmModal
          title="Confirm Delete"
          message="Are you sure you want to delete this user"
          visible={showDelete}
          setVisible={setShowDelete}
          onConfirm={handleDelete}
        />
        <Row className="header-users">
          <Col span={16}>
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              All Users ({props.users.length})
              <BEInput
                placeholder="Search for users"
                style={{ width: "313px", marginLeft: "10px" }}
                search
                size="large"
              />
            </div>
          </Col>
          <Col span={8} style={{ display: "flex", justifyContent: "flex-end",gap:'1rem' }}>
            <BEButton
              size="large"
              className="primary"
              onClick={() => props.setAddUser(true)}
            >
              + Add User(s)
            </BEButton>
  {/* 
            <BEButton size="large" className="primary"
                onClick={()=>{
                  DownloadTemplate(['first_name','last_name','email','role','department'])
                }}
              >
                Download Template
              </BEButton> */}
  {/* 
              <Upload
                  beforeUpload={(e) => {
                    bulkUpload(e);
                    return false;
                  }}
                  action={undefined}
                  showUploadList={false}
                  multiple={false}
                  fileList={[]}
                >
                  {" "}
                  <BEButton size="large" className="primary">
                   Bulk Upload
                  </BEButton>
                </Upload> */}
                <BEButton size="large" className="primary"
                onClick={()=>{
                  setOpenBulkUpload(true);
                }}
                >
                  Bulk Upload
                </BEButton>
          </Col>
        </Row>
        <BETable
          data={props.users.slice().sort((a,b)=>a.first_name?.localeCompare(b.first_name))}
          columns={columns}
          loading={props.loading}
          handleEdit={() => {
            props.setEditUser(true);
          }}
          tableProps={{ rowKey: "id" }}
          handleDelete={() => {
            setShowDelete(true);
          }}
          pagination
        />
          <BEBulkUploadDrawer
            fileKey="User_bulk_upload_template"
            heading='Bulk Upload Users'
            open={openBulkUpload}
            setOpen={setOpenBulkUpload}
            uploadFunction={bulkUploadUsers}
            fileName='UsersTemplate'
            columnArray={['first_name', 'last_name', 'email', 'department', 'role']}
          />
      </div>
     </BEWrapper>
  );
};

export default UserListTable;
