import * as React from 'react';
import FileUpload from '../../../../../../../../Components/BEFileUpload/FileUpload';
import { LableRequired } from '../../../../../../../../Components/BEFormItems/LableRequired';
import { Form, Select } from 'antd';
import { BEInput } from '../../../../../../../../Components/BEFormItems/BEInput';
import { BETextArea } from '../../../../../../../../Components/BEFormItems/BETextArea';
import { getColumnHeadings } from '../../../../../../../../Utils/Download/Excell/ExportExcell';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import { setDataUploadFileData,setExcleHeaders } from '../../../../../../../../Redux/Scope3';
import { BEMessage } from '../../../../../../../../Components/BEMessage';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { showFYFormatByYear } from '../../../../../../../../Config/Functions/UsefullFunctions';
import { currencyList } from '../../../../../../../../Config/Data/genralData';
import { SPEND_BASED } from '../../../../../../Data/constants';
import InfoIcon from '../../../../../../../../Components/BEIcons/InfoIcon';
import { PrimaryTheme } from '../../../../../../../../Config/Theme/theames';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../Redux/Types/scope3Types';

export interface IDataUploadStep1Props {
  setStep: Function;
}

const DataUploadStep1: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IDataUploadStep1Props> = (props, ref) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = React.useState<File | null>(null)
  const [form] = Form.useForm();
  const year = useTypedSelector(state => state.common.date.currentYear);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const files = useTypedSelector(state => state.scope3.typeFiles);
  const basedOn = useTypedSelector(state => state.scope3.basedOn);
  const {type} = useParams<{type:Scope3TypeType}>();

  const downloadSampleFile = () => {
      const link = document.createElement('a');
      
      // files are saved in public/Samples
      //... add more files if in format of {type} {basedOn} sample-file.xlsx
      
      link.href = `/Samples/${type} ${basedOn} sample-file.xlsx`;
      console.log('link', link.href)
      link.setAttribute('download', `${type} ${basedOn} sample-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  const onButtonClick = async () => {
    if (!file) return BEMessage.error('Please upload a file');
    if (!form.getFieldValue('file_name')) return BEMessage.error('Please enter file name');

    let headings = await getColumnHeadings(file);
    //check duplicate columns
    let duplicateColumns = headings.filter((item, index) => headings.indexOf(item) !== index);
    if (duplicateColumns.length > 0) return BEMessage.error(`Duplicate column found: ${duplicateColumns.join(', ')}`);
    const fileNames = files.data.map((file: any) => file.name);
    if(fileNames.includes(form.getFieldValue('file_name'))) return BEMessage.error('File name already exists');
    if(!form.getFieldValue('currency') && basedOn === SPEND_BASED) return BEMessage.error('Please select a currency');
    
    dispatch(setExcleHeaders(headings));
    dispatch(setDataUploadFileData({
      file: file,
      ...form.getFieldsValue(),
      year: year,
    }));
    props.setStep(2);
  }

  React.useImperativeHandle(ref, () => ({ onButtonClick }), [file, form]);
  return (
    <div className='data-upload-step1'>
      <Form form={form}>

        <LableRequired>Upload File</LableRequired>
        <FileUpload
          width='100%'
          handleFileChange={(file: File) => setFile(file)}
          text='Upload your data file'
          hint='Please upload only XLS or XLSX , Max file size is 20MB'
          fileType={'excel'}
          fileSize={20 * 1024 * 1024}
        /><br />
        <div className='sample-download'>
          <div className='icon'><InfoIcon inheritSize fill={PrimaryTheme.primaryGray} /></div>
          <p><span
          onClick={downloadSampleFile}
          >Click here</span> to download and review sample file</p>
        </div>

        <LableRequired>File Name</LableRequired>
        <Form.Item name='file_name'>
          <BEInput name='file_name' placeholder='Enter File Name' size='large' />
        </Form.Item>

        <LableRequired>Year</LableRequired>
        <BEInput value={showFYFormatByYear(year, yearEnd || '12-31')} disabled size='large' />
        <br/><br/>

        {
          basedOn === SPEND_BASED &&
          <>
          <p>Currency</p>
          <Form.Item name='currency'>
            <Select size='large' placeholder='Select Currency'>
              {
                currencyList.map((item, index) => (
                  <Select.Option key={index} value={item}>{item}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </>
        }

        <p>Description</p>
        <Form.Item  name='description'>
          <BETextArea rows={5} form={form} name='description' placeholder='Enter Description' size='large'/>
        </Form.Item>

       

      </Form>
    </div>
  );
}

export default React.forwardRef(DataUploadStep1);