import React from 'react'
import IconProps from './Interface/IconProps';

const Analytics = (props:IconProps) => {
  return (
    <svg  width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20}
    viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.7168 6.90829L10.0002 6.12496L11.7168 5.34163L12.5002 3.62496L13.2835 5.34163L15.0002 6.12496L13.2835 6.90829L12.5002 8.62496L11.7168 6.90829ZM3.3335 10.2916L4.11683 8.57496L5.8335 7.79163L4.11683 7.00829L3.3335 5.29163L2.55016 7.00829L0.833496 7.79163L2.55016 8.57496L3.3335 10.2916ZM7.0835 6.12496L7.99183 4.11663L10.0002 3.20829L7.99183 2.29996L7.0835 0.291626L6.17516 2.29996L4.16683 3.20829L6.17516 4.11663L7.0835 6.12496ZM3.75016 15.7083L8.75016 10.7L12.0835 14.0333L19.1668 6.06663L17.9918 4.89163L12.0835 11.5333L8.75016 8.19996L2.50016 14.4583L3.75016 15.7083Z" fill="white"/>
  </svg>
  );
}

export default Analytics