import * as React from 'react';
import { BEDrawer } from '../../../../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import './style.scss';
import SelectFormula  from './Steps/SelectFormula';
import AddNewFormula  from './Steps/AddNewFormula';
import { BEMessage } from '../../../../../../../../../Components/BEMessage';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../../../../Config/Hooks/useTypedSelector';
import { getQuantityFormulas, getQuantityFormulaVariables, patchScope3TypeCategory } from '../../../../../../../Actions';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { CatagoryMap } from '../../../../../../../Data/data';


export interface IScope3TypeEditMeasurementProps {
  open: boolean;
  setOpen: Function;
  data:any;
}

export function Scope3TypeEditMeasurement(props: IScope3TypeEditMeasurementProps) {
  const dispatch = useAppDispatch();
  const [step, setStep] = React.useState<number>(1);
  const [selectedFormula, setSelectedFormula] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const currentCategory = useTypedSelector(state => state.scope3.currentCategory);
  const basedOn = useTypedSelector(state => state.scope3.basedOn);
  const { type } = useParams<{ type: Scope3TypeType }>();
  const step1Ref = React.useRef<any>({ onButtonClick: () => { } });
  const step2Ref = React.useRef<any>({ onButtonClick: () => { } });

  React.useEffect(() => {
    setStep(1);
  }, [props.open]);

  React.useEffect(() => {
    dispatch(getQuantityFormulas());
    dispatch(getQuantityFormulaVariables());
  }, []);

  return (
    <BEDrawer
      width='761px'
      open={props.open}
      setOpen={props.setOpen}
      heading='Define quantity conversion logic'
      secondryHeading={{
        heading:`${basedOn && props.data[CatagoryMap[type as Scope3TypeType][basedOn].dataIndex]}`,
      }}
      footer={
        <>
          <BEButton size='large'
          loading={loading}
          className='primary' onClick={async () => {
              setLoading(true);
              if(step === 1) await step1Ref.current.onButtonClick();
              if(step === 2) await step2Ref.current.onButtonClick();
              setLoading(false);
          }}>Save</BEButton>
          <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
        </>
      }
    >
      {
        step === 1 ?
          <SelectFormula setStep={setStep} setOpen={props.setOpen} ref={step1Ref} open={props.open}/> :
          <AddNewFormula setStep={setStep} ref={step2Ref} setOpen={props.setOpen}/>
      }
    </BEDrawer>
  );
}
