
import URLIcon from "../../../../../../../Components/BEIcons/URLIcon";
import { PrimaryTheme } from "../../../../../../../Config/Theme/theames";
import { Header } from "../../../../../Data/emissionfactorsources";

interface EFSHeaderProps{
	header:Header
}
export function EFSHeader(props:EFSHeaderProps){
	return( 
	<>
	<div style={{backgroundColor:'white',paddingLeft:'1.5rem'}}>
		<p style={{fontSize:'2em', fontWeight:600,margin:'1rem 0rem'}}>{props.header.sourceName}</p>
		<p style={{color:PrimaryTheme.primaryGray}}>{props.header.sourceDescription}</p>
		<div style={{padding:'1.5rem 0rem 2rem 0rem'}}>
			<a target="_blank" href={props.header.sourcelink.link} style={{color:PrimaryTheme.primaryBlueInfo,textDecoration:'underline',display:'flex'}}>{props.header.sourcelink.placeholder}<span style={{height:'12px',width:'12px', marginLeft:'5px',display:'block'}}><URLIcon inheritSize fill={PrimaryTheme.primaryBlueInfo}/></span></a>
		</div>
	</div>
	</>
	)
}