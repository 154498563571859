import { Modal } from 'antd';
import * as React from 'react';
import ChangeCalculationMethod from '../../../Components/ChangeCalculationMethod';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { navigateTo } from '../../../../../Navigation/NavigationManager';
import './style.scss';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../Redux/Types/scope3Types';

export interface ISelectBasedOnModalProps {
}

export function SelectBasedOnModal (props: ISelectBasedOnModalProps) {
  const chooseMethodRef = React.useRef<any>({ onButtonClick: () => { } })
  const [loading, setLoading] = React.useState<boolean>(false)
  const { type } = useParams<{ type: Scope3TypeType }>();

  return (
    <div>
      <Modal
        footer={<div style={{display:'flex',gap:'0.5rem', justifyContent:'flex-start'}}>
          <BEButton className='primary' size='large' loading={loading}
            onClick={async () => {
              setLoading(true);
              await chooseMethodRef.current.onButtonClick();
              setLoading(false);
            }}>
            Save
          </BEButton>
          <BEButton size='large'
            onClick={() => {
              navigateTo('home/dashboard');
            }}
          >
            Back to Dashboard
          </BEButton>
        </div>}
        open={true}
        width={'fit-content'}
        onCancel={() => navigateTo('home/dashboard')}
      >
        <div className='choose-calculation-method'>
          <p className='heading'>Choose calculation method</p>
          <p className='detail'>Please select the approach you would like to take to calculate scope 3 
            emissions from {type} for the current financial year.</p>
            <ChangeCalculationMethod ref={chooseMethodRef} />
        </div>
      </Modal>
    </div>
  );
}
