import * as React from 'react';
import { BEDrawer } from '../../../../../../../../../../Components/BEDrawer';
import { Form } from 'antd';
import { BEInput } from '../../../../../../../../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../../../../../../../../Components/BEFormItems/LableRequired';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { patchQuantityFormulaVariables, postQuantityFormulaVariables } from '../../../../../../../../Actions';

export interface IAddVariableProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
  currentVariable?: any;
}

export function AddVariable(props: IAddVariableProps) {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm();
  const currentFormula = useTypedSelector(state => state.scope3.formulaCalculation.currentFormula)
  const [loading, setLoading] = React.useState<boolean>(false)
  const onSubmit = async () => {
    setLoading(true)
    const dataToSend = {
      title: form.getFieldValue('name'),
      value: Number(form.getFieldValue('value')),
      formula: Number(currentFormula.id),
      unit: form.getFieldValue('unit')
    }
    let res = null
    if (!props.isEdit)
      res = await dispatch(postQuantityFormulaVariables(dataToSend))
    else 
      res = await dispatch(patchQuantityFormulaVariables(props.currentVariable.id, dataToSend))
    setLoading(false)
    if(res)
      props.setOpen(false)
  }

  React.useEffect(() => {
    if (props.isEdit) {
      console.log('currentVariable', props.currentVariable)
      form.setFieldsValue({
        name: props.currentVariable?.title,
        value: props.currentVariable?.value,
        unit: props.currentVariable?.unit
      })
    }
    else form.resetFields()
  }, [props.currentVariable, props.open])




  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      width='400px'
      heading='Add New Variable'
      footer={
        <>
          <BEButton size='large' className='primary' onClick={() => form.submit()}
            loading={loading}
          >
            {props.isEdit ? 'Update' : 'Add'}
          </BEButton>
          <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
        </>
      }
    >

      <div>
        {/* name,value,unit */}
        <Form
          form={form}
          onFinish={onSubmit}
        >
          <LableRequired>Name</LableRequired>
          <Form.Item name='name' rules={[{ required: true, message: 'Please enter name' }]}>
            <BEInput placeholder='Name' name='name' />
          </Form.Item>
          <LableRequired>Value</LableRequired>
          <Form.Item name='value' rules={[{ required: true, message: 'Please enter value' }]}>
            <BEInput placeholder='Value' type='number' name='value' />
          </Form.Item>
          <LableRequired>Unit</LableRequired>
          <Form.Item name='unit' rules={[{ required: true, message: 'Please enter unit' }]}>
            <BEInput placeholder='Unit' name='unit' />
          </Form.Item>
        </Form>
      </div>
    </BEDrawer>
  );
}
