import { Navigate, useParams } from "react-router-dom"
import { EFSHeader } from "./Header";
import { EFSBody } from "./Body";
import { PrimaryTheme } from "../../../../../../Config/Theme/theames";
import BEBackButton from "../../../../../../Components/BEBackButton";
import ArrowLeft from "../../../../../../Components/BEIcons/ArrowLeftIcon";
import { emissionFactorSourceDetails } from "../../../../Data/emissionfactorsources";


export function EmissionFactorSourceDetails(){
	const {source}=useParams()
	
	
	if(!source){
		return <Navigate to='/404'/>
	}

	return (
	<div>
	<div style={{padding:'1rem ',paddingLeft:'1.8rem', backgroundColor:'white'}}>
	<BEBackButton title='Back' icon={<ArrowLeft/>}/>
	</div>
	{
		emissionFactorSourceDetails.map((item)=>{
			return item.header.sourceName.includes(source) && <div style={{backgroundColor:PrimaryTheme.primaryGreyLight}}>
				<EFSHeader header={item.header}/>
				<EFSBody body={item.body}/>
			</div>	
		})
	}
		
	</div>)
}