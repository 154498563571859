import { useEffect, useState } from "react"
import { Body } from "../../../../../Data/emissionfactorsources";
import { PrimaryTheme } from "../../../../../../../Config/Theme/theames";
import URLIcon from "../../../../../../../Components/BEIcons/URLIcon";
import './styles.scss'
interface EFSBodyProps{
	body: Body[]
}

export function EFSBody(props:EFSBodyProps){
	const {body} = props;
	const [activeBody,setActiveBody]=useState<Body>();
	

	useEffect(()=>{
		setActiveBody(props.body[0])
	},[])

	return (
	<div className="efs-body">
		<p className="dataset-title">{activeBody?.dataSetTitle}</p>
		<div style={{display:'flex'}}>	
			<div style={{marginTop:'1.5rem',marginLeft:'2rem'}}>
			{body.map((item)=>{
				return	(
					<div className={activeBody?.dataSetTitle==item.dataSetTitle ? 'select-dataset active':'select-dataset'} onClick={()=>setActiveBody(item)} >
						{item.dataSetTitle}
					</div>
				)
			})}
			</div>
			<div className="dataset-details">
				<p className="table-title">Dataset</p>
				<div style={{paddingBottom:'3rem'}}>
					{Object.entries(activeBody?.dataSet ?? []).map(([key, value])=> {
								const keyToWord=key.replace(/([A-Z])/g, ' $1')
								return <div className="row">
									<p className="title">{key==='datasetUrl' ? 'Dataset URL': key==='typeOfData' ?'Type of data':keyToWord.charAt(0).toUpperCase()+keyToWord.slice(1)}</p>
									<p className="description">{key==='datasetUrl' ?
									 <a target="_blank" href={value} className="url">
										URL
										<span style={{height:'12px',width:'12px', marginLeft:'5px',display:'block'}}>
											<URLIcon inheritSize fill={PrimaryTheme.primaryBlueInfo}/>
										</span>
									</a> : value}
									</p>
								</div>
					})}
				</div>
				<p className="table-title">License</p>
				<div style={{paddingLeft:'0.5rem',paddingBottom:'3rem'}}>
					{Object.entries(activeBody?.license ?? []).map(([key, value])=> {
								const keyToWord=key.replace(/([A-Z])/g, ' $1')
								return <div className="row">
									<p className="title">{key==='licenseURL' ? 'License URL':keyToWord.charAt(0).toUpperCase()+keyToWord.slice(1)}</p>
									<p className="description">{key==='licenseURL' ?
									 <a target="_blank" href={value} className="url">
										URL
										<span style={{height:'12px',width:'12px', marginLeft:'5px',display:'block'}}>
											<URLIcon inheritSize fill={PrimaryTheme.primaryBlueInfo}/>
										</span>
									</a> : value}
									</p>
						</div>
					})}
				</div>
			</div>
		</div>
	</div>)
}