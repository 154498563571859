import { GET_ALL_COMPANIES,PATCH_INTERNAL_USER_COMPANY,GET_ESG_CONSULTANT_COMPANIES } from "../../Utils/Routes/UserRouts";
import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { setCompanies } from "../../Redux/CompaniesReducer";


const getAllCompanies = () => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(GET_ALL_COMPANIES());
    if (data) {
        return data.data;
    }
    else{
        console.log(error);
        return null;
    }
}

const getESGCONSULTANTCompaniesRefs = () => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(GET_ESG_CONSULTANT_COMPANIES());
    if (data) {
        return data.data;
    }
    else{
        console.log(error);
        return null;
    }
}

export const getESGCONSULTANTCompanies = () => async (dispatch:AppDispatch) => {
    dispatch(setCompanies({
        status:'loading',
        data:[]
    }));
    const [companies,refs] = await Promise.all([dispatch(getAllCompanies()),dispatch(getESGCONSULTANTCompaniesRefs())]);
    if(companies && refs){
        console.log(companies,refs);
        let dataToset : any = [];
        companies.forEach((company:any) => {
            if(refs.find((ref:any) => ref.company === company.id))
                dataToset.push(company);
        })
        
        dispatch(setCompanies(
            {
                status:'success',
                data:dataToset
            }
        ));
    }
    else{
        BEMessage.error('Something went wrong');
        dispatch(setCompanies({
            status:'error',
            data:[]
        }));
    }
}

export const patchInternalUserCompany = (id:any,setLoading:Function) => async (dispatch:AppDispatch) => {
    setLoading(true);
    const [data, error] = await handleAPICall(PATCH_INTERNAL_USER_COMPANY(id));
    if (data) {
        console.log(data);
        navigateTo("/home/dashboard");
    }
    else{
        console.log(error);
        setLoading(false);
    }
}