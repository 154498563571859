import * as React from 'react';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import {
  getScope3TypeCategories,
  getScope3TypeEmissionFactors,
  patchProcurementSpendCategory,
  getProcurementExpenditureTypesReference
} from '../../../../../../../Actions';
import { Scope3TypeQuantityBasedConfiguration } from './QuantityBased';
import { Scope3TypeSpendBasedConfiguration } from './SpendBased';
import { Scope3TypePassengerDistanceBasedConfiguration } from './PassengerDistanceBased';
import { Scope3TypeFreightDistanceBasedConfiguration } from './FreightDistanceBased';

import { useParams } from 'react-router-dom';
import { Scope3BasedOnType, Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { useTypedSelector } from '../../../../../../../../../Config/Hooks/useTypedSelector';
import { 
  BUSINESS_STAY, 
  BUSINESS_TRANSPORT, 
  DOWNSTREAM_LOGISTICS, 
  END_OF_LIFE_WASTE_TREATMENT, 
  PROCUREMENT, 
  QUANTITY_BASED, 
  SPEND_BASED, 
  PASSENGER_DISTANCE_BASED,
  FREIGHT_DISTANCE_BASED,
  UPSTREAM_LOGISTICS, 
  WASTE_GENERATED 
} from '../../../../../../../Data/constants';
import { CatagoryMap, TypeSpendData } from '../../../../../../../Data/data';
import { PrimaryTheme } from '../../../../../../../../../Config/Theme/theames';
import InfoIcon from '../../../../../../../../../Components/BEIcons/InfoIcon';
import { BEDrawer } from '../../../../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import { changeSnakeCaseToSentence } from '../../../../../../../../../Config/Functions/UsefullFunctions';

export interface IDataUploadStep4Props {
  calculate?: boolean
}

const categoryConfigurationDetails:any={
  [PROCUREMENT]:{
    [SPEND_BASED]:[
      {
        header:'Confirm/Update Expenditure Type:',
        content:[
          'Expenditure type is used to classify each procurement category as either: Purchased goods, Purchased services or Capital goods.',
          'Based on this selection, GHG emission calculations are accounted for under Scope 3.1 (Purchased goods & services) or Scope 3.2 (Capital goods).',
          'By default, all procurement categories will be classified as  “Purchased goods”. ',
          'Please update the default selection as required.'
        ]
      },
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the nature of spend within the procurement category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a procurement category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
    [QUANTITY_BASED]:[
      {
        header:'Confirm/Update Unit of Measurement: ',
        content:[
          'Quantity data can be measured in terms of: kg, tonne or custom',
          'If the unit of measurement is selected as “custom”, please create and apply a formula which will allow the quantity to be converted from its default unit of measurement to the mass equivalent measured in kg.',
          'By default, unit of measurement for all material categories is set to “kg”.',
          'Please update the default selection as required.'
        ]
      },
      {
        header:'Create/Select/Update Quantity Conversion Formula:',
        content:[
          'Define a formula by creating variables and using operator signs, and apply the formula to a material category with “custom” unit of measurement.',
          'The formula logic should convert the quantity from its default unit of measurement to the mass equivalent measured in kg using the below expression:Quantity in kg = Quantity in default unit of measurement x (Factor defined by variables and operator signs)',
          'A created formula can be edited/updated as required.',
          'Please note that emission calculation for a material category with “custom” unit of measurement will only be conducted once an appropriate formula has been applied for quantity conversion. This can be tracked under “Quantity Conversion Status”.'
        ]
      },
      {
        header:'Confirm/Update Expenditure Type: ',
        content:[
          'Expenditure type is used to classify each material category as either: Purchased goods, Purchased services or Capital goods. ',
          'Based on this selection, GHG emission calculations are accounted for under Scope 3.1 (Purchased goods & services) or Scope 3.2 (Capital goods)',
          'By default, all material categories will be classified as  “Purchased goods”. ',
          'Please update the default selection as required.'
        ]
      },
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the material type and specifications included in the material category. The emission factor selection can also be updated as required.',
        'Please note that emission calculation for a material category will only be conducted once an emission factor has been selected.'
        ]
      }
    ]
  },
  [WASTE_GENERATED]:{
    [QUANTITY_BASED]:[
      {
        header:'Confirm/Update Unit of Measurement: ',
        content:[
          'Quantity data can be measured in terms of: kg, tonne or custom',
          'If the unit of measurement is selected as “custom”, please create and apply a formula which will allow the quantity to be converted from its default unit of measurement to the mass equivalent measured in kg.',
          'By default, unit of measurement for all waste categories is set to “kg”.',
          'Please update the default selection as required.'
        ]
      },
      {
        header:'Create/Select/Update Quantity Conversion Formula:',
        content:[
          'Define a formula by creating variables and using operator signs, and apply the formula to a  waste category with “custom” unit of measurement',
          'The formula logic should convert the quantity from its default unit of measurement to the mass equivalent measured in kg using the below expression:Quantity in kg = Quantity in default unit of measurement x (Factor defined by variables and operator signs)',
          'A created formula can be edited/updated as required.',
          'Please note that emission calculation for a waste category with “custom” unit of measurement will only be conducted once an appropriate formula has been applied for quantity conversion. This can be tracked under “Quantity Conversion Status”.'
        ]
      },
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the waste material type, specifications and method of disposal included in the waste category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a waste category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
  },
  [BUSINESS_TRANSPORT]:{
    [SPEND_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the nature of spend within the transport category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a transport category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
    [PASSENGER_DISTANCE_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the mode and specification of transport used within the transport category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a transport category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
  },
  [BUSINESS_STAY]:{
    [SPEND_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the nature of spend within the accommodation category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for an accommodation category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
  },
  [UPSTREAM_LOGISTICS]:{
    [SPEND_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the mode and specification of transport used within the logistics category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a logistics category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
    [FREIGHT_DISTANCE_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the mode and specification of transport used within the logistics category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a logistics category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
  },
  [DOWNSTREAM_LOGISTICS]:{
    [SPEND_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the nature of spend within the logistics category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a logistics category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
    [FREIGHT_DISTANCE_BASED]:[
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the nature of spend within the logistics category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a logistics category will only be conducted once an emission factor has been selected.',
        ]
      }
    ],
  },
  [END_OF_LIFE_WASTE_TREATMENT]:{
    [QUANTITY_BASED]:[
      {
        header:'Confirm/Update Unit of Measurement: ',
        content:[
          'Quantity data can be measured in terms of: kg, tonne or custom',
          'If the unit of measurement is selected as “custom”, please create and apply a formula which will allow the quantity to be converted from its default unit of measurement to the mass equivalent measured in kg.',
          'By default, unit of measurement for all product categories is set to “kg”',
          'Please update the default selection as required.'
        ]
      },
      {
        header:'Create/Select/Update Quantity Conversion Formula:',
        content:[
          'Define a formula by creating variables and using operator signs, and apply the formula to a  product category with “custom” unit of measurement.',
          'The formula logic should convert the quantity from its default unit of measurement to the mass equivalent measured in kg using the below expression:Quantity in kg = Quantity in default unit of measurement x (Factor defined by variables and operator signs)',
          'A created formula can be edited/updated as required.',
          'Please note that emission calculation for a product category with “custom” unit of measurement will only be conducted once an appropriate formula has been applied for quantity conversion. This can be tracked under “Quantity Conversion Status”.'
        ]
      },
      {
        header:'Select/Update Emission Factor:',
        content:[
          'Select the emission factor which best represents the product material type, specifications and method of disposal included in the product category. The emission factor selection can also be updated as required.',
          'Please note that emission calculation for a product category will only be conducted once an emission factor has been selected.',
        ]
      }
    ]
  }
}
export function Scope3TypeConfigurationSettings(props: IDataUploadStep4Props) {
  const dispatch = useAppDispatch();
  const basedOn = useTypedSelector(state => state.scope3.basedOn);
  const {type} = useParams<{type:Scope3TypeType}>()
  const year = useTypedSelector(state => state.common.date.currentYear);
  const [infoDrawer,setInfoDrawer] = React.useState<boolean>(false)

  React.useEffect(() => {
    dispatch(getScope3TypeEmissionFactors(type as Scope3TypeType));
    if(type === PROCUREMENT) dispatch(getProcurementExpenditureTypesReference());
  }, [type]);

  React.useEffect(() => {
    dispatch(getScope3TypeCategories(type as Scope3TypeType));
  }, [type,year,basedOn]);

  if (!TypeSpendData[type as Scope3TypeType] || !TypeSpendData[type as Scope3TypeType][basedOn as Scope3BasedOnType]) return null;


  return (
    <div>

      <div style={{display:'flex',alignItems:'center',gap:'1rem'}} >
        <p style={{fontWeight:600,fontSize:'1.8rem'}}>Category Configurations</p>
        <div style={{cursor:'pointer'}} onClick={()=>setInfoDrawer(true)}><InfoIcon fill={PrimaryTheme.primaryGreyDark} /></div>

      </div>
      {
        basedOn === SPEND_BASED ?
        <Scope3TypeSpendBasedConfiguration calculate={props.calculate} /> :
        basedOn === QUANTITY_BASED ?
        <Scope3TypeQuantityBasedConfiguration calculate={props.calculate} /> :
        basedOn === PASSENGER_DISTANCE_BASED ?
        <Scope3TypePassengerDistanceBasedConfiguration calculate={props.calculate} /> :
        basedOn === FREIGHT_DISTANCE_BASED ?
        <Scope3TypeFreightDistanceBasedConfiguration calculate={props.calculate} /> :
        <></>
      }
      <BEDrawer 
        heading='Learn more' 
        footer={
          <>
            <BEButton onClick={()=>setInfoDrawer(false)}>Close</BEButton>
          </>} 
        open={infoDrawer} 
        setOpen={setInfoDrawer}>
          <div style={{color:PrimaryTheme.primaryGray}}>
            <h4>Please complete configurations for each {CatagoryMap[type as Scope3TypeType][basedOn as Scope3BasedOnType]?.title?.toLowerCase()}:</h4>
            {/* <p>{TypeSpendData[type as Scope3TypeType][basedOn as Scope3BasedOnType]?.changeCalculationMethod.boxText}</p> */}
                {categoryConfigurationDetails[type as any][basedOn as any]?.map((el:any)=>{
                  return <div>
                    <h3>{el.header}</h3>
                    <ol style={{paddingLeft:'1rem'}}>
                      {el.content.map((item:any, index:number) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ol>
                  </div>
                })}
              <p>Please click the “Calculate” button to refresh emission calculations after a change in configurations</p>
          </div>
      </BEDrawer>
    </div>
  );
}


