import * as React from 'react';
import { Topbar } from '../../../Components/TopBar';
import { getESGCONSULTANTCompanies,patchInternalUserCompany } from '../../Actions/ChooseCompanyActions';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../Components/GlobalLoader';
import './style.scss';
import { BEPagination } from '../../../Components/BEPagination';
import { CompanyCard } from './CompanyCard';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import Vector1 from '../../../assets/images/ChooseCompanyVectors/Vector1.svg';
import Vector2 from '../../../assets/images/ChooseCompanyVectors/Vector2.svg';
import { CompanyType } from '../../../Redux/Types/companyTypes';


export interface IChooseCompanyProps {
}

export function ChooseCompany(props: IChooseCompanyProps) {
    const companies = useTypedSelector((state) => state.companies.companies.data);
    const companiesStatus = useTypedSelector((state) => state.companies.companies.status);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useAppDispatch();
    const [search, setSearch] = React.useState('')
    const [filteredCompanies, setFilteredCompanies] = React.useState<any>([]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setFilteredCompanies(companies?.filter((company:any) => {
            return company.firm_name.toLowerCase().includes(e.target.value.toLowerCase());
        }))
    }

    React.useEffect(() => {
        dispatch(getESGCONSULTANTCompanies());
    }, []);

    React.useEffect(() => {
        setFilteredCompanies(companies);
    }, [companies]);

    if (companiesStatus === 'loading' || loading) {
        return <GlobalLoader />
    }

    return (
        <div className='choose-company'>
            <Topbar 
                children={<p style={{
                    fontSize: '1.3rem',
                    marginLeft: '2rem'
                }}>Select a Client Company</p>}
            />
            <p style={{ fontSize: '1.5rem', marginTop: '3rem', fontWeight: '600' }}>Which company would you like to access?</p>
            <p style={{ fontSize: '1rem', color: PrimaryTheme.primaryGray, marginTop: '1rem' }}>You have {companies?.length} companies linked to your account, select one to get started!</p>
            <BEInput search onChange={handleSearch} style={{ width: '400px', marginTop: '2rem' }} size='large' placeholder='Search for a company' />
            <div className='companies-box'>
                {filteredCompanies?.slice((page - 1) * 10, page * 10).map((company:any) => {
                    return <div onClick={()=>{
                        dispatch(patchInternalUserCompany(company.id,setLoading))
                    }}>
                        <CompanyCard id={company.id} company={company} />
                    </div>
                })}
                
            </div>
            <BEPagination total={filteredCompanies?.length} pageSize={10} onChange={(page) => setPage(page)} />
            <img className='vector1' src={Vector1} alt="Vector1" />
            <img className='vector2' src={Vector2} alt="Vector2" />
        </div>

    );
}
