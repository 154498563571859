import { useEffect, useState } from "react";
import { PrimaryTheme } from "../../Config/Theme/theames";
import CancleIcon from "../BEIcons/CancleIcon";
import WarningIcon from "../BEIcons/WarningIcon";

interface BEWarningProps{
	description:React.ReactNode
}
export function BEWarning(props:BEWarningProps){
	const [closeButton,setCloseButton]=useState<boolean>(true)
	return (
		<>
		{closeButton && <div style={{display:'flex',alignItems:'start',color:PrimaryTheme.amber,border:`1px solid ${PrimaryTheme.amber}`,padding:'0.5rem',borderRadius:'6px',marginBottom:'1rem'}}>
			<div style={{margin:'0rem 0.5rem',height:'1.3rem'}}><WarningIcon inheritSize fill={PrimaryTheme.amber}/></div>
			<div style={{width:'27rem'}}>
				<p style={{fontWeight:600,fontSize:'16px',margin:'3px 0px'}}>Warning</p>
				<p style={{fontSize:'14px',fontStyle:'italic'}}>{props.description}</p>
			</div>
			<div style={{height:'0.8rem',cursor:'pointer'}} onClick={()=>setCloseButton(false)}>
				<CancleIcon  inheritSize fill={PrimaryTheme.amber}/>
			</div>
		</div>
		}	
	</>
	)
}