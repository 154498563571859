
import { Select } from "antd";
import { BEDrawer } from "../../../../../../../../../../../Components/BEDrawer";
import { LableRequired } from "../../../../../../../../../../../Components/BEFormItems/LableRequired";
import { Scope3TypeType } from "../../../../../../../../../../../Redux/Types/scope3Types";
import { patchScope3TypeCategory } from "../../../../../../../../../Actions";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../../../../../../Config/Hooks/useAppDispatch";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../../../../../../../Config/Hooks/useTypedSelector";
import { BEButton } from "../../../../../../../../../../../Components/BEFormItems/BEButton";
import { BEMessage } from "../../../../../../../../../../../Components/BEMessage";
import CancleIcon from "../../../../../../../../../../../Components/BEIcons/CancleIcon";
import WarningIcon from "../../../../../../../../../../../Components/BEIcons/WarningIcon";
import { PrimaryTheme } from "../../../../../../../../../../../Config/Theme/theames";
import { BEWarning } from "../../../../../../../../../../../Components/BEWarning";
import { CatagoryMap } from "../../../../../../../../../Data/data";

interface EditUnitDrawerProps{
	open:boolean;
	setOpen:Function;
	data:any
	setOpenFormulaSelection:Function
}
interface UnitData {
    title: string;
    value: string;
}
const unitData:UnitData[] = [
	{
	  title: 'kg',
	  value: 'kg'
	},
	{
	  title: 'tonne',
	  value: 'tonne'
	},
	{
	  title: 'custom',
	  value: 'other'
	}
  ]

export function EditUnitDrawer(props:EditUnitDrawerProps){
	const [loading,setLoading]=useState<boolean>(false)
	const dispatch=useAppDispatch()
	const {type} = useParams<{type:Scope3TypeType}>()
	const basedOn = useTypedSelector(state => state.scope3.basedOn);
	const categoryConfigurations = useTypedSelector(state => state.scope3.catagoryConfigurations.data)
	const [unitType,setUnitType]=useState<string>("")
	useEffect(()=>{
		setUnitType(props.data.unit)
	},[categoryConfigurations,props.data])
	async function onSubmitUnitMeasurement(){
		setLoading(true);
		await dispatch(patchScope3TypeCategory(type as Scope3TypeType,props.data.id, { unit: unitType}));
		if(unitType ==='other'){
			props.setOpenFormulaSelection(true)
		}
		props.setOpen(false)
		setLoading(false);
	}
	return <BEDrawer
		open={props.open}
		width='27rem'
		setOpen={props.setOpen}
		heading="Change unit of measurement"
		secondryHeading={{
			heading:`${basedOn && props.data[CatagoryMap[type as Scope3TypeType][basedOn].dataIndex]}`,
		  }}
		footer={
			<div style={{display:'flex',gap:8}}>
			<BEButton onClick={onSubmitUnitMeasurement} loading={loading} className="primary" >
				{unitType=='other' ? "Select formula" :"Save"}
			</BEButton>
			<BEButton onClick={()=>props.setOpen(false)}>
				Cancel
			</BEButton>
			</div>
		}
	>
		<BEWarning description={<div>
			<p>Unit of measurement for quantity data of each material category can be one of:</p>
					<p>1. kg</p>
					<p>2. tonnes</p>
					<p>3. custom</p>
					<p>If the unit of measurement is selected as “custom” for a material category, please create and apply a formula which converts the quantity data from its default unit of measurement to the mass equivalent in kg.</p>
					<p>By default, unit of measurement for all material category is set to “kg”.</p>
					<p>Please update the default selection as required.</p>
		</div>}/>
		<LableRequired>Unit of Measurement</LableRequired>
		<Select style={{ width: '100%' }}
            value={unitType}
            onChange={
			(value:any)=>{
				setUnitType(value)
			}
            }
          >
            {
              unitData
                .map((unit: any) => <Select.Option value={unit.value}>
                  {unit.title}
                </Select.Option>)
            }
          </Select> 
	</BEDrawer>
}


