import React, { useEffect, useState } from "react";
import { BEDrawer } from "../../../../Components/BEDrawer";
import { Form, InputNumber } from "antd";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { LableRequired } from "../../../../Components/BEFormItems/LableRequired";
import { BEInput } from "../../../../Components/BEFormItems/BEInput";
import BEDatePicker from "../../../../Components/BEDatePicker";
import BEMultipleSelect from "../../../../Components/BEMultipleSelect";
import { getAllIndustries, updateCompanyDetails } from "../../../../views/Actions/OnboardingActions";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import FileUpload from "../../../../Components/BEFileUpload/FileUpload";
import LocationModal from "../../../../views/Onboarding/Step1/LocationModal";
import dayjs from "dayjs";
import ChevronDownIcon from "../../../../Components/BEIcons/ChevronDownIcon";
import CurrencyModal from "../../../../views/Onboarding/Step1/CurrencyModal";
import { BEEyeButton } from "../../../../Components/BEEyeButton";
import { sectors } from "../../../../data/sectors";
import { color } from "highcharts";
import GooglePlaces from "../../../../Components/GooglePlaces";

interface BEEditCompanyDetailsProps {
  open: boolean;
  setOpen: Function;
}

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const EditCompanyDetails = (props: BEEditCompanyDetailsProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const companyDetails = useTypedSelector(
    (state) => state.companies.companyDetails
  );
  const industriesState = useTypedSelector(
    (state) => state.onBoarding.industries
  );
  const onBoardingStep1Status = useTypedSelector(
    (state) => state.onBoarding.stage1Data.status
  );

  const [industries, setIndustries] = useState<any>([]);
  const [logo, setLogo] = useState(null);
  const [openLocationModal, setLocationOpenModal] = useState(false);
  const [openCurrencyModal, setCurrencyOpenModal] = useState(false);
  const [currency, setCurrency] = useState("INR");

  const onFinish = async (values: any) => {
    console.log(values)
    const obj = {
      ...values,
      logo,
      currency,
      firm_name: values.name,
      placesApi: values.address,
      industry: JSON.stringify(values.industry),
      year_of_incorporation: dayjs(values.year_of_inc).format("YYYY"),
      reporting_year_end: dayjs(values.reporting_year_end).format(
        "YYYY-MM-DD"
      ),
    };
    if (!obj.logo) {
      delete obj.logo
    }
    await dispatch(updateCompanyDetails(obj, Number(companyDetails?.data?.id), obj.hasOwnProperty('logo')))
    props.setOpen(false);
  };

  const addOn = (
    <div
      onClick={() => setCurrencyOpenModal(true)}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      {currency} <ChevronDownIcon fill="gray" />
    </div>
  );

  function AddOn() {
    return (
      <div
        onClick={() => setCurrencyOpenModal(true)}
        style={{ display: "flex", alignItems: "center", cursor: "pointer", border: "1px solid #d9d9d9", padding: "0.5rem", borderRadius: "5px" }}
      >
        {currency} <ChevronDownIcon fill="gray" />
      </div>
    )
  }

  useEffect(() => {
    form.setFieldsValue({
      name: companyDetails.data?.firm_name,
      year_of_inc: dayjs(`${companyDetails.data?.year_of_incorporation}-01-01`),
      address: companyDetails.data?.address,
      industry:
        companyDetails.data?.industry && isJson(companyDetails.data?.industry)
          ? JSON.parse(companyDetails.data?.industry)
          : companyDetails.data?.industry,
      website: companyDetails.data?.website,
      revenue: companyDetails.data?.revenue,
      sector: companyDetails.data?.sector,
      reporting_year_end: dayjs(companyDetails.data?.reporting_year_end),
    });
    setCurrency(companyDetails.data?.currency ? companyDetails.data?.currency : 'INR')
  }, [companyDetails]);

  useEffect(() => {
    if (industriesState.status === "success") {
      setIndustries(industriesState.data);
    }
    console.log(industriesState);
  }, [industriesState.status]);

  useEffect(() => {
    dispatch(getAllIndustries());
  }, []);
  return (
    <div>
      <BEDrawer
        open={props.open}
        setOpen={props.setOpen}
        heading="Edit General Settings"
        width="fit-content"
        footer={
          <div>
            <BEButton
              size="large"
              className="primary"
              onClick={() => {
                form.submit();
              }}
              loading={onBoardingStep1Status === "loading"}
            >
              Save Changes
            </BEButton>
            <BEButton
              style={{ marginLeft: "0.5rem" }}
              size="large"
              onClick={() => {
                form.resetFields();
                props.setOpen(false);
              }}
            >
              Cancel
            </BEButton>
          </div>
        }
      >
        <LocationModal
          openModal={openLocationModal}
          setOpenModal={setLocationOpenModal}
          form={form}
        />
        <CurrencyModal
          openModal={openCurrencyModal}
          setOpenModal={setCurrencyOpenModal}
          setCurrency={setCurrency}
        />
        <Form form={form} onFinish={onFinish}>
          <Form.Item>
            <div style={{ margin: "auto", padding: "1rem", display: "flex", flexDirection: "column", justifyContent: "center", gap: "1rem" }}>
              <img
                src={
                  logo
                    ? URL.createObjectURL(logo)
                    : companyDetails.data?.logo
                      ? companyDetails.data?.logo
                      : "https://placehold.co/400?text=Company+Logo"
                }
                alt={logo ? logo : companyDetails.data?.logo}
                style={{
                  objectFit: "contain",
                  height: "200px",
                  width: "100%",
                }}
              />
              <FileUpload
                handleFileChange={setLogo}
                text="Upload new Logo"
                hint="Accepted image formats: *.jpg, *.png, & *.jpeg, Max 2MB"
                fileType="image"
              />
            </div>
          </Form.Item>
          <div>
            <p style={{ marginBottom: "0.8rem" }}>
              <LableRequired>Company Name </LableRequired>
            </p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name of company!",
                },
              ]}
            >
              <BEInput size="large" placeholder="Enter name of the company" />
            </Form.Item>
          </div>

          <div>
            <p style={{ marginBottom: "0.8rem" }}>
              <LableRequired>Year of Incorporation </LableRequired>
            </p>
            <Form.Item
              name="year_of_inc"
              rules={[
                {
                  required: true,
                  message: "Please input a valid Year of Incorporation!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <BEDatePicker picker="year" form={form} field="year_of_inc" />
            </Form.Item>
          </div>

          <div>
            <p style={{ marginBottom: "0.8rem" }}>
              <LableRequired>Address </LableRequired>
            </p>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter an address for your company!",
                },
              ]}
            >
              <GooglePlaces form={form} />
            </Form.Item>
          </div>

          <div>
            <p style={{ marginBottom: "0.8rem" }}>
              <LableRequired>Industry </LableRequired>
            </p>
            <BEMultipleSelect
              single
              data={industries}
              rules={[
                {
                  required: true,
                  message: "Please input industry of company!",
                },
              ]}
              name="industry"
              placeholder="Enter the industry"
            />
          </div>

          <div>
            <p style={{ marginBottom: "0.8rem" }}>
              <LableRequired>Website </LableRequired>
            </p>
            <Form.Item
              name="website"
              rules={[
                {
                  required: true,
                  message: "Please input a valid website of company!",
                },
                {
                  type: "url",
                  message: "Please enter a valid url",
                },
              ]}
            >
              <BEInput
                placeholder="Enter the link to the company’s website"
                size="large"
              />
            </Form.Item>
          </div>
          <div>
            <BEEyeButton
              style={{ marginBottom: '0.8rem' }}
              title={"Reporting Year End"}
              discription={"Reporting Year End refers to the end of the year for which the reports will be generated"}
            >
              <LableRequired>Reporting Year End </LableRequired>
            </BEEyeButton>
            <Form.Item
              name="reporting_year_end"
              rules={[
                {
                  required: true,
                  message: "Please input reporting year end!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <BEDatePicker
                form={form}
                field="reporting_year_end"
                format="DD MMM"
              />
            </Form.Item>
          </div>
          <div>
            <p style={{ marginBottom: "0.8rem" }}>
              <LableRequired>Currency </LableRequired>
            </p>
            <AddOn />

          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginBottom: "0.8rem" }}>
                <LableRequired>Sector</LableRequired>
              </p>
              {/* <BEEyeButton
                title={"Sector"}
                discription={"Sector selection is required for emission calculations. If you are unsure about which sector your company belongs to, please select 'Commercial/Institutional'."}
              >
              </BEEyeButton> */}
            </div>
            <Form.Item name="sector" style={{ width: "313px" }}>
              <BEMultipleSelect
                data={sectors}
                single
                name="sector"
                placeholder="Select the sector"
                isDataStrArray
              />
            </Form.Item>
          </div>

        </Form>
      </BEDrawer>
    </div>
  );
};

export default EditCompanyDetails;
