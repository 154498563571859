import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { addBusinessUnit, addBusinessUnitContributor, editBusinessUnit, getBusinessUnitRefs } from '../../../Actions/BusinessUnitActions';
import { Form, Checkbox, Radio } from 'antd';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { LableRequired } from '../../../../Components/BEFormItems/LableRequired';
import GooglePlaces from '../../../../Components/GooglePlaces';
import BEMultipleSelect from '../../../../Components/BEMultipleSelect';
import { BETextArea } from '../../../../Components/BEFormItems/BETextArea';
import { getAllIndustries } from '../../../Actions/OnboardingActions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getAllUsers } from '../../../Actions/UsersManagementActions';
import { getBusinessUnitContributors } from '../../../Actions/BusinessUnitActions';
import '../style.scss';
import { BusinessUnitContributorTable } from './BusinessUnitContributorTable';
import { BEEyeButton } from '../../../../Components/BEEyeButton';
import { BEMessage } from '../../../../Components/BEMessage';
import { postBusinessUnitRef, deleteBusinessUnitRef } from '../../../Actions/BusinessUnitActions';
import { BusinessGroupsOfUnitTable } from './BusinessGroupOfUnitTable';
import { add } from '@dnd-kit/utilities';

export interface IAddBusinessUnitProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
}

export function AddBusinessUnit(props: IAddBusinessUnitProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups);
  const [loading, setLoading] = React.useState(false);
  const currentBusinessUnit = useTypedSelector(state => state.common.selectedRow);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const [ownershipError, setOwnershipError] = React.useState<any>(null);
  const allUsers = useTypedSelector(state => state.userMgmt);
  const businessUnitContributors = useTypedSelector(state => state.entity.BusinessUnitContributors);
  const [usersToAdd, setUsersToAdd] = React.useState<any>([]);
  const [is_headquarter, setIs_headquarter] = React.useState<any>(false);
  const businessUnitRef = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const [addedBusinessGroups, setAddedBusinessGroups] = React.useState<any>([]);
  const [contractedElectricityStatus, setContractedElectricityStatus] = React.useState<any>(null);

  const onFinish = async (values: any) => {
    let buWithHQLocation = businessUnits.data.find((bu: any) => bu.is_headquarter === true)
    if (values.is_headquarter && buWithHQLocation && buWithHQLocation.id !== currentBusinessUnit.id) {
      BEMessage.error('Headquarter location already exists');
      return;
    }
    if (values.contracted_electricity_demand < 0) {
      BEMessage.error('Contracted Electricity Demand can not be less than 0');
      return;
    }
    setLoading(true);
    if (!props.isEdit) {
      let data = await dispatch(addBusinessUnit({ ...values, sector: 'Marketing', is_headquarter: values.is_headquarter ? values.is_headquarter : false }));

      if (data) {

        // Create an array of functions that return dispatch calls for posting business unit references
        const postBusinessUnitRefFunctions = addedBusinessGroups.map((item:any) => async () =>
          await dispatch(postBusinessUnitRef({ business_group: item.id, business_unit: data.id }))
        );

        // Prepare an async function for adding business unit contributors if applicable
        let addBusinessUnitContributorFunction;
        if (usersToAdd.length > 0) {
          addBusinessUnitContributorFunction = async () =>
            await dispatch(addBusinessUnitContributor(data.id, usersToAdd.map((user: any) => ({
              user: user,
              business_unit: data.id,
            }))));
        }

        // Combine both sets of functions into a single array
        const allOperations = [
          ...postBusinessUnitRefFunctions,
          ...(addBusinessUnitContributorFunction ? [addBusinessUnitContributorFunction] : [])
        ];

        // Use Promise.all to execute all dispatch calls
        await Promise.all(allOperations.map(async operation => await operation()));

      }
    }
    else {
      let buRefsToAdd: any = addedBusinessGroups.filter((item: any) => !businessUnitRef.data.find((item2: any) => item2.business_group === item.id && item2.business_unit === currentBusinessUnit.id))
      await Promise.all(buRefsToAdd.map(async (item: any) => {
        await dispatch(postBusinessUnitRef({ business_group: item.id, business_unit: currentBusinessUnit.id }))
      }))
      await dispatch(editBusinessUnit(currentBusinessUnit.id, values));
    }
    dispatch(getBusinessUnitRefs())
    form.resetFields();
    setUsersToAdd([])
    setLoading(false);
    props.setOpen(false);
  }

  React.useEffect(() => {
    if (props.isEdit) {
      form.setFieldsValue({
        name: currentBusinessUnit.name,
        description: currentBusinessUnit.description,
        business_group: currentBusinessUnit.business_group,
        industry: currentBusinessUnit.industry,
        address: currentBusinessUnit.address,
        owner: currentBusinessUnit.owner,
        is_headquarter: currentBusinessUnit.is_headquarter,
        max_occupancy: currentBusinessUnit.max_occupancy,
        water_stress: currentBusinessUnit.water_stress,
        ownership_percentage: currentBusinessUnit.ownership_percentage,
        contracted_electricity_demand: currentBusinessUnit.contracted_electricity_demand,
      })
      setIs_headquarter(currentBusinessUnit.is_headquarter)
    }
  }, [currentBusinessUnit, businessUnitContributors])

  return (
    <div>
      <BEDrawer
        className="add-bu"
        heading={props.isEdit ? "Edit Business Unit" : "Add Business Unit"}
        open={props.open}
        setOpen={props.setOpen}
        width="400"
        footer={
          <div>
            <BEButton
              size="large"
              className="primary"
              loading={loading}
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </BEButton>
            <BEButton
              style={{ marginLeft: "0.5rem" }}
              size="large"
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Cancel
            </BEButton>
          </div>
        }
      >
        <Form form={form} onFinish={onFinish}>
          <LableRequired>Name</LableRequired>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <BEInput placeholder="Name of Business Unit" />
          </Form.Item>
          <p>Description</p>
          <Form.Item
            name="description"
          >
            <BETextArea
              form={form}
              rows={4}
              placeholder="Description of Business Unit"
            />
          </Form.Item>

          <LableRequired>Address</LableRequired>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: "Please enter address",
              },
            ]}
          >
            <GooglePlaces form={form} />
          </Form.Item>
          <LableRequired>Owner Name</LableRequired>
          <BEMultipleSelect
            loading={
              allUsers.status === "loading" ||
              !props.isEdit ||
              businessUnitContributors.status === "loading"
            }
            form={form}
            name="owner"
            data={allUsers.data
              // .filter((user:any)=>user.role==='CONTRIBUTOR' || user.id === currentBusinessUnit.owner || user.id === userInfo?.id)
              .map((user: any) => {
                return {
                  name: user.first_name + " " + user.last_name,
                  id: user.id,
                };
              })}
            labelFeild="name"
            valueFeild="id"
            single
            rules={[
              { required: true, message: "Please select a owner" },
            ]}
          />

          <BusinessGroupsOfUnitTable
            open={props.open}
            isEdit={props.isEdit ? true : false}
            businessUnit={currentBusinessUnit}
            addedBusinessGroups={addedBusinessGroups}
            setAddedBusinessGroups={setAddedBusinessGroups}
          />

          {/* <p>Max Occupancy</p> */}
          <BEEyeButton
            title=""
            discription={
              "Refers to the maximum number of occupants this business unit can accomodate."
            }
          >
            <div style={{ lineHeight: "2rem" }}>Max Occupancy</div>
          </BEEyeButton>
          <Form.Item name="max_occupancy">
            <BEInput type="number" placeholder="Max Occupancy" />
          </Form.Item>
          <div className="water_stress">
            <BEEyeButton
              title=""
              discription={
                "Refers to areas where water demand is higher than the amount available during a certain period of time, or when use is restricted because of low quality. Being collected to calculate water consumption in such locations."
              }
            >
              <div>Is this unit in a water stress area ?</div>
            </BEEyeButton>
            <Form.Item name="water_stress">
              <Radio.Group name="water_stress">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <p>Ownership Percentage (%)</p>
          <Form.Item
            name="ownership_percentage"
            help={ownershipError}
            validateStatus={ownershipError ? "error" : "success"}
          >
            <BEInput
              type="number"
              onChange={(e) => {
                if (Number(e.target.value) > 100) {
                  setOwnershipError(
                    "Ownership percentage can not be greater than 100"
                  );
                }
                else if (Number(e.target.value) < 0) {
                  setOwnershipError(
                    "Ownership percentage can not be less than 0"
                  );
                }
                else {
                  setOwnershipError(null);
                }
              }}
              placeholder="Ownership Percentage"
            />
          </Form.Item>

          <p>Contracted Electricity Demand (KWH)</p>
          <Form.Item
            name="contracted_electricity_demand"
            validateStatus={contractedElectricityStatus ? 'error' : 'success'}
            help={contractedElectricityStatus}
          >
            <BEInput
              type="number"
              placeholder="Contracted Electricity Demand"
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  setContractedElectricityStatus('Contracted Electricity Demand can not be less than 0');
                } else {
                  setContractedElectricityStatus(null);
                }
              }}
            />
          </Form.Item>
       
          <Form.Item name="is_headquarter">
            <Checkbox
              checked={is_headquarter}
              onChange={(e) => {
                setIs_headquarter(e.target.checked);
                form.setFieldsValue({ is_headquarter: e.target.checked });
              }}
            ><p>Is this the headquarter?</p></Checkbox>
          </Form.Item>
        </Form>
        <BusinessUnitContributorTable
          isEdit={props.isEdit}
          businessUnit={currentBusinessUnit}
          usersToAdd={usersToAdd}
          setUsersToAdd={setUsersToAdd}
        />
      </BEDrawer>
    </div>
  );
}
