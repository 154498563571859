import React from 'react'
import IconProps from './Interface/IconProps';

const ReviewIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 21}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.958 0H2.95801C1.85801 0 0.958008 0.9 0.958008 2V20L4.95801 16H18.958C20.058 16 20.958 15.1 20.958 14V2C20.958 0.9 20.058 0 18.958 0ZM18.958 14H4.12801L2.95801 15.17V2H18.958V14Z"
        fill={props.stroke ? props.stroke : "#666666"}
      />
      <path
        d="M10.958 13L12.528 9.57L15.958 8L12.528 6.43L10.958 3L9.38801 6.43L5.95801 8L9.38801 9.57L10.958 13Z"
        fill={props.stroke ? props.stroke : "#666666"}
      />
    </svg>
  );
}

export default ReviewIcon