import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { GET_STAGE, GET_TASK_STATUS } from "../../Utils/Routes/Onboarding";
import { setOnboardingStage,setNotification,setVersion, setNotificationRead } from "../../Redux/CommonReducer";
import { AppDispatch, RootState } from "../../Redux/store";
import { 
  GET_NOTIFICATIONS,
  PATCH_NOTIFICATION,
  GET_APP_VERSION_AND_DATE
 } from "../../Utils/Routes/UserRouts";

export const getStage = () => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(GET_STAGE());
    if (data) {
      console.log(data)
      dispatch(setOnboardingStage({
        status: 'success',
        stage: data.data.stage
      }))
      if(data.data.stage<5)navigateTo('/onboarding');
    }
    else {
      console.log(data);
      dispatch(
        setOnboardingStage({
          status: "error",
          stage: 0,
        })
      );
    }
}

export const getNotifications = () => async (dispatch:AppDispatch) => {
  const [data, error] = await handleAPICall(GET_NOTIFICATIONS());
  if (data) {
    dispatch(setNotification({
      status: 'success',
      data: data.data
    }))
  }
  else {
    dispatch(setNotification({
      status: 'error',
      data: []
    }))
  }
} 

export const patchNotification = (id:any,data:any) => async (dispatch:AppDispatch,getState:()=>RootState) => {
  const state = getState();
  const [res, error] = await handleAPICall(PATCH_NOTIFICATION(id,data));
  if (res) {
    console.log(res);
    dispatch(setNotificationRead({
      id,
      read: data.read
    }))
  }
  else {
    console.log(res);
  }
}

export const getAppVersionAndDate = () => async (dispatch:AppDispatch) => {
  const [data, error] = await handleAPICall(GET_APP_VERSION_AND_DATE());
  if (data) {
    console.log(data)
    dispatch(setVersion({
      number: data.data.version,
      date: data.data.release_date
    }))
  }
  else {
    console.log(data);
  }
}

export const getTaskStatus = (task_id:number) => async (dispatch:AppDispatch) => {
  const [data, error] = await handleAPICall(GET_TASK_STATUS(task_id));
  if (data) {
    console.log(data,'task status');
    return data;
  }
  else {
    console.log(data);
    return null;
  }
}

// this is the funcion to get task status every 3 seconds
// it will keep checking the status of the task until it is completed or failed

export const trackTaskStatus =(task_id:number,intervalTime?:number) => async (dispatch:AppDispatch) => {
  const interval = intervalTime || 3000;
  while(true){
    await new Promise(r => setTimeout(r, interval));
    const data = await dispatch(getTaskStatus(task_id));
    if (data) {
      console.log('status',data);
      if(data?.data === 'FAILURE' || data?.result === false ){
        return null;
      }
      else if(data?.data === 'SUCCESS') return data?.result;
    }
    else return null;
  }
}