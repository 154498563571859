import * as React from 'react';
import { BusinessGroupsSection } from './BusinessGroupsSection';
import { BusinessUnitsSection } from './BusinessUnitsSection';
import './style.scss'
import { OnboardingNavButtons } from '../Components/NavButtons';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { getAllUsers } from '../../Actions/UsersManagementActions';
import { StepsView } from '../Components/StepView';

export interface IStep2Props {
}

export function Step2(props: IStep2Props) {
  const dispatch = useAppDispatch();
  const users = useTypedSelector(state => state.userMgmt)

  React.useEffect(() => {
    if(users.status === 'idle')
    dispatch(getAllUsers());
  }, [])
  
  return (
    <div className="onboarding-step2">
      <StepsView />
      <div style={{padding: "2rem 0rem"}}>
      <BusinessGroupsSection />
      <BusinessUnitsSection />
      </div>
    </div>
  );
}
 