import React from 'react'
import { BETabButton } from '../../../../Components/BETabButton';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import Dashboard from '../../../../Components/BEIcons/Dashboard';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Select } from 'antd';
import { useLocation } from 'react-router-dom';
import { GlobalMonthsSlicer } from '../../../../Components/BEMultipleSelect/MonthSlicer';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setCurrentMonth, setCurrentYear } from '../../../../Redux/CommonReducer';

const TopSection = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const CompanyID = useTypedSelector((state) => state.companies.companyDetails.data?.id);
  const currentMonth = useTypedSelector((state) => state.common.date.currentMonth);
  const currentYear = useTypedSelector((state) => state.common.date.currentYear);

  return (
    <div className="global-top-section">
      <BETabButton
        title="DASHBOARD"
        icon={
          <Dashboard
            stroke={
              location.pathname.includes("dashboard")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("dashboard")}
        onClick={() => navigateTo("/home/dashboard")}
      />

      <div style={{ marginLeft: 'auto' }}>
        <GlobalMonthsSlicer 
          setDateSlicerMonth={setCurrentMonth}
          setDateSlicerYear={setCurrentYear}
          currentMonth={currentMonth}
          currentYear={currentYear}
        />  
      </div>
    </div>
  );
}

export default TopSection