
import React from 'react'
import IconProps from './Interface/IconProps';

const SpendBasedCalculation = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	  <path d="M13.3333 30V20H16.6667V23.3333H30V26.6667H16.6667V30H13.3333ZM0 26.6667V23.3333H10V26.6667H0ZM6.66667 20V16.6667H0V13.3333H6.66667V10H10V20H6.66667ZM13.3333 16.6667V13.3333H30V16.6667H13.3333ZM20 10V0H23.3333V3.33333H30V6.66667H23.3333V10H20ZM0 6.66667V3.33333H16.6667V6.66667H0Z"   fill={props.fill ? props.fill : '24px'} />
	  </svg>
  );
}

export default SpendBasedCalculation


