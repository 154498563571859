import * as React from 'react';
import TopSection from '../Topbar';
import { Outlet } from 'react-router-dom';

export interface IMarketplaceLayoutProps {
}

export function MarketplaceLayout (props: IMarketplaceLayoutProps) {
  return (
    <div>
      <TopSection />
      <Outlet />
    </div>
  );
}
