import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { CONFIG } from '../../config';
import { navigateTo } from '../../Navigation/NavigationManager';
const API_URL = CONFIG.host;

interface BEInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
    _retry?: boolean;
    accessToken?: string;
    refreshToken?: string;
    multipart?: boolean;
    skipInterceptor?: boolean;
}

const instance = axios.create({
    headers: { 'Content-Type': 'application/json', }, // default  content type
    baseURL: API_URL,
    timeout: 300000, // Set a timeout limit for requests * for testing purpose
});

//helper function to convert data to form data
const toFormData = (data: Record<string, any>): FormData => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }
    return formData;
};

// Helper function to get tokens from local storage
const getTokens = () => {
    return {
        accessToken: localStorage.getItem('access'),
        refreshToken: localStorage.getItem('refresh'),
    };
};

// Set the access token for every request
instance.interceptors.request.use((config: BEInternalAxiosRequestConfig) => {
    if(config.skipInterceptor) return config;
    const { accessToken } = getTokens();
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
});

// Handle token expiration and refresh token logic
instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const originalRequest = error.config as BEInternalAxiosRequestConfig;
        const { refreshToken } = getTokens();

        if (error.response?.status === 401 && !originalRequest._retry && refreshToken) {
            originalRequest._retry = true;

            try {
                //skip interceptor to avoid infinite loop
                let refreshConfig = { ...originalRequest, skipInterceptor: true };
                const { data } = await instance.post('/token/refresh/', { refresh:refreshToken }, refreshConfig);
                console.log('refreshed token', data);
                localStorage.setItem('access', data.access);
                originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
                return instance(originalRequest);
            } catch (refreshError) {
                localStorage.clear();
                // navigateTo('/login');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

const API = {
    GET: (path: string, params?: any) => instance.get(path, { params }),

    POST: (path: string, data?: any, multipart?: boolean) => {
        let config: AxiosRequestConfig = {};
        if (multipart) {
            config.headers = { 'Content-Type': 'multipart/form-data' };
            data = toFormData(data);
        }
        return instance.post(path, data, config);
    },

    PATCH: (path: string, data?: any, multipart?: boolean) => {
        let config: AxiosRequestConfig = {};
        if (multipart) {
            config.headers = { 'Content-Type': 'multipart/form-data' };
            data = toFormData(data);
        }
        return instance.patch(path, data, config);
    },

    DELETE: (path: string) => instance.delete(path),
};


export default API;
