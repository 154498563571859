import * as React from 'react';
import './style.scss';
import Logo from '../../assets/images/Company/Logo.png'
import UserIcon from '../../assets/images/Avatar.png'
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface ITopbarProps {
  children?: React.ReactNode;
  mode?: 'light' | 'dark';
  logo?: any;
  imageStyle?:React.CSSProperties;
  firstName?:string;
  lastName?:string;
}

export function Topbar (props: ITopbarProps) {
  return (
    <div className={'top-bar ' + (props.mode || 'light')}>
        <img className='logo' src={props.logo || Logo} alt="Logo" style={{...props.imageStyle}} />
        {
            props.children
        }
        <div className='right-div'>
            <p style={{
          fontWeight: '600',
          color: PrimaryTheme.primaryGray,
          fontSize: '0.9rem',}} >
          {props.firstName} {props.lastName}
          </p>
            <img className='user-icon' src={UserIcon}  alt="User Icon" />
        </div>
    </div>
  );
}
