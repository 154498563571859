import React, { useEffect } from 'react'
import BEMenuBar from '../../../Components/BEMenubar'
import { Outlet } from 'react-router-dom'
import './styles.scss'
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector'
import { TopMenubar } from '../TopMenubar'
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { getStage } from '../../Actions/HomeActions';
import { GlobalLoader } from '../../../Components/GlobalLoader'
import { DashboardWelcomeScreen } from '../WelcomeScreens/DashboardWelcomeScreen'
import { setShowWelcomePage } from '../../../Redux/CommonReducer'
import { getCompanyDetails } from '../../Actions/OnboardingActions'
import { handleBackgroundAPICalls } from '../../Actions/HandleBackgroundApiCalls'
import { menubarWidth,menubarWidthCollapsed } from '../../../Config/Constants'
import { getYears } from '../../../Features/Settings/Actions'
import { StatusType } from '../../../Redux/Types/userTypes'

const Layout = () => {
  const dispatch = useAppDispatch()
  const menuCollapsed = useTypedSelector(state => state.common.menuCollapsed)
  const showWelcomeScreen = useTypedSelector(state => state.common.showWelcomePage)
  const companyDetails = useTypedSelector(state => state.companies.companyDetails)
  const [Loading, setLoading] = React.useState<StatusType>("loading")
  const years = useTypedSelector(state => state.settings.years)
  const role = localStorage.getItem('role');

  React.useEffect(() => {
    getStageFun()
  }, []);

  useEffect(() => {
    const lodingDependancies = [companyDetails,years]
    if(lodingDependancies.every(item => item.status === 'success')) setLoading('success')
    if(lodingDependancies.some(item => item.status === 'error')) setLoading('error')
  }, [companyDetails,years])

  useEffect(() => {
    if(Loading === 'success') dispatch(handleBackgroundAPICalls())
  }, [Loading])

  const getStageFun = async () => { 
    if(role === 'SUPER_ADMIN') await dispatch(getStage());
    dispatch(getYears());
    dispatch(getCompanyDetails());
  }

  if ( Loading === 'loading') {
    return <GlobalLoader />
  }
  if(Loading === "error") {
    return <div>Sorry for the inconvenience, something went wrong<br />Please try again later</div>
  }
  if(showWelcomeScreen){
    return <DashboardWelcomeScreen
      onClick={() => {
        dispatch(setShowWelcomePage(false))
      }}
    />
  }
  return (
    <div style={{ display: "flex" }}>
      <BEMenuBar />
      <div className='outlet-cnt' style={{
        width: menuCollapsed ? `calc(100vw - ${menubarWidthCollapsed})` : `calc(100vw - ${menubarWidth})`
      }}>
        <TopMenubar />
        <Outlet />
      </div>
    </div>
  );
}

export default Layout