import React from 'react'
import IconProps from "./Interface/IconProps";

const ExportExcelIcon = (props:IconProps) => {
  return (
    <svg width={props.inheritSize ? "100%" : 24}
    height={props.inheritSize ? "100%" : 24} viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.07113 0.833252H9.40446L14.9045 6.33325V17.3333C14.9045 17.8195 14.7113 18.2858 14.3675 18.6296C14.0237 18.9734 13.5574 19.1666 13.0711 19.1666H2.07113C1.5849 19.1666 1.11858 18.9734 0.774764 18.6296C0.430947 18.2858 0.237793 17.8195 0.237793 17.3333V2.66659C0.237793 2.18036 0.430947 1.71404 0.774764 1.37022C1.11858 1.02641 1.5849 0.833252 2.07113 0.833252ZM8.48779 7.24992H9.40446H13.0711L8.48779 2.66659V7.24992ZM9.16129 15.8333H10.2378L8.30562 13.2649L10.0998 10.8333H9.08637L7.75357 12.7279L6.41287 10.8333H5.36792L7.1542 13.2409L5.23779 15.8333H6.2512L7.71019 13.7847L9.16129 15.8333Z"
  fill={props.fill ? props.fill : "#333333"}/>
</svg>
  );
}

export default ExportExcelIcon