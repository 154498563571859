import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BETable } from '../../../../../Components/BETable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { ColumnsType } from 'antd/es/table';
import { addBusinessUnitContributor } from '../../../../Actions/BusinessUnitActions';
import { BEMessage } from '../../../../../Components/BEMessage';

export interface IAddContributorToBusinessUnitProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
  businessUnit: any;
  usersToAdd: any;
  setUsersToAdd: Function;
}

export function AddContributorToBusinessUnit(props: IAddContributorToBusinessUnitProps) {
  const dispatch = useAppDispatch();
  const users = useTypedSelector(state => state.userMgmt);
  const businessUnitContributors = useTypedSelector(state => state.entity.BusinessUnitContributors);
  const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const columns: ColumnsType<any> = [
    {
      title: 'Contributor',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: any) =>record.first_name + ' ' + record.last_name
    }
  ]

  const handleSave = async () => {
    if(!props.isEdit){
      // if(selectedUsers.length === 0){
      //   BEMessage.error('Please select atleast one user');
      //   return;
      // }
      props.setUsersToAdd(selectedUsers);
      props.setOpen(false);
      return;
    }
    // if(selectedUsers.filter((user:any)=>!businessUnitContributors.data.find((contributor:any)=>contributor.user === user && contributor.business_unit === props.businessUnit.id)).length === 0){
    //   BEMessage.error('Please select atleast one user');
    //   return;
    // }
    setLoading(true);
    console.log(selectedUsers);
    let dataToAdd:any = [];
    selectedUsers.forEach((user:any)=>{
      if(!businessUnitContributors.data.find((contributor:any)=>contributor.user === user && contributor.business_unit === props.businessUnit.id))
      dataToAdd.push({
        user:user,
        business_unit:props.businessUnit.id
      })
    })
    let data = await dispatch(addBusinessUnitContributor(
      props.businessUnit.id,
      dataToAdd));
    setLoading(false);
    if (data) {
      props.setOpen(false);
    }
  }

  return (
    <BEDrawer
      heading='Add Contributors'
      open={props.open}
      setOpen={props.setOpen}
      
      footer={
        <div style={{ display: 'flex', gap: '1rem' }}>
          <BEButton
            loading={loading}
            size='large'
            className='primary'
            onClick={handleSave}
          >
            Save
          </BEButton>
          <BEButton
            size='large'
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </BEButton>
        </div>
      }
    >
       { props.open &&
       <BETable
          data={users.data.filter((user:any)=>user.role === 'CONTRIBUTOR')}
          columns={columns}
          handleRowSelection={(selectedRowKeys: any, selectedRows: any) => {
            setSelectedUsers(selectedRowKeys);
          }}
          rowKey='id'
          disabledRows={
            !props.isEdit?undefined:
            users.data.filter((user:any)=>businessUnitContributors.data.find((contributor:any)=>contributor.user === user.id && contributor.business_unit === props.businessUnit.id))}
          defaultSelectedRows={
            !props.isEdit?
            undefined
            :
            businessUnitContributors.data.filter((contributor:any)=>contributor.business_unit === props.businessUnit.id).map((contributor:any)=>contributor.user)
          }
       />}
    </BEDrawer>
  );
}
