
import React from 'react'
import IconProps from './Interface/IconProps';

const GreyESGIcon = (props:IconProps) => {
  return (
	<svg width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask id="mask0_1294_1551"  maskUnits="userSpaceOnUse" x="3" y="2" width="16" height="17">
	<path d="M13.0699 10.0655L16.5598 12.3547L11.7847 17.1302H6.00537L13.0699 10.0655ZM4.95253 4.74155L11.7981 9.23138L4.95253 16.0774V4.74155ZM4.20796 2.61914H3.46338V18.6191H4.20776H4.51641H12.4014L18.9058 12.1145L6.69934 4.10829H11.9493L14.4355 6.59462L13.4097 7.62021L14.6812 8.45431L16.5405 6.59482L12.5656 2.61934H4.20796V2.61914Z" fill="white"/>
	</mask>
	<g mask="url(#mask0_1294_1551)">
	<path d="M6.83721 25.0004L-3.14819 6.1151L15.532 -3.76172L25.5172 15.1236L6.83721 25.0004Z" fill="#BABABA"/>
	</g>
	</svg>
	
	
  );
}

export default GreyESGIcon