import * as React from 'react';
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBEWrapperProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export function BEWrapper (props: IBEWrapperProps) {
  return (
    <div style={{
      padding: '1rem',
      borderRadius:'10px',
      border:`1px solid ${PrimaryTheme.primaryGreenLight}`,
      width:'calc(100% - 2rem)',
      ...props.style
    }}>
      {props.children}
    </div>
  );
}
