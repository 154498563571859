import React from 'react'
import IconProps from './Interface/IconProps'

const NoDataIcon = (props: IconProps) => {
    return (
        <svg      width={props.inheritSize ? "100%" : 37}
				height={props.inheritSize ? "100%" : 37} viewBox="0 0 37 37" fill={props.fill? props.fill :'none'} xmlns="http://www.w3.org/2000/svg">
			<path d="M20.4583 20.4583H28.2917V28.2917H20.4583V20.4583Z" fill="#235E4A"/>
			<path d="M8.70833 20.4583H16.5417V28.2917H8.70833V20.4583Z" fill="#235E4A"/>
			<path d="M4.79167 0.875H32.2083C34.3625 0.875 36.125 2.6375 36.125 4.79167V32.2083C36.125 34.3625 34.3625 36.125 32.2083 36.125H4.79167C2.6375 36.125 0.875 34.3625 0.875 32.2083V4.79167C0.875 2.6375 2.6375 0.875 4.79167 0.875ZM4.79167 32.2083H32.2083V4.79167H4.79167V32.2083Z" fill="#235E4A"/>
			<path d="M20.4583 8.70833H28.2917V16.5417H20.4583V8.70833Z" fill="#235E4A"/>
			<path d="M8.70833 8.70833H16.5417V16.5417H8.70833V8.70833Z" fill="#235E4A"/>
		</svg>

    );
}

export default NoDataIcon