import { clearStore } from "../ExtraReducrActions";
import { SettingsState } from "../Types/settingsTypes";

import { Reducer, createSlice } from "@reduxjs/toolkit";

const initialState: SettingsState = {
  activeTab: 0,
  logs: {
    data: [],
    status: "idle",
  },
  years: {
    data: [],
    status: "idle",
  },
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setSettingsActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setYears: (state, action) => {
      state.years = action.payload;
    },
  },
});

export const {
  setSettingsActiveTab,
  setLogs,
  setYears,
} = userSlice.actions;

const companiesReducer: Reducer<SettingsState> = userSlice.reducer;

export default companiesReducer;
