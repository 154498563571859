import { useParams } from "react-router-dom";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import InfoIcon from "../../../../Components/BEIcons/InfoIcon";
import { changeSnakeCaseToSentence } from "../../../../Config/Functions/UsefullFunctions";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import { setScope3BasedOn } from "../../../../Redux/Scope3";
import { Scope3BasedOnType, Scope3TypeType } from '../../../../Redux/Types/scope3Types'
import './style.scss'
import { TypeSpendData } from "../../Data/data";

export interface NoDataScreenProps {

	description?: any;
	size?: 'small' | 'middle' | 'large'; //by default medium
	icon?: any
	buttonDetails: any[]
	buttonLabel?: any
	onClick?: Function
	setOpenUpload?: Function
}
export function NoDataScreen(props: NoDataScreenProps) {
	const dispatch = useAppDispatch()
  const { type } = useParams<{ type: Scope3TypeType }>();

	return (
		<div className={'be-no-data ' + props.size}>
			<div className='empty-icon'>
				<div className='icon-box'>{props.icon ? props.icon : <InfoIcon fill={PrimaryTheme.primaryGreenDark} inheritSize />}</div>
			</div>
			<div className='description'>
				<span >{props.description}</span>
			</div>
			<div style={{ display: 'flex', gap: '1rem' }}>
				{
					props.buttonDetails.map((button: Scope3BasedOnType) => {
						return (
							<>
								<BEButton size={props.size || 'middle'} className={props.buttonDetails?.length > 1 ? 'secondary-green' : 'primary'}
									onClick={() => {
										dispatch(setScope3BasedOn(button))
										if (props.setOpenUpload) {
											props.setOpenUpload(true)
										}
									}}>
									{`+ ${props.buttonDetails?.length > 1 ? 
										TypeSpendData[type as Scope3TypeType]?.[button as Scope3BasedOnType]?.changeCalculationMethod?.name ?
										TypeSpendData[type as Scope3TypeType]?.[button as Scope3BasedOnType]?.changeCalculationMethod?.name :
										changeSnakeCaseToSentence(button)
									:'Add Data'}`}
								</BEButton>
							</>
						)
					})
				}

			</div>
		</div>
	)
}





