import * as React from 'react';
import './style.scss';
import { DashboardCard } from '../../../../Components/Cards/DashboardCard';
import { BEWrapper } from '../../../../Components/BEWrapper';
import StackedColumnCharts from '../../../../Components/BECharts/StackedColumnCharts';
import { shortMonths } from '../../../../Config/Data/genralData';
import { NewBETable } from '../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';

export interface IScope3ViewProps {
}

let data = [{name: 'Scope 1', data:[5,4,3,4,6,3,2,4,5,6,7,8]}, {name: 'Scope 2', data:[5,4,3,4,6,3,2,4,5,6,7,8]}, {name: 'Scope 3', data:[5,4,3,15,6,3,2,4,5,6,7,8]}]


export function Scope3View (props: IScope3ViewProps) {
  
  let columns:ColumnsType<any> = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Uploaded By',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploadedOn',
      key: 'uploadedOn',
    },
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'File Size',
      dataIndex: 'fileSize',
      key: 'fileSize',
    },
  ]

  let dummyData = [
    {companyName: 'Company 1', uploadedBy: 'John Doe', uploadedOn: '12/12/2021', fileName: 'file1', fileSize: '2MB'},
    {companyName: 'Company 2', uploadedBy: 'John Doe', uploadedOn: '12/12/2021', fileName: 'file2', fileSize: '2MB'},
    {companyName: 'Company 3', uploadedBy: 'John Doe', uploadedOn: '12/12/2021', fileName: 'file3', fileSize: '2MB'},
    {companyName: 'Company 4', uploadedBy: 'John Doe', uploadedOn: '12/12/2021', fileName: 'file4', fileSize: '2MB'},
  ]

  return (
    <div className='scope3'>
        <h3>Total Scope 3 emissions</h3>
        <div className='scope-cards'>
            <DashboardCard metricName='Scope 3' metricValue={34} metricUnit='tCO2e'/>
        </div>
        <div className='scope-graph'> 
          <BEWrapper>
            <StackedColumnCharts 
              yAxisLabel='tCO2e'
              data={data}
              xAxisLabels={shortMonths}
            />
          </BEWrapper>
        </div>
        <h3>Scope 3 Emissions by Category </h3>
        <div className='scope-cards'>
            <DashboardCard metricName='Purchased goods and services' metricValue={3334} metricUnit='tCO2e'/>
            <DashboardCard metricName='Capital goods' metricValue={235} metricUnit='tCO2e'/>
            <DashboardCard metricName='Business travel (transport)' metricValue={543} metricUnit='tCO2e'/>
            <DashboardCard metricName='Business travel (stay)' metricValue={45} metricUnit='tCO2e'/>
            <DashboardCard metricName='Waste generated in operations' metricValue={566} metricUnit='tCO2e'/>
        </div>
        <h3>Data uploads</h3>
        <NewBETable
          search
          columns={columns}
          data={dummyData}
          pagination
        />
    </div>
  );
}
