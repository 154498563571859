import * as React from 'react';

export interface ILableRequiredProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export function LableRequired (props: ILableRequiredProps) {
  return (
    <p style={props.style}>
        {props.children}<span style={{color:'red'}}>*</span>
    </p>
  );
}
