import React from 'react'
import IconProps from './Interface/IconProps';

const MarketPlace = (props:IconProps) => {
  return (
	<svg width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g opacity="0.7">
	<path d="M2.5 5.83382L8.33333 2.50049L17.5 5.83382M2.5 5.83382V10.0005L11.6667 13.3338L17.5 10.0005V5.83382M2.5 5.83382L11.6667 9.16715L17.5 5.83382" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
	<path d="M2.5 10V14.1667L11.6667 17.5L17.5 14.1667V10" stroke="white" stroke-width="2" stroke-linejoin="round"/>
	</g>
	</svg>
  );
}

export default MarketPlace