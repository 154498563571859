import { PrimaryTheme } from "../../../../Config/Theme/theames"
import './styles.scss'
interface EmissionFactorSourceCardProps{
	icon:React.ReactNode
	title:string,
	description:string
}

export function EmissionFactorSourceCard(props:EmissionFactorSourceCardProps){
	return (
	<div className="card">
		<div className="icon" >{props.icon}</div>
		<h4 className="title">{props.title}</h4>
		<p className="description">{props.description}</p>
	</div>)
}