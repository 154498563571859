import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { getOnboardingStage } from "../../../Actions/OnboardingActions";
import { setOnboardingStage } from "../../../../Redux/CommonReducer";
import { navigateTo } from "../../../../Navigation/NavigationManager";
import CompanyIcon from "../../../../Components/BEIcons/CompanyIcon";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import UsersIcon from "../../../../Components/BEIcons/UsersIcon";
import EntityMappingIcon from "../../../../Components/BEIcons/EntityMappingIcon";
import './style.scss'
import { OnboardingNavButtons } from "../NavButtons";

interface IStepProps {
  unaccessible?: boolean;
  title: string;
  icon: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

function Step(props: IStepProps) {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        fontSize: "0.8rem",
        color: PrimaryTheme.primaryGray,
        cursor: props.unaccessible ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        if (!props.unaccessible) props.onClick();
      }}
    >
      <div style={{ height: "1.2rem" }}>{props.icon}</div>
      <p
        style={{
          fontWeight: 'bold',
          color: props.active
            ? PrimaryTheme.secondaryGreenLight
            : PrimaryTheme.primaryBlack,
        }}
      >
        {props.title}
      </p>
    </div>
  );
}

interface IStepsViewProps {
  handleSave?:Function
}

export function StepsView(props: IStepsViewProps) {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const onboardingStage = useTypedSelector(
    (state) => state.common.onboardingStage
  );
  const location = useLocation()
  const role = localStorage.getItem("role");

  

  React.useEffect(() => {
    if (onboardingStage.status === "idle") {
      if (role === 'SUPER_ADMIN') dispatch(getOnboardingStage());
      else {
        dispatch(setOnboardingStage({
          stage: 5,
          status: 'success'
        }))
      }
    }
    else if (onboardingStage.status !== "loading") {

      // check if we are trying to navigate to a step which is beyond the stage no

      if (location.pathname.includes('step') && onboardingStage.stage < Number(location.pathname.split('step')[1])) {
        navigateTo(`/onboarding/step${onboardingStage.stage + 1}`)
      }
      else if (!location.pathname.includes('step')) {
        navigateTo(`/onboarding/step${onboardingStage.stage + 1}`)
      }
    }
  }, [location.pathname, onboardingStage]);

  // Function to navigate to the correct stepon initial load
  //   const navigateToCorrectStepOnLoad = async () => {
  //     await dispatch(getOnboardingStage());
  //     if (onboardingStage.stage < 5)
  //       navigateTo(`/onboarding/step${onboardingStage.stage+1}`);
  //     else navigateTo('/onboarding/step1')
  //     }

  //   React.useEffect(() => {
  //     if(searchParams.get("redirect_from") !== 'settings') navigateToCorrectStepOnLoad()
  // }, []);
  const currentStep = useTypedSelector(state => state.common.onboardingStage.stage);

  const StepMap =
    location.pathname === "/onboarding/step5"
      ? [
        {
          title: "REVIEW CONFIGURATIONS",
          icon: (
            <CompanyIcon
              inheritSize
              stroke={
                location.pathname === "/onboarding/step5"
                  ? PrimaryTheme.secondaryGreenLight
                  : PrimaryTheme.primaryGray
              }
            />
          ),
          link: "/onboarding/step5",
        },
      ]
      : [
        {
          title: "ABOUT THE COMPANY",
          icon: (
            <CompanyIcon
              inheritSize
              stroke={
                location.pathname === "/onboarding/step1"
                  ? PrimaryTheme.secondaryGreenLight
                  : PrimaryTheme.primaryGray
              }
            />
          ),
          link: "/onboarding/step1",
        },
        {
          title: "USERS",
          icon: (
            <UsersIcon
              inheritSize
              fill={
                location.pathname === "/onboarding/step2"
                  ? PrimaryTheme.secondaryGreenLight
                  : PrimaryTheme.primaryGray
              }
            />
          ),
          link: "/onboarding/step2",
        },
        {
          title: "ENTITY MAPPING",
          icon: (
            <EntityMappingIcon
              inheritSize
              stroke={
                location.pathname === "/onboarding/step3"
                  ? PrimaryTheme.secondaryGreenLight
                  : PrimaryTheme.primaryGray
              }
            />
          ),
          link: "/onboarding/step3",
        },
        // {
        //   title: "ESG METRICS & REPORTING",
        //   icon: (
        //     <ESGMetricsIcon
        //       inheritSize
        //       fill={
        //         location.pathname === "/onboarding/step4"
        //           ? PrimaryTheme.secondaryGreenLight
        //           : PrimaryTheme.primaryGray
        //       }
        //     />
        //   ),
        //   link: "/onboarding/step4",
        // },
      ];

  return (
   <div className="steps-view-box">
      <div className="steps-view"
      >
        {
        StepMap.map((step, index) => {
          return (
            <>
              <Step
                unaccessible={index > currentStep}
                title={step.title}
                icon={step.icon}
                active={location.pathname === `/onboarding/step${index + 1}` || location.pathname === '/onboarding/review'}
                onClick={() => navigateTo(step.link)}
              />
              {
                index < StepMap.length - 1 && <div className="step-separator">
                  {'>>'}
                </div>
              
              }
            </>
          );
        })
        }
      </div>
      <div>
        <OnboardingNavButtons handleSave={async () => {
          if (props.handleSave) await props.handleSave();
        }} />
      </div>
   </div>
  );
}