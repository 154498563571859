import React, { useEffect, useState } from "react";
import AddUser from "../Drawers/AddUser";
import { BEButton } from "../../../Components/BEFormItems/BEButton";
import Users from "../../../assets/images/OnboardingImages/Users.png"
import "./styles.scss";
import UserListTable from "./UserListTable";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import { getAllUsers } from "../../Actions/UsersManagementActions";
import { OnboardingNavButtons } from "../Components/NavButtons";
import DownloadExcelTemplate from "../../../Utils/Download/Excell/DownloadTemplate";
import Upload from "antd/es/upload/Upload";
import { bulkUploadUsers } from "../../Actions/UsersManagementActions";
import { StepsView } from "../Components/StepView";

const Step3 = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const users = useTypedSelector((state) => state.userMgmt);
  const selectedRow = useTypedSelector((state) => state.common.selectedRow);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log(selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const bulkUpload = async (file: any) => {
    const formData = {
      User_bulk_upload_template: file.file,
    }
    await dispatch(bulkUploadUsers(formData));
  }
  

  return (
    <div>
      <StepsView />
      <div className="user-mgmt">
        <AddUser open={openDrawer} setOpen={setOpenDrawer} />
        <AddUser isEdit open={openEditDrawer} setOpen={setOpenEditDrawer} />
        {(users.data.length === 0 && users.status === 'success') ? (
          <div className="empty">
            <div>
              <img src={Users} alt="user" />
            </div>
            <p>
              No Users have been added yet.
            </p>
            <BEButton
              size="large"
              className="primary"
              onClick={() => setOpenDrawer(true)}
            >
              + Add User
            </BEButton>
            <BEButton size="large" className="primary"
              onClick={()=>{
                DownloadExcelTemplate(['first_name','last_name','email','role','department'])
              }}
            >
              Download Template
            </BEButton>
            <Upload
                beforeUpload={(e) => {
                  bulkUpload(e);
                  return false;
                }}
                action={undefined}
                showUploadList={false}
                multiple={false}
                fileList={[]}
              >
                {" "}
                <BEButton size="large" className="primary">
                 Bulk Upload
                </BEButton>
              </Upload>
              
          </div>
        ) : (
          <UserListTable
            loading={users.status === "loading"}
            users={users.data}
            setAddUser={setOpenDrawer}
            setEditUser={setOpenEditDrawer}
          />
        )}
      </div>
    </div>
  );
};

export default Step3;
