import EmissionCard from "../../../../../../../../Components/Cards/EmissionCard";
import { AddCommaToNumber } from "../../../../../../../../Config/Functions/UsefullFunctions";
import { Scope3TypeType } from "../../../../../../../../Redux/Types/scope3Types";
import { 
	BUSINESS_STAY,
	BUSINESS_TRANSPORT, 
	CAPITAL_GOODS, DOWNSTREAM_LOGISTICS, 
	END_OF_LIFE_WASTE_TREATMENT, 
	PROCUREMENT, 
	PURCHASED_GOODS_AND_SERVICES, 
	UPSTREAM_LOGISTICS, 
	WASTE_GENERATED,
	SPEND_BASED
 } from "../../../../../../Data/constants";
import { useTypedSelector } from "../../../../../../../../Config/Hooks/useTypedSelector";
import { useParams } from "react-router-dom";
import { EmissionByCategories } from "./Graphs";
import { Scope3CustomDonutChart } from "./Graphs/Scope3CustomDonutChart";
import { BEWrapper } from "../../../../../../../../Components/BEWrapper";
import { BENoData } from "../../../../../../../../Components/BENoData";
import NoDataIcon from "../../../../../../../../Components/BEIcons/NoDataIcon";
import { NoDataScreen } from "../../../../../../Components/NoDataScreen";
import { useAppDispatch } from "../../../../../../../../Config/Hooks/useAppDispatch";
import { setScope3BasedOn } from "../../../../../../../../Redux/Scope3";
import { GlobalLoader } from "../../../../../../../../Components/GlobalLoader";

const TypeNames: { [key: string]: string } = {
	[PROCUREMENT]: 'PROCUREMENT',
	[BUSINESS_STAY]: 'BUSINESS ACCOMMODATION',
	[BUSINESS_TRANSPORT]: 'BUSINESS TRANSPORT',
	[UPSTREAM_LOGISTICS]: 'UPSTREAM TRANSPORTATION',
	[DOWNSTREAM_LOGISTICS]: 'DOWNSTREAM TRANSPORTATION',
	[WASTE_GENERATED]:'WASTE GENERATED IN OPERATIONS',
	[END_OF_LIFE_WASTE_TREATMENT]:'END OF LIFE TREATMENT OF SOLD PRODUCTS'
  }

  interface DashboardDataScreenProps{
	setOpenUpload?:Function
  }
export function DashboardDataScreen(props:DashboardDataScreenProps){
	const emissionsCalculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
	const selectedBasedOnFortype=useTypedSelector(state=>state.scope3.selectedBasedOnForType)
	const { type } = useParams<{ type: Scope3TypeType }>();
	const dispatch=useAppDispatch()

	if(emissionsCalculations.status === 'loading') return <GlobalLoader height="50vh"/>
	return (<>
	{
		emissionsCalculations.data.reduce((acc: number, item: any) => item.category === type ? acc + item.tco2e : acc, 0) !=0 ?		
		<>
		<div className=' calculations-box'>
		<EmissionCard description={TypeNames[type as Scope3TypeType] + ' EMISSIONS'} value={
		AddCommaToNumber(emissionsCalculations.data.reduce((acc: number, item: any) => item.category === type ? acc + item.tco2e : acc, 0), false, true,false,3)
		} unit='tCO2e' />
		{
		type === PROCUREMENT &&
		<>
			<p className='operator'>=</p>
			<EmissionCard description='PURCHASED GOODS & SERVICES' value={
			AddCommaToNumber(emissionsCalculations.data.reduce((acc: number, item: any) => item.emission_category === PURCHASED_GOODS_AND_SERVICES ? acc + item.tco2e : acc, 0), false, true,false,3)
			} unit='tCO2e' />
			<p className='operator'>+</p>
			<EmissionCard description='CAPITAL GOODS' value={
			AddCommaToNumber(emissionsCalculations.data.reduce((acc: number, item: any) => item.emission_category === CAPITAL_GOODS ? acc + item.tco2e : acc, 0), false, true,false,3)
			} unit='tCO2e' />
		</>
		}
		</div>
		<hr/>
		<div className="scope3-dashboard-graphs-box">
				<BEWrapper>
					<div className="scope3-dashboard-graphs">
						{/* <EmissionByCategories/> */}
						<Scope3CustomDonutChart/>
					</div>
				</BEWrapper>
		</div>
		</>:
		<div style={{height:'400px',display:'flex',alignItems:'center'}}>
			{/* {
			type !== PROCUREMENT ? 
				<BENoData icon={<NoDataIcon/>}
					description="Click the add data button below to add data to the emission category"
					buttonLabel="+ Add data" 
					onClick={()=>{
						dispatch(setScope3BasedOn(SPEND_BASED))
						if(props.setOpenUpload){
							props.setOpenUpload(true)
						}
					}}
			/>
			: */}
				<NoDataScreen 
					buttonDetails={selectedBasedOnFortype}
					setOpenUpload={props.setOpenUpload}
					icon={<NoDataIcon/>} 
					description={
					selectedBasedOnFortype.length > 1 ?
					<>Choose one of the methods below to start adding data. Once your data is added, you'll be able to view <br/> detailed graphs and emission calculations</>
					:
					<>Click the below button to add data. Once your data is added, you'll be able to view <br/> detailed graphs and emission calculations</>
				}
				/>
					{/* } */}
		</div>
	}
  </>)
}