import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { filterDataByMonthOrBU } from '../../../../Actions';

export interface ITotalEmissionsByScopeProps {
}

export function TotalEmissionsByScopeGraph(props: ITotalEmissionsByScopeProps) {
  const dispatch =useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const scope1Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope1Calculations);
  const scope2Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope2Calculations);
  const scope3Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const [filteredScope1Calculations, setFilteredScope1Calculations] = React.useState<any>([]);
  const [filteredScope2Calculations, setFilteredScope2Calculations] = React.useState<any>([]);
  const [filteredScope3Calculations, setFilteredScope3Calculations] = React.useState<any>([]);
  const [chartData, setChartData] = React.useState<any>([]);

  const setFilteredData = async() => {
    let data = await dispatch(filterDataByMonthOrBU(false,true));
    setFilteredScope1Calculations(data.scope1Data);
    setFilteredScope2Calculations(data.scope2Data);
    setFilteredScope3Calculations(data.scope3Data);
    console.log('scope3',data.scope3Data,)
  }

  React.useEffect(() => {
    if(scope1Calculations.status === 'success' && scope2Calculations.status === 'success' && scope3Calculations.status === 'success') 
      setFilteredData();
  }, [scope1Calculations,scope2Calculations,scope3Calculations, currentBU,currentBG]);

  React.useEffect(() => {
    let scope1MonthsAggrigateArey: any = new Array(12).fill(0);
    let scope2MonthsAggrigateArey: any = new Array(12).fill(0);
    let scope3MonthsAggrigateArey: any = new Array(12).fill(0);
    
    filteredScope1Calculations.forEach((element: any) => {
      let monthIndex = ((element.month-1)+ 12 - yearEnd)%12;
      scope1MonthsAggrigateArey[monthIndex] += element.tco2e;
    });
    filteredScope2Calculations.forEach((element: any) => {
      let monthIndex = ((element.month-1)+ 12 - yearEnd)%12;
      scope2MonthsAggrigateArey[monthIndex] += element.tco2e;
    });
    filteredScope3Calculations.forEach((element: any) => {
      let monthIndex = ((element.month-1)+ 12 - yearEnd)%12;
      scope3MonthsAggrigateArey[monthIndex] += element.tco2e;
    });
    scope1MonthsAggrigateArey = scope1MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    scope2MonthsAggrigateArey = scope2MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    scope3MonthsAggrigateArey = scope3MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    console.log('scope3MonthsAggrigateArey',scope3MonthsAggrigateArey)
    let tempChartData: any = [
      {
        name: 'Scope 1',
        data: scope1MonthsAggrigateArey
      },
      {
        name: 'Scope 2',
        data: scope2MonthsAggrigateArey
      },
      {
        name: 'Scope 3',
        data: scope3MonthsAggrigateArey
      }
    ]
    setChartData(tempChartData)
  }, [filteredScope1Calculations,filteredScope2Calculations,filteredScope3Calculations]);

  return (
    <div style={{ margin: "2rem 0" }}>
      <p className='chart-title'>Total emissions by Scope (tCO2e)</p>
      <div style={{ padding: "1rem" }} className='bordered-container'>
        <StackedColumnCharts
          data={chartData}
          xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
          // chartTitle="Total emissions by Scope (tCO2e)"
          yAxisLabel="Tonnes of Co2 equivalent"
        />
      </div>
    </div>
  );
}
