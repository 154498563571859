import * as React from 'react';
import welcomeImage from '../../../../assets/images/WelcomeSvgs/welcomeConfigurationImage.svg';
import { WelcomeScreen } from '..';

export interface IConfigurstionWelcomeScreenProps {
  onClick:Function;
}

export function ConfigurstionWelcomeScreen (props: IConfigurstionWelcomeScreenProps) {
  return (
    <div>
      <WelcomeScreen
        buttonText='Go to Home'
        description='Congratulations on starting your Net-Zero journey with Breathe Zero.'
        image={welcomeImage}
        onClick={props.onClick}
      />
    </div>
  );
}
