
import React from 'react'
import IconProps from './Interface/IconProps';

const UploadManagerIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
	  viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	  <path  fill={props.fill ? props.fill : '24px'} d="M12.3346 28.6663H15.668V21.708L18.3346 24.3747L20.668 21.9997L14.0013 15.333L7.33463 21.9997L9.70963 24.333L12.3346 21.708V28.6663ZM4.0013 33.6663C3.08464 33.6663 2.29991 33.34 1.64714 32.6872C0.994358 32.0344 0.667969 31.2497 0.667969 30.333V3.66634C0.667969 2.74967 0.994358 1.96495 1.64714 1.31217C2.29991 0.659397 3.08464 0.333008 4.0013 0.333008H17.3346L27.3346 10.333V30.333C27.3346 31.2497 27.0082 32.0344 26.3555 32.6872C25.7027 33.34 24.918 33.6663 24.0013 33.6663H4.0013ZM15.668 11.9997V3.66634H4.0013V30.333H24.0013V11.9997H15.668Z" />
	  </svg>
  );
}

export default UploadManagerIcon


