import * as React from "react";
import { Topbar } from "../../../Components/TopBar";
import { Outlet} from "react-router-dom";
import "./style.scss";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import { GlobalLoader } from "../../../Components/GlobalLoader";
import { getOnboardingStage } from "../../Actions/OnboardingActions";
import { ConfigurstionWelcomeScreen } from "../../Home/WelcomeScreens/ConfigurationWelcomeScreen";
import Logo from '../../../assets/images/Company/ZeroLogo.png';

export interface IOnboardingViewProps { }

export function OnboardingView(props: IOnboardingViewProps) {
  const dispatch = useAppDispatch();
  const onboardingStage = useTypedSelector((state) => state.common.onboardingStage);
  const [showWelcomeScreen, setShowWelcomeScreen] = React.useState(true);
  const {first_name,last_name} = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const role = localStorage.getItem("role");

  React.useEffect(() => {
    if (onboardingStage.status === "idle" && role === "SUPER_ADMIN")
    dispatch(getOnboardingStage());
  }, []);

  if (onboardingStage.status === 'loading') return <GlobalLoader />

  if (onboardingStage.stage === 0 && showWelcomeScreen && role === "SUPER_ADMIN") {
    return (
      <ConfigurstionWelcomeScreen
        onClick={() => setShowWelcomeScreen(false)}
      />
    )
  }

  return (
    <div className="onboarding-layout">
      <Topbar mode='light' logo={Logo} imageStyle={{height:'30px'}} firstName={first_name} lastName={last_name}/>
      <div className="onboarding-box">
        <Outlet />
      </div>
    </div>
  );
}
