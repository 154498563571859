import { configureStore, createAction } from "@reduxjs/toolkit";
import userReducer from "./UserReducer";
import companiesReducer from "./CompaniesReducer";
import onBoadingReducer from "./OnboardingReducer";
import entityReducer from "./EntityReducer";
import userManagementReducer from "./UserManagementReducer";
import commonReducer from "./CommonReducer";
import scope3Reducer from "./Scope3";
import settingsReducer from "./SettingsReducer";
import dashboardReducer from "./DashboardReducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    companies: companiesReducer,
    onBoarding: onBoadingReducer,
    entity: entityReducer,
    userMgmt: userManagementReducer,
    common: commonReducer,
    scope3:scope3Reducer,
    settings:settingsReducer,
    dashboard:dashboardReducer
  },
});

//to clear the store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
