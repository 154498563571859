
import React from 'react'
import IconProps from './Interface/IconProps';

const AverageBasedCalculation = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.5833 29.5L13.5 19.3333C12.9444 19.5556 12.3819 19.7222 11.8125 19.8333C11.2431 19.9444 10.6389 20 10 20C7.22222 20 4.86111 19.0278 2.91667 17.0833C0.972222 15.1389 0 12.7778 0 10C0 9 0.138889 8.04861 0.416667 7.14583C0.694444 6.24306 1.08333 5.38889 1.58333 4.58333L7.66667 10.6667L10.6667 7.66667L4.58333 1.58333C5.38889 1.08333 6.24306 0.694444 7.14583 0.416667C8.04861 0.138889 9 0 10 0C12.7778 0 15.1389 0.972222 17.0833 2.91667C19.0278 4.86111 20 7.22222 20 10C20 10.6389 19.9444 11.2431 19.8333 11.8125C19.7222 12.3819 19.5556 12.9444 19.3333 13.5L29.5 23.5833C29.8333 23.9167 30 24.3194 30 24.7917C30 25.2639 29.8333 25.6667 29.5 26L26 29.5C25.6667 29.8333 25.2639 30 24.7917 30C24.3194 30 23.9167 29.8333 23.5833 29.5ZM24.7917 25.9583L25.9167 24.8333L15.25 14.1667C15.75 13.6111 16.1111 12.9653 16.3333 12.2292C16.5556 11.4931 16.6667 10.75 16.6667 10C16.6667 8.33333 16.1319 6.88194 15.0625 5.64583C13.9931 4.40972 12.6667 3.66667 11.0833 3.41667L14.1667 6.5C14.5 6.83333 14.6667 7.22222 14.6667 7.66667C14.6667 8.11111 14.5 8.5 14.1667 8.83333L8.83333 14.1667C8.5 14.5 8.11111 14.6667 7.66667 14.6667C7.22222 14.6667 6.83333 14.5 6.5 14.1667L3.41667 11.0833C3.66667 12.6667 4.40972 13.9931 5.64583 15.0625C6.88194 16.1319 8.33333 16.6667 10 16.6667C10.7222 16.6667 11.4444 16.5556 12.1667 16.3333C12.8889 16.1111 13.5417 15.7639 14.125 15.2917L24.7917 25.9583Z"  fill={props.fill ? props.fill : '24px'}/>
	  </svg>
  );
}

export default AverageBasedCalculation


