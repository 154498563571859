import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import businessGroupOnboardingImage from '../../../../assets/images/OnboardingImages/businessGroupsOnboardingImage.png';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BusinessGroupcard } from '../../../../Components/Cards/BusinessGroupCard';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getBusinessGroups } from '../../../Actions/BusinessGroupActions';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { AddBusinessGroup } from '../../Drawers/AddBusinessGroup';
import { getAllUsers } from '../../../Actions/UsersManagementActions';
import { getBusinessUnitRefs } from '../../../Actions/BusinessUnitActions';
import '../style.scss';
import dayjs from 'dayjs';
import { menubarWidth, menubarWidthCollapsed } from '../../../../Config/Constants';
import { BEWrapper } from '../../../../Components/BEWrapper';

export interface IBusinessGroupsSectionProps {
}

export function BusinessGroupsSection(props: IBusinessGroupsSectionProps) {
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups.data);
  const businessGroupsStatus = useTypedSelector(state => state.entity.BusinessGroups.status);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const [filteredBusinessGroups, setFilteredBusinessGroups] = React.useState(businessGroups);
  const dispatch = useAppDispatch();
  const [openAddBusinessGroup, setOpenAddBusinessGroup] = React.useState(false);
  const collapsed = useTypedSelector(state => state.common.menuCollapsed)

  React.useEffect(() => {
    dispatch(getAllUsers())
  }, [])

  React.useEffect(() => {
    if (!openAddBusinessGroup) {
      if (businessGroupsStatus === 'idle')
        dispatch(getBusinessGroups())
      if (businessUnitRefs.status === 'idle')
        dispatch(getBusinessUnitRefs())
    }
  }, [openAddBusinessGroup])

  React.useEffect(() => {
    setFilteredBusinessGroups(businessGroups)
  }, [businessGroups])

  if (businessGroupsStatus === 'loading') {
    return <GlobalLoader height='12rem' size='default' />
  }
  return (
    <div className='business-groups-section'
    >
      
      <p className='title'><strong>Business Groups</strong></p>
      <BEWrapper>
        <div className='headings'>
          {
            businessGroups.length !== 0 &&
            <>
              <BEInput
                onChange={(e) => {
                  const filtered = businessGroups.filter((businessGroup) => {
                    return businessGroup.name.toLowerCase().includes(e.target.value.toLowerCase())
                  })
                  setFilteredBusinessGroups(filtered)
                }}
                placeholder='Search for business groups' style={{ width: '313px' }} search size="large" />
              <BEButton size='large' className='primary'
                onClick={() => setOpenAddBusinessGroup(true)}
              >+ Add Business Group</BEButton>
            </>
          }

        </div>
        {
          businessGroups.length === 0 ?
            <div className='empty-state'>
              <div >
                <img src={businessGroupOnboardingImage} alt='businessGroupImg' />
              </div>
              <p>Click the button below to add a Business Group!</p>
              <BEButton size='large' className='primary' onClick={() => setOpenAddBusinessGroup(true)}>+ Add Business Group</BEButton>
            </div>

            :

            <div className='cards-wraper'>
              <div className='all-cards'
                style={{
                  width: collapsed ? `calc(100vw - 8rem - ${menubarWidthCollapsed})` : `calc(100vw - 8rem - ${menubarWidth})`
                }}
              >

                {
                  //sort by latest time and date using timestamp
                  [...filteredBusinessGroups].sort((a, b) => {
                    return dayjs(b.last_updated).unix() - dayjs(a.last_updated).unix()
                  })
                    .map((businessGroup, index) => {
                      return <BusinessGroupcard businessGroup={businessGroup} />
                    })
                }

              </div>
            </div>
        }
        <AddBusinessGroup open={openAddBusinessGroup} setOpen={setOpenAddBusinessGroup} />

      </BEWrapper>
    </div>
  );
}
