import * as React from 'react';
import './style.scss';
import SettingsItemIcon from '../../BEIcons/SettingsItemIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';

export interface ISettingsCardProps {
  title: string;
  onClick: Function;
  onMouseEnter: Function;
  icon?:React.ReactNode
}

export function SettingsCard(props: ISettingsCardProps) {
  return (
    <div
      className='new-folder'
      onClick={() => props.onClick()}
      onMouseEnter={() => props.onMouseEnter()}
    >
      <div className='circle'>
        <div className='settings-icon' >
          {props.icon ? props.icon : <SettingsItemIcon inheritSize fill={PrimaryTheme.primaryGreen}/>}
        </div>
      </div>
      <span style={{ fontSize: "14px", lineHeight: "21px", fontWeight: "600" ,textAlign:'center'}}>{props.title}</span>
    </div>
  );
}
