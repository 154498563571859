// import { CompanyState } from "../Types/companyTypes";
import { clearStore } from "../ExtraReducrActions";
import { onboardingState, stage1Interface } from "../Types/onBoarding";
import { Reducer, createSlice } from "@reduxjs/toolkit";
import { Scope3State } from "../Types/scope3Types";
import { SPEND_BASED } from "../../Features/Scope3/Data/constants";

const initialState: Scope3State= {
  methods: {
    status: "idle",
    data: [],
  },
  dashboardData: {
    graphData: {
      status: "idle",
      data: [],
    }
  },
  dataUpload:{
    currentFile: null,
    fileData: {
      file: null,
      file_name: "",
      description: "",
      year: "",
      currency: "",
    },
    excleHeaders: [],
    systemFeilds: {
      status: "idle",
      data: [],
    },
    step3Data: null
  },
  typeData:{
    status: "idle",
    data: [],
    filterValues: {},
    totalPages: 0
  },
  catagoryConfigurations: {
    status: "idle",
    data: [],
  },
  currentCategory: null,
  emissionFactors: {
    status: "idle",
    data: [],
  },
  expenditureTypesReference: {
    status: "idle",
    data: [],
  },
  type: "",
  basedOn: null,  //spend-based , quantity-based , passenger-distance-based, freight-distance-based
  typeFiles:{
    status: "idle",
    data: [],
  },
  selectedBasedOnForType: [],
  formulaCalculation: {
    formulas: {
      status: "idle",
      data: [],
    },
    variables: {
      status: "idle",
      data: [],
    },
    currentFormula: null,
  }
};

export const scope3Slicer = createSlice({
  name: "onboarding",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setMethods: (state, action) => {
      state.methods = action.payload;
    },
    setScope3DashboardGraphData: (state, action) => {
      state.dashboardData.graphData = action.payload;
    },
    setExcleHeaders: (state, action) => {
      state.dataUpload.excleHeaders = action.payload;
    },
    setDataUploadFileData: (state, action) => {
      state.dataUpload.fileData = action.payload;
    },
    setTypeData: (state, action) => {
      state.typeData = action.payload;
    },
    setSystemFeilds: (state, action) => {
      state.dataUpload.systemFeilds = action.payload;
    },
    setStep3Data: (state, action) => {
      state.dataUpload.step3Data = action.payload;
    },
    setCatagoryConfigurations: (state, action) => {
      state.catagoryConfigurations = action.payload;
    },
    setEmissionFactors: (state, action) => {
      state.emissionFactors = action.payload;
    },
    setExpenditureTypesReference: (state, action) => {
      state.expenditureTypesReference = action.payload;
    },
    setScope3Type: (state, action) => {
      state.type = action.payload;
    },
    setScope3BasedOn: (state, action) => {
      state.basedOn = action.payload;
    },
    setBasedOnForType: (state, action) => {
      state.selectedBasedOnForType = action.payload;
    },
    setTypeFiles: (state, action) => {
      state.typeFiles = action.payload;
    },
    setCurrentFile: (state, action) => {
      state.dataUpload.currentFile = action.payload;
    },
    setFormulas: (state, action) => {
      state.formulaCalculation.formulas = action.payload;
    },
    setVariables: (state, action) => {
      state.formulaCalculation.variables = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setCurrentFormula: (state, action) => {
      state.formulaCalculation.currentFormula = action.payload;
    }
  },
});

export const {
  setMethods,
  setScope3DashboardGraphData,
  setExcleHeaders,
  setDataUploadFileData,
  setTypeData,
  setSystemFeilds,
  setStep3Data,
  setCatagoryConfigurations,
  setEmissionFactors,
  setExpenditureTypesReference,
  setScope3Type,
  setScope3BasedOn,
  setBasedOnForType,
  setTypeFiles,
  setCurrentFile,
  setFormulas,
  setVariables ,
  setCurrentCategory,
  setCurrentFormula
} = scope3Slicer.actions;

const scope3Reducer: Reducer<Scope3State> = scope3Slicer.reducer;

export default scope3Reducer;