import * as React from 'react';
import { Table } from 'antd';
import { ColumnsType, ColumnType, TableProps } from "antd/es/table";
import ArrowDownIcon from '../../BEIcons/ArrowDownIcon';
import FilterIcon from '../../BEIcons/FilterIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { TableRowSelection } from 'antd/es/table/interface';
import { TableActions } from '.././TableActions';
import '../style.scss'
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { setSelectedRow } from '../../../Redux/CommonReducer';

//***//filter dropdown to be made

export interface IBETableProps {
  data: Array<any>,
  columns: ColumnsType<any>;
  actionFunction: Function,
  loading?: boolean,
  tableProps?: TableProps<any>
  className?: string
  rowClassName?: any
  formatTitle?: boolean
  filterValues: any
  totalPages: number
}

export function FiltrableTable(props: IBETableProps) {
  const [coloumns, setColoumns] = React.useState<ColumnsType<any>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [sortBy, setSortBy] = React.useState<string>('');
  const [sortOrder, setSortOrder] = React.useState<string>('asc'); // 'asc' or 'desc'
  const [filters, setFilters] = React.useState<any>({});
  const dispatch = useAppDispatch();

  const sortOrderMap: any = {
    'ascend': 'asc',
    'descend': 'desc'
  }

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('params', pagination, filters, sorter, extra);
    //convert page,filter and sorter to query params
    let query: string = '';
    if(extra.action === 'paginate'){
      setPage(pagination.current);
      query += `page=${pagination.current}&`;
    }
    else setPage(1);
    Object.keys(filters).map((key: any) => {
      if(!filters[key]) return;
      filters[key]?.map((filter: any) => {
        const encodedFilter = encodeURIComponent(filter);
        query += `${key}=${encodedFilter}&`;
      })
    });

    if(sorter.column){
        query += `sort_by=${sorter.column.dataIndex}&order=${sortOrderMap[sorter.order]}&`;
    }
    props.actionFunction(query)
  };


  React.useEffect(() => {
    let tempColumns: ColumnsType = [];
    props.columns.map((column: any) => {
      tempColumns.push({
        ...column,
        title: !column.title ? '' : (typeof column.title === "string" && !props.formatTitle) ? String(column.title)?.toUpperCase() : column.title,
        sortIcon: column.sorter ? ({ sortOrder: SortOrder }) => !SortOrder ?
          <div style={{ height: '16px' }}><ArrowDownIcon inheritSize /></div> : SortOrder === 'ascend' ?
            <div style={{ rotate: '180deg', margin: 0, padding: 0, height: '16px' }}><ArrowDownIcon inheritSize fill={PrimaryTheme.primaryGreen} /></div> :
            <div style={{ height: '16px' }}><ArrowDownIcon inheritSize fill={PrimaryTheme.primaryGreen} /></div> : undefined,
        filterIcon: column.filters ? <div style={{ height: '16px' }}><FilterIcon inheritSize /></div> : undefined,
        showSorterTooltip: false,

        filters: Object.keys(props.filterValues).includes(column.dataIndex) ?
          props.filterValues[column.dataIndex].map((filter: any) => {
            return { text: filter, value: filter }
          })
          : undefined,
      });
    })

    setColoumns(tempColumns);
  }, [props.columns]);

  return (
    <div className='be-table'>

      <Table
        {...props.tableProps}
        className={props.className}
        columns={coloumns}
        dataSource={props.data}
        loading={props?.loading}
        onChange={onChange}
        pagination={
          {
            position: ['bottomCenter'],
            current: page,
            total: props.totalPages * 10,
            showSizeChanger: false,
          }
        }
        rowClassName={props.rowClassName}
      />
    </div>
  );
}
