import { getFYByDate } from "../../../Config/Functions/UsefullFunctions";
import { AppDispatch } from "../../../Redux/store"
import { RootState } from "../../../Redux/store"
import dayjs from "dayjs";
import { getAllUsers } from "../UsersManagementActions";
import { getYears } from "../../../Features/Settings/Actions";



export const handleBackgroundAPICalls = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  let role = localStorage.getItem('role');
  const state = getState();
  dispatch(getAllUsers());
}

export const setCurrentYears = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  let currentYear = await dispatch(getCurentFY());
}

export const getCurentFY = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  let state = getState();
  let YearEnd = state.companies.companyDetails.data?.reporting_year_end;
  const year = getFYByDate(dayjs().format('YYYY-MM-DD'), YearEnd || '12-31');
  return year;
}


