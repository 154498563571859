// NavigationManager.ts

type NavigateFunctionType = (to: string) => void;

let navigateFn: NavigateFunctionType | undefined;

export const setNavigateFunction = (fn: NavigateFunctionType) => {
  navigateFn = fn;
};

export const navigateTo = (path: string, newTab?: boolean,exact?: boolean) => {
  if (newTab) {
    window.open(`${
      exact ? "" : window.location.origin
    }${path}`)
    return;
  }
  if (navigateFn) {
    navigateFn(path);
  } else {
    console.error("Navigate function has not been set");
  }
};
