import React from 'react'
import IconProps from './Interface/IconProps';

const LeafIcon= (props:IconProps) => {
  return (
    <svg  width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill={props.fill ? props.fill :'black'} d="M18.6422 1.59798C18.6315 1.4145 18.5538 1.24134 18.4238 1.11137C18.2939 0.981411 18.1207 0.903695 17.9372 0.89298C10.8 0.473917 5.08315 2.62267 2.64565 6.65392C1.80074 8.0329 1.38439 9.63174 1.4494 11.2477C1.50284 12.7402 1.93784 14.2477 2.74221 15.7336L0.913151 17.5617C0.772421 17.7025 0.693359 17.8933 0.693359 18.0924C0.693359 18.2914 0.772421 18.4822 0.913151 18.623C1.05388 18.7637 1.24475 18.8428 1.44378 18.8428C1.6428 18.8428 1.83367 18.7637 1.9744 18.623L3.80253 16.7939C5.28753 17.5974 6.79596 18.0324 8.28753 18.0858C8.3919 18.0895 8.49596 18.0914 8.59971 18.0914C10.1107 18.0955 11.5931 17.6793 12.8813 16.8895C16.9125 14.452 19.0622 8.7361 18.6422 1.59798ZM12.1078 15.607C9.97503 16.8989 7.45034 16.9195 4.91628 15.6792L13.2253 7.37111C13.295 7.30142 13.3503 7.2187 13.388 7.12765C13.4257 7.03661 13.4451 6.93903 13.4451 6.84048C13.4451 6.74193 13.4257 6.64435 13.388 6.55331C13.3503 6.46226 13.295 6.37954 13.2253 6.30985C13.1557 6.24017 13.0729 6.1849 12.9819 6.14719C12.8908 6.10947 12.7933 6.09006 12.6947 6.09006C12.5962 6.09006 12.4986 6.10947 12.4075 6.14719C12.3165 6.1849 12.2338 6.24017 12.1641 6.30985L3.85596 14.6227C2.6194 12.0914 2.63721 9.56017 3.92815 7.4311C5.99909 4.01204 10.9219 2.13985 17.176 2.36298C17.4 8.61236 15.5269 13.5361 12.1078 15.607Z" />
	</svg>
	
  );
}

export default LeafIcon