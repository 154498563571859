import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { ChangeDateFormat } from "../../../Config/Functions/UsefullFunctions";
import { setCurrentYear } from "../../../Redux/CommonReducer";
import {
    setLogs,
    setYears
} from "../../../Redux/SettingsReducer";
import { AppDispatch, RootState } from "../../../Redux/store";
import exportToCSV from "../../../Utils/Download/Excell/ExportExcell";
import {
    GET_LOGS,
    GET_YEARS,
    // POST_YEARS
} from "../../../Utils/Routes/SettingsRouts";


export const getLogs = () => async (dispatch: AppDispatch) => {
    dispatch(setLogs({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_LOGS());
    if (data) {
        dispatch(setLogs({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setLogs({
            status: "error",
            data: []
        }));
    }
}

export const getYears = () => async (dispatch: AppDispatch) => {
    dispatch(setYears({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_YEARS());
    if (data) {
        const dataToSet = data.data.sort((a: any, b: any) => { return (b.year - a.year)>0 ? 1 : -1})
        dispatch(setYears({
            status: "success",
            data: dataToSet
        }));
        dispatch(setCurrentYear(dataToSet[0].year));
        if(data.data.length === 0){
            // dispatch(postYears({
            //     year: new Date().getFullYear() - 1
            // }));
        }
    }
    else {
        console.log(error);
        dispatch(setYears({
            status: "error",
            data: []
        }));
    }
}

// export const postYears = (data: any) => async (dispatch: AppDispatch) => {
//     const [res, error] = await handleAPICall(POST_YEARS(data));
//     if (res) {
//         dispatch(getYears());
//     }
//     else {
//         console.log(error);
//     }
// }


