import * as React from 'react';
import EditIcon from '../../../../../../../../../../Components/BEIcons/EditIcon';
import { NewBETable } from '../../../../../../../../../../Components/BETable/newBETable';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import {
  patchScope3TypeCalculations,
  patchScope3TypeCategory
} from '../../../../../../../../Actions';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../../../../../../Config/Theme/theames';
import { EditEmissionFactorDrawer } from '../../EditEmissionFactorDrawer';
import { Select } from 'antd';
import { AddCommaToNumber } from '../../../../../../../../../../Config/Functions/UsefullFunctions';
import { BEMessage } from '../../../../../../../../../../Components/BEMessage';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../../../Redux/Types/scope3Types';
import { CatagoryMap } from '../../../../../../../../Data/data';
import { PROCUREMENT, SPEND_BASED } from '../../../../../../../../Data/constants';
import BEStatusTag from '../../../../../../../../../../Components/BEStatusTag';
import { EditExpenditureTypeDrawer } from './EditExpenditureTypeDrawer';

export interface IDataUploadStep4Props {
  calculate?: boolean
}

export function Scope3TypeSpendBasedConfiguration(props: IDataUploadStep4Props) {
  const dispatch = useAppDispatch();
  const [fullDetails, setFullDetails] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [currentRow, setCurrentRow] = React.useState<any>({});
  const procurementSpendCategories = useTypedSelector(state => state.scope3.catagoryConfigurations)
  const procurementExpenditureTypes = useTypedSelector(state => state.scope3.expenditureTypesReference)
  const [loadingSelect, setLoadingSelect] = React.useState<boolean>(false)
  const [openExpenditureEditDrawer,setOpenExpenditureEditDrawer]=React.useState<boolean>(false)
  const [openEditDrawer, setOpenEditDrawer] = React.useState<boolean>(false)
  const [loadingCalculate, setLoadingCalculate] = React.useState<boolean>(false)
  const {type} = useParams<{type:Scope3TypeType}>()
  const basedOn = useTypedSelector(state => state.scope3.basedOn);


  const columns: ColumnsType<any> = [
    {
      title: CatagoryMap[type as Scope3TypeType][SPEND_BASED].title,
      dataIndex: CatagoryMap[type as Scope3TypeType][SPEND_BASED].dataIndex,
    },
    {
      title: 'Total Spend',
      dataIndex: 'total_spend',
      render: (text: any, record: any) => text && AddCommaToNumber(text, false, true, true),
      sorter: (a: any, b: any) => (a.total_spend > b.total_spend) ? 1 : -1
    },
    {
      title: '% of Spend',
      dataIndex: 'percentage_spend',
      render: (text: any, record: any) => text && text.toFixed(2) + '%'
    },
    {
      title: 'Expenditure Type',
      dataIndex: 'expenditure_type',
      render: (text: any, record: any) => {
        return (
          <div style={{display:'flex',justifyContent:'space-between', width:'80%'}}>
            <div>{text}</div>
            <div style={{ height: '1rem', cursor: 'pointer' }}
             onMouseEnter={() => setCurrentRow(record)}  onClick={() => setOpenExpenditureEditDrawer(true)}><EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
          </div>
        )
      }
    },
    {
      title: 'Emission Factor Status',
      render: (text: any, record: any) =>  <BEStatusTag status={record.configured ? 'success' : 'error'}>
        <span>{record.configured ? 'COMPLETED' : 'PENDING'}</span>
      </BEStatusTag> 
      
    },
    {
      title: 'Emission Factor Selection',
      dataIndex: 'emission_factor',
      render: (text: any, record: any) => {
        return <div style={{ height: '1rem',width:'max-content', cursor: 'pointer' }}
          onMouseEnter={() => setCurrentRow(record)} onClick={() => setOpenEditDrawer(true)}
        ><EditIcon inheritSize stroke={PrimaryTheme.primaryGray} /></div>
      }
    }
  ]



  React.useEffect(() => {
    if (procurementSpendCategories.status === 'success') {
      setTableData(procurementSpendCategories.data);
    }
  }, [procurementSpendCategories]);

  return (
    <div className='scope3-type-configuration-settings'>
      <NewBETable
        pagination
        loading={procurementSpendCategories.status === 'loading'}
        TableTopRightComponents={
          <>
            {
              props.calculate &&
              <BEButton className='primary' size='large'
                loading={loadingCalculate}
                onClick={async () => {
                  setLoadingCalculate(true);
                  await dispatch(patchScope3TypeCalculations(type as Scope3TypeType));
                  setLoadingCalculate(false);
                }}
              >Calculate</BEButton>
            }
          </>
        }
        search
        data={[...tableData].sort((a: any, b: any) => a.id - b.id)}
        columns={type !== PROCUREMENT? [...columns.slice(0, 3), ...columns.slice(4)] : columns}
      />
      <EditEmissionFactorDrawer open={openEditDrawer} setOpen={setOpenEditDrawer} data={currentRow} />
      <EditExpenditureTypeDrawer open={openExpenditureEditDrawer} setOpen={setOpenExpenditureEditDrawer} data={currentRow}/>
    </div>
  );
}


