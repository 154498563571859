import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { filterDataByMonthOrBU } from '../../../../Actions';

export interface ITotalEmissionsByScopeProps {
}

export function TotalEmissionsByTypeGraph(props: ITotalEmissionsByScopeProps) {
  const dispatch =useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const scope1Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope1Calculations);
  const scope2Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope2Calculations);
  const scope3Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const [filteredScope1Calculations, setFilteredScope1Calculations] = React.useState<any>([]);
  const [filteredScope2Calculations, setFilteredScope2Calculations] = React.useState<any>([]);
  const [filteredScope3Calculations, setFilteredScope3Calculations] = React.useState<any>([]);
  const [chartData, setChartData] = React.useState<any>([]);

  const setFilteredData = async() => {
    let data = await dispatch(filterDataByMonthOrBU(false,true));
    setFilteredScope1Calculations(data.scope1Data);
    setFilteredScope2Calculations(data.scope2Data);
    setFilteredScope3Calculations(data.scope3Data);
  }

  React.useEffect(() => {
    if(scope1Calculations.status === 'success' && scope2Calculations.status === 'success' && scope3Calculations.status === 'success') 
      setFilteredData();
  }, [scope1Calculations,scope2Calculations,scope3Calculations, currentBU,currentBG]);


  React.useEffect(() => {
      const allData = [...filteredScope1Calculations,...filteredScope2Calculations, ...filteredScope3Calculations];
      let allEmissionCategoryArray:any = Array.from(new Set(allData.map((item:any) => item?.emission_category))).map((item:any) => {
        return{
          name:item,
          data: new Array(12).fill(0)
        }
      });
       allData.forEach((item:any) => {
        let index = allEmissionCategoryArray.findIndex((category:any) => category.name === item.emission_category);
        console.log(index,'index');
        let monthIndex = ((item.month-1)+ 12 - yearEnd)%12;
        allEmissionCategoryArray[index].data[monthIndex] += item.tco2e;
      });


      setChartData(allEmissionCategoryArray);
  }, [filteredScope1Calculations,filteredScope2Calculations,filteredScope3Calculations]);

  return (
    <div style={{ margin: "3rem 0" }} >
    <p className='chart-title'>Total emissions by Source (tCO2e)</p>
      <div style={{ padding: "1rem" }} className='bordered-container'>
        <StackedColumnCharts
          colorTheme={3}
          data={chartData}
          xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
          // chartTitle="Total Emissions by Source (tCO2e)"
          yAxisTitle="tCO2e"
          yAxisLabel="Tonnes of Co2 equivalent"
        />
      </div>
    </div>
  );
}
