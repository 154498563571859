import { Select } from "antd";
import { BEDrawer } from "../../../../../../../../../../../Components/BEDrawer";
import { LableRequired } from "../../../../../../../../../../../Components/BEFormItems/LableRequired";
import { Scope3TypeType } from "../../../../../../../../../../../Redux/Types/scope3Types";
import { patchScope3TypeCategory } from "../../../../../../../../../Actions";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../../../../../../Config/Hooks/useAppDispatch";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../../../../../../../Config/Hooks/useTypedSelector";
import { BEButton } from "../../../../../../../../../../../Components/BEFormItems/BEButton";
import { BEMessage } from "../../../../../../../../../../../Components/BEMessage";
import CancleIcon from "../../../../../../../../../../../Components/BEIcons/CancleIcon";
import WarningIcon from "../../../../../../../../../../../Components/BEIcons/WarningIcon";
import { PrimaryTheme } from "../../../../../../../../../../../Config/Theme/theames";
import { BEWarning } from "../../../../../../../../../../../Components/BEWarning";
import { CatagoryMap } from "../../../../../../../../../Data/data";

interface EditExpenditureTypeDrawerProps{
	open:boolean;
	setOpen:Function;
	data:any
	
}
export function EditExpenditureTypeDrawer(props:EditExpenditureTypeDrawerProps){
	const [loading,setLoading]=useState<boolean>(false)
	const dispatch=useAppDispatch()
	const {type} = useParams<{type:Scope3TypeType}>()
	const basedOn = useTypedSelector(state => state.scope3.basedOn);
	const procurementExpenditureTypes = useTypedSelector(state => state.scope3.expenditureTypesReference)
	const [expenditureType,setExpenditureType]=useState<string>("")

	useEffect(()=>{
		setExpenditureType(props.data.expenditure_type)
	},[props.data])

	async function onSubmitExpenditureType(){
		setLoading(true);
		await dispatch(patchScope3TypeCategory(type as Scope3TypeType,props.data.id, { expenditure_type: expenditureType}));
		props.setOpen(false)
		setLoading(false);
	}

	return <BEDrawer
		open={props.open}
		width='27rem'
		setOpen={props.setOpen}
		heading="Change Expenditure type"
		secondryHeading={{
			heading:`${basedOn && props.data[CatagoryMap[type as Scope3TypeType][basedOn].dataIndex]}`,
		  }}
		footer={
			<div style={{display:'flex',gap:8}}>
			<BEButton onClick={onSubmitExpenditureType} loading={loading} className="primary" >
				Save
			</BEButton>
			<BEButton onClick={()=>props.setOpen(false)}>
				Cancel
			</BEButton>
			</div>
		}
	>
		<BEWarning description={<div>
			<p>Expenditure type is used to classify each procurement category as one of:</p>
				<p>1. Purchased goods</p>
				<p>2. Purchased services</p>
				<p>3. Capital goods</p>
			<p>Based on this selection, GHG emission calculations are accounted for under Scope 3.1 (Purchased goods & services) or Scope 3.2 (Capital goods).</p>
			<p>By default, all procurement categories will be classified as “Purchased goods”.</p>
			<p>Please update the default selection as required.</p>
		</div>}/>
		<LableRequired>Expenditure type</LableRequired>
		<Select style={{ width: '100%' }}
            loading={!props.data.expenditure_type}
            value={expenditureType}
            onChange={
			(value:any)=>{
				setExpenditureType(value)
			}
            }
          >
            {
              procurementExpenditureTypes.data
                .map((type: any) => <Select.Option value={type.expenditure_type}>
                  {type.expenditure_type}
                </Select.Option>)
            }
          </Select> 
	</BEDrawer>
}