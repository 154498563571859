import React, { useEffect, useState } from "react";
import "./styles.scss";
import { PrimaryTheme } from "../../Config/Theme/theames";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "../../assets/images/Company/zeroLogoWhite.png";
import CompanyLogoCollapsed from "../../assets/images/Company/Logo.png";
import ESGLogoColored from "../../assets/images/Company/ESGLogoColored.svg"
import GreyESGLogo from '../../assets/images/Company/GreyESGLogo.svg'
import ChevronFilled from "../BEIcons/ChevronFilled";
import Dashboard from "../BEIcons/Dashboard";
import DataManager from "../BEIcons/DataManager";
import EntityManager from "../BEIcons/EntityManager";
import Targets from "../BEIcons/Targets";
import Emissions from "../BEIcons/Emissions";
import Reporting from "../BEIcons/Reporting";
import Analytics from "../BEIcons/Analytics";
import Materiality from "../BEIcons/Materiality";
import Suppliers from "../BEIcons/Suppliers";
import SettingsIcon from "../BEIcons/SettingsIcon";
import Logout from "../BEIcons/Logout";
import { setSelectedMenuItem } from "../../Redux/CommonReducer";
import { useAppDispatch } from "../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import BEConfirmModal from "../BEConfirmModal";
import { getNewToken, logoutAction } from "../../views/Actions/LoginActions";
import { setMenuCollapsed, increaseMenuClicks } from "../../Redux/CommonReducer";
import ToolTip from "../BEToolTip";
import InternalIcon from "../BEIcons/InternalIcon";
import dayjs from "dayjs";
import { menubarWidth, menubarWidthCollapsed } from "../../Config/Constants";
import Planning from "../BEIcons/PlanningIcon";
import MarketPlace from "../BEIcons/MarketPlaceIcon";
import ESGIcon from "../BEIcons/ESGIcon";
import GreyESGIcon from "../BEIcons/GreyESGIcon";



const menuItems = [
  {
    label: "Dashboard",
    icon: Dashboard,
    link: "/home/dashboard",
    comingsoon:false
  },
  // {
  //   label: "Scope-3 Emissions",
  //   icon: DataManager,
  //   link: "/home/scope-3",
  // },
  {
    label: 'Analytics',
    icon: Analytics,
    link: "/home/analytics",
    comingsoon:true
  },

  {
    label: "Planning",
    icon: Reporting,
    link: "/home/planning",
    comingsoon:true
  },
  {
    label: "Market place",
    icon: MarketPlace,
    link: "/home/marketplace",
    comingsoon:true
  },
  // {
  //   label: "Emissions",
  //   icon: Emissions,
  //   link: "/home/emissions",
  // },
  {
    label: "Settings",
    icon: SettingsIcon,
    link: "/home/settings",
    comingsoon:false
  },
];



const BEMenuBar = () => {
  const role = localStorage.getItem('role');
  const displayMenuItems =  menuItems;
  const clicks = useTypedSelector((state) => state.common.menuClicks);
  const [showList, setShowList] = useState(false);
  const collapsed = useTypedSelector((state) => state.common.menuCollapsed);
  const version = useTypedSelector((state) => state.common.version);
  const activeMenuItem = useTypedSelector((state) => state.common.menuBar.activeMenuItem);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hoverBreatheESGIcon,setHoverBreatheESGIcon]=useState(false);
  

  const selectMenuItemFromPathname = () => {
    displayMenuItems.forEach((item, ind) => {
      if (location.pathname.includes(item.link)) {
        dispatch(setSelectedMenuItem(ind));
        return;
      }
    });
  };
  
  const goToESG = async() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    const token = await dispatch(getNewToken());
    if(!token) return; 
    const querryString = `?token=${JSON.stringify(token)}&user_info=${JSON.stringify(userInfo)}`
    const esgUrl  = process.env.REACT_APP_ESG_URL;
    window.open(`${esgUrl}/login${querryString}`, '_blank');
  }

  useEffect(() => {
    selectMenuItemFromPathname();
    console.log(location.pathname, 'location.pathname', clicks, 'clicks');
    if(clicks === 0 && location.pathname === '/home/dashboard') dispatch(setMenuCollapsed(false));
    if(clicks === 1 && location.pathname !== '/home/dashboard') dispatch(setMenuCollapsed(true))
    dispatch(increaseMenuClicks());                  
  }, [location.pathname]);

  useEffect(() => {
    if (collapsed) {
      setShowList(false)
    }
    else
      setTimeout(() => {
        setShowList(true)
      }, 400)
  }, [collapsed])

  const handleMenuClick = (link: string ) => {
    navigate(link);
  }

  return (
    <div
      className="menu-bar"
      style={{
        minHeight: "100vh",
        background: PrimaryTheme.secondaryBlack,
        position: "sticky",
        top: 0,
        color: "#ffffff",
        alignSelf: "flex-start",
        width: collapsed ? menubarWidthCollapsed : menubarWidth,
        paddingTop: "1rem",
      }}
    >
      <div
        className="collapse-btn"
        style={collapsed ? {} : { transform: "rotateZ(-180deg)" }}
        onClick={() => {
          dispatch(setMenuCollapsed(!collapsed));
        }}
      >
        <ChevronFilled />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          className="logo"
          onClick={() => navigate("/home/dashboard")}
        >
          <img
            src={!showList ? CompanyLogoCollapsed : CompanyLogo}
            height={"22px"}
          />{" "}
        </div>
      </div>

      <div className="menu-list">
        {
          displayMenuItems.map((item, ind) => {
            return collapsed ? (
              <ToolTip title={item.comingsoon ? `${item.label} (Coming Soon)` : item.label} placement="right">
                <div
                  key={ind}
                  style={{
                    color:
                      activeMenuItem === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight,
                  }}
                  className="list-itm"
                  onClick={!item.comingsoon ? () => handleMenuClick(item.link): undefined}
                >
                  <item.icon
                    stroke={
                      activeMenuItem === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight
                    }
                  />
                </div>
              </ToolTip>
            ) : (
              <div
                key={ind}
                style={{
                  color:
                    activeMenuItem === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight,
                }}
                className={!item.comingsoon ? `list-itm`:'coming-soon'}
                onClick={!item.comingsoon ? () => handleMenuClick(item.link): undefined}
              >
                <item.icon
                  stroke={
                    activeMenuItem === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight
                  }
                />

                
                { showList && item.comingsoon ?
                <span style={{width:'80%',transition: 'all 0.2s ease'}}>
                  <span style={{ width: "50%",marginRight:'5px',whiteSpace:'nowrap' }}>{item.label}</span>
                  <span style={{ backgroundColor:PrimaryTheme.primaryGreenDisabled , padding:'2px 4px 2px 4px',  fontSize:'9px' , borderRadius:"12px" ,whiteSpace:'nowrap' }}>Coming soon</span> 
                </span>
                :
                showList && <span style={{ width: "80%" }}>{item.label}</span>
                }
              </div>
            );
          })
        }
        <div
          onClick={goToESG}
          onMouseEnter={()=>setHoverBreatheESGIcon(true)}
          onMouseLeave={()=>setHoverBreatheESGIcon(false)}
        >
         {collapsed ? <ToolTip title='BREATHE ESG' placement="right">  
            <div className="list-itm ">
            {
              showList ? (
                hoverBreatheESGIcon ? (
                  <img src={ESGLogoColored} height={"22px"} />
                ) : (
                  <img src={GreyESGLogo} height={'22px'} />
                )
              ) : (
                hoverBreatheESGIcon ? <ESGIcon  /> : <GreyESGIcon />
              )
            }
            </div>
          </ToolTip>
          : <div className="list-itm logo ">
            {
              showList ? (
                hoverBreatheESGIcon ? (
                  <>
                    <ESGIcon/>
                    <span style={{width:'80%',fontSize:'0.8rem'}}>BREATHE ESG</span>
                  </>
                ) : (
                  <>
                    <GreyESGIcon/>
                    <span  style={{width:'80%',fontSize:'0.8rem'}}>BREATHE ESG</span>
                  </>
                )
              ) : (
                hoverBreatheESGIcon ? <ESGIcon  /> : <GreyESGIcon />
              )
            }
            </div>}
        </div>
        
      </div>

      {/* {
        !collapsed &&
        <p className="version">
          {
            `${version.number}.${version.date !== '' &&
            dayjs(version.date).format('DDMMYY')
            }`
          }
        </p>
      } */}
    </div>
  );
};

export default BEMenuBar;
