import * as React from 'react';
import './style.scss';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { Row, Col } from 'antd';
import ArrowDownIcon from '../../../Components/BEIcons/ArrowDownIcon';
import { LeftComponentLogin } from '../../Login/LeftComponentLogin';
import CompanyLogo from '../../../assets/images/Company/ZeroLogo.png';
import { PrimaryTheme } from '../../../Config/Theme/theames';

export interface IWelcomeScreenProps {
  buttonText: string;
  description: string;
  image: any;
  onClick: any;
  heading?: string;
}

export function WelcomeScreen(props: IWelcomeScreenProps) {
  return (
    <div className='welcome-screens'>
      <Row style={{ width: '100%' }}>
        <Col span={14} >
          <LeftComponentLogin />
        </Col>
        <Col span={10}>
          <div className='right-section'>
            <div className='welcome'>
              <p> Welcome To</p>
              <img src={CompanyLogo} alt="image" className='img-1' />
              <p className='disc'><strong>{props.description}</strong></p>
              <BEButton className='primary' size='large' style={{paddingLeft:'5rem',paddingRight:'5rem'}}
                onClick={props.onClick}
              >
                {props.buttonText}
              </BEButton>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}


