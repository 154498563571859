import * as React from 'react';
import { ButtonProps, Button } from 'antd';
import './style.scss'
import useDebounce from '../../../Config/Hooks/useDebouncedClick';
import { clickDelay } from '../../../Config/Constants';

export interface IBEButtonProps extends ButtonProps {
}

//ClassNames --> primary,disabled
export function BEButton(props: IBEButtonProps) {
  const { children, ...otherProps } = props;

  // preventing multiple clicks
  // useDebounce is a custom hook that debounces the click event
  const debouncedClick = useDebounce(otherProps.onClick ? otherProps.onClick : () => {},clickDelay); 

  return (
    <Button 
    {...otherProps} 
    onClick={otherProps?.onClick ? debouncedClick : () => {}}
    style={{ ...otherProps.style }} className={
      `be-button ${otherProps.className ? otherProps.className : ''}`
    }>
      {children}
    </Button>
  );
}
