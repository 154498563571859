import React, { useRef } from "react";
import { Tooltip } from "antd";
import { PrimaryTheme } from "../../Config/Theme/theames";
import { TooltipPlacement } from "antd/es/tooltip";
import './styles.scss'

interface IFToolTipProps {
  children?: React.ReactNode;
  title: React.ReactNode | (() => React.ReactNode);
  placement?: TooltipPlacement | undefined;
}

const ToolTip = (props: IFToolTipProps) => {
  const { children } = props;
  const ref= useRef(null)
  return (
    <span ref={ref}>
      <Tooltip
        title={props.title}
        color={PrimaryTheme.secondaryBlack}
        placement={props.placement}
      >
        <div>{children}</div>
      </Tooltip>
    </span>
  );
};


export default ToolTip;
