import React, { useRef, useState } from "react";
import UploadIcon from "../BEIcons/UploadIcon";
import UploadedIcon from "../BEIcons/UploadedIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import "./styles.scss";
import DeleteIcon from "../BEIcons/DeleteIcon";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";

interface FileUploadProps {
  text?: string; // text to be shown
  hint?: string; // hint on file format/size
  handleFileChange: Function;
  required?: boolean;
  fileType?: string | string[]; // file format
  fileSize?: number; // max file size in bytes
  children?: React.ReactNode
  loading?: boolean;
  refreshUpload?: boolean;
  width?: string;
}

const FileUpload = (props: FileUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState(false); // track if a file was uploaded
  const [success, setSuccess] = useState(true); // track if upload was a success
  const [message, setMessage] = useState(""); // validation error message
  const [fileName, setFileName] = useState(""); // uploaded file name
  const [drag, setDrag] = useState(false); // trach drag event

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFileChange(null);
    if (event.target.files) {
      if (!validateFile(event.target.files[0])) return;
      props.handleFileChange(event.target.files[0]);
      setUploaded(true);
      setSuccess(true);
      setFileName(event.target.files[0].name);
    }
  };

  const validateFile = (file: any) => {
    if(!file) return false
    if (
      props.fileType === "image" &&
      !(file.type === "image/jpeg" || file.type === "image/png")
    ) {
      setMessage("File format should be in .png, .jpeg, .jpg! ");
      setSuccess(false);
      setDrag(false);
      return false;
    }
    if (props.fileType === "file" && !(file.type === "application/pdf")) {
      setMessage("File format should be in .pdf! ");
      setSuccess(false);
      setDrag(false);
      return false;
    }
    if(props.fileType === "excel" ){
      if(file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && file.type !== "application/vnd.ms-excel"){
        setMessage("File format should be in .xlsx! or .xls");
        setSuccess(false);
        setDrag(false);
        return false;
      }   
    }

    if (typeof props.fileType !== 'string') {
      const fileTypeCheck = props.fileType?.find((item: string) => item === file.type)
      if (!fileTypeCheck) {
        setMessage("File should be in excel, image, pdf");
        setSuccess(false);
        setDrag(false);
        return false;
      }
    }

    if (props.fileSize && file.size > props.fileSize) {
      setMessage(`File should be less than ${props.fileSize/(1024*1024)} MB`);
      setSuccess(false);
      setDrag(false);
      return false;
    }
    return true;
  };

  const onBoxClick = (e:any) => {
    if(e.target.parentNode.className === 'delete-btn') return
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    props.handleFileChange(null);
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      const fileItem = event.dataTransfer.items[0];
      if (fileItem.kind === "file") {
        const file = fileItem.getAsFile();
        if (file) {
          if (!validateFile(file)) return;

          props.handleFileChange(file);
          setUploaded(true);
          setSuccess(true);
          setFileName(file.name);
          setDrag(true);
        }
      }
      event.dataTransfer.clearData();
    }
  };

  React.useEffect(() => {
    setUploaded(false);
    setMessage("");
    setSuccess(true);
    setFileName("");
    setDrag(false);
  }, [props.refreshUpload])

  return (
    <>
      <div style={{ width: 
      props.width ? `calc(${props.width} - 20px)`
      : "330px"}}
        className={`${success ? "upload-box" : "upload-box-fail"} ${
          drag || props.loading ? "drag-box" : ""
        } ${uploaded ? "drag-box" : ""}`}
        onClick={onBoxClick}
        onDrop={onDrop}
        onDragOver={(e) => {
          e.preventDefault();
          setDrag(true);
        }}
        onDragLeave={() => {
          setDrag(false);
        }}
      >
        {
          props.loading ?
          <div className="loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: '2.5rem',color:PrimaryTheme.primaryGreenDark }} spin />} />
            <strong className="uploading-text">Uploading...</strong>
            </div>
          :
        <>
          {(uploaded || !success) && (
            <div
              className="delete-btn"
              onClick={(e) => {
                e.preventDefault();
                props.handleFileChange(null);
                setSuccess(true);
                setUploaded(false);
                setDrag(false);
              }}
            >
              <DeleteIcon inheritSize stroke="red" />
            </div>
          )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="input"
          />
          <div className="icon">
            {uploaded ? (
              <UploadedIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize />
            ) : (
              <UploadIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize />
            )}
          </div>
          {uploaded ? (
            <p className="text">Uploaded: {fileName}</p>
          ) : (
            <p className="text">
              {props.text}{" "}
              {props.required && <span style={{ color: "red" }}>*</span>}
            </p>
          )}
          <p className={`${success ? "hint" : "hint-fail"}`}>
            {success ? props.hint : message}
          </p>
          {!success && <div className="browse-file">Browse File</div>}
        </>
        }
      </div>
    </>
  );
};

FileUpload.defaultProps = {
  text: "Click or drag file to this area to upload",
  hint: "Upload a file in the format .png or .jpg under the size of 2 MB",
  required: true,
  fileType: "image",
  fileSize: 2000000,
};

export default FileUpload;
