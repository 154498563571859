import React from 'react'
import IconProps from './Interface/IconProps';


const NotificationIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 16}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M8.00033 15C8.73366 15 9.33366 14.4 9.33366 13.6667H6.66699C6.66699 14.4 7.26699 15 8.00033 15ZM12.0003 11V7.66667C12.0003 5.62 10.9137 3.90667 9.00033 3.45333V3C9.00033 2.44667 8.55366 2 8.00033 2C7.44699 2 7.00033 2.44667 7.00033 3V3.45333C5.09366 3.90667 4.00033 5.61333 4.00033 7.66667V11L2.66699 12.3333V13H13.3337V12.3333L12.0003 11ZM10.667 11.6667H5.33366V7.66667C5.33366 6.01333 6.34033 4.66667 8.00033 4.66667C9.66033 4.66667 10.667 6.01333 10.667 7.66667V11.6667Z"
          fill={props.fill ? props.fill : "#181818"}
        />
      </g>
    </svg>
  );
}

export default NotificationIcon