import React, { useEffect } from "react";
import { Form, Select, Space } from "antd";

interface MultipleSelectProps {
  data: any[];
  children?: React.ReactNode;
  onChange?: Function;
  name?: string;
  rules?: any[];
  placeholder?: string;
  single?: boolean;
  loading?: boolean;
  valueFeild?: string;    //if data is array of objects pass the valueFeild and labelFeild
  labelFeild?: string;
  form?: any;
  isDataStrArray?: boolean; // if data is array of strings pass this prop
  disabled?: boolean
  defaultValue?: any
}

const BEMultipleSelect = (props: MultipleSelectProps) => {
  if(props.valueFeild && props.labelFeild)return(
    <Form.Item name={props.name} rules={props.rules}>
      <Select
      onChange={(e)=>{
        props?.form?.setFieldValue(props.name,e)
        props.onChange && props.onChange(e)
      }}
        loading={props.loading}
        size="large"
        mode={props.single ? undefined : "multiple"}
        allowClear
        style={{ width: "100%" }}
        showSearch
        optionFilterProp="children"
        placeholder={props.placeholder}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      >
        {props.data.map((industry, index) => {
          return (
            <Select.Option key={index} value={industry[props?.valueFeild || '']}>
              {industry[props?.labelFeild || '']}
            </Select.Option>
          );
        })}
      </Select>
      </Form.Item>
  )
  return (
    <Form.Item name={props.name} rules={props.rules}>
      <Select
      onChange={(e)=>{
        props?.form?.setFieldValue(props.name,e)
        props.onChange && props.onChange(e)
      }}
        loading={props.loading}
        size="large"
        mode={props.single ? undefined : "multiple"}
        allowClear
        style={{ width: "100%" }}
        showSearch
        placeholder={props.placeholder}
        disabled={props.disabled}
      >
        {
          props.isDataStrArray?
          props.data.map((industry) => {
            return (
              <Select.Option value={industry}>
                {industry}
              </Select.Option>
            );
          })
          :

        props.data.map((industry) => {
          return (
            <Select.Option value={industry.title}>
              <Space>{industry.title} </Space>
            </Select.Option>
          );
        })
        }
      </Select>
    </Form.Item>
  );
};

export default BEMultipleSelect;
