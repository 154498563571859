import * as React from 'react';
import { BEWrapper } from '../../../../../../../../Components/BEWrapper';
import DataUploadDonutChart from './DataUploadDonutChart';
import { BETabs } from '../../../../../../../../Components/BETabs';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { NewBETable } from '../../../../../../../../Components/BETable/newBETable';
import { FREIGHT_DISTANCE_BASED, PASSENGER_DISTANCE_BASED, QUANTITY_BASED, SPEND_BASED } from '../../../../../../Data/constants';
import { PrimaryTheme } from '../../../../../../../../Config/Theme/theames';
import { Scope3BasedOnType } from '../../../../../../../../Redux/Types/scope3Types';

export interface IDataUploadStep3Props {
  setStep: Function;
}

const DataUploadStep3: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IDataUploadStep3Props> = (props, ref) => {
  const [columns, setColumns] = React.useState<ColumnsType<any>>([]);
  const systemFeilds = useTypedSelector(state => state.scope3.dataUpload.systemFeilds);
  const step3Data = useTypedSelector(state => state.scope3.dataUpload.step3Data);
  const basedOn = useTypedSelector(state => state.scope3.basedOn);

  const basedOnDataMap:any = {
    [SPEND_BASED]: 'Spend',
    [QUANTITY_BASED]: 'Quantity',
    [PASSENGER_DISTANCE_BASED]: 'Distance (Passenger-Km)',
    [FREIGHT_DISTANCE_BASED]: 'Distance (Tonne-Km)',
  }
  
  const onButtonClick = async () => {
    props.setStep(4);
  }

  React.useEffect(() => {
    let tempColumns: ColumnsType<any> = []
    systemFeilds.data
      .forEach((feild) => {
        tempColumns.push({
          title: feild?.name,
          dataIndex: feild?.name,
          key: feild?.name,
          width:'200px',
          render: (text: any, record: any) => text==='nan'?'':text,
        })
      });
    tempColumns.push({
      title: 'Remark',
      dataIndex: 'error',
      key: 'remark',
    })
    setColumns(tempColumns);
  }, [systemFeilds]);

  React.useImperativeHandle(ref, () => ({ onButtonClick }), [ systemFeilds]);
  return (
    <div className='data-upload-step3'>
      <h3>Review the data uploaded from the file </h3>
      <div className='graph-cards'>

        <div className='graph-card'>
          <DataUploadDonutChart
            chartTitle={'Total no of rows: ' + step3Data?.total_row }
            data={[
              { name: 'Accepted Entries', value: step3Data?.accepted_row || 0 },
              { name: 'Rejected Entries', value: step3Data?.rejected_row || 0},
            ]}
          />
        </div>

        <div className='graph-card'>
          <DataUploadDonutChart
            chartTitle={'Total '+ (basedOn === SPEND_BASED ? 'Spend:INR ' : 'Quantity: ') 
              + (step3Data?.total_value || 0)}
            data={[
              { name: 'Accepted ' + basedOnDataMap[basedOn as Scope3BasedOnType]
                , value:  step3Data?.total_accepted_value || 0 },
              { name: 'Rejected ' + basedOnDataMap[basedOn as Scope3BasedOnType]
                , value: (step3Data?.total_value - step3Data?.total_accepted_value) || 0 },
            ]}
          />
        </div>
      </div>
      <div className='all-entries'>
        <BETabs
          type='card'
          tabBarStyle={{ paddingLeft: '1.5rem' ,paddingTop:'2rem',backgroundColor:PrimaryTheme.primaryBlueLight}}
          items={[
            {
              label: 'Accepted Entries',
              children: <NewBETable
                tableProps={{
                  scroll: { x: 'max-content' }
                }}
                pagination
                columns={columns}
                data={step3Data?.accepted_data || []}
              />
              , key: 'Accepted Entries'
            },
            {
              label: 'Rejected Entries',
              children: <NewBETable
                pagination
                columns={columns}
                tableProps={{
                  scroll: { x: 'max-content' }
                }}
                data={step3Data?.rejected_data || []}
              />
              , key: 'Rejected Entries'
            },
          ]}
        />
      </div>
    </div>
  );
}

export default React.forwardRef(DataUploadStep3);