import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
  GET_SCOPE_1_AND_2_EMISSION_CALCULATIONS,
  GET_SCOPE_3_EMISSION_CALCULATIONS
} from "../../../Utils/Routes/DashboardRouts/indes";
import {
  setScope1Calculations,
  setScope2Calculations,
  setScope3Calculations
} from "../../../Redux/DashboardReducer";
import { getMonthsByQuarter } from "../../../Config/Functions/UsefullFunctions";
import dayjs from "dayjs";
import { TypeData } from "../../Scope3/Data/data";


export const getAllEmissionCalculations = (year: number) => {
  return async (dispatch: any) => {
    dispatch(setScope1Calculations({ status: "loading", data: [] }));
    dispatch(setScope2Calculations({ status: "loading", data: [] }));
    const [res, error] = await handleAPICall(GET_SCOPE_1_AND_2_EMISSION_CALCULATIONS(year));
    if (res) {
      const scope1Data = res.data.filter((item: any) => item.scope === 'Scope 1');
      const scope2Data = res.data.filter((item: any) => item.scope === 'Scope 2');
      dispatch(setScope1Calculations({ status: "success", data: scope1Data }));
      dispatch(setScope2Calculations({ status: "success", data: scope2Data }));
    }
    else {
      dispatch(setScope1Calculations({ status: "error", data: [] }));
      dispatch(setScope2Calculations({ status: "error", data: [] }));
    }
  }
}

export const getScope3EmissionCalculations = (year: number) => {
  return async (dispatch: any) => {
    dispatch(setScope3Calculations({ status: "loading", data: [] }));
    const [res, error] = await handleAPICall(GET_SCOPE_3_EMISSION_CALCULATIONS(year));
    if (res) {
      let feild_name_map:any = {}
      Object.keys(TypeData).map((key) => {
        feild_name_map[TypeData[key].feildName] = key
      })
      const data = res.data.map((item: any) => {
          let temp = item; 
          if(item.emission_category === item.category){
            temp['emission_category'] = feild_name_map[item.category]
          }
          temp['category'] = feild_name_map[item.category]
          return temp
      })
      dispatch(setScope3Calculations({ status: "success", data: data }));
    }
    else {
      dispatch(setScope3Calculations({ status: "error", data: [] }));
    }
  }
}


export const filterDataByMonthOrBU = (filterByMonth: boolean, filterByBU: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const yearEnd = state.companies.yearEnd;
    const businessUnitRefs = state.entity.BusinessUnitRefs;
    const currentMonth = state.common.date.currentMonth;
    const currentBU = state.common.homePage.currentBusinessUnit;
    const currentBG = state.common.homePage.currentBusinessGroup;
    const scope1CalculationsByMonth = state.dashboard.emissionsCalculations.scope1Calculations;
    const scope2CalculationsByMonth = state.dashboard.emissionsCalculations.scope2Calculations;
    const scope3CalculationsByMonth = state.dashboard.emissionsCalculations.scope3Calculations;

    let filteredScope1Data: any = []
    let filteredScope2Data: any = []
    let filteredScope3Data: any = []

    if (filterByMonth) {
      let filteredDataByMonth: any = []
      let filteredDataByMonth2: any = []
      let filteredDataByMonth3: any = []
      if (currentMonth === 0) {
        filteredDataByMonth = scope1CalculationsByMonth.data
        filteredDataByMonth2 = scope2CalculationsByMonth.data
        filteredDataByMonth3 = scope3CalculationsByMonth.data
      }
      else if (currentMonth < 13) {
        filteredDataByMonth = scope1CalculationsByMonth.data.filter((obj: any) => obj.month === currentMonth)
        filteredDataByMonth2 = scope2CalculationsByMonth.data.filter((obj: any) => obj.month === currentMonth)
        filteredDataByMonth3 = scope3CalculationsByMonth.data.filter((obj: any) => obj.month === currentMonth)
      }
      else {
        const monthsOfQuarter = getMonthsByQuarter(currentMonth, dayjs(yearEnd).month())
        filteredDataByMonth = scope1CalculationsByMonth.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
        filteredDataByMonth2 = scope2CalculationsByMonth.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
        filteredDataByMonth3 = scope3CalculationsByMonth.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
      }
      filteredScope1Data = filteredDataByMonth
      filteredScope2Data = filteredDataByMonth2
      filteredScope3Data = filteredDataByMonth3
    }
    else{
      filteredScope1Data = scope1CalculationsByMonth.data
      filteredScope2Data = scope2CalculationsByMonth.data
      filteredScope3Data = scope3CalculationsByMonth.data
    }


    if(filterByBU){
      let filteredData = [];
      let filteredData2 = [];
      let filteredData3 = [];
      if (currentBU !== 0) {
        filteredData = filteredScope1Data.filter((obj: any) => obj?.business_unit === currentBU)
        filteredData2 = filteredScope2Data.filter((obj: any) => obj?.business_unit === currentBU)
        filteredData3 = filteredScope3Data.filter((obj: any) => obj?.business_unit === currentBU)
      }
      else if (currentBG !== 0) {
        let BUs = businessUnitRefs.data.filter((obj: any) => obj.business_group === currentBG).map((obj2: any) => obj2.business_unit)
        filteredData = filteredScope1Data.filter((obj: any) => BUs.includes(obj?.business_unit))
        filteredData2 = filteredScope2Data.filter((obj: any) => BUs.includes(obj?.business_unit))
        filteredData3 = filteredScope3Data.filter((obj: any) => BUs.includes(obj?.business_unit))
      }
      else {
        filteredData = filteredScope1Data
        filteredData2 = filteredScope2Data
        filteredData3 = filteredScope3Data
      }
      filteredScope1Data = filteredData
      filteredScope2Data = filteredData2
      filteredScope3Data = filteredData3
    }
    return {
      'scope1Data': filteredScope1Data,
      'scope2Data': filteredScope2Data,
      'scope3Data': filteredScope3Data
    }
  }
}