import * as React from 'react';
import './style.scss';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { setScope3BasedOn } from '../../../../Redux/Scope3';
import { BEMessage } from '../../../../Components/BEMessage';
import { SPEND_BASED } from '../../Data/constants';
import { BasedOnData, TypeData, TypeSpendData } from '../../Data/data';
import { Scope3TypeType } from '../../../../Redux/Types/scope3Types';
import { useParams } from 'react-router-dom';
import { postMethods } from '../../Actions';
import { changeSnakeCaseToSentence } from '../../../../Config/Functions/UsefullFunctions';
export interface IChangeCalculationMethodProps {
  // setOpenUpload?: Function;
  // isSettings?: boolean;
}


// const steps = [
//   { title: '1. Review sample', description: `Before uploading data, review the sample file to understand the data upload requirements. Please ensure that the data upload comprises of a single excel sheet.` },
//   { title: '2. Upload data', description: 'Upload the data file with the corresponding file name & description.' },
//   { title: '3. Map columns', description: 'Map the column headers of the data upload to the corresponding system column header names to facilitate seamless data ingestion & validation.' },
//   { title: '4. Review & configure', description: 'Review the validated data upload & configure emission factors for automated emission calculations.' },
// ]


const ChangeCalculationMethod: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IChangeCalculationMethodProps> = (props, ref) => {
  const dispatch = useAppDispatch();
  const [basedOn, setBasedOn] = React.useState('');
  const { type } = useParams<{ type: Scope3TypeType }>()
  const selectedBasedOnForType = useTypedSelector(state => state.scope3.selectedBasedOnForType);
  const year = useTypedSelector(state => state.common.date.currentYear);

  const onButtonClick = async () => {
    if (!basedOn) return BEMessage.error('Please select a calculation method')
    let methodsToAdd: any = []
    if (basedOn === 'both') {
      Object.keys(TypeSpendData[type as Scope3TypeType]).map((_basedOn: any) => {
        methodsToAdd.push({
           method: TypeSpendData[type as Scope3TypeType][_basedOn].code,
           year: year
          })
      })
    }
    else methodsToAdd.push({ 
      method: TypeSpendData[type as Scope3TypeType][basedOn].code,
      year: year
     })
    await dispatch(postMethods(methodsToAdd))
  }

  React.useEffect(() => {
    if (selectedBasedOnForType.length > 1) setBasedOn('both')
    else setBasedOn(selectedBasedOnForType[0])
  }, [type, selectedBasedOnForType])

  React.useImperativeHandle(ref, () => ({ onButtonClick }), [basedOn,selectedBasedOnForType]);

  return (
    <div className='scope3-no-data'>
      <div className={`choose-method`}>
        {/* {
          !props.isSettings?
          <>
            <h3>Step 1: Choose calculation method</h3>
            <p>Please select the approach you would like to take to calculate {type} emissions for the current financial year.</p>
          </>
          :
          <div>
            <h3>Choose/change calculation method</h3>
            <p>Please select the approach you would like to take to calculate {type} emissions for the current financial year.</p>
          </div>
        } */}

        <div className='methods'>
          {
            Object.keys(TypeSpendData[type as Scope3TypeType]).map((_basedOn: any, index) => (
              <div key={index} className={`method ${basedOn === _basedOn ? 'selected' : ''}`} onClick={() => setBasedOn(_basedOn)}>
                 <h4>
                  {
                  TypeSpendData[type as Scope3TypeType][_basedOn]?.changeCalculationMethod?.name || changeSnakeCaseToSentence(_basedOn,true)
                  }{' '}
                  method</h4>
                 <p style={{paddingBottom:'1rem'}}>Calculate emissions based on the {TypeSpendData[type as Scope3TypeType][_basedOn]?.changeCalculationMethod?.boxText}</p>
                <BEButton onClick={() => setBasedOn(_basedOn)}
                  className={basedOn === _basedOn ? 'primary' : 'secondary-green'}
                >
                  {
                    basedOn === _basedOn ? 'Selected' : 'Choose Method'
                  }
                </BEButton>
              </div>
            ))
          }
          {
            Object.keys(TypeSpendData[type as Scope3TypeType]).length === 2 &&
            <div key={'both'} className={`method ${basedOn === 'both' ? 'selected' : ''}`} onClick={() => setBasedOn('both')}>
              <h4>Combined method</h4>
              <p style={{paddingBottom:'1rem'}}>Calculate emissions using a combination of {
                Object.keys(TypeSpendData[type as Scope3TypeType]).map((key)=>`${
                  TypeSpendData[type as Scope3TypeType][key]?.changeCalculationMethod?.name || changeSnakeCaseToSentence(key,true)
                } method`).join(' & ')
              }.
              </p>
              <BEButton className={basedOn === 'both' ? 'primary' : 'secondary-green'}
                onClick={() => setBasedOn('both')}
              >
                {
                  basedOn === 'both' ? 'Selected' : 'Choose Method'
                }
              </BEButton>
            </div>
          }
        </div>

      </div>
      <hr />
      {/* {
        !props.isSettings &&
        <div className='instructions gap-bottom'>
          <h3>Step 2: Review sample and upload data</h3>
          <div className='steps'>
            {
              steps.map((step, index) => (
                <div key={index} className='step'>
                  <h4>{step.title}</h4>
                  <p>{step.description}
                  </p>
                </div>
              ))
            }
          </div>
        </div>
      } */}
      {/* <BEButton className={'primary'} size='large' loading={loading} style={{
        marginLeft: !props.isSettings ? '1.5rem' : 'auto',
      }}
        onClick={handleSelect}
      >
        {
          props.isSettings ? 'Save' : 'Start Upload'
        }
      </BEButton> */}

    </div>
  );
}

export default React.forwardRef(ChangeCalculationMethod);