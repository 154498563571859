import React, { useEffect } from "react";
import GeneralSettings from "./Views/GeneralSettings";
import Users from "./Views/Users";
import './styles.scss'
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import { Outlet } from "react-router-dom";
import { navigateTo } from "../../Navigation/NavigationManager";
import { Logs } from "./Views/Logs";
import SettingsIcon from "../../Components/BEIcons/SettingsIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import UsersIcon from "../../Components/BEIcons/UsersIcon";
import NotificationIcon from "../../Components/BEIcons/NotificationIcon";
import { setSettingsActiveTab } from "../../Redux/SettingsReducer";
import { useAppDispatch } from "../../Config/Hooks/useAppDispatch";
import IntegrationIcon from "../../Components/BEIcons/IntegrationIcon";
import Layers from "../../Components/BEIcons/Layers";
import DownloadIcon from "../../Components/BEIcons/DownloadIcon";
import {TopSection} from "./Components/TopSection";


const Settings = () => {
  const activeState = useTypedSelector((state) => state.settings.activeTab);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (window.location.pathname.includes('/home/settings/vault')) {
      dispatch(setSettingsActiveTab(3));
    } else if (window.location.pathname === '/home/settings') {
      dispatch(setSettingsActiveTab(0));
    }
  }, [window.location.pathname])

  const settingsTabs = [
    {
      name: "General Settings",
      icon: (
        <SettingsIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/general-settings'
    },
    {
      name: "Users",
      icon: (
        <UsersIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/users'
    },
    // {
    //   name: "Logs",
    //   icon: (
    //     <IntegrationIcon
    //       fill={PrimaryTheme.primaryGreen}
    //       inheritSize
    //     />
    //   ),
    //   path: '/home/settings/logs'
    // },
      {
      name: "Emission factor sources",
      icon: (
        <IntegrationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/emission-factor-sources'
    },
  ];

  return (
    <div className="settings">
      <TopSection/> <br/>
      {activeState === 1 && <GeneralSettings />}
      {activeState === 2 && <Users />}
      {activeState === 3 && <Outlet />}
      {activeState === 4 && <Logs />}
      {activeState === 0 &&
        <div className="settings-container">
          {
            settingsTabs.
              map((tab, index) => (
                <div
                  key={index}
                  className='new-folder'
                  onClick={() => {
                    dispatch(setSettingsActiveTab(index + 1));
                    navigateTo(tab.path);
                  }}
                >
                  <div className='circle'>
                    <div className='settings-icon'>
                      {tab.icon}
                    </div>
                  </div>
                  <span style={{ fontSize: "14px", lineHeight: "21px", fontWeight: "500" }}>{tab.name}</span>
                </div>
              ))
          }
        </div>}
    </div>
  );
};

export default Settings;
