import { 
    REQUEST_OTP, 
    VERIFY_OTP, 
    LOGOUT,
    GET_NEW_TOKEN,
} from "../../Utils/Routes/UserRouts";
import { 
    signIn, 
    setOtp, 
    signOut, 
    signInFailed, 
    setResendOtp, 
} from "../../Redux/UserReducer";
import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { getOnboardingStage } from "./OnboardingActions";
import { BENotify } from "../../Components/BENotify";
import { clearStore } from "../../Redux/ExtraReducrActions";

export const getOtp = (email: string) => async (dispatch: AppDispatch) => {
    console.log('here');
    dispatch(setOtp('loading'));
    const [data, error] = await handleAPICall(REQUEST_OTP({ email: email }));
    if (data) {
        dispatch(setOtp('success'));
        BEMessage.success('OTP sent successfully')
    }
    else {
        dispatch(setOtp('error'))
        // BEMessage.error('This Email ID isn’t registered')
        BENotify.info(
            "Hey, this email is not registered with a client account",
            "If you would like to onboard with us, please connect with us at info@breatheesg.com.",
            () => { window.location.href = "mailto:info@breatheesg.com"; }
        );
    }
}

export const verifyOtp = (email: string, otp: string, redirect?:string) => async (dispatch: AppDispatch) => {
    dispatch(setOtp('loading'));
    const [data, error] = await handleAPICall(VERIFY_OTP({ email: email, otp: otp }));
    dispatch(setOtp('success'))
    if (data) {
        dispatch(signIn(data));
        if(redirect) navigateTo(redirect);
        else if (data?.userInfo?.role === 'ESG_CONSULTANT')
            navigateTo('/choose-company');
        else
            navigateTo('/home/dashboard');
    }
    else {
        BEMessage.error('Invalid OTP')
        dispatch(setOtp('error'))
        dispatch(signInFailed());
    }
}

export const getNewToken = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_NEW_TOKEN());
    if (data) {
        return data.data;
    }
    else {
        BEMessage.error('Something went wrong, please try again later.')
        return null;
    }
}

export const resendOtp = (email: string) => async (dispatch: AppDispatch) => {
    dispatch(setResendOtp('loading'));
    const [data, error] = await handleAPICall(REQUEST_OTP({ email: email }));
    if (data) {
        dispatch(setResendOtp('success'));
        BEMessage.success('OTP sent successfully')
    }
    else {
        dispatch(setResendOtp('error'))
    }
}

export const logoutAction = () => async (dispatch: AppDispatch) => {
    let body = {
        refresh_token: localStorage.getItem('refresh')
    }
    const [data, error] = await handleAPICall(LOGOUT(body));
    if (data) {
        dispatch(signOut());
        navigateTo('/login');
        dispatch(clearStore());
        localStorage.clear();
    }
    else{
        BEMessage.error('Something went wrong, please try again later.')
    }
}

export const autoLoginFromQuery = (location: any) => async (dispatch: AppDispatch) => {
    const params = new URLSearchParams(location.search);
    const userInfo = params.get('user_info');
    const token = params.get('token');
    if (userInfo && token) {
        const userData = JSON.parse(userInfo || "{}");
        const tokenData = JSON.parse(token || "{}");
        const data = {
            token: tokenData,
            userInfo: userData
        }
        dispatch(signIn(data));
        if (userData?.role === 'ESG_CONSULTANT')
            navigateTo('/choose-company');
        else
            navigateTo('/home/dashboard');
    }
}