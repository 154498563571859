import React from 'react'
import IconProps from './Interface/IconProps'

const PendingIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
	  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	  <rect x="0.00244141" width="20" height="20" rx="10" fill="#009904" fill-opacity="0.3"/>
	  </svg>	  
  );
}

export default PendingIcon