import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Select, Space, InputNumber, Modal } from "antd";
import FileUpload from "../../../Components/BEFileUpload/FileUpload";
import BEDatePicker from "../../../Components/BEDatePicker";
import ToolTip from "../../../Components/BEToolTip";
import InfoIcon from "../../../Components/BEIcons/InfoIcon";
import "./styles.scss";
import { BEButton } from "../../../Components/BEFormItems/BEButton";
import BEMultipleSelect from "../../../Components/BEMultipleSelect";
import ChevronDownIcon from "../../../Components/BEIcons/ChevronDownIcon";
import CurrencyModal from "./CurrencyModal";
import { LableRequired } from "../../../Components/BEFormItems/LableRequired";
// import LocationModal from "./LocationModal";
import { BEMessage } from "../../../Components/BEMessage";
import {
  getAllIndustries,
  completeOnboardingStep1,
  getCompanyDetails,
  updateCompanyDetails,
} from "../../Actions/OnboardingActions";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { GlobalLoader } from "../../../Components/GlobalLoader";
import GooglePlaces from "../../../Components/GooglePlaces";
import { sectors } from "../../../data/sectors";
import { BEEyeButton } from "../../../Components/BEEyeButton";
import { ConfigurstionWelcomeScreen } from "../../Home/WelcomeScreens/ConfigurationWelcomeScreen";
import { MaxCharLength } from "../../../Config/Constants";
import { StepsView } from "../Components/StepView";
import { PrimaryTheme } from "../../../Config/Theme/theames";


export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const AddCompany = () => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState(null);
  const [industries, setIndustries] = useState<any>([]);
  const [openCurrencyModal, setCurrencyOpenModal] = useState(false);
  const [openLocationModal, setLocationOpenModal] = useState(false);
  const [currency, setCurrency] = useState("INR");
  const industriesState = useTypedSelector((state) => state.onBoarding.industries);
  const onBoardingStep1Status = useTypedSelector((state) => state.onBoarding.stage1Data.status)
  const onboardingStage = useTypedSelector((state) => state.common.onboardingStage);
  const companyDetails = useTypedSelector((state) => state.companies.companyDetails);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleEdit = async (values: any) => {
    const obj = {
      ...values,
      logo,
      currency,
      industry: values.industry,
      year_of_incorporation: Number(values.year_of_inc.format("YYYY")),
      reporting_year_end: values.reporting_year_end.format("YYYY-MM-DD"),
      firm_name: values.name,
      placesApi: values.address
    };
    delete obj.name
    if (!logo) delete obj.logo
    await dispatch(updateCompanyDetails(obj, Number(companyDetails?.data?.id), obj.hasOwnProperty('logo')));
  }

  const handleSubmit = async () => {
    const values = await form.getFieldsValue();
    if (values.year_of_inc > dayjs()) {
      BEMessage.error("Year of incorporation should not be greater than current year");
      return;
    }
    await handleEdit(values)
  };

  const fetchCompanyDetails = async () => {
    await dispatch(getCompanyDetails())
  }

  useEffect(() => {
    if (industriesState.status === "success") {
      setIndustries(industriesState.data);
    }
    // console.log(industriesState);
  }, [industriesState.status]);

  useEffect(() => {
    if (onboardingStage.stage > 0) {
      fetchCompanyDetails()
    }
  }, [onboardingStage.stage])

  useEffect(() => {
    dispatch(getAllIndustries());
  }, []);

  useEffect(() => {
    if (companyDetails.status === 'success') {
      form.setFieldsValue({
        name: companyDetails.data?.firm_name,
        year_of_inc: dayjs(`${companyDetails.data?.year_of_incorporation}-01-01`),
        address: companyDetails.data?.address,
        industry: companyDetails.data?.industry,
        website: companyDetails.data?.website,
        revenue: companyDetails.data?.revenue,
        reporting_year_end: dayjs(companyDetails.data?.reporting_year_end),
        sector: companyDetails.data?.sector
      });
      setCurrency(
        companyDetails.data?.currency ? companyDetails.data?.currency : "INR"
      );
    }
  }, [companyDetails.data])

  function AddOn() {
    return (
      <div
        onClick={() => setCurrencyOpenModal(true)}
        style={{ display: "flex", alignItems: "center", cursor: "pointer", borderRadius:'6px' , border:`1px solid ${PrimaryTheme.primaryGrayInputBorder}`, padding:'7px 11px 7px' }}
      >
        {currency} <ChevronDownIcon fill="gray" />
      </div>
    )
  }

  return (
    <div>
      <CurrencyModal
        openModal={openCurrencyModal}
        setOpenModal={setCurrencyOpenModal}
        setCurrency={setCurrency}
      />

      
      <StepsView handleSave={async () => {await handleSubmit()}} />
      <Form form={form} name="basic" autoComplete="off" onFinish={handleSubmit} style={{ paddingTop:'2rem'}}>
        {
          companyDetails.status === "loading" ? <GlobalLoader height="50vh" /> :
          <Row style={{marginTop:'1rem'}} gutter={8}>
          <Col span={16}>
            <Row>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <p style={{ marginBottom: "0.8rem" }}>
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Name </LableRequired>
                  </p>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input name of company!",
                      },{
                        type: 'string',
                        max:MaxCharLength,
                        message: 'Company name should be less than '+ MaxCharLength +' characters'
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter name of the company"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <p style={{ marginBottom: "0.8rem" }}>
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Year of Incorporation </LableRequired>
                  </p>
                  <Form.Item
                    name="year_of_inc"
                    rules={[
                      {
                        required: true,
                        message: "Please input a valid Year of Incorporation!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <BEDatePicker
                      picker="year"
                      form={form}
                      field="year_of_inc"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <p style={{ marginBottom: "0.8rem" }}>
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Address </LableRequired>
                  </p>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter an address for your company!",
                      },
                    ]}
                  >
                    <GooglePlaces form={form} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <p style={{ marginBottom: "0.8rem" }}>
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Industry </LableRequired>
                  </p>
                  <BEMultipleSelect
                    data={industries}
                    single
                    rules={[
                      {
                        required: true,
                        message: "Please input industry of company!",
                      },
                    ]}
                    name="industry"
                    placeholder="Enter the industry"
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <p style={{ marginBottom: "0.8rem" }}>
                  <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Website</LableRequired>
                  </p>
                  <Form.Item
                    name="website"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please input a valid website of company!",
                      // },
                      {
                        type: "url",
                        message: "Please enter a valid url",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the link to the company’s website"
                      size="large"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <p style={{ marginBottom: "0.8rem" }}>
                    {/* Revenue in last financial year{" "} */}
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Currency</LableRequired>
                    {/* <ToolTip
                      title={() => {
                        return (
                          <>
                            <div className="tool-title">
                              Revenue in last financial year
                            </div>
                            <div className="tool-body">
                              Revenue in last financial year refers to the
                              revenue generated in the last financial year
                            </div>
                          </>
                        );
                      }}
                      placement="right"
                    > */}
                    {/* <span style={{ display: "inline-block", height: "16px" }}>
                        <InfoIcon inheritSize fill="gray" />
                      </span>
                    </ToolTip> */}
                  </p>
                  {/* <Form.Item
                    name="revenue"
                    rules={[
                      {
                        required: true,
                        message: "Please input a valid number for revenue!",
                      },
                      {
                        type: "number",
                      },
                    ]}
                  > */}
                  {/* <InputNumber
                      placeholder="Enter the revenue of last financial year"
                      style={{ width: "100%" }}
                      size="large"
                      addonBefore={addOn}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    /> */}
                  <AddOn />
                  {/* </Form.Item> */}
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>

                  <BEEyeButton
                    style={{ marginBottom: '0.8rem' }}
                    title={"Reporting Year End"}
                    discription={"Reporting Year End refers to the end of the year for which the reports will be generated"}
                  >
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Reporting Year End </LableRequired>
                  </BEEyeButton>


                  <Form.Item
                    name="reporting_year_end"
                    rules={[
                      {
                        required: true,
                        message: "Please input reporting year end!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <BEDatePicker
                      form={form}
                      field="reporting_year_end"
                      format="DD MMM"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: "1rem 1rem 1rem 0" }}>
                  <BEEyeButton
                    style={{ marginBottom: '0.8rem' }}
                    title={"Sector"}
                    discription={"Sector selection is required for emission calculations. If you are unsure about which sector your company belongs to, please select 'Commercial/Institutional'."}
                  >
                    <LableRequired style={{color:PrimaryTheme.primaryGreyDark}}>Sector</LableRequired>
                  </BEEyeButton>
                  <Form.Item name="sector" style={{ width: "100%" }}>
                    <BEMultipleSelect
                      data={sectors}
                      single
                      name="sector"
                      placeholder="Select the sector"
                      isDataStrArray
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'end',marginTop:'1rem'}}>
              <Form.Item>
                <div>
                  {(
                    <img
                      src={
                        logo
                          ? URL.createObjectURL(logo)
                          : companyDetails.data?.logo
                            ? companyDetails.data?.logo
                            : "https://placehold.co/400?text=Company+Logo"
                      }
                      alt={logo ? logo : companyDetails.data?.logo}
                      style={{
                        objectFit: "contain",
                        height: "145px",
                        width: "100%",
                      }}
                    /> 
                  )}
                  <FileUpload
                    handleFileChange={setLogo}
                    text={
                      companyDetails.data?.logo
                        ? "Upload new logo"
                        : "Upload the Company’s Logo"
                    }
                    width="100%"
                    hint=" *.jpg, *.png or *.jpeg, Max 2MB"
                    required
                    fileType="image"
                  />
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>}
      </Form>
    </div>
  );
};

export default AddCompany;

