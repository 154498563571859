import * as React from 'react';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import LoginImage from '../../../assets/images/loginImage.svg';
export interface ILeftComponentLoginProps {
}

export function LeftComponentLogin (props: ILeftComponentLoginProps) {
  return (
    <div className='left-section'>
        <div>
           <img src={LoginImage} alt="image" className='img-1' />
        </div>
    </div>
  );
}
