import * as React from 'react';
import './style.scss';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { Steps } from 'antd';
import DataUploadStep1 from './Steps/Step1';
import DataUploadStep2 from './Steps/Step2';
import DataUploadStep3 from './Steps/Step3';
import DataUploadStep4 from './Steps/Step4';
import CurrentIcon from '../../../../../../Components/BEIcons/StepCurrentIcon';
import SuccessIcon from '../../../../../../Components/BEIcons/StepSuccessIcon';
import PendingIcon from '../../../../../../Components/BEIcons/StepPendingIcon';


export interface IDataUploadDrawerProps {
  open: boolean;
  setOpen: Function;
}

const StepNames = ['Add Data', 'Upload', 'Column Mapping', 'Review', 'Configure']


export function DataUploadDrawer(props: IDataUploadDrawerProps) {
  const step1Ref = React.useRef<any>({ onButtonClick: () => { } })
  const step2Ref = React.useRef<any>({ onButtonClick: () => { } })
  const step3Ref = React.useRef<any>({ onButtonClick: () => { } })
  const step4Ref = React.useRef<any>({ onButtonClick: () => { } })
  const [loading, setLoading] = React.useState<boolean>(false)
  const [step, setStep] = React.useState<number>(1);
  
  React.useEffect(() => {
    setStep(1);
  }, [props.open]);
  
  const customDot = (dot:any, { status, index }:{status:any,index:any}) => {
    console.log(status)
    if (status === 'process') {
      return (
        <CurrentIcon />
      );
    } else if(status==='finish') {
      return (
        <SuccessIcon  />
      );
    }else{
      return <PendingIcon />
    }
  };
  return (
    <BEDrawer
      width='100vw'
      heading={StepNames[step]}
      open={props.open}
      setOpen={props.setOpen}
      footer={
        <>
          <BEButton className='primary' size='large' loading={loading}
            onClick={async () => {
              setLoading(true);
              if (step === 1) await step1Ref.current.onButtonClick();
              if (step === 2) await step2Ref.current.onButtonClick();
              if (step === 3) await step3Ref.current.onButtonClick();
              if (step === 4) await step4Ref.current.onButtonClick();
              setLoading(false);
            }}
          >{
            step === 1 ? 'Save & map': step === 2 ? 'Continue': step === 3 ? 'Continue': 'Save & Next'
          }</BEButton>
          <BEButton size='large'
            onClick={() => props.setOpen(false)}
          >Cancel</BEButton>
        </>
      }
    >
      <div className='data-upload'>
        <div style={{margin:'2rem 2rem 4rem 2rem'}}>
              <Steps 
                size='small'
                progressDot={customDot}
                labelPlacement='vertical'
                current={step-1}
                items={StepNames.filter(name=> name!=='Add Data').map((name)=>{
                  return {
                    title:name,
                  }
                })}
              />
        </div> 
        {step === 1 && <DataUploadStep1 ref={step1Ref} setStep={setStep} />}
        {step === 2 && <DataUploadStep2 ref={step2Ref} setStep={setStep} />}
        {step === 3 && <DataUploadStep3 ref={step3Ref} setStep={setStep} />}
        {step === 4 && <DataUploadStep4 ref={step4Ref} setOpen={props.setOpen} />}

      </div>
    </BEDrawer>
  );
}
