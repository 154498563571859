import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../../../Redux/Types/scope3Types';
import { getGraphData } from '../../../../../../../../Actions';
import { Select } from 'antd';
import { TypeSpendData } from '../../../../../../../../Data/data';
import { AddCommaToNumber, changeSnakeCaseToSentence } from '../../../../../../../../../../Config/Functions/UsefullFunctions';
import Scope3ModifiedDonutChart from './Scope3ModifiedDonutChart';
import { BEWrapper } from '../../../../../../../../../../Components/BEWrapper';
import { NewBETable } from '../../../../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';

export interface IScope3CustomDonutChartProps {
}

export function Scope3CustomDonutChart (props: IScope3CustomDonutChartProps) {
  const dispatch = useAppDispatch();
  const [chartData,setChartData]=React.useState<any>([])
  const data = useTypedSelector(state => state.scope3.dashboardData.graphData);
  const year = useTypedSelector(state => state.common.date.currentYear);
  const {type} = useParams<{type: Scope3TypeType}>();
  const methods = useTypedSelector(state => state.scope3.methods.data);
  const [filters,setFilters]=React.useState<any>({
    method:[],
    filter_by:'category',
  })

  const columns:ColumnsType<any> = [
    {
      title: 'CATEGORY',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title:'% OF EMISSIONS',
      render:(text:any,record:any)=>`${((record.value/data.data.reduce((acc:any,item:any)=>acc+item.value,0))*100).toFixed(3)}%`
    },
    {
      title: 'TOTAL EMISSIONS (tCO2e)',
      dataIndex: 'value',
      key: 'value',
      render:(text:any)=>AddCommaToNumber(text,false,true,false,3)
    }
  ]

  React.useEffect(()=>{
    const methodsOfCurrentType =  Object.keys(TypeSpendData[type as Scope3TypeType]).map((item:any)=>TypeSpendData[type as Scope3TypeType][item]?.code)
    const firstMethod  = methods.find((method:any)=>method.year == year && methodsOfCurrentType.includes(method.method))
    if(firstMethod){
      setFilters({
        method:[firstMethod.method],
        filter_by:'category'
      })
    }
  },[methods,year,type])

  React.useEffect(()=>{
    if(filters.method?.length == 0) return
    dispatch(getGraphData(year,filters.filter_by,filters.method))
  },[filters])

  React.useEffect(()=>{
    let temp:any = data.data.slice(0,9)
    if(data.data.length > 9){
      temp.push({
        name:'Other',
        value:data.data.slice(9).reduce((acc:any,item:any)=>acc+item.value,0)
    })
    }
    setChartData(temp)
  },[data])
      
  return (
    <div className='scope-3-custom-donut-chart'>
      <p className='heading'>Emissions Hotspot Analysis</p><br/>

        <div className='filters'>
         <div>
          <p>Method :</p>
            <Select  size='large' style={{width:'200px'}}
              value={
                filters.method?.length == 1 ? filters.method[0] : 'all'
              }
              onChange={
                (value:any)=> {
                  if(value == 'all'){
                    setFilters({...filters,method:Object.keys(TypeSpendData[type as Scope3TypeType]).map((item:any)=>TypeSpendData[type as Scope3TypeType][item]?.code)})
                  }else{
                    setFilters({...filters,method:[value]})
                  }
              }
            }
            >
              {
                Object.keys(TypeSpendData[type as Scope3TypeType]).map((item:any)=>(
                  <Select.Option key={item} value={
                    TypeSpendData[type as Scope3TypeType][item!]?.code
                  }>
                    {
                      TypeSpendData[type as Scope3TypeType][item]?.changeCalculationMethod?.name 
                      ? TypeSpendData[type as Scope3TypeType][item]?.changeCalculationMethod?.name:
                      changeSnakeCaseToSentence(item,false,true)
                    }
                  </Select.Option>
                ))
                
              }
              {
                Object.keys(TypeSpendData[type as Scope3TypeType])?.length > 1 &&
                <Select.Option value={
                  'all'
                }>
                  All Methods
                </Select.Option>
              }
            </Select>
         </div>
    
         <div>
          <p>Filter By :</p>
            <Select size='large' style={{width:'200px'}}
              value={filters.filter_by}
              onChange={(value:any)=>{setFilters({...filters,filter_by:value})}}
              >
                {
                  ['category','vendor_name','facility'].map((item:any)=>(
                    <Select.Option key={item} value={item}>
                      {
                        changeSnakeCaseToSentence(item,false,true)
                      }
                    </Select.Option>
                  ))
        
                }
            </Select>
         </div>
        </div>
  
         <div className='graph-box'>
   
            <BEWrapper>
               <Scope3ModifiedDonutChart
                    loading={data.status === 'loading'}
                    size={120}
                    data={chartData}
               />
            </BEWrapper>


            <BEWrapper>
              <NewBETable 
                showIndex
                data={data.data} 
                loading={data.status === 'loading'} 
                columns={columns}
                pagination 
              />
            </BEWrapper>
   
         </div>
    </div>
  );
}
