import * as React from 'react';
import ChangeCalculationMethod from '../../../../../../../Components/ChangeCalculationMethod';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import './style.scss';
import { Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { useParams } from 'react-router-dom';

export interface IChangeBasedOnProps {
}

export function ChangeBasedOn(props: IChangeBasedOnProps) {
  const changeCalculationMethodRef = React.useRef<any>({ onButtonClick: () => { } })
  const [loading, setLoading] = React.useState<boolean>(false)
  const { type } = useParams<{ type: Scope3TypeType }>();
  return (
    <div className='change-based-on'>
      <p className='heading'>Choose/change calculation method</p>
      <p className='detail'>Please select the approach you would like to take to calculate {type} emissions for the current financial year.</p>
      <ChangeCalculationMethod ref={changeCalculationMethodRef} />
      <BEButton className='primary' size='large' loading={loading} onClick={async () => {
        setLoading(true);
        await changeCalculationMethodRef.current.onButtonClick();
        setLoading(false);
      }}>
        Save
      </BEButton>
    </div>
  );
}
