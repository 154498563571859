import React from 'react'
import IconProps from './Interface/IconProps';

const Dashboard = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M6.66665 7.50016H3.33331V16.6668H6.66665V7.50016Z"
          fill={props.stroke ? props.stroke : "white"}
        />
        <path
          d="M16.6666 10.8335H13.3333V16.6668H16.6666V10.8335Z"
          fill={props.stroke ? props.stroke : "white"}
        />
        <path
          d="M11.6666 3.3335H8.33331V16.6668H11.6666V3.3335Z"
          fill={props.stroke ? props.stroke : "white"}
        />
      </g>
    </svg>
    
  );
}

export default Dashboard