import * as React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import './style.scss';

export interface IBETextAreaProps extends TextAreaProps {
  rows?: number;
  children?: React.ReactNode;
  placeholder: string;
  form: any;
  name?: string
  onChange?: any
}

export function BETextArea(props: IBETextAreaProps) {
  return (
    <Input.TextArea onChange={(e) => {
      props.form.setFieldValue(props.name ? props.name : 'description', e.target.value)
      props?.onChange && props.onChange(e)
    }
    } className='be-text-area'
      value={props.form.getFieldValue(props.name ? props.name : 'description')}
      rows={props.rows}
      placeholder={props.placeholder}
    />
  );
}
