import * as React from 'react';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { getOtp, verifyOtp, resendOtp, autoLoginFromQuery } from '../../Actions/LoginActions';
import { Form,} from 'antd';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../Components/BEFormItems/LableRequired';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { BEMessage } from '../../../Components/BEMessage';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation, useSearchParams } from 'react-router-dom';
import CompanyLogo from '../../../assets/images/Company/ZeroLogo.png';

export interface IRightComponentLoginProps {
    step: number;
    setStep: Function;
}

export function RightComponentLogin(props: IRightComponentLoginProps) {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const otpStatus = useTypedSelector(state => state.user.otp.status);
    const otpResendStatus = useTypedSelector(state => state.user.otp.resendStatus);
    const [otpValue, setOtpValue] = React.useState('');
    const [email, setEmail] = React.useState('');

    const FetchOtp = (values: any) => {
        setEmail(values.email);
        dispatch(getOtp(values.email));
    }

    const handleResendOTP = () => {
        dispatch(resendOtp(email));
    }

    const handleVerifyOtp = (values: any) => {
        const redirect = searchParams.get('redirect') || undefined;
        dispatch(verifyOtp(email, values.otp, redirect));
    }

    React.useEffect(() => {
        if (otpStatus === 'success')  props.setStep(2);
        if(email==='')  props.setStep(1);
    }, [otpStatus])

    React.useEffect(() => {
        dispatch(autoLoginFromQuery(location));
    }, [])

    return (
        <div className='right-section'>
            <div className='welcome'>
                <p> Welcome To</p>
                <img src={CompanyLogo} alt="image" className='img-1' />
            </div>
            {
                props.step === 2 ?
                    <div className='form-box'>
                        <p style={{ fontSize: '1.5rem' }}>Email Verification</p>
                        <br />
                        <p style={{ color: PrimaryTheme.primaryGray }}>Please verify your email by entering the OTP that has been sent to {email}</p>
                        <br />
                        <Form onFinish={handleVerifyOtp}>
                            <LableRequired>OTP</LableRequired>
                            <Form.Item noStyle name={'otp'} rules={[{ required: true }]} >
                                <BEInput style={{ marginTop: '0.5rem', color: 'black' }} size='large' placeholder='Enter OTP here' />
                            </Form.Item>
                            <Form.Item noStyle>
                                <BEButton
                                    loading={otpStatus === 'loading'}
                                    htmlType='submit' style={{ width: '100%', marginTop: '0.5rem' }} className='primary' size='large'>
                                    Verify & Login
                                </BEButton>
                            </Form.Item>
                        </Form>
                        <p style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                            Didn’t Get OTP? 
                            {
                                otpResendStatus === 'loading' ?
                                    <LoadingOutlined style={{marginLeft:'0.5rem'}}/>
                                    :
                                    <span onClick={handleResendOTP} style={{ color: PrimaryTheme.primaryGreen,cursor:'pointer' }}> Resend OTP</span>
                            }
                        </p>
                        <p style={{ textAlign: 'center', marginTop: '2rem' }}>
                            <>Having trouble logging in?</>
                            <a href="mailto:info@breatheesg.com" style={{ color: PrimaryTheme.primaryGreen }}> Contact Us</a>
                        </p>
                    </div>

                    :


                    <div className='form-box'>
                        <Form onFinish={FetchOtp}>
                            <p style={{ fontSize: '1.5rem' }}>Login</p>
                            <br />
                            <p>Enter your registered Email ID to continue</p>
                            <br />
                            <LableRequired>Email</LableRequired>
                            <Form.Item name={'email'} rules={[{ required: true,message: 'Please enter an email' }, { type: 'email', message: 'Please enter a valid email' }]}>
                                <BEInput style={{ marginTop: '0.5rem', color: 'black' }} size='large' placeholder='Your Email ID' />
                            </Form.Item>
                            <Form.Item noStyle>
                                <BEButton
                                    loading={otpStatus === 'loading'}
                                    htmlType='submit' style={{ width: '100%', marginTop: '0.5rem' }} className='primary' size='large'>
                                    Continue
                                </BEButton>
                            </Form.Item>
                            <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                                <>Having trouble logging in?</>
                                <a href="mailto:info@breatheesg.com" style={{ color: PrimaryTheme.primaryGreen }}> Contact Us</a>
                            </p>
                        </Form>
                    </div>
            }
        </div>
    );
}
