import React from 'react'
import TopSection from '../TopSection';
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  return (
    <div style={{padding: "0 1.5rem"}}>
      <TopSection />
      <Outlet />
    </div>
  );
}

export default DashboardLayout