import React, { useEffect, useState } from 'react'
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { filterDataByMonthOrBU, getAllEmissionCalculations, getScope3EmissionCalculations } from '../../Actions';
import { AddCommaToNumber, getMonthsByQuarter } from '../../../../Config/Functions/UsefullFunctions';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { Col, Row } from 'antd';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import dayjs from 'dayjs';
import { TotalEmissionsByScopeGraph } from './Graphs/EmmisionsByScopeGraph';
import { TotalEmissionsByTypeGraph } from './Graphs/EmissionsBYTypeGraph';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { EmmisionsDonutChart } from './Graphs/EmmisionsDonutCharts';
import './style.scss';
import EmissionCard from '../../../../Components/Cards/EmissionCard';
import { getTaskStatus } from '../../../../views/Actions/HomeActions';

export interface IOverviewProps {

}

const Overview = () => {
  const dispatch = useAppDispatch()
  const scope1Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope1Calculations);
  const scope2Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope2Calculations);
  const scope3Calculations = useTypedSelector(state => state.dashboard.emissionsCalculations.scope3Calculations);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs)
  const yearEnd = useTypedSelector((state) => state.companies.yearEnd);
  const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit)
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup)
  const [scope1Total, setScope1Total] = useState(0)
  const [scope2Total, setScope2Total] = useState(0)
  const [scope3Total, setScope3Total] = useState(0)
  const [totalEmission, setTotalEmission] = useState(0)
  const currentYear = useTypedSelector(state => state.common.date.currentYear)
  const currentMonth = useTypedSelector((state) => state.common.date.currentMonth);
  const [filterdScope1Calculation, setFilteredScope1Calculations] = useState<any>([])
  const [filteredScope2Calculation, setFilteredScope2Calculations] = useState<any>([])
  const [filteredScope3Calculation, setFilteredScope3Calculations] = useState<any>([])

  const columns: ColumnsType<any> = [
    {
      title: "Sr. No.",
      dataIndex: "metric",
      render: (value, record, ind) => ind + 1,
    },
    {
      title: "Metric",
      dataIndex: "title",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      render: (value, record) => {
        return <p>{value}</p>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,false,3) : "-"}</p>;
      },
    },
    {
      title: "CO2 (kg)",
      dataIndex: "co2",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,false,3) : "-"}</p>;
      },
    },
    {
      title: "CH4 (kg)",
      dataIndex: "ch4",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,false,3) : "-"}</p>;
      },
    },
    {
      title: "N2O (kg)",
      dataIndex: "n2o",
      render: (value) => {
        return <p>{value ? AddCommaToNumber(Number(value), false, true,false,3) : "-"}</p>;
      },
    },
    {
      title: "CO2e (tonne)",
      dataIndex: "tco2e",
      render: (value, record) => {
        return {
          props: {
            style: { backgroundColor: PrimaryTheme.primaryGreenLight },
          },
          children: (
            <p
              style={{
                fontWeight: 700,
                backgroundColor: PrimaryTheme.primaryGreenLight,
              }}
            >
              {/* {value !== undefined
                ? value?.toFixed(3)
                : record?.Tco2e?.toFixed(3)} */}
              {AddCommaToNumber(Number(value), false, true,false,3)}
            </p>
          ),
        };
      },
      sorter: (a, b) => {
        return a.tco2e < b.tco2e ? -1 : 1;
      },
    },
    // {
    //   title: "Biogenic CO2*",
    //   dataIndex: "co2",
    // },
  ];



  const setFilteredData = async() => {
    let data = await dispatch(filterDataByMonthOrBU(true,true))
    setFilteredScope1Calculations(data?.scope1Data)
    setFilteredScope2Calculations(data?.scope2Data)
    setFilteredScope3Calculations(data?.scope3Data)
  }

  useEffect(() => {
      setFilteredData()
  }, [scope1Calculations, scope2Calculations, scope3Calculations, currentBU, currentBG, currentMonth])



  useEffect(() => {
    let total = filterdScope1Calculation.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    let total2 = filteredScope2Calculation.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    let total3 = filteredScope3Calculation.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    setScope1Total(total)
    setScope2Total(total2)
    setScope3Total(total3)
    setTotalEmission(total + total2 + total3)
  }, [filterdScope1Calculation, filteredScope2Calculation, filteredScope3Calculation])


  return (
    <div style={{ padding: "1rem 0" }} className='emissions-overview'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
        }}
      >
        <EmissionCard
          value={AddCommaToNumber(totalEmission, false, true)}
          loading={
            scope1Calculations.status === 'loading' ||
            scope2Calculations.status === 'loading'
          }
          unit="tCO2e"
          description="Total Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope1Total, false, true)}
          loading={
            scope1Calculations.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 1 Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope2Total, false, true)}
          loading={
            scope2Calculations.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 2 Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope3Total, false, true)}
          loading={
            scope3Calculations.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 3 Emissions"
        />
      </div>

      <div style={{ margin: "3rem 0" }}>
        {
          scope1Calculations.status !== 'loading' && scope2Calculations.status !== 'loading'
            ? <TotalEmissionsByScopeGraph /> : <GlobalLoader height='20rem' />
        }

      </div>
      {/* {
        currentBU === 0 &&
        <div style={{ paddingTop: "1rem" }}>
          <EmmisionsDonutChart />
        </div>
      } */}
      <div style={{ margin: "5rem 0" }}>
        {
          scope1Calculations.status !== 'loading' && scope2Calculations.status !== 'loading'
            ? <TotalEmissionsByTypeGraph /> : <GlobalLoader height='20rem' />
        }

      </div>
    </div>
  );
}

export default Overview;