import React, { useEffect } from 'react'
import TopSection from '../TopSection';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getMethods } from '../../Actions';

const Scope3Layout = () => {
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    dispatch(getMethods())
  }, [])

  return (
    <div>
      <TopSection />
      <Outlet />
    </div>
  );
}

export default Scope3Layout