import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BETable } from '../../../../../Components/BETable';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import { deleteBusinessUnitRef } from '../../../../Actions/BusinessUnitActions';
import { ColumnsType } from 'antd/es/table';
import { Select } from 'antd';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';

export interface IBusinessGroupsOfUnitTableProps {
    isEdit: boolean;
    businessUnit: any;
    addedBusinessGroups: any;
    setAddedBusinessGroups: Function;
    open: boolean;
}

export function BusinessGroupsOfUnitTable (props: IBusinessGroupsOfUnitTableProps) {
    const dispatch = useAppDispatch();
    const businessGroups = useTypedSelector(state => state.entity.BusinessGroups);
    const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
    const [loading, setLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState('')

    React.useEffect(() => {
      console.log('isEdit',props.isEdit);
        if (props.isEdit) {
            props.setAddedBusinessGroups(businessGroups.data.filter((item: any) => businessUnitRefs.data.find((item2: any) => item2.business_unit === props.businessUnit.id && item2.business_group === item.id)));
        }
        else {
            props.setAddedBusinessGroups([]);
        }
    }, [props.isEdit,props.open]);

    const handleDelete = async (id: any) => {
      if(props.isEdit && businessUnitRefs.data.find((item:any)=>item.business_unit === props.businessUnit.id && item.business_group === id)){
        await dispatch(deleteBusinessUnitRef(businessUnitRefs.data.find((item:any)=>item.business_unit === props.businessUnit.id && item.business_group === id)?.id));
      }
      props.setAddedBusinessGroups(props.addedBusinessGroups.filter((item:any)=>item.id !== id));
    }

    const columns: ColumnsType<any> = [
      {
        title:'S.No',
        render:(text:any,record:any,index:any)=>index+1
      },
      {
       title: 'Business Group',
       dataIndex: 'name',
       key: 'name'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (text: any, record: any) => {
          return (
            <div onClick={()=>{
              handleDelete(record.id)
            }}>
              <DeleteIcon stroke={PrimaryTheme.primaryGray}/>
              </div>
          )
        }
      }
    ]

    return (
        <div>
          <br/>
          <hr/>
          <p>Part of the below Business Groups</p>
          <div style={{display:'flex',alignItems:'center',gap:'1rem',marginBottom:'1rem'}}>
             <Select
             style={{width:'200px'}}
             showSearch
                value={searchText}
                onChange={(value)=>{
                  setSearchText(value);
                }}
                optionFilterProp="children"
             >
                {
                  businessGroups.data.filter((item:any)=>!props.addedBusinessGroups.find((item2:any)=>item2.id === item.id)).map((item:any)=>(
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))
                }
             </Select>
             <BEButton
                onClick={()=>{
                  let selectedBusinessGroup = businessGroups.data.find((item:any)=>item.id === searchText)
                  if(!selectedBusinessGroup) return;
                  props.setAddedBusinessGroups([...props.addedBusinessGroups, selectedBusinessGroup]);
                  setSearchText('');
                }}
                className='primary' 
             >
                 Add
             </BEButton>
          </div>
            <BETable
                columns={columns}
                data={props.addedBusinessGroups}
            />
            <hr/>
            <br/>
        </div>
    );
}
