
import React from 'react'
import IconProps from './Interface/IconProps';

const SettingsItemIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1667 0.333496L6 15.3335H24.3333L15.1667 0.333496ZM15.1667 6.7335L18.3833 12.0002H11.9333L15.1667 6.7335ZM24.3333 18.6668C20.1833 18.6668 16.8333 22.0168 16.8333 26.1668C16.8333 30.3168 20.1833 33.6668 24.3333 33.6668C28.4833 33.6668 31.8333 30.3168 31.8333 26.1668C31.8333 22.0168 28.4833 18.6668 24.3333 18.6668ZM24.3333 30.3335C22.0333 30.3335 20.1667 28.4668 20.1667 26.1668C20.1667 23.8668 22.0333 22.0002 24.3333 22.0002C26.6333 22.0002 28.5 23.8668 28.5 26.1668C28.5 28.4668 26.6333 30.3335 24.3333 30.3335ZM0.166672 32.8335H13.5V19.5002H0.166672V32.8335ZM3.50001 22.8335H10.1667V29.5002H3.50001V22.8335Z"
        fill={props.fill ? props.fill : '24px'} />
    </svg>
  );
}

export default SettingsItemIcon