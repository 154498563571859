// import { CompanyState } from "../Types/companyTypes";
import { clearStore } from "../ExtraReducrActions";
import { onboardingState, stage1Interface } from "../Types/onBoarding";

import { Reducer, createSlice } from "@reduxjs/toolkit";

const initialState: onboardingState = {
  stage: 0,
  industries: {
    status: "idle",
    data:[]
  },
  stage1Data: {
    status: 'idle',
    data: null
  },
  frameworks: {
    selectedFrameworks: []
  }
};

export const onBoardingSlice = createSlice({
  name: "onboarding",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setOnBoardingStep1: (state, action) => {
      state.stage = 1;
      state.stage1Data = action.payload;
    },
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
  },
});

export const {
  setOnBoardingStep1,
  setIndustries,
} = onBoardingSlice.actions;

const onBoadingReducer: Reducer<onboardingState> = onBoardingSlice.reducer;

export default onBoadingReducer;