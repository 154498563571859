import * as React from 'react';
import './style.scss';
import { title } from 'process';
import { SettingsCard } from '../../../../../../../../Components/Cards/SettingsCard';
import { BEInput } from '../../../../../../../../Components/BEFormItems/BEInput';
import { Breadcrumb, Upload } from 'antd';
import { Scope3TypeConfigurationSettings } from '../../../../../Drowers/DataUploadDrawers/Type/Steps/Step4/EditConfiguration';
import { UploadManager } from './UploadManager';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../Redux/Types/scope3Types';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import { setScope3BasedOn } from '../../../../../../../../Redux/Scope3';
import { QUANTITY_BASED, SPEND_BASED, PASSENGER_DISTANCE_BASED, FREIGHT_DISTANCE_BASED } from '../../../../../../Data/constants';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { on } from 'events';
import { ChangeBasedOn } from './ChangeBasedOn';
import ArrowLeft from '../../../../../../../../Components/BEIcons/ArrowLeftIcon';
import SpendBasedCalculation from '../../../../../../../../Components/BEIcons/SpendBasedSettingIcon';
import { PrimaryTheme } from '../../../../../../../../Config/Theme/theames';
import AverageBasedCalculation from '../../../../../../../../Components/BEIcons/QuantityBasedSettingIcon';
import ChangeCalculationMethodIcon from '../../../../../../../../Components/BEIcons/ChangeCalculationMethodIcon';
import UploadManagerIcon from '../../../../../../../../Components/BEIcons/UploadManagerIcon';
import { TopSection } from './Components/TopSection';
import { TypeSpendData } from '../../../../../../Data/data';

export interface IScope3TypeSettingsProps {
  inSettings?: boolean;
}

const CONFIGURE_SPEND_BASED_CALCULATIONS = 'Configure spend-based calculations';
const CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS = 'Configure average-data based calculations';
const CHANGE_CALCULATION_METHOD = 'Change calculation method';
const UPLOAD_MANAGER = 'Upload manager';

export function Scope3TypeSettings(props: IScope3TypeSettingsProps) {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedSetting, setSelectedSetting] = React.useState<any>(null);
  const {type} = useParams<{type:Scope3TypeType}>()
  const selectedBasedOnForType = useTypedSelector(state => state.scope3.selectedBasedOnForType);

  React.useEffect(() => {
    setSelectedSetting(null);
  }, [type,props.inSettings])

  return (
    <div className='scope3-type-settings'>
      {selectedSetting !== null ? 
        <div style={{display:'flex',alignItems:'center', gap:'0.7rem', margin:'1.5rem 0 1.5rem 0'}} onClick={()=>setSelectedSetting(null)}>
          <ArrowLeft/>
          <span style={{fontWeight:600}}>Back</span>
        </div> 
        :null}
      {
          selectedSetting ===  CONFIGURE_SPEND_BASED_CALCULATIONS ||  selectedSetting === CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS ?
           <Scope3TypeConfigurationSettings calculate/>
            :
          selectedSetting === UPLOAD_MANAGER ?
            <UploadManager/>
            :
          selectedSetting === CHANGE_CALCULATION_METHOD ?
            <ChangeBasedOn/>
            :

            //  default screen
          <>
            <TopSection/>
            <div style={{ display: 'flex' }}>
              <BEInput
                style={{ width: '313px', margin: '0 0 1rem auto' }}
                search size='large' placeholder='Search here' value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className='cards-box'>
              {
                [
                  ...(
                    selectedBasedOnForType.includes(SPEND_BASED) ?
                    [{
                    title: TypeSpendData[type as Scope3TypeType][QUANTITY_BASED]?.changeCalculationMethod?.name ?
                    'Configure ' +TypeSpendData[type as Scope3TypeType][QUANTITY_BASED]?.changeCalculationMethod?.name +' Calculations' :
                    CONFIGURE_SPEND_BASED_CALCULATIONS,
                    onClick: () => { setSelectedSetting(CONFIGURE_SPEND_BASED_CALCULATIONS) },
                    onMouseEnter : () => { dispatch(setScope3BasedOn(SPEND_BASED)) },
                    icon:<SpendBasedCalculation inheritSize fill={PrimaryTheme.primaryGreen}/>
                  }]
                  :[]
                ),
                  ...(
                  selectedBasedOnForType.includes(QUANTITY_BASED) ?
                    [{
                    title: TypeSpendData[type as Scope3TypeType][QUANTITY_BASED]?.changeCalculationMethod?.name ?
                    'Configure ' +TypeSpendData[type as Scope3TypeType][QUANTITY_BASED]?.changeCalculationMethod?.name +' Calculations' :
                    CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS,
                    onClick: () => { setSelectedSetting(CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS) },
                    onMouseEnter : () => { dispatch(setScope3BasedOn(QUANTITY_BASED)) },
                    icon:<AverageBasedCalculation inheritSize fill={PrimaryTheme.primaryGreen}/>
                  }]
                  :
                  []
                ),
                ...(
                  selectedBasedOnForType.includes(PASSENGER_DISTANCE_BASED) ?
                    [{
                    title: TypeSpendData[type as Scope3TypeType][PASSENGER_DISTANCE_BASED]?.changeCalculationMethod?.name ?
                    'Configure ' +TypeSpendData[type as Scope3TypeType][PASSENGER_DISTANCE_BASED]?.changeCalculationMethod?.name +' Calculations' :
                    CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS,
                    onClick: () => { setSelectedSetting(CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS) },
                    onMouseEnter : () => { dispatch(setScope3BasedOn(PASSENGER_DISTANCE_BASED)) },
                    icon:<AverageBasedCalculation inheritSize fill={PrimaryTheme.primaryGreen}/>
                  }]
                  :
                  []
                ),
                ...(
                  selectedBasedOnForType.includes(FREIGHT_DISTANCE_BASED) ?
                    [{
                    title: TypeSpendData[type as Scope3TypeType][FREIGHT_DISTANCE_BASED]?.changeCalculationMethod?.name ?
                    'Configure ' +TypeSpendData[type as Scope3TypeType][FREIGHT_DISTANCE_BASED]?.changeCalculationMethod?.name +' Calculations' :
                    CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS,
                    onClick: () => { setSelectedSetting(CONFIGURE_AVERAGE_DATA_BASED_CALCULATIONS) },
                    onMouseEnter : () => { dispatch(setScope3BasedOn(FREIGHT_DISTANCE_BASED)) },
                    icon:<AverageBasedCalculation inheritSize fill={PrimaryTheme.primaryGreen}/>
                  }]
                  :
                  []
                ),
                  {
                    title: CHANGE_CALCULATION_METHOD,
                    onClick: () => {setSelectedSetting(CHANGE_CALCULATION_METHOD) },
                    onMouseEnter : () => { },
                    icon:<ChangeCalculationMethodIcon inheritSize fill={PrimaryTheme.primaryGreen}/>
                  },
                  // {
                  //   title: 'Export',
                  //   onClick: () => { }
                  // },
                  // {
                  //   title: 'Log files',
                  //   onClick: () => { }
                  // },
                  {
                    title: UPLOAD_MANAGER,
                    onClick: () => { setSelectedSetting(UPLOAD_MANAGER) },
                    onMouseEnter : () => {  },
                    icon:<UploadManagerIcon inheritSize fill={PrimaryTheme.primaryGreen}/>
                  }
                ].filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase())).
                  map((item, index) => {
                    return (
                      <SettingsCard key={index} title={item.title} onClick={item.onClick} 
                      onMouseEnter={item.onMouseEnter} icon={item.icon}
                      />
                    )
                  })
              }
            </div>
          </>
         }
    </div>
  );
}
