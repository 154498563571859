import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { getScope3TypeData } from '../../../Actions';
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import { AddCommaToNumber } from '../../../../../Config/Functions/UsefullFunctions';
import BEBackButton from '../../../../../Components/BEBackButton';
import { useParams } from 'react-router-dom';
import { Scope3BasedOnType, Scope3TypeType } from '../../../../../Redux/Types/scope3Types';
import { Scope3TypeDataKeysMap } from '../../../Data/data';
import { SPEND_BASED } from '../../../Data/constants';
import { FiltrableTable } from '../../../../../Components/BETable/FilterableTable';
import { navigateTo } from '../../../../../Navigation/NavigationManager';

export interface IScope3TypeViewDataProps {
}

export function Scope3TypeViewData(props: IScope3TypeViewDataProps) {
  const dispatch = useAppDispatch();
  const typeData = useTypedSelector(state => state.scope3.typeData);
  const { type } = useParams<{ type: Scope3TypeType }>();
  const year = useTypedSelector(state => state.common.date.currentYear);
  const basedOn = useTypedSelector(state => state.scope3.basedOn);

  const columns: ColumnsType<any> = [
     ...(Scope3TypeDataKeysMap[type as Scope3TypeType]?.[basedOn as Scope3BasedOnType] ?? [])
    ,
    {
      title: 'Calculated tCO2e',
      dataIndex: 'co2e',
      render: (text: any) => text && AddCommaToNumber(text, false, true, true, 5),
      sorter: true,
    }
  ]


  React.useEffect(() => {
    if (year)
      if(!basedOn) navigateTo(`/home/scope3/${type}/`);
      dispatch(getScope3TypeData(type as Scope3TypeType));
  }, [year, type]);

  return (
    <div className='type-view-data'>
      <BEBackButton title='Back' /><br />
      {/* <NewBETable
        formatTitle
        columns={columns}
        data={[...typeData.data].sort((a: any, b: any) => a.id - b.id)}
        loading={typeData.status === 'loading'}
        pagination
      /> */}
      <FiltrableTable
        loading={typeData.status === 'loading'}
        data={typeData.data}
        filterValues={typeData.filterValues}
        totalPages={typeData.totalPages}
        columns={columns}
        actionFunction={(query:string) => dispatch(getScope3TypeData(type as Scope3TypeType, query))}
        />
    </div>
  );
}
