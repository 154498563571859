
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend  } from 'recharts';
import { useEffect, useState } from 'react';
import { graphColors } from '../../../../../../../../../Config/Theme/graphColors';
import { GlobalLoader } from '../../../../../../../../../Components/GlobalLoader';
import { PrimaryTheme } from '../../../../../../../../../Config/Theme/theames';
import { AddCommaToNumber } from '../../../../../../../../../Config/Functions/UsefullFunctions';
import './style.scss';



interface ChartProps {
  xAxisLabels?: string[];
  data: any[];                          /// data should be in this format [{name:'name',value:123}]
  xAxisTitle?: string;
  yAxisTitle?: string;
  chartTitle?: string;
  loading?: boolean;
}

const DataUploadDonutChart = (props: ChartProps) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let tempData: any = [];
    props.data.map((item: any) => {
      tempData.push({
        name: item?.name,
        value: Number(item?.value?.toFixed(2)),
      });
    });
    setData(tempData);
  }, [props.data]);

  if(props.loading)return <GlobalLoader height='400px' />

  return (
    <div style={{width:'400px',position:'relative',height:'250px',border: `2px solid ${PrimaryTheme.primaryGreyLight}`, marginTop: '1rem', borderRadius: '8px'}}>
      
      <h3 style={{position:'absolute',left:'1rem',color:PrimaryTheme.primaryGray}}>{props.chartTitle}</h3>
      <ResponsiveContainer
       width="100%"
       height="100%" >
        <PieChart width={200} height={200} >
          <Pie
            data={data}
            innerRadius={30}
            outerRadius={60}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry:any, index:number) => (
              <Cell key={`cell-${index}`} fill={graphColors[index % graphColors.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend layout="vertical" verticalAlign="middle" align="right"  payload={
            data.map((item:any, index:any) => {
              return {
                value: `${item?.name} : ${item?.value && AddCommaToNumber(item?.value, false, true)}`,
                type: 'square',
                color: graphColors[index % graphColors.length]
              }
            })
          
          } />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DataUploadDonutChart