
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend  } from 'recharts';
import { useEffect, useState } from 'react';
import { graphColors, graphColors2, graphColors3 } from '../../../../../../../../../../../Config/Theme/graphColors';
import { GlobalLoader } from '../../../../../../../../../../../Components/GlobalLoader';
import { PrimaryTheme } from '../../../../../../../../../../../Config/Theme/theames';
import { AddCommaToNumber } from '../../../../../../../../../../../Config/Functions/UsefullFunctions';


interface ChartProps {
  xAxisLabels?: string[];
  data: any[];                          /// data should be in this format [{name:'name',value:123}]
  xAxisTitle?: string;
  yAxisTitle?: string;
  chartTitle?: string;
  loading?: boolean;
  colorTheme?: number;
  size?: number;       // this is the relative size of the chart
}

const Scope3ModifiedDonutChart = (props: ChartProps) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let tempData: any = [];
    props.data.map((item: any) => {
      tempData.push({
        name: item?.name,
        value: Number(item?.value),
      });
    });
    setData(tempData);
  }, [props.data]);

  if(props.loading)return <GlobalLoader height='600px' />

  return (
    <div style={{width:'100%',
    height:`${props?.size ? props.size*4 : 400}px`,
    }}>
      <h2>{props.chartTitle}</h2>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart 
          width={props?.size ? props.size*2 : 200}
          height={props?.size ? props.size*2 : 200}
         >
          <Pie
            data={data}
            innerRadius={props?.size ? props.size*0.5 : 60}
            outerRadius={props?.size ? props.size*0.9 : 90}
            fill="#8884d8"
            dataKey="value"
            label={(data) => `${data.name}`}
  
          >
            {data.map((entry:any, index:number) => (
              <Cell key={`cell-${index}`} fill={
                !props.colorTheme ? graphColors[index % graphColors.length] :
                props.colorTheme === 2 ? graphColors2[index % graphColors.length] :
                props.colorTheme === 3 ?graphColors3[index % graphColors.length] :
                'white'
              } 
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value:any) => AddCommaToNumber(value,false,true) + ' tCO2e' + ' (' + (value*100/props.data.reduce((acc:any, item:any) => acc + item.value, 0)).toFixed(3) + '%)'}
          />
          <Legend
          //  layout="vertical" verticalAlign="top"  align="right"
             />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Scope3ModifiedDonutChart