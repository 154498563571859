import * as React from 'react';
import { DataUploadDrawer } from '../../../Drowers/DataUploadDrawers/Type';
import { Scope3TypeDataScreen } from './DataScreen';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { getTypeFiles } from '../../../../Actions';
import { GlobalLoader } from '../../../../../../Components/GlobalLoader';
import { useParams } from 'react-router-dom';
import { setBasedOnForType, setScope3BasedOn, setScope3Type } from '../../../../../../Redux/Scope3';
import { Scope3TypeType } from '../../../../../../Redux/Types/scope3Types';
import { BasedOnData, FileFeildMap, TypeData } from '../../../../Data/data';
import { SelectBasedOnModal } from '../../../Modal/SelectBasedOnModal';

export interface IScope3TypeScreenProps {
}

export function Scope3Type(props: IScope3TypeScreenProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState<boolean>(false)
  const { type } = useParams<{ type: Scope3TypeType }>();
  const typeFiles = useTypedSelector(state => state.scope3.typeFiles);
  const methods = useTypedSelector(state => state.scope3.methods);
  const year = useTypedSelector(state => state.common.date.currentYear);
  const basedOn = useTypedSelector(state => state.scope3.basedOn);
  
  
  React.useEffect(() => {
    let tempMethods: any = []
    methods.data.filter((item: any) => item.year === year)
    .map((item: any) => {
      const currentTypeCode = TypeData[type as Scope3TypeType].code;
      const thisItemTypeCode = Math.floor(item.method / 100);
      if(thisItemTypeCode === currentTypeCode){
        const thisItemBasedOn = Object.keys(BasedOnData).find(key => BasedOnData[key]?.code === item.method % 100);
        if(thisItemBasedOn) tempMethods.push(thisItemBasedOn)
      }
    })
    dispatch(setBasedOnForType(tempMethods))
    // dispatch(setScope3BasedOn(tempMethods[0]))
    console.log('tempMethods', tempMethods)
  }, [methods, type, year]);

  React.useEffect(() => {
    if(!year) return;
    dispatch(setScope3Type(type));
  }, [type]);

  React.useEffect(() => {
      dispatch(getTypeFiles());
  }, []);


  if (typeFiles.status === 'loading' || !year || methods.status === 'loading') return <GlobalLoader height='60vh'/>
  return (
    <div>
      {
        methods.data.filter((item: any) => Math.floor(item.method/100) === TypeData[type as Scope3TypeType].code && item?.year === year).length === 0
        &&
          <SelectBasedOnModal />
        }
        
      <Scope3TypeDataScreen setOpenUpload={setOpen} />
      <DataUploadDrawer open={open} setOpen={setOpen} />
    </div>
  );
}
