import React from 'react'
import IconProps from './Interface/IconProps'

const SuccessIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
	  viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect x="0.666504" width="20" height="20" rx="10" fill="#009904"/>
	<path d="M9.66649 11.793L8.01999 10.1465L7.31299 10.8535L9.66649 13.207L14.52 8.35348L13.813 7.64648L9.66649 11.793Z" fill="white"/>
	</svg>
  );
}

export default SuccessIcon