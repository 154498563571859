import * as React from 'react';
import EditIcon from '../../../../../../../../../../Components/BEIcons/EditIcon';
import { NewBETable } from '../../../../../../../../../../Components/BETable/newBETable';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import {
  patchScope3TypeCalculations,
  patchScope3TypeCategory
} from '../../../../../../../../Actions';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../../../../../../Config/Theme/theames';
import { EditEmissionFactorDrawer } from '../../EditEmissionFactorDrawer';
import { Select } from 'antd';
import { AddCommaToNumber } from '../../../../../../../../../../Config/Functions/UsefullFunctions';
import { BEMessage } from '../../../../../../../../../../Components/BEMessage';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../../../Redux/Types/scope3Types';
import { CatagoryMap } from '../../../../../../../../Data/data';
import { FREIGHT_DISTANCE_BASED } from '../../../../../../../../Data/constants';
import BEStatusTag from '../../../../../../../../../../Components/BEStatusTag';

export interface IDataUploadStep4Props {
  calculate?: boolean
}

export function Scope3TypeFreightDistanceBasedConfiguration(props: IDataUploadStep4Props) {
  const dispatch = useAppDispatch();
  const [fullDetails, setFullDetails] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [currentRow, setCurrentRow] = React.useState<any>({});
  const freightDistanceCategories = useTypedSelector(state => state.scope3.catagoryConfigurations)
  const procurementExpenditureTypes = useTypedSelector(state => state.scope3.expenditureTypesReference)
  const [loadingSelect, setLoadingSelect] = React.useState<boolean>(false)
  const [openEditDrawer, setOpenEditDrawer] = React.useState<boolean>(false)
  const [loadingCalculate, setLoadingCalculate] = React.useState<boolean>(false)
  const {type} = useParams<{type:Scope3TypeType}>()
  const basedOn = useTypedSelector(state => state.scope3.basedOn);


  const columns: ColumnsType<any> = [
    {
      title: CatagoryMap[type as Scope3TypeType][FREIGHT_DISTANCE_BASED].title,
      dataIndex: CatagoryMap[type as Scope3TypeType][FREIGHT_DISTANCE_BASED].dataIndex,
    },
    {
      title:'tonne-km',
      dataIndex: 'tonne_km',
      render:(text:any)=>text && AddCommaToNumber(text,false,true,true,2)
    },
    {
      title:'% of tonne-km',
      dataIndex: 'percentage_tonne_km',
      render:(text:any)=>text && AddCommaToNumber(text,false,true,true,2) + '%'
    },
    {
      title: 'Emission Factor Status',
      render: (text: any, record: any) =>  <BEStatusTag status={record.configured ? 'success' : 'error'}>
        <span>{record.configured ? 'COMPLETED' : 'PENDING'}</span>
      </BEStatusTag> 
    },
    {
      title: 'Emission Factor Selection',
      dataIndex: 'emission_factor',
      render: (text: any, record: any) => {
        return <div style={{ height: '1rem',width:'max-content', cursor: 'pointer' }}
          onMouseEnter={() => setCurrentRow(record)} onClick={() => setOpenEditDrawer(true)}
        ><EditIcon inheritSize stroke={PrimaryTheme.primaryGray} /></div>
      }
    }
  ]



  React.useEffect(() => {
    if (freightDistanceCategories.status === 'success') {
      setTableData(freightDistanceCategories.data);
    }
  }, [freightDistanceCategories]);

  return (
    <div className='scope3-type-configuration-settings'>
      <NewBETable
        pagination
        loading={freightDistanceCategories.status === 'loading'}
        TableTopRightComponents={
          <>
            {
              props.calculate &&
              <BEButton className='primary' size='large'
                loading={loadingCalculate}
                onClick={async () => {
                  setLoadingCalculate(true);
                  await dispatch(patchScope3TypeCalculations(type as Scope3TypeType));
                  setLoadingCalculate(false);
                }}
              >Calculate</BEButton>
            }
          </>
        }
        search
        data={[...tableData].sort((a: any, b: any) => a.id - b.id)}
        columns={columns}
      />
      <EditEmissionFactorDrawer open={openEditDrawer} setOpen={setOpenEditDrawer} data={currentRow} />
    </div>
  );
}


