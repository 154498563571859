import type { StatusType } from "../Types/userTypes";
import { CompanyState } from "../Types/companyTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";
import { DashboardState } from "../Types/dashboard";




const initialState: DashboardState = {
    emissionsCalculations:{
        scope1Calculations:{
            status:'idle',
            data:[]
        },
        scope2Calculations:{
            status:'idle',
            data:[]
        },
        scope3Calculations:{
            status:'idle',
            data:[]
        },
    }
}

export const userSlice = createSlice({
    name: "dashboard",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setScope1Calculations: (state, action) => {
            state.emissionsCalculations.scope1Calculations = action.payload;
        },
        setScope2Calculations: (state, action) => {
            state.emissionsCalculations.scope2Calculations = action.payload;
        },
        setScope3Calculations: (state, action) => {
            state.emissionsCalculations.scope3Calculations = action.payload;
        },
    }
});

export const {
    setScope1Calculations,
    setScope2Calculations,
    setScope3Calculations,
} = userSlice.actions;

const dashboardReducer: Reducer<DashboardState> = userSlice.reducer;

export default dashboardReducer;
