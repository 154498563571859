import React from 'react'
import IconProps from './Interface/IconProps';

const CheckFilledIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={props.fill?props.fill:"#00B876"} />
      <path
        d="M6.32129 11.4961L8.07629 13.7525C8.13551 13.8294 8.21139 13.892 8.29824 13.9354C8.38508 13.9788 8.48063 14.002 8.57772 14.0032C8.67324 14.0044 8.76781 13.9841 8.85455 13.9441C8.94129 13.9041 9.01801 13.8452 9.07915 13.7718L14.6784 6.99609"
        stroke={props.stroke ? props.stroke : "white"}
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CheckFilledIcon