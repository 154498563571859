import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { setRecentlyViewedGroups, setRecentlyViewedUnits } from "../../Redux/EntityReducer";
import { AppDispatch } from "../../Redux/store";
import { DELETE_RECENTLY_VIEWED_BUSINESS_GROUPS, DELETE_RECENTLY_VIEWED_BUSINESS_UNITS, GET_RECENTLY_VIEWED_BUSINESS_GROUPS, GET_RECENTLY_VIEWED_BUSINESS_UNITS, PATCH_RECENTLY_VIEWED_BUSINESS_GROUPS, PATCH_RECENTLY_VIEWED_BUSINESS_UNITS, POST_RECENTLY_VIEWED_BUSINESS_GROUPS, POST_RECENTLY_VIEWED_BUSINESS_UNITS } from "../../Utils/Routes/EntityRouts";

export const getRecentlyViewedUnits = () => async (dispatch: AppDispatch) => {
    dispatch(setRecentlyViewedUnits({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_RECENTLY_VIEWED_BUSINESS_UNITS());
    if (data) {
        dispatch(setRecentlyViewedUnits({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setRecentlyViewedUnits({
            status: "error",
            data: []
        }));
    }
}

export const postRecentlyViewedUnits = (data: any) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(POST_RECENTLY_VIEWED_BUSINESS_UNITS(data));
    if (responseData) {
        dispatch(getRecentlyViewedUnits());
    }
    else {
        console.log(error);
    }
}

export const deleteRecentlyViewedUnits = (id: number) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(DELETE_RECENTLY_VIEWED_BUSINESS_UNITS(id));
    if (responseData) {
        dispatch(getRecentlyViewedUnits());
    }
    else {
        console.log(error);
    }
}

export const patchRecentlyViewedUnits = (id: number, data: any) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(PATCH_RECENTLY_VIEWED_BUSINESS_UNITS(id, data));
    if (responseData) {
        dispatch(getRecentlyViewedUnits());
    }
    else {
        console.log(error);
    }
}

export const getRecentlyViewedGroups = () => async (dispatch: AppDispatch) => {
    dispatch(setRecentlyViewedGroups({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_RECENTLY_VIEWED_BUSINESS_GROUPS());
    if (data) {
        dispatch(setRecentlyViewedGroups({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setRecentlyViewedGroups({
            status: "error",
            data: []
        }));
    }
}

export const postRecentlyViewedGroups = (data: any) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(POST_RECENTLY_VIEWED_BUSINESS_GROUPS(data));
    if (responseData) {
        dispatch(getRecentlyViewedGroups());
    }
    else {
        console.log(error);
    }
}

export const deleteRecentlyViewedGroups = (id: number) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(DELETE_RECENTLY_VIEWED_BUSINESS_GROUPS(id));
    if (responseData) {
        dispatch(getRecentlyViewedGroups());
    }
    else {
        console.log(error);
    }
}

export const patchRecentlyViewedGroups = (id: number, data: any) => async (dispatch: AppDispatch) => {
    const [responseData, error] = await handleAPICall(PATCH_RECENTLY_VIEWED_BUSINESS_GROUPS(id, data));
    if (responseData) {
        dispatch(getRecentlyViewedGroups());
    }
    else {
        console.log(error);
    }
}