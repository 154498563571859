import * as React from 'react';
import { Input, InputProps, Select } from 'antd';
import './style.scss';
import SearchIcon from '../../BEIcons/SearchIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { SizeType } from 'antd/es/config-provider/SizeContext';

interface ObjectType {
    [key: string]: string
}
export interface IBEInputProps extends InputProps {
    search?: boolean;
    searchForColumns?: ObjectType;   // {name: 'value_feild'} to search on
    onChangeColumnSearch?: Function;
    columnSearchValue?: string;
    selectSize?: SizeType;
}

export function BEInput(props: IBEInputProps) {
    const { children, ...otherProps } = props;
    return (
        <Input
            addonBefore={
                props.searchForColumns ?
                    <div className='input-select'>
                        <Select
                            value={props.columnSearchValue ? props.columnSearchValue : props.searchForColumns[0]}
                            onChange={(value: any) => {
                                if (props.onChangeColumnSearch)
                                    props.onChangeColumnSearch(value)
                            }}
                            style={{ width: '100px', backgroundColor: 'transparent',
                            borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}
                            size={props.selectSize ? props.selectSize : 'large'}
                        >
                            {
                                props.searchForColumns &&
                                Object.keys(props.searchForColumns).map((key: any, index: any) => {
                                    return (
                                        props.searchForColumns ?
                                            <Select.Option key={index} value={props.searchForColumns[key]}>{key}</Select.Option>
                                            : null
                                    )
                                })
                            }
                        </Select>
                    </div>
                    : null
            }

            maxLength={otherProps.maxLength ? otherProps.maxLength : 45}    // default max length is 45
            prefix={
                otherProps.search ? <div style={{
                    height: '1rem', padding: '0 0.1rem',
                    display: 'flex', alignItems: 'center',
                }}><SearchIcon inheritSize stroke={PrimaryTheme.primaryGray} /></div> : null
            }
            {...otherProps} style={{ ...otherProps.style }} className={
                `be-input ${otherProps.className ? otherProps.className : ''}`
            }>
            {children}
        </Input>
    )
}
