import React from 'react'
import IconProps from './Interface/IconProps'

const LockIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 11}
      height={props.inheritSize ? "100%" : 14}
      fill={props.fill ? props.fill : "none"}
	  viewBox="0 0 11 14"  xmlns="http://www.w3.org/2000/svg">
	  <path d="M9.25 4.99536H8.625V3.74536C8.625 2.02036 7.225 0.620361 5.5 0.620361C3.775 0.620361 2.375 2.02036 2.375 3.74536V4.99536H1.75C1.0625 4.99536 0.5 5.55786 0.5 6.24536V12.4954C0.5 13.1829 1.0625 13.7454 1.75 13.7454H9.25C9.9375 13.7454 10.5 13.1829 10.5 12.4954V6.24536C10.5 5.55786 9.9375 4.99536 9.25 4.99536ZM3.625 3.74536C3.625 2.70786 4.4625 1.87036 5.5 1.87036C6.5375 1.87036 7.375 2.70786 7.375 3.74536V4.99536H3.625V3.74536ZM9.25 12.4954H1.75V6.24536H9.25V12.4954ZM5.5 10.6204C6.1875 10.6204 6.75 10.0579 6.75 9.37036C6.75 8.68286 6.1875 8.12036 5.5 8.12036C4.8125 8.12036 4.25 8.68286 4.25 9.37036C4.25 10.0579 4.8125 10.6204 5.5 10.6204Z" fill={props.fill ? props.fill : "none"}/>
	  </svg>
  );
}

export default LockIcon