import React from 'react'
import IconProps from './Interface/IconProps'

const CurrentIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
	  viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	  <rect x="0.916504" y="0.25" width="19.5" height="19.5" rx="9.75" stroke="#009904" stroke-width="0.5"/>
	  <rect x="5.6665" y="5" width="10" height="10" rx="5" fill="#009904"/>
	  </svg>
	  
	  
  );
}

export default CurrentIcon