import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function arrayToCSV(data: any) {
  let csv = data.map((row: any) => Object.values(row));
  csv.unshift(Object.keys(data[0]));
  return csv.join("\n");
}
const exportToCSV = (jsonData: any[], fileName: string) => {
  // Convert JSON to worksheet
  const worksheet = XLSX.utils.json_to_sheet(jsonData);

  // Create a workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  // Generate XLSX file
  const excelBuffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save the file using FileSaver.js
  FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

export const getColumnHeadings = (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        if (json.length > 0) {
          const headers = json[0] as string[];
          resolve(headers.filter(header => typeof header === 'string'));
        } else {
          resolve([]);
        }
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};

export default exportToCSV;
