import API from "../../API";


export const ONBOARDING_STEP_1 = async (data:any) => {
    return await API.POST("/company-detail-onboarding/", data, true);
}

export const GET_COMPANY_DETAILS = async () => {
    return await API.GET("/company-profile/");
}

export const UPDATE_COMPANY_DETAILS = async (data: any,id: number,isFormData: boolean) => {
    return await API.PATCH(`/company-profile/${id}/`, data, isFormData);
}

export const GET_INDUSTRIES = async () => {
    return await API.GET("/enterprise/materiality-assessment-industries/")
}

export const GET_ALL_METRICS = async () => {
    return await API.GET("/cleopatra/esg-metrics/")
}   

export const GET_SELECTED_METRICS = async () => {
    return await API.GET("/cleopatra/my-metrics/");
}

export const PATCH_SELECTED_METRICS_STATUS = async (id:any, data:any) => {
    return await API.PATCH("/cleopatra/my-metrics/"+id+"/", data);
}

export const ADD_METRICS = async (data:any) => {
    return await API.POST("/cleopatra/my-metrics/", data);
}

export const DELETE_METRICS = async (id:any) => {
    return await API.DELETE(`/cleopatra/my-metrics/${id}/`);
}

export const GET_STAGE = async () => {
    return await API.GET("/account-stage/");
}

export const UPDATE_STAGE = async (data:any) => {
    return await API.PATCH("/account-stage/", data);
}

export const GET_METRICS_BY_FRAMEWORK = async (metric:any) => {
    return await API.GET(`/cleopatra/metric-config-by-framework/?framework=${metric}`);
}

export const GET_TASK_STATUS = async (id:number) => {
    return await API.GET("/celery-task-status/"+id+"/");
}