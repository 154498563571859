interface License {
	licenseType: string;
	licenseURL: string;
  }
  
  export interface DataSet {
	description: string;
	yearReleased: number;
	sourceType: string;
	typeOfData: string;
	datasetUrl: string;
	remarks: string;
  }
  
 export interface Body {
	dataSetTitle: string;
	dataSet: DataSet;
	license: License;
  }
  
 export interface Header {
	sourceName: string;
	sourceDescription: string;
	sourcelink: {
		placeholder:string,
		link:string
	};
  }
  
  interface EmissionConversionFactors {
	header: Header;
	body: Body[];
  }
export const emissionFactorSourceDetails:EmissionConversionFactors[]=[
	{
		header:{
			sourceName:'UK Government (DEFRA, DESNZ)',
			sourceDescription:'The UK Government provides emission conversion factors for both UK and international organizations to report and disclose their greenhouse gas emissions. The key responsible departments are: Department for Environment, Food & Rural Affairs (DEFRA) and  Department for Energy Security and Net Zero (DESNZ).',
			sourcelink:{
				placeholder:'Visit UK Government (BEIS, DEFRA, DESNZ) website',
				link: 'https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting'
			}
		},
		body:[
			{
			dataSetTitle:'Greenhouse gas reporting: conversion factors 2023',
			dataSet:{
				description:'The dataset of  emission conversion factors are for use by UK and international organisations to report on 2023 greenhouse gas emissions. ',
				yearReleased:2023,
				sourceType:'Government',
				typeOfData:'Activity-based',
				datasetUrl:'https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2023',
				remarks:'The GWPs used in the calculation of CO2e are based on the Intergovernmental Panel on Climate Change (IPCC) Fifth Assessment Report (AR5) '
			},
			license:{
				licenseType:'Open Government License',
				licenseURL:'https://www.nationalarchives.gov.uk/doc/open-government-licence/version/1/open-government-licence.htm'
			}
			},
			{
				dataSetTitle:'Greenhouse gas reporting: conversion factors 2024',
				dataSet:{
					description:'The dataset of emission conversion factors are for use by UK and international organisations to report on 2024 greenhouse gas emissions.',
					yearReleased:2024,
					sourceType:'Government',
					typeOfData:'Activity-based',
					datasetUrl:'https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2024',
					remarks:'The GWPs used in the calculation of CO2e are based on the Intergovernmental Panel on Climate Change (IPCC) Fifth Assessment Report (AR5) .'
				},
				license:{
					licenseType:'Open Government License',
					licenseURL:'https://www.nationalarchives.gov.uk/doc/open-government-licence/version/1/open-government-licence.htm'
				}
			}
		]
	},
	{
		header:{
			sourceName:'CBAM',
			sourceDescription:"The European Union's Carbon Border Adjustment Mechanism (CBAM) is the EU's tool to put a fair price on the carbon emitted during the production of carbon intensive goods that are entering the EU, and to encourage cleaner industrial production in non-EU countries. To meet this objective, the European Commission publishes emission factors to help declarants meet compliance requirements and promote the standardization of emission calculations in the absence of actual embedded emissions data.",
			sourcelink:{
				placeholder:'Visit CBAM website',
				link: ' https://taxation-customs.ec.europa.eu/carbon-border-adjustment-mechanism_en'
			}
		},
		body:[
			{
			dataSetTitle:'CBAM default values',
			dataSet:{
				description:'The dataset represents a ‘world’ average, weighted by production volumes. They are based on estimations on embedded emissions in CBAM goods as carried out by the European Commission’s Joint Research Centre (JRC). Default values play a specific role in CBAM implementation during the transitional period, in particular when importers do not have all the necessary information to calculate actual embedded emissions.',
				yearReleased:2023,
				sourceType:'Government',
				typeOfData:'Activity-based',
				datasetUrl:'https://taxation-customs.ec.europa.eu/news/commission-publishes-default-values-determining-embedded-emissions-during-cbam-transitional-period-2023-12-22_en',
				remarks:'The GWPs used in the calculation of CO2e are based on the Intergovernmental Panel on Climate Change (IPCC) Fifth Assessment Report (AR5) .'
			},
			license:{
				licenseType:'Creative Commons Attribution 4.0 International (CC BY 4.0) license',
				licenseURL:'https://commission.europa.eu/legal-notice_en#copyright-notice'
			}
			},
		]
	},
	{
		header:{
			sourceName:'U.S. Government (EPA)',
			sourceDescription:"The Environmental Protection Agency (EPA) is the U.S. government agency responsible for regularly updating default emission factors used for organizational greenhouse gas reporting in the country.",
			sourcelink:{
				placeholder:'Visit U.S. Government (EPA) website',
				link: 'https://www.epa.gov/'
			}
		},
		body:[
			{
			dataSetTitle:'Supply Chain Greenhouse Gas Emission Factors v1.2',
			dataSet:{
				description:'The dataset is comprised of greenhouse gas (GHG) emission factors for U.S. commodities as defined by the 2017 version of the North American Industry Classification System (NAICS). The Factors are created using a combination of two models - the National Greenhouse Gas Industry Attribution Model (NGIAM), and the U.S. Environmentally-Extended Input-Output (USEEIO) model.',
				yearReleased:2023,
				sourceType:'Government',
				typeOfData:'Spend-based',
				datasetUrl:'https://catalog.data.gov/dataset/supply-chain-greenhouse-gas-emission-factors-v1-2-by-naics-6',
				remarks:'The GWPs used in the calculation of CO2e for this dataset are based on the Intergovernmental Panel on Climate Change (IPCC) Fourth Assessment Report (AR4).'
			},
			license:{
				licenseType:'US Public Domain License',
				licenseURL:'https://pasteur.epa.gov/license/sciencehub-license.html'
			}
			},
		]
	},
	]