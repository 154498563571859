import BEBackButton from "../../../../Components/BEBackButton"
import ArrowLeft from "../../../../Components/BEIcons/ArrowLeftIcon"
import LeafIcon from "../../../../Components/BEIcons/LeafIcon"
import TreeIcon from "../../../../Components/BEIcons/TreeIcon"
import { PrimaryTheme } from "../../../../Config/Theme/theames"
import { navigateTo } from "../../../../Navigation/NavigationManager"
import { EmissionFactorSourceCard } from "../../Components/EmissionFactorSourceCard"
import './styles.scss'
export function EmissionFactorSources(){
	const emissionsources=[
		{
			key:1,
			title:'UK Government (DEFRA,DESNZ)',
			icon:<TreeIcon inheritSize fill={PrimaryTheme.primaryGreen}/>,
			description:'The UK Government provides emission conversion factors for both UK and international organizations to report and disclose their greenhouse gas emissions. The key responsible departments are: Department for Environment, Food & Rural Affairs (DEFRA) and  Department for Energy Security and Net Zero (DESNZ).',
			href:'/home/settings/emission-factor-sources/DEFRA'
		},
		{
			key:2,
			title:'CBAM',
			icon:<LeafIcon inheritSize fill={PrimaryTheme.primaryGreen}/>,
			description:"The European Union's Carbon Border Adjustment Mechanism (CBAM) is the EU's tool to put a fair price on the carbon emitted during the production of carbon intensive goods that are entering the EU, and to encourage cleaner industrial production in non-EU countries. To meet this objective, the European Commission publishes emission factor......",
			href:'/home/settings/emission-factor-sources/CBAM'
		},
		{
			key:3,
			title:'U.S. Government (EPA)',
			icon:<TreeIcon inheritSize fill={PrimaryTheme.primaryGreen}/>,
			description:'The Environmental Protection Agency (EPA) is the U.S. government agency responsible for regularly updating default emission factors used for organizational greenhouse gas reporting in the country.',
			href:'/home/settings/emission-factor-sources/EPA'
		}
	]
	return (
	<div>
		<div style={{padding:'1rem ',paddingLeft:'1.8rem', backgroundColor:'white'}}>
        	<BEBackButton title='Back' icon={<ArrowLeft/>}/>
	    </div>
		<div className="emission-source-group">
			{
			emissionsources.map((item)=>
				<div style={{cursor:'pointer'}} onClick={()=>navigateTo(item.href)}>
					<EmissionFactorSourceCard
						key={item.key}
						title={item.title} 
						icon={item.icon}
						description={item.description}
					/>
				</div>
				)
			}
		</div>
	</div>)
}