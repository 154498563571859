export const graphColors = [
  'rgba(255, 180, 79, 1)'//orange
  ,'rgba(249, 155, 171, 1)'//pink
  ,'rgba(155, 223, 196, 1)'//orange
  ,'rgba(17, 144, 199, 1)'//blue 2
  ,'rgba(17, 197, 209, 1)'//blue 3
  ,'rgba(170, 57, 81, 1)'//maroon
  ,'rgba(224, 107, 241, 1)'//purple
  ,'rgba(255, 123, 159, 1)'//pink
  ,'rgba(255, 204, 209, 1)'//pink
  ,'rgba(107, 175, 153, 1)'//green
  ,'rgba(196, 242, 227, 1)'//green
  ,'rgba(195, 247, 222, 1)'//green
]

export const graphColors2 = [
  'rgba(242, 174, 114, 1)',//brown
  'rgba(129, 199, 212, 1)',//blue1
  'rgba(109, 142, 184, 1)',//blue2
  'rgba(195, 247, 222, 1)'//blue3
]

export const graphColors3 = [
  'rgba(107, 175, 153, 1)',//green1
  'rgba(155, 223, 196, 1)',//green2
  'rgba(196, 242, 227, 1)',//green3
  'rgba(255, 204, 209, 1)',//pink
]