import { Scope3BasedOnType, Scope3TypeType } from "../../../Redux/Types/scope3Types";
import { ColumnsType } from "antd/es/table";
import { 
  //types
  BUSINESS_STAY, 
  BUSINESS_TRANSPORT, 
  DOWNSTREAM_LOGISTICS, 
  END_OF_LIFE_WASTE_TREATMENT,
  UPSTREAM_LOGISTICS, 
  WASTE_GENERATED,
  //basedOns
  PASSENGER_DISTANCE_BASED, 
  PROCUREMENT, QUANTITY_BASED, 
  SPEND_BASED,
  FREIGHT_DISTANCE_BASED, 
} from "./constants";
import { AddCommaToNumber } from "../../../Config/Functions/UsefullFunctions";

export const CatagoryMap:any = {
  [PROCUREMENT]: {
    [SPEND_BASED]: { title: 'Procurement Category', dataIndex: 'procurement_category' },
    [QUANTITY_BASED]: { title: 'Material Category', dataIndex: 'material_category' }
  },
  [BUSINESS_TRANSPORT]: {
    [SPEND_BASED]: { title: 'Transport Category', dataIndex: 'transport_category' },
    [PASSENGER_DISTANCE_BASED]: { title: 'Transport Category', dataIndex: 'transport_category' }
  },
  [UPSTREAM_LOGISTICS]: {
    [SPEND_BASED]: { title: 'Logistics Category', dataIndex: 'logistics_category' },
    [FREIGHT_DISTANCE_BASED]: { title: 'Logistics Category', dataIndex: 'logistics_category' }
  },
  [BUSINESS_STAY]: {
    [SPEND_BASED]: { title: 'Accommodation Category', dataIndex: 'accommodation_category' },
  },
  [DOWNSTREAM_LOGISTICS]: {
    [SPEND_BASED]: { title: 'Logistics Category', dataIndex: 'logistics_category' },
    [FREIGHT_DISTANCE_BASED]: { title: 'Logistics Category', dataIndex: 'logistics_category' }
  },
  [WASTE_GENERATED]: {
    [QUANTITY_BASED]: { title: 'Waste Category', dataIndex: 'waste_category' },
  },
  [END_OF_LIFE_WASTE_TREATMENT]: {
    [QUANTITY_BASED]: { title: 'Product Category', dataIndex: 'product_category' },
  }
};

type TypeColumns = {
  [key in Scope3BasedOnType]?: ColumnsType<any>;
}
type TypeDataType = {
  [key in Scope3TypeType]?: TypeColumns;
}

export const Scope3TypeDataKeysMap: TypeDataType = {
  [PROCUREMENT]: {
    [SPEND_BASED]: [
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
        sorter: true,
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Procurement Category',
        dataIndex: 'procurement_category',
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
        title: 'Spend Excluding Deductible Tax',
        dataIndex: 'spend_excluding_deductible_tax',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
    [QUANTITY_BASED]: [
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Material Category',
        dataIndex: 'material_category',
      },
      {
        title: 'Quantity in Kg',
        dataIndex: 'quantity_in_kg',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ]
  },
  [UPSTREAM_LOGISTICS]: {
    [SPEND_BASED]: [
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Logistics Category',
        dataIndex: 'logistics_category',
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
      title: 'Spend Excluding Deductible Tax',
      dataIndex: 'spend_excluding_deductible_tax',
      render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
    [FREIGHT_DISTANCE_BASED]: [
      {
        title: 'Trip ID',
        dataIndex: 'trip_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Logistics Category',
        dataIndex: 'logistics_category',
      },
      {
        title: 'Distance (km)',
        dataIndex: 'distance_in_km',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Uploaded emissions (tCO2e)',
        dataIndex: 'emissions_in_tco2e',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Freight Mass (tonnes)',
        dataIndex: 'freight_mass_in_tonnes',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ]
  },
  [BUSINESS_TRANSPORT]: {
    [SPEND_BASED]: [
      {
        title: 'Booking ID',
        dataIndex: 'booking_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id'
      },
      {
        title: 'Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Transport Category',
        dataIndex: 'transport_category',
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
        title: 'Spend Excluding Deductible Tax',
        dataIndex: 'spend_excluding_deductible_tax',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
    [PASSENGER_DISTANCE_BASED]: [
      {
        title: 'Booking ID',
        dataIndex: 'booking_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id'
      },
      {
        title: 'Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Transport Category',
        dataIndex: 'transport_category',
      },
      {
        title: 'Distance (km)',
        dataIndex: 'distance_in_km',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title:'Uploaded emissions (tCO2e)',
        dataIndex: 'emissions_in_tco2e',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Number of Passengers',
        dataIndex: 'number_of_passengers',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
  },
  [BUSINESS_STAY]: {
    [SPEND_BASED]: [
      {
        title: 'Booking ID',
        dataIndex: 'booking_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Accommodation Category',
        dataIndex: 'accommodation_category'
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
        title: 'Spend Excluding Deductible Tax',
        dataIndex: 'spend_excluding_deductible_tax',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
  },
  [DOWNSTREAM_LOGISTICS]: {
    [SPEND_BASED]: [
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Logistics Category',
        dataIndex: 'logistics_category',
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
        title: 'Spend Excluding Deductible Tax',
        dataIndex: 'spend_excluding_deductible_tax',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
    [FREIGHT_DISTANCE_BASED]: [
      {
        title: 'Trip ID',
        dataIndex: 'trip_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Logistics Category',
        dataIndex: 'logistics_category',
      },
      {
        title: 'Uploaded emissions (tCO2e)',
        dataIndex: 'emissions_in_tco2e',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Distance (km)',
        dataIndex: 'distance_in_km',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Freight Mass (tonnes)',
        dataIndex: 'freight_mass_in_tonnes',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ]
  },
  [WASTE_GENERATED]: {
    [QUANTITY_BASED]: [
      {
        title: 'Disposal ID',
        dataIndex: 'disposal_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Waste Category',
        dataIndex: 'waste_category',
      },
      {
        title: 'Quantity in Kg',
        dataIndex: 'quantity_in_kg',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
  },
  [END_OF_LIFE_WASTE_TREATMENT]: {
    [QUANTITY_BASED]: [
      {
        title: 'Disposal ID',
        dataIndex: 'disposal_id',
      },
      {
        title: 'Document ID',
        dataIndex: 'document_id',
      },
      {
        title: 'Document Date',
        dataIndex: 'document_date',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
      },
      {
        title: 'Product Category',
        dataIndex: 'product_category',
      },
      {
        title: 'Quantity in Kg',
        dataIndex: 'quantity_in_kg',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: (text: any) => text ? AddCommaToNumber(text, false, true, true) : '-'
      }
    ],
  }
}


export const FileFeildMap = {
  [PROCUREMENT]: {
    [SPEND_BASED]: 'procurement_spend_data',
    [QUANTITY_BASED]: 'procurement_quantity_data'
  },
  [BUSINESS_STAY]: {
    [SPEND_BASED]: 'business_stay_spend_data',
  },
  [BUSINESS_TRANSPORT]: {
    [SPEND_BASED]: 'business_transport_spend_data',
    [PASSENGER_DISTANCE_BASED]: 'business_transport_passenger_distance_data'
  },
  [UPSTREAM_LOGISTICS]: {
    [SPEND_BASED]: 'upstream_logistics_spend_data',
    [FREIGHT_DISTANCE_BASED]: 'upstream_logistics_freight_distance_data',
  },
  [DOWNSTREAM_LOGISTICS]: {
    [SPEND_BASED]: 'downstream_logistics_spend_data',
    [FREIGHT_DISTANCE_BASED]: 'downstream_logistics_freight_distance_data',
  },
  [WASTE_GENERATED]: {
    [QUANTITY_BASED]: 'waste_generated_quantity_data'
  },
  [END_OF_LIFE_WASTE_TREATMENT]: {
    [QUANTITY_BASED]: 'end_of_life_waste_treatment_quantity_data'
  },
}


export const TypeSpendData: any = {
  [PROCUREMENT]: {
    [SPEND_BASED]: {
      code: 101,
      changeCalculationMethod: {
        boxText: 'financial value of purchased goods and services.'
      }
    },
    [QUANTITY_BASED]: {
      code: 102,
      changeCalculationMethod: {
        boxText: 'mass or quantity of purchased goods and services.'
      }
    }
  },
  [BUSINESS_TRANSPORT]: {
    [SPEND_BASED]: {
      code: 201,
      changeCalculationMethod: {
        boxText: 'amount of money spent on each mode of business travel transport.'
      }
    },
    [PASSENGER_DISTANCE_BASED]: {
      code: 203,
      changeCalculationMethod: {
        boxText: 'distance travelled, number of passengers and mode of transport used for business travel.',
        name:'Distance-based'
      }
    }
  },
  [BUSINESS_STAY]: {
    [SPEND_BASED]: {
      code: 301,
      changeCalculationMethod: {
        boxText: 'amount of money spent on each type of accommodation.'
      }
    }
  },
  [UPSTREAM_LOGISTICS]: {
    [SPEND_BASED]: {
      code: 401,
      changeCalculationMethod: {
        boxText: 'amount of money spent on each mode of upstream transportation.'
      }
    },
    [FREIGHT_DISTANCE_BASED]: {
      code: 404,
      changeCalculationMethod: {
        boxText: 'mass, distance, and mode of shipments used for upstream transportation.',
        name:'Distance-based'
      }
    }
  },
  [DOWNSTREAM_LOGISTICS]: {
    [SPEND_BASED]: {
      code: 501,
      changeCalculationMethod: {
        boxText: 'amount of money spent on each mode of downstream transportation.'
      }
    },
    [FREIGHT_DISTANCE_BASED]: {
      code: 504,
      changeCalculationMethod: {
        boxText: 'mass, distance, and mode of shipments used for downstream transportation.',
        name:'Distance-based'
      }
    }
  },
  [WASTE_GENERATED]: {
    [QUANTITY_BASED]: {
      code: 602,
      changeCalculationMethod: {
        boxText: 'mass or quantity of waste generated in operations, disposed by specific waste types and waste treatment methods.',
        name:'Waste-type-specific'
      }
    }
  },
  [END_OF_LIFE_WASTE_TREATMENT]: {
    [QUANTITY_BASED]: {
      code: 702,
      changeCalculationMethod: {
        boxText: 'the mass or quantity of sold products disposed at end-of-life by specific waste types and waste treatment methods.',
        name:'Waste-type-specific'
      }
    }
  }
}

export const BasedOnData: any = {
  [SPEND_BASED]: {
    code: 1,
    percentage: 'percentage_spend'
  },
  [QUANTITY_BASED]: {
    code: 2,
    percentage: 'percentage_quantity'
  },
  [PASSENGER_DISTANCE_BASED]: {
    code: 3,
    percentage: 'percentage_passenger_distance'
  },
  [FREIGHT_DISTANCE_BASED]: {
    code: 4,
    percentage: 'percentage_freight_distance'
  }
}

export const TypeData: any = {
  [PROCUREMENT]: { 
    code: 1,
    feildName: 'procurement',
   },
  [BUSINESS_TRANSPORT]: { 
    code: 2,
    feildName: 'business_transport',
   },
  [BUSINESS_STAY]: { 
    code: 3,
    feildName: 'business_stay',
   },
  [UPSTREAM_LOGISTICS]: { 
    code: 4,
    feildName: 'upstream_logistics',
   },
  [DOWNSTREAM_LOGISTICS]: { 
    code: 5,
    feildName: 'downstream_logistics',
   },
  [WASTE_GENERATED]:{
    code:6,
    feildName: 'waste_generated',
  },
  [END_OF_LIFE_WASTE_TREATMENT]:{
    code:7,
    feildName: 'end_of_life_waste_treatment',
  }
}