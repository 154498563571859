import * as React from 'react';
import { DashboardCard } from '../../../../../../../../Components/Cards/DashboardCard';
import InfoIcon from '../../../../../../../../Components/BEIcons/InfoIcon';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import {
  patchScope3TypeCalculations
} from '../../../../../../Actions';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { PrimaryTheme } from '../../../../../../../../Config/Theme/theames';
import { Scope3TypeConfigurationSettings } from './EditConfiguration';
import { useParams } from 'react-router-dom';
import { Scope3BasedOnType, Scope3TypeType } from '../../../../../../../../Redux/Types/scope3Types';
import { BasedOnData } from '../../../../../../Data/data';
import { SPEND_BASED } from '../../../../../../Data/constants';
import { Progress } from 'antd';

export interface IDataUploadStep4Props {
  setOpen: Function;
}


const DataUploadStep4: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IDataUploadStep4Props> = (props, ref) => {
  const dispatch = useAppDispatch();
  const [fullDetails, setFullDetails] = React.useState<boolean>(false);
  const typeBasedOnCategories = useTypedSelector(state => state.scope3.catagoryConfigurations)
  const { type } = useParams<{ type: Scope3TypeType }>()
  const basedOn = useTypedSelector(state => state.scope3.basedOn)
  const conicColors={'50%':'#F64B41', '100%':'#ECD955'}

  const onButtonClick = async () => {
    // if all record.configured === true then go to next step
    // if (typeBasedOnCategories.data.every((record: any) => record.configured === true)) {
    await dispatch(patchScope3TypeCalculations(type as Scope3TypeType));
    props.setOpen(false);
    // }
    // else BEMessage.error('Please configure all procurement categories before proceeding');
  }


  const percentageSpendCategorised=typeBasedOnCategories.data.length === 0 ? 0 :
  typeBasedOnCategories.data.reduce((acc: number, item: any) =>
    item.configured ? acc + item[BasedOnData[basedOn as Scope3BasedOnType].percentage] : acc, 0) / 
  typeBasedOnCategories.data.reduce((acc: number, item: any) => 
    acc + item[BasedOnData[basedOn as Scope3BasedOnType].percentage], 0) * 100

  React.useImperativeHandle(ref, () => ({ onButtonClick }), [typeBasedOnCategories]);
  return (
    <div className='data-upload-step4'>
      {/* <div className='info'>
        <div><InfoIcon fill={PrimaryTheme.primaryGray} /></div>
        <p>
          <strong>Please complete configurations for each procurement category:</strong><br /><br />
          Step 1: Confirm/Update expenditure type:<br />
          Expenditure type is used to classify each procurement category as either:<br />
          Purchased goods<br />
          {
            fullDetails &&
            <>
              Purchased services<br />
              Capital goods.<br />
              Based on this selection, GHG emission calculations are accounted for under Scope 3.1 (Purchased goods & services) or Scope 3.2 (Capital goods)
              By default, all procurement categories will be classified as  “Purchased goods”. Please update the default selection as required.<br /><br />

              Step 2: Emission Factor Selection<br />
              Select the emission factor which best represents the nature of spend within the procurement category. We recommend prioritizing emission factor selection for procurement categories that contribute most to total spend.
              If unable to complete emission factor selection for all procurement categories, this can  be completed later within “Settings --Configure Spend-Based Calculations”. Please note that emission calculation for a procurement category will only be conducted once an emission factor has been selected.

            </>
          }
          <span className='read-more'
            onClick={() => setFullDetails(!fullDetails)}
          >
            Read {fullDetails ? 'less' : 'more'}
          </span>
        </p>

      </div> */}
      {
        basedOn==SPEND_BASED &&  <div style={{display:'flex', justifyContent:'end', alignItems:'center'}}> 
          {/* <h2 style={{fontWeight:600}}>Configure Expenditure Type and Emission Factor (EF)</h2> */}
          <div style={{width:'20%'}}>
        <h3>% OF {basedOn?.split('-')[0].toUpperCase()} CATEGORISED - {percentageSpendCategorised.toFixed()}%</h3>
        <Progress showInfo={false} percent={percentageSpendCategorised} strokeColor={conicColors}/>
      </div> 
        </div>
      
      }
      
      <Scope3TypeConfigurationSettings />
    </div>
  );
}

export default React.forwardRef(DataUploadStep4);
