import React from "react";
import { useLocation } from "react-router-dom";
import { PrimaryTheme } from "../../../../../../../../../../Config/Theme/theames";
import SettingsIcon from "../../../../../../../../../../Components/BEIcons/SettingsIcon";


export function TopSection(){

	const overviewTab={
		name:'Overview',
		icon:<SettingsIcon  fill={PrimaryTheme.primaryGreen}
        inheritSize/>,
	  }
    return (
        <div className='global-top-section'>
            <div
                style={{
                    fontSize: "1rem",
                    color: PrimaryTheme.primaryGreen,
					margin:'1rem',
					marginLeft:0,
                    height: "1.25rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem"
                }}>
                <span>
                    {overviewTab.icon}
                </span>
                <span>
                    {overviewTab.name.toUpperCase()}
                </span>
            </div>
        </div>
    );
}
