import * as React from 'react';
import { BEInfoBox } from '../../../../../../../../../../../Components/BEEyeButton/BEInfoBox';
import { LableRequired } from '../../../../../../../../../../../Components/BEFormItems/LableRequired';
import BEMultipleSelect from '../../../../../../../../../../../Components/BEMultipleSelect';
import { BEButton } from '../../../../../../../../../../../Components/BEFormItems/BEButton';
import { ColumnsType } from 'antd/es/table';
import { NewBETable } from '../../../../../../../../../../../Components/BETable/newBETable';
import { useAppDispatch } from '../../../../../../../../../../../Config/Hooks/useAppDispatch';
import { setCurrentFormula } from '../../../../../../../../../../../Redux/Scope3';
import { useTypedSelector } from '../../../../../../../../../../../Config/Hooks/useTypedSelector';
import EditIcon from '../../../../../../../../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../../../../../../../../Config/Theme/theames';
import { Scope3TypeType } from '../../../../../../../../../../../Redux/Types/scope3Types';
import { patchScope3TypeCategory } from '../../../../../../../../../Actions';
import { BEMessage } from '../../../../../../../../../../../Components/BEMessage';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import DeleteIcon from '../../../../../../../../../../../Components/BEIcons/DeleteIcon';

export interface ISelectFormulaProps {
  setStep: Function;
  setOpen: Function;
  open: boolean;
}

const SelectFormula: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, ISelectFormulaProps> = (props, ref) => {
  const dispatch = useAppDispatch();
  const formulas = useTypedSelector(state => state.scope3.formulaCalculation.formulas);
  const [selectedFormula, setSelectedFormula] = React.useState<any>(null);
  const type = useParams<{ type: Scope3TypeType }>().type;
  const currentCategory = useTypedSelector(state => state.scope3.currentCategory);
  const columns: ColumnsType<any> = [
    {
      title: 'SR No.',
      render: (text: any, record: any, index: number) => index + 1
    }, {
      title: 'Formula',
      dataIndex: 'title'
    }, {
      title: 'Unit',
      dataIndex: 'unit'
    }, {
      title: 'Action',
      render: (text: any, record: any) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <div style={{ height: '1rem', cursor: 'pointer' }}
            onMouseEnter={() => dispatch(setCurrentFormula(record))}
            onClick={() => props.setStep(2)}
          >
            <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
          </div>
        </div>
      )
    }
  ]

  const onButtonClick = async () => {
    if (!currentCategory?.forumla && !selectedFormula) return BEMessage.error('Please select a formula');
    console.log('selectedFormula', selectedFormula);
    const _selectedFormula = formulas.data.find((item: any) => item.id === selectedFormula);
    if(selectedFormula){
      await dispatch(patchScope3TypeCategory(type as Scope3TypeType, currentCategory.id, {
        forumla: _selectedFormula ? _selectedFormula.id : null,
        formula_conversion_factor: _selectedFormula.numerical_value
      }));
    }
    else{
      await dispatch(patchScope3TypeCategory(type as Scope3TypeType, currentCategory.id, {
        forumla: null,
        formula_conversion_factor: 0
      }));
    }
    props.setOpen(false);
  }
  React.useImperativeHandle(ref, () => ({ onButtonClick }), [formulas,selectedFormula]);

  React.useEffect(() => {
    if(currentCategory?.forumla) setSelectedFormula(currentCategory.forumla);
    else setSelectedFormula(null);
  }, [props.open,currentCategory]);

  return (
    <div className='select-formula'>
      <BEInfoBox title='Action' description={<div>
        <p>Formulas can be created and applied to material categories to convert quantity data for a category from its default unit of measurement to the mass equivalent in kg using the below structure:</p>
        <p>Quantity in kg = Quantity in default unit of measurement x (Factor defined by variables and operator signs)</p>
        <div style={{marginTop:'15px',marginBottom:'15px'}}>
          <p>Illustrative example:</p>
          <p>Default unit of measurement for Material Category "A" = litres</p>
          <p>Quantity in kg = Quantity in default unit of measurement (litres) x (density of material "A" in kg per litre)</p>
        </div>
        <p>The formula manager can be used to create and edit formulas, and apply them to material categories.</p>
        <p>Please apply formulas to material categories with "custom" unit of measurement.</p>
      </div>} />
      <br />
      <LableRequired style={{color:PrimaryTheme.primaryGray}}>Select formula to convert</LableRequired>
      {/* <BEMultipleSelect single valueFeild='id' labelFeild='title' onChange={(value: any) => setSelectedFormula(value)}
        data={formulas.data}
      /> */}
      <div className='formula-dropdown-outer'>
        <Select 
          showSearch
          optionFilterProp="children"
          size='large' style={{ width: '100%',marginBottom:'1rem' }}  value={selectedFormula} onChange={(value: any) => setSelectedFormula(value)} 
        >
            {
              formulas.data.filter((item: any) => item.category === type)
              .map((item: any) => <Select.Option value={item.id}>{item.title}</Select.Option>)
            }
        </Select>
        <div className='delete-icon' onClick={() => setSelectedFormula(null)} >
            <DeleteIcon inheritSize stroke={PrimaryTheme.primaryRedError}/>
        </div>
      </div>
      {/* <div className='formula-library-heading'>
        <p>Formula Library</p>
        <BEButton size='large' className='primary'
          onMouseEnter={() => dispatch(setCurrentFormula(null))}
          onClick={() => props.setStep(2)}
        >Create New Formula</BEButton>
      </div> */}
      <NewBETable
      search
      TableTopRightComponents={
        <BEButton size='large' className='primary'
          onMouseEnter={() => dispatch(setCurrentFormula(null))}
          onClick={() => props.setStep(2)}
        >Create New Formula</BEButton>
      }
      columns={columns}
       data={formulas.data.filter((item: any) => item.category === type)}
        pagination />
    </div>
  );
}

export default React.forwardRef(SelectFormula);
