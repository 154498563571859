import { Col, Row } from 'antd'
import { BEInput } from '../../../../Components/BEFormItems/BEInput'
import React, { useEffect, useState } from 'react'
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { getAllUsers } from '../../../../views/Actions/UsersManagementActions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import UserListTable from '../../../../views/Onboarding/Step3/UserListTable';
import AddUser from '../../../../views/Onboarding/Drawers/AddUser';
import BEBackButton from '../../../../Components/BEBackButton';
import ArrowLeft from '../../../../Components/BEIcons/ArrowLeftIcon';

const Users = () => {
  const dispatch = useAppDispatch()
  const users = useTypedSelector((state) => state.userMgmt);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);


  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <div>
      <div style={{padding:'1rem ',paddingLeft:0,margin: '0rem 1.5rem'}}>
        	<BEBackButton title='Back' icon={<ArrowLeft/>}/>
	    </div>
      <AddUser open={openDrawer} setOpen={setOpenDrawer} />
      <AddUser isEdit open={openEditDrawer} setOpen={setOpenEditDrawer} />
      <div>
        <UserListTable
          loading={users.status === "loading"}
          users={users.data}
          setAddUser={setOpenDrawer}
          setEditUser={setOpenEditDrawer}
        />
      </div>
    </div>
  );
}

export default Users