import * as React from 'react';
import { AddVariable } from '../../AddVariable';
import { BEInfoBox } from '../../../../../../../../../../../Components/BEEyeButton/BEInfoBox';
import { BEInput } from '../../../../../../../../../../../Components/BEFormItems/BEInput';
import { BELineWithText } from '../../../../../../../../../../../Components/BELinewithText';
import { LableRequired } from '../../../../../../../../../../../Components/BEFormItems/LableRequired';
import { PrimaryTheme } from '../../../../../../../../../../../Config/Theme/theames';
import ErrorIcon from '../../../../../../../../../../../Components/BEIcons/ErrorIcon';
import EditIcon from '../../../../../../../../../../../Components/BEIcons/EditIcon';
import InfoIcon from '../../../../../../../../../../../Components/BEIcons/InfoIcon';
import { useTypedSelector } from '../../../../../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../../../../../Config/Hooks/useAppDispatch';
import { getScope3TypeCategories, patchQuantityFormulas, postQuantityFormulas, processFormulaStringToArray } from '../../../../../../../../../Actions';
import { BEMessage } from '../../../../../../../../../../../Components/BEMessage';
import { Scope3TypeType } from '../../../../../../../../../../../Redux/Types/scope3Types';
import { useParams } from 'react-router-dom';
import { title } from 'process';

export interface IAddNewFormulaProps {
  setOpen: Function;
  setStep: Function;
}


const TagSigns = ['+', '-', '×', '÷', '(', ')']

const AddNewFormula: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IAddNewFormulaProps> = (props, ref) => {
  const dispatch = useAppDispatch()
  const [formula, setFormula] = React.useState<any>([])
  const [openAddVariable, setOpenAddVariable] = React.useState<boolean>(false)
  const [openEditVariable, setOpenEditVariable] = React.useState<boolean>(false)
  const [currentVariable, setCurrentVariable] = React.useState<any>(null);
  const currentCategory = useTypedSelector(state => state.scope3.currentCategory)
  const currentFormula = useTypedSelector(state => state.scope3.formulaCalculation.currentFormula)
  const variables = useTypedSelector(state => state.scope3.formulaCalculation.variables)
  const [name, setName] = React.useState<string>('')
  const [defaultUoM, setDefaultUoM] = React.useState<string>('')
  const {type} = useParams<{type:Scope3TypeType}>()

  const onButtonClick = async() => {
    if((name === '' || defaultUoM === '') && !currentFormula) return BEMessage.error('Please fill the name and default unit of measurement')
    if(!currentFormula || formula.length === 0) return BEMessage.error('Please input the formula to save it')
    let formulaString = ''
    // add the sign normaly
    // add the tag in this format {id}
    formula.forEach((item:any) => {
      if (item.type === 'sign') formulaString += item.value
      else formulaString += `(${item.id})`
    })
    //wrap the formula in brackets
    formulaString = `(${formulaString})`
    console.log('formulaString', formulaString)
    console.log(name,defaultUoM,'<--')
    let res = await dispatch(patchQuantityFormulas(currentFormula.id, {
      title: name,
      unit: defaultUoM,
      numerical_expression: formulaString,
    }));
    dispatch(getScope3TypeCategories(type as Scope3TypeType))
    if(res) props.setStep(1)
  }

  React.useImperativeHandle(ref, () => ({ onButtonClick }), [currentFormula, formula, name, defaultUoM]);

  const saveFormula = () => {
    if (currentFormula) return
    if (name !== '' && defaultUoM !== '') {
      dispatch(postQuantityFormulas({
        title: name,
        unit: defaultUoM,
        category: type,
      },true))
    }
  }

  const setExistingFormula = async() => {
    const formulaArray = await dispatch(processFormulaStringToArray(currentFormula.numerical_expression))
    setFormula(formulaArray)
  }

  React.useEffect(() => {
    if(currentFormula){
      setName(currentFormula.title)
      setDefaultUoM(currentFormula.unit)
      setExistingFormula()
    }}, [currentFormula])

  return (
    <div className='scope3-type-edit-measurement'>

      <BEInfoBox description={'Define quantity conversion logic to convert the provided quantity from its default unit of measurement to quantity in kg.'} />

      <div className='default-lable'><LableRequired>Name</LableRequired></div>
      <BEInput
        value={name}
        onBlur={saveFormula}
        onChange={(e) => setName(e.target.value)}
        size='large' placeholder='Enter Name' />

      <div className='default-lable'><LableRequired>Default Unit of Measurement (UoM)</LableRequired></div>
      <BEInput
        value={defaultUoM}
        onChange={(e) => setDefaultUoM(e.target.value)}
        onBlur={saveFormula}
        size='large' placeholder='Enter Default Unit of Measurement' />

      <BELineWithText text='Configure your formula' style={{ fontSize: '0.8rem', margin: '1rem 0' }} />

      <div className='default-lable formula'><LableRequired>Quantity in kg = Quantity in default UoM <span style={{fontSize:'1.rem',color:'black'}}>× </span></LableRequired>
        {
          formula.length > 0 &&
          <span
            onClick={() => setFormula([])}
            style={{ color: PrimaryTheme.primaryRedError, cursor: 'pointer' }}>Clear formula</span>
        }
      </div>
      <div className='formula-outer-box'>
        <div className='formula-box'>
          {/* <div key={-1} className={'formula-item'}>
            <span>Quantity in default UoM</span>
          </div> */}
          {
            formula.map((item:any, index:number) => {
              return (
                <div key={index} className={'formula-item ' + item.type}>
                  <div className='remove-sign'
                    onClick={() => {
                      let newFormula = [...formula]
                      newFormula.splice(index, 1)
                      setFormula(newFormula)
                    }}
                  ><ErrorIcon inheritSize fill={PrimaryTheme.secondaryGray} /></div>
                  <span>{item.value}</span>
                </div>
              )
            })
          }
          {
            formula.length === 0 &&
            <div className='formula-item-add'>
              <span>Complete the formula by clicking the tags below  </span>
            </div>
          }
        </div>
        <div className='operational-tags'>
          <div className='content'>
            {
              TagSigns.map((item, index) => {
                return (
                  <div key={index} className='tag-sign'
                    onClick={() => {
                      setFormula([...formula, {
                        type: 'sign',
                        value: item
                      }])
                    }}
                  >

                    <span>{item}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div>

        </div>
      </div>

      <div className='formula-feilds'>

        <div className='feild-tags'>
          <div className='title-box'>
            <p>Conversion factors </p>
            <span
              onClick={() => {
                if(name==='' || defaultUoM==='') return BEMessage.error('Please fill the name and default unit of measurement first')
                setOpenAddVariable(true)
              }}
            >+ Add New</span>
          </div>
          <div className='content'>
            {
              variables.data.filter((item:any) => item.formula === currentFormula?.id)
              .map((item, index) => {
                return (
                  <div key={index} className='tag-sign' onMouseEnter={(e) =>{
                    e.stopPropagation();
                    setCurrentVariable(item)}
                  }
                    onClick={() => {
                      setFormula([...formula, {
                        type: 'tag',
                        value: item.title,
                        id: item.id,
                      }])
                    }}
                  >
                    <div className='edit-sign'
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenEditVariable(true)
                      }}
                    ><EditIcon inheritSize stroke={PrimaryTheme.secondaryGray} fill={PrimaryTheme.secondaryGray} /></div>
                    <span>{item.title}</span>
                  </div>
                )
              })
            }
            {/* <BENoData buttonLabel='Add New' size='small' description='Empty (click add new button and fill the data to see tags here)
                                No Waste Generated & Disposed Metrics have been added yet'/> */}
          </div>
        </div>
      </div>
      <div className='info-outer'><div className='info-icon'><InfoIcon inheritSize fill={PrimaryTheme.secondaryGray} /></div>
        <p>Click add new button to create your own converter tags by inputing values </p>
      </div>
      <AddVariable open={openAddVariable} setOpen={setOpenAddVariable} />
      <AddVariable open={openEditVariable} setOpen={setOpenEditVariable} isEdit currentVariable={currentVariable} />
    </div>
  );
}

export default React.forwardRef(AddNewFormula)