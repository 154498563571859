import API from "../../API";

//** my methods */

export const GET_MY_METHODS = async () => {
    return await API.GET("/zero/my-zero-methods/");
}

export const POST_MY_METHODS = async (data:any) => {
    return await API.POST("/zero/my-zero-methods/", data);
}

// ***Scope3 Graph Data Routes ***//

export const GET_SCOPE3_GRAPH_DATA = async (year:number,filter_by:string,methods:number[]) => {
    let methodsQuery = methods.map((method) => `&method=${method}`).join('');
    console.log(methodsQuery,'methodsQuery',methods);  
    return await API.GET("/zero/zero-method-graph-data/?year=" + year + "&filter_by=" + filter_by + methodsQuery);
}

//***Spend routes */


export const GET_SPEND_EMISION_FACTORS = async () => {
    return await API.GET("/zero/procurement-spend-emission-factor/");
}


// ***quantity routes ***//


export const GET_QUANTITY_FORMULAS = async () => {
    return await API.GET("/zero/procurement-quantity-formula-conversion/");
}

export const PATCH_QUANTITY_FORMULAS = async (id:any,data:any) => {
    return await API.PATCH(`/zero/procurement-quantity-formula-conversion/${id}/`, data);
}

export const POST_QUANTITY_FORMULAS = async (data:any) => {
    return await API.POST("/zero/procurement-quantity-formula-conversion/", data);
}

export const GET_QUANTITY_FORMULA_VARIABLES = async () => {
    return await API.GET("/zero/procurement-quantity-conversion-variables/");
}

export const PATCH_QUANTITY_FORMULA_VARIABLES = async (id:any,data:any) => {
    return await API.PATCH(`/zero/procurement-quantity-conversion-variables/${id}/`, data);
}

export const POST_QUANTITY_FORMULA_VARIABLES = async (data:any) => {
    return await API.POST("/zero/procurement-quantity-conversion-variables/", data);
}

// ***passeger distance routes ***//

export const GET_PASSENGER_DISTANCE_EMISION_FACTORS = async () => {
    return await API.GET("/zero/passenger-distance-emission-factor-bulk-upload/");
}

// ***freight distance routes ***//

export const GET_FREIGHT_DISTANCE_EMISION_FACTORS = async () => {
    return await API.GET("/zero/freight-distance-emission-factor-bulk-upload/");
}


// *****procurement-spend-routes***** //

export const GET_PROCUREMENT_SPEND_DATA = async (query:string) => {
    return await API.GET("/zero/procurement-spend-data/?" + query);
}

export const POST_PROCUREMENT_FILE = async (data:any) => {
    return await API.POST("/zero/procurement-spend-data/", data,true);
}

export const GET_PROCUREMENT_DATA_FEILDS = async () => {
    return await API.GET("/zero/procurement-spend-data-fields/");
}

export const GET_PROCUREMENT_SPEND_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/spend-based-procurement-category-configuration/?year=" + year);
}

export const GET_PROCUREMENT_EXPENDITURE_TYPES_REFRENCE = async () => {
    return await API.GET("/zero/procurement-spend-expenditure-type-reference/");
}

export const PATCH_PROCUREMENT_SPEND_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/spend-based-procurement-category-configuration/${id}/`, data);
}

export const PATCH_PROCUREMENT_SPEND_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/procurement-spend-based-calculations/?year=${year}`);
}

export const GET_TYPE_FILES = async () => {
    return await API.GET("/zero/zero-files/");
}

export const GET_TYPE_FILE = async (id:any) => {
    return await API.GET(`/zero/zero-files/${id}/`);
}

export const PATCH_TYPE_FILE = async (id:any,data:any) => {
    return await API.PATCH(`/zero/zero-files/${id}/`, data);
}

export const DELETE_TYPE_FILE = async (id:any) => {
    return await API.DELETE(`/zero/zero-files/${id}/`);
}

// *********** / procurement quantity routes ************//

export const GET_PROCUREMENT_QUANTITY_DATA = async (query:string) => {
    return await API.GET("/zero/procurement-quantity-data/?" + query);
}   

export const POST_PROCUREMENT_QUANTITY_FILE = async (data:any) => {
    return await API.POST("/zero/procurement-quantity-data/", data,true);
}

export const GET_PROCUREMENT_QUANTITY_DATA_FEILDS = async () => {
    return await API.GET("/zero/procurement-quantity-data-fields/");
}

export const PATCH_PROCUREMENT_QUANTITY_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/procurement-quantity-calculation/?year=${year}`);
}

export const GET_PROCUREMENT_QUANTITY_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/procurement-quantity-category-configuration/?year=" + year);
}

export const PATCH_PROCUREMENT_QUANTITY_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/procurement-quantity-category-configuration/${id}/`, data);
}

export const GET_PROCUREMENT_QUANTITY_EMISION_FACTORS = async () => {
    return await API.GET("/zero/procurement-quantity-emission-factor-bulk-upload/");
}

//********** */ business-transport-routes ***********//

export const GET_BUSINESS_TRANSPORT_DATA = async (query:string) => {
    return await API.GET("/zero/business-transport-spend-data/?" + query);
}

export const POST_BUSINESS_TRANSPORT_SPEND_BASED_DATA = async (data:any) => {
    return await API.POST("/zero/business-transport-spend-data/", data,true);
}

export const GET_BUSINESS_TRANSPORT_DATA_FEILDS = async () => {
    return await API.GET("/zero/business-transport-spend-data-fields/");
}

export const GET_BUSINESS_TRANSPORT_SPEND_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/business-transport-spend-based-category-configuration/?year=" + year);
}

export const PATCH_BUSINESS_TRANSPORT_SPEND_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/business-transport-spend-based-category-configuration/${id}/`, data);
}

export const PATCH_BUSINESS_TRANSPORT_SPEND_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/business-transport-spend-based-calculations/?year=${year}`);
}

//********upstream logistics routes*********//

export const GET_UPSTREAM_LOGISTICS_DATA = async (query:string) => {
    return await API.GET("/zero/upstream-logistics-spend-data/?" + query);
}

export const POST_UPSTREAM_LOGISTICS_SPEND_BASED_DATA = async (data:any) => {
    return await API.POST("/zero/upstream-logistics-spend-data/", data,true);
}

export const GET_UPSTREAM_LOGISTICS_DATA_FEILDS = async () => {
    return await API.GET("/zero/upstream-logistics-spend-data-fields/");
}

export const GET_UPSTREAM_LOGISTICS_SPEND_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/upstream-logistics-spend-based-category-configuration/?year=" + year);
}

export const PATCH_UPSTREAM_LOGISTICS_SPEND_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/upstream-logistics-spend-based-category-configuration/${id}/`, data);
}

export const PATCH_UPSTREAM_LOGISTICS_SPEND_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/upstream-logistics-spend-based-calculation/?year=${year}`);
}


//*********** */ Business Stay Routes ************//

export const GET_BUSINESS_STAY_DATA = async (query:string) => {
    return await API.GET("/zero/business-stay-spend-data/?" + query);
}

export const POST_BUSINESS_STAY_SPEND_BASED_DATA = async (data:any) => {
    return await API.POST("/zero/business-stay-spend-data/", data,true);
}

export const GET_BUSINESS_STAY_DATA_FEILDS = async () => {
    return await API.GET("/zero/business-stay-spend-data-fields/");
}

export const GET_BUSINESS_STAY_SPEND_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/business-stay-spend-based-category-configuration/?year=" + year);
}

export const PATCH_BUSINESS_STAY_SPEND_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/business-stay-spend-based-category-configuration/${id}/`, data);
}

export const PATCH_BUSINESS_STAY_SPEND_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/business-stay-spend-based-calculations/?year=${year}`);
}


// ***********/ Downstream Logistics Routes ************//

export const GET_DOWNSTREAM_LOGISTICS_DATA = async (query:string) => {
    return await API.GET("/zero/downstream-logistics-spend-data/?" + query);
}

export const POST_DOWNSTREAM_LOGISTICS_SPEND_BASED_DATA = async (data:any) => {
    return await API.POST("/zero/downstream-logistics-spend-data/", data,true);
}

export const GET_DOWNSTREAM_LOGISTICS_DATA_FEILDS = async () => {
    return await API.GET("/zero/downstream-logistics-spend-data-fields/");
}

export const GET_DOWNSTREAM_LOGISTICS_SPEND_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/downstream-logistics-spend-based-category-configuration/?year=" + year);
}

export const PATCH_DOWNSTREAM_LOGISTICS_SPEND_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/downstream-logistics-spend-based-category-configuration/${id}/`, data);
}

export const PATCH_DOWNSTREAM_LOGISTICS_SPEND_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/downstream-logistics-spend-based-calculation/?year=${year}`);
}

// ********** */ Waste Generated Quantity Routes ************//

export const GET_WASTE_GENERATED_QUANTITY_DATA = async (query:string) => {
    return await API.GET("/zero/waste-generated-quantity-data/?" + query);
}

export const POST_WASTE_GENERATED_QUANTITY_DATA = async (data:any) => {
    return await API.POST("/zero/waste-generated-quantity-data/", data,true);
}

export const GET_WASTE_GENERATED_QUANTITY_DATA_FEILDS = async () => {
    return await API.GET("/zero/waste-generated-quantity-data-fields/");
}

export const GET_WASTE_GENERATED_QUANTITY_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/waste-generated-quantity-category-configuration/?year=" + year);
}

export const PATCH_WASTE_GENERATED_QUANTITY_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/waste-generated-quantity-category-configuration/${id}/`, data);
}

export const PATCH_WASTE_GENERATED_QUANTITY_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/waste-generated-quantity-calculation/?year=${year}`);
}

export const GET_WASTE_GENERATED_QUANTITY_EMISION_FACTORS = async () => {
    return await API.GET("/zero/waste-generated-quantity-emission-factor-bulk-upload/");
}

// ********** */ End of Life waste treatment quantity routes ************//

export const GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA = async (query:string) => {
    return await API.GET("/zero/end-of-life-waste-treatment-quantity-data/?" + query);
}

export const POST_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA = async (data:any) => {
    return await API.POST("/zero/end-of-life-waste-treatment-quantity-data/", data,true);
}

export const GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_DATA_FEILDS = async () => {
    return await API.GET("/zero/end-of-life-waste-treatment-quantity-data-fields/");
}

export const GET_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/end-of-life-waste-treatment-quantity-category-configuration/?year=" + year);
}

export const PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/end-of-life-waste-treatment-quantity-category-configuration/${id}/`, data);
}

export const PATCH_END_OF_LIFE_WASTE_TREATMENT_QUANTITY_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/end-of-life-waste-treatment-quantity-calculation/?year=${year}`);
}

export const GET_END_OF_LIFE_WASTE_TREATMENT__QUANTITY_EMISION_FACTORS = async () => {
    return await API.GET("/zero/end-of-life-waste-treatment-quantity-emission-factor-bulk-upload/");
}


// ********** */ Business Transport Passenger Distance Routes ************//

export const GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA = async (query:string) => {
    return await API.GET("/zero/business-transport-passenger-distance-data/?" + query);
}

export const POST_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA = async (data:any) => {
    return await API.POST("/zero/business-transport-passenger-distance-data/", data,true);
}

export const GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_DATA_FEILDS = async () => {
    return await API.GET("/zero/business-transport-passenger-distance-data-fields/");
}

export const GET_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/business-transport-passenger-distance-category-configuration/?year=" + year);
}

export const PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/business-transport-passenger-distance-category-configuration/${id}/`, data);
}

export const PATCH_BUSINESS_TRANSPORT_PASSENGER_DISTANCE_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/business-transport-passenger-distance-calculation/?year=${year}`);
}


// ********** */ Upstream Logistics Freight Distance Routes ************//

export const GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA = async (query:string) => {
    return await API.GET("/zero/upstream-logistics-freight-distance-data/?" + query);
}

export const POST_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA = async (data:any) => {
    return await API.POST("/zero/upstream-logistics-freight-distance-data/", data,true);
}

export const GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FEILDS = async () => {
    return await API.GET("/zero/upstream-logistics-freight-distance-data-fields/");
}

export const GET_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/upstream-logistics-freight-distance-category-configuration/?year=" + year);
}

export const PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/upstream-logistics-freight-distance-category-configuration/${id}/`, data);
}

export const PATCH_UPSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/upstream-logistics-freight-distance-calculation/?year=${year}`);
}

// ********** */ Downstream Logistics Freight Distance Routes ************//

export const GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA = async (query:string) => {
    return await API.GET("/zero/downstream-logistics-freight-distance-data/?" + query);
}

export const POST_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA = async (data:any) => {
    return await API.POST("/zero/downstream-logistics-freight-distance-data/", data,true);
}

export const GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_DATA_FEILDS = async () => {
    return await API.GET("/zero/downstream-logistics-freight-distance-data-fields/");
}

export const GET_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORIES = async (year:number) => {
    return await API.GET("/zero/downstream-logistics-freight-distance-category-configuration/?year=" + year);
}

export const PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CATEGORY = async (id:any,data:any) => {
    return await API.PATCH(`/zero/downstream-logistics-freight-distance-category-configuration/${id}/`, data);
}

export const PATCH_DOWNSTREAM_LOGISTICS_FREIGHT_DISTANCE_CALCULATIONS = async (year:number) => {
    return await API.PATCH(`/zero/downstream-logistics-freight-distance-calculation/?year=${year}`);
}