import API from "../../API";

export const GET_LOGS = async () => {
  return await API.GET("/cleopatra/my-data-audit-logs/");
}

export const GET_YEARS = async () => {
  return await API.GET("/financial-years/?apply_zero_app_filter=true");
}

// export const POST_YEARS = async (data: any) => {   
//   return await API.POST("/zero/zero-years/", data);
// }