import { useLocation } from "react-router-dom";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { navigateTo } from "../../../../Navigation/NavigationManager";
import { setShowWelcomePage } from "../../../../Redux/CommonReducer";
import { updateOnboardingStage } from "../../../Actions/OnboardingActions";
import { useState } from "react";

interface OnboardingTopNavProps {
  handleSave?:Function
}

export function OnboardingNavButtons(props:OnboardingTopNavProps) {
  const onboardingStage = useTypedSelector(
    (state) => state.common.onboardingStage
  );
  const dispatch = useAppDispatch();
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  const [loadingSkip, setLoadingSkip] = useState(false);

  const handleEditConfig = async () => {
    navigateTo('/onboarding/step1')
  }
  const handleNext = async () => {
    console.log('handleNext')
    setLoading(true);
    if(props.handleSave) await props.handleSave();
    setLoading(false);
    if (
      (onboardingStage.stage === 0 &&
        location.pathname === "/onboarding/step1") ||
      (onboardingStage.stage === 1 &&
        location.pathname === "/onboarding/step2") ||
      (onboardingStage.stage === 2 &&
        location.pathname === "/onboarding/step3") 
    ) {
      setLoading(true);
      await dispatch(updateOnboardingStage(onboardingStage.stage));
      dispatch(setShowWelcomePage(true));                               // welcome page will be shown only once after onboarding is completed
      setLoading(false);
    }
    if(location.pathname === '/onboarding/step1')
      navigateTo('/onboarding/step2')
    if (location.pathname === "/onboarding/step2")
      navigateTo("/onboarding/step3");
    else if (location.pathname === "/onboarding/step3")
      navigateTo("/home/dashboard");
  };

  const handleSkip = async () => { 
    if (
      (onboardingStage.stage === 0 &&
        location.pathname === "/onboarding/step1") ||
      (onboardingStage.stage === 1 &&
        location.pathname === "/onboarding/step2") ||
      (onboardingStage.stage === 2 &&
        location.pathname === "/onboarding/step3") 
    ) {
      setLoadingSkip(true);
      await dispatch(updateOnboardingStage(onboardingStage.stage)).then(() => {
        setLoadingSkip(false);
        if(onboardingStage.stage === 2) navigateTo('/home/dashboard')  // if stage 3 is skipped, navigate to dashboard
        else navigateTo(`/onboarding/step${onboardingStage.stage+1}`);
      });
    }
    else if(location.pathname === "/onboarding/step3") navigateTo('/home/dashboard') // if stage 3 is skipped, navigate to dashboard
    else navigateTo(`/onboarding/step${ Number(location.pathname.split("step")[1])+1}`);
  }

  
  return (
    <div className="onboarding-top-nav">
      {location.pathname !=='/onboarding/step1' ? 
        <BEButton size="large" className="secondary-green" onClick={
          location.pathname === "/onboarding/step3"? handleEditConfig:handleSkip
        }
          loading= {loadingSkip}
        >
          Skip this step
        </BEButton> :null
      }
       
        <BEButton size="large" className="primary" onClick={handleNext}
          loading = {loading}
        >
          {location.pathname === "/onboarding/step3" ? "Save & Close":"Save & Next"}
        </BEButton>
    </div>
  );
}
