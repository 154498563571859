import { ColumnsType } from 'antd/es/table';
import * as React from 'react';
import EditIcon from '../../../../../../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../../../../../../Config/Theme/theames';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import ExportExcelIcon from '../../../../../../../../../Components/BEIcons/ExportExcelIcon';
import { NewBETable } from '../../../../../../../../../Components/BETable/newBETable';
import { useTypedSelector } from '../../../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import { deleteTypeFile, getTypeFiles } from '../../../../../../../Actions';
import { ChangeDateFormat, changeSnakeCaseToSentence } from '../../../../../../../../../Config/Functions/UsefullFunctions';
import { BEDrawer } from '../../../../../../../../../Components/BEDrawer';
import { Select } from 'antd';
import { EditFile } from './editFile';
import { useParams } from 'react-router-dom';
import { Scope3BasedOnType, Scope3TypeType } from '../../../../../../../../../Redux/Types/scope3Types';
import { FileFeildMap, TypeSpendData } from '../../../../../../../Data/data';
import DeleteIcon from '../../../../../../../../../Components/BEIcons/DeleteIcon';
import BEConfirmModal from '../../../../../../../../../Components/BEConfirmModal';
import { SPEND_BASED } from '../../../../../../../Data/constants';

export interface IUploadManagerProps {
}

export function UploadManager(props: IUploadManagerProps) {
  const dispatch = useAppDispatch();
  const typeFiles = useTypedSelector(state => state.scope3.typeFiles);
  const users = useTypedSelector(state => state.userMgmt.data);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);
  const [currentRow, setCurrentRow] = React.useState<any>(null);
  const { type } = useParams<{ type: Scope3TypeType }>()
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const year = useTypedSelector(state => state.common.date.currentYear);

  const columns: ColumnsType<any> = [
    {
      title: 'File name',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    // },
    {
      title: 'Uploaded date',
      dataIndex: 'uploaded_at',
      key: 'uploaded_date',
      render: (text: any) => ChangeDateFormat(text)
    },
    {
      title: 'Uploaded by',
      dataIndex: 'user',
      key: 'uploaded_by',
      render: (text: any) => users.find((user: any) => user.id === text)?.first_name + ' ' + users.find((user: any) => user.id === text)?.last_name
    },
    {
      title:'Method',
      dataIndex:'category',
      key:'category',
      render:(text:any,record:any)=> {
        const method = Object.entries(FileFeildMap[type as Scope3TypeType]).find(([key, value]) => value === record.category)?.[0]
        const methodName= TypeSpendData[type as Scope3TypeType][method as Scope3BasedOnType].changeCalculationMethod.name || method
        return <span>{methodName && changeSnakeCaseToSentence(methodName,false,true)}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (text: any) => text ? <BEButton className='primary' size='small'>Active</BEButton> : <BEButton className='primary-red' size='small'>Inactive</BEButton>
    },
    {
      title: 'Action',
      dataIndex: 'file',
      key: 'file',
      render: (text: any, record: any) =>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <div
            style={{ height: '1.2rem' }}
            onClick={() => window.open(text, '_blank')}
          >
            <ExportExcelIcon fill={PrimaryTheme.primaryGreen} inheritSize />
          </div>
          {/* <div style={{ height: '1.2rem' }}
            onClick={() => { setSelectedFile(record); setOpen(true) }}
            onMouseEnter={() => setSelectedFile(record)}
          >
            <DeleteIcon inheritSize stroke={PrimaryTheme.secondaryRedError} />
          </div> */}
          {
            record.active &&
            <div style={{ height: '1rem' }}
              onMouseEnter={() => setCurrentRow(record)}
              onClick={() => setOpenEdit(true)}
            >
              <EditIcon stroke={PrimaryTheme.primaryGray} inheritSize />
            </div>
          }
        </div>
    }
  ]

  React.useEffect(() => {
    dispatch(getTypeFiles(true));
  }, []);


  return (
    <div>
      <NewBETable
        data={[...typeFiles.data].filter((file: any) => Object.values(FileFeildMap[type as Scope3TypeType]).includes(file.category) && file.year === year)
          .sort((a: any, b: any) => a.id - b.id)}
        columns={columns} loading={typeFiles.status === 'loading'} pagination />
      <EditFile open={openEdit} setOpen={setOpenEdit} data={currentRow} />
      <BEConfirmModal
        visible={open}
        setVisible={setOpen}
        onConfirm={async () => { await dispatch(deleteTypeFile(type as Scope3TypeType, selectedFile.id)) }}
        title='Delete File'
        message='Are you sure you want to delete this file? This action cannot be undone.'
      />
    </div>
  );
}
