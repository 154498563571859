import { AppDispatch } from "../../Redux/store";

export const AutoLogin = (navigate:Function,location:any) => async (dispatch:AppDispatch) => {
  const access = localStorage.getItem("access");
  if(access){
        if(location.pathname === '/login' || location.pathname === '/') navigate('/home/dashboard');
        return true;
  }
  else{
    if(location.pathname.includes('/home') || location.pathname === '/' )  navigate('/login');
  }
}