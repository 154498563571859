import * as React from 'react';
import InfoIcon from '../../../../../../../../Components/BEIcons/InfoIcon';
import { NewBETable } from '../../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import CheckFilledIcon from '../../../../../../../../Components/BEIcons/CheckFilledIcon';
import { Modal, Select } from 'antd';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import { getBusinessStaySpendFeilds, getScope3TypeFeilds, postBusinessStaySpendBasedFile, postScope3TypeFile } from '../../../../../../Actions';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../../../../Components/BEMessage';
import { setStep3Data, setSystemFeilds } from '../../../../../../../../Redux/Scope3';
import { LableRequired } from '../../../../../../../../Components/BEFormItems/LableRequired';
import { BEInfoBox } from '../../../../../../../../Components/BEEyeButton/BEInfoBox';
import { BEEyeButton } from '../../../../../../../../Components/BEEyeButton';
import { useParams } from 'react-router-dom';
import { Scope3TypeType } from '../../../../../../../../Redux/Types/scope3Types';
import DeleteIcon from '../../../../../../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../../../../../../Config/Theme/theames';
import CloudUploadIcon from '../../../../../../../../Components/BEIcons/CloudUploadIcon';
import { GlobalLoader } from '../../../../../../../../Components/GlobalLoader';

export interface IDataUploadStep2Props {
  setStep: Function;
}



const DataUploadStep2: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IDataUploadStep2Props> = (props, ref) => {
  const dispatch = useAppDispatch();
  const excleHeaders = useTypedSelector(state => state.scope3.dataUpload.excleHeaders);
  const fileData = useTypedSelector(state => state.scope3.dataUpload.fileData);
  const systemFeilds = useTypedSelector(state => state.scope3.dataUpload.systemFeilds);
  const [selectedFeildsMap, setSelectedFeildsMap] = React.useState<any>({});
  const [tableData, setTableData] = React.useState<any>([]);
  const {type} =  useParams<{type:Scope3TypeType}>()
  const [loadingModal, setLoadingModal] = React.useState<boolean>(false);

  const columns: ColumnsType<any> = [
    {
      title: 'System names',
      dataIndex: 'system_name',
      key: 'system_name',
      render: (text: any, record: any) => <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
        {record.mandatory ? <LableRequired>{record.system_name}</LableRequired> : <p>{
          record.system_name
          }</p>} <BEEyeButton title={record.system_name} discription={record.description} />
      </div>
    },
    {
      title: 'Uploded file name',
      key: 'uploaded_file_name',
      render: (text:any,record: any) => 
      <div className='upload-file-feild-select-box'>
        <Select style={{width:'313px'}} showSearch
          value={selectedFeildsMap[record.system_name]}
          onChange={(value) => setSelectedFeildsMap({ ...selectedFeildsMap, [record.system_name]: value })}
        >
          {
            excleHeaders.filter((header:any)=>!Object.values(selectedFeildsMap).includes(header))
            .map((header:any)=><Select.Option value={header}>{header}</Select.Option>)
          }
        </Select>
        <div className={'delete-icon '+ (selectedFeildsMap[record.system_name] ? '' : 'hide')}
          onClick={() => {
            let newSelectedFeildsMap = { ...selectedFeildsMap };
            delete newSelectedFeildsMap[record.system_name];
            setSelectedFeildsMap(newSelectedFeildsMap);
          }}
        >
            <div><DeleteIcon inheritSize stroke={PrimaryTheme.secondaryRedError}/></div>
        </div>
      </div>
    },
    {
      title: 'Status',
      key: 'status',
      render: (status: boolean,record:any) => selectedFeildsMap.hasOwnProperty(record.system_name) ? <CheckFilledIcon fill='green' /> : '---'
    },
  ]
  const onButtonClick = async () => {
    console.log(selectedFeildsMap,'selectedFeildsMap')
    //check if all mandatory fields are mapped
    let mandatoryFields = systemFeilds.data.filter((field:any)=>field.mandatory);
    if(mandatoryFields.some((field:any)=>!selectedFeildsMap.hasOwnProperty(field.name))){
      BEMessage.error('Please map all mandatory fields');
      return;
    }
    setLoadingModal(true)
    let res = await dispatch(postScope3TypeFile(type as Scope3TypeType,fileData, selectedFeildsMap));
    if(res){
      setLoadingModal(false)
      dispatch(setStep3Data(res));
      BEMessage.success('File uploaded successfully');
      props.setStep(3);
    }
    else BEMessage.error('File upload failed');
    setLoadingModal(false)
  }
  React.useImperativeHandle(ref, () => ({ onButtonClick }), [selectedFeildsMap,fileData]);

  React.useEffect(() => {
    dispatch(getScope3TypeFeilds(type as Scope3TypeType))
  }, []);

  React.useEffect(() => {
    let data:any = [];
    systemFeilds.data.forEach((field:any) => {
      if(field.name !== 'year')
      data.push({
        system_name: field.name,
        mandatory: field.mandatory,
        description: field.description,
      })
    });
    setTableData(data);
  }, [systemFeilds]);


  return (
    <div className='data-upload-step2'>
      <div className='info'>
        <div><InfoIcon fill='black' /></div>
        <p>
          In this screen, we request you to match the system field name to the corresponding column name from the file that you have just uploaded. For e.g. for the system field name “vendor name” please map the column which contains corresponding supplier/vendor names.

          This will ensure the right data gets mapped and calculations are accurate.
        </p>
      </div><br/>
      <NewBETable
        loading={systemFeilds.status === 'loading'}
        data={tableData}
        columns={columns}
      />
      <Modal centered  width={'max-content'} open={loadingModal} footer={null} closeIcon={null}>
          <div style={{padding:'1rem',paddingTop:'2rem' ,paddingBottom:'2rem'}}>
            <div style={{display:'flex', alignItems:'center',gap:'1rem'}}>
              <CloudUploadIcon fill='black'/>
              <h1>Your data is being uploaded and validated</h1>
            </div>
            <GlobalLoader height='6rem'/>
            <p style={{textAlign:'center',fontSize:'1.2rem'}}>Hold tight—please do not press the back button</p>
          </div>
      </Modal>
    </div>
  );
}

export default React.forwardRef(DataUploadStep2);