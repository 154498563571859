import React from 'react'
import { BETabButton } from '../../../../Components/BETabButton';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import EmissionIcon from '../../../../Components/BEIcons/Emissions';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Select } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { GlobalMonthsSlicer } from '../../../../Components/BEMultipleSelect/MonthSlicer';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setCurrentMonth, setCurrentYear } from '../../../../Redux/CommonReducer';
import { showFYFormatByYear } from '../../../../Config/Functions/UsefullFunctions';

const TopSection = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentMonth = useTypedSelector((state) => state.common.date.currentMonth);
  const currentYear = useTypedSelector((state) => state.common.date.currentYear);
  const type = useParams<any>().type;
  const years = useTypedSelector(state => state.settings.years);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  

  return (
    <div className="global-top-section" style={{padding:'0 1.5rem 1rem 1.5rem'}}>
      <BETabButton
        title={`SCOPE 3${type ? ` / ${type.toUpperCase()}` : ""}`}
        icon={
          <EmissionIcon
            stroke={
              location.pathname.includes("scope-3")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("scope-3")}
        onClick={() => {}}
      />

      <div style={{ marginLeft: 'auto' }}>
        {/* <GlobalMonthsSlicer 
          setDateSlicerMonth={setCurrentMonth}
          setDateSlicerYear={setCurrentYear}
          currentMonth={currentMonth}
          currentYear={currentYear}
        />   */}
        {
          currentYear!==0 &&
          <Select value={currentYear} style={{ width: 200 }} onChange={(value) => dispatch(setCurrentYear(value))}
          size='large' loading={years.status === 'loading'}>
          {
            years.data.map((year: any) => (
              <Select.Option key={year.year} value={year.year}>
                {
                  showFYFormatByYear(year.year, yearEnd || '12-31')
                }
              </Select.Option>
            ))
          }
        </Select>
        }
      </div>
    </div>
  );
}

export default TopSection