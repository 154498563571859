import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import SwitchIcon from '../../BEIcons/SwitchIcon';
import dayjs from 'dayjs';
import { Radio, Select, Row, Col } from 'antd';
import { AllMonths, showFYFormatByYear } from '../../../Config/Functions/UsefullFunctions';
import { BEButton } from '../../BEFormItems/BEButton';
import { rotateArray } from '../../../Config/Functions/UsefullFunctions';
import { LoadingOutlined } from '@ant-design/icons';

import './style.scss';
import { BENotify } from '../../BENotify';

export interface IMonthsSlicerProps {
  setDateSlicerMonth: Function;
  setDateSlicerYear: Function;
  currentMonth: number;
  currentYear: number;
}

export function GlobalMonthsSlicer(props: IMonthsSlicerProps) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [year, setYear] = useState(props.currentYear);
  const [month, setMonth] = useState(props.currentMonth)
  const recentYears = useTypedSelector(state => state.settings.years);
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const yearEndDate = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  //***handle out click */
  const wrapperRef = useRef<HTMLDivElement>(null);
  function handleClickOutside(event: MouseEvent) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    // document.addEventListener('mousedown', handleClickOutside);
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside);
    // };
  }, [wrapperRef]);

  //********* */

  useEffect(() => {
    setYear(props.currentYear)
  }, [props.currentYear])

  return (
    <div ref={wrapperRef} className='months-slicer'>
      <div className='selector-box'
        onClick={() => year && setOpen(!open)}
      >
        <p className='pipe-right'>
          {
            year ?
            showFYFormatByYear(year, yearEndDate || '')
            : ''
          }
        </p>
        <p style={{ marginLeft: '0.5rem', fontWeight: 400 }}>{
          props.currentMonth === 0 ? 'Annual' : AllMonths[props.currentMonth - 1]
        } </p>
      </div>
      <div className='slicer-box'

        ref={wrapperRef}
        style={{ display: open ? 'inherit' : 'none' }}
      >
        <Select
          size='large'
          style={{ width: '100%' }}
          value={year}
          onChange={(value: any) => {
            setYear(value)
          }}
        >
          {
            recentYears.data.map((item: any, index: any) => {
              return (
                <Select.Option key={index} value={item?.year}>
                  {
                    showFYFormatByYear(item?.year, yearEndDate || '')
                  }
                </Select.Option>
              )
            })
          }
        </Select>

        <div style={{ padding: '1rem' }}>
          <Radio.Group
            value={month}
            onChange={(e: any) => {
              setMonth(e.target.value)
            }}
          >
            <div >
              <div key={13} className='month-box'>
                <Radio value={0}>
                  <p className='month'>Annual</p>
                </Radio>
              </div>
              <div className='months-box'>
                <div>
                  {
                    rotateArray(AllMonths, yearEnd, true).slice(0, 6).map((item: any, index: any) => {
                      return (
                        <div key={index} className='month-box'>
                          <Radio value={
                            AllMonths.indexOf(item) + 1
                          }>
                            <p className='month'>{item}</p>
                          </Radio>
                        </div>
                      )
                    })
                  }
                </div>
                <div style={{ marginLeft: '25%' }}>
                  {
                    rotateArray(AllMonths, yearEnd, true).slice(6, 12).map((item: any, index: any) => {
                      return (
                        <div key={index} className='month-box'>
                          <Radio value={AllMonths.indexOf(item) + 1}>
                            <p className='month'>{item}</p>
                          </Radio>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Radio.Group>

        </div>
        <BEButton className='primary' size='large'
          style={{ width: '100%' }}
          onClick={() => {
            dispatch(props.setDateSlicerYear(year))
            dispatch(props.setDateSlicerMonth(month))
            BENotify.success(`${year}`, `You will now be entering data for FY${year}.`, () => { }, false);
            setOpen(false)
          }}
        >
          Apply
        </BEButton>
      </div>
      <div className='down-icon'
        onClick={() => setOpen(!open)}
      >
        {
          recentYears.status === 'loading' ?
            <div style={{ position: 'absolute', right: '-5px', top: '1rem' }}> <LoadingOutlined size={20} style={{}} spin /> </div>
            :
            <SwitchIcon stroke='black' />
        }
      </div>
    </div>
  );
}
