import React from 'react'
import IconProps from './Interface/IconProps';

const URLIcon= (props:IconProps) => {
  return (
    <svg  width={props.inheritSize ? "100%" : 14}
    height={props.inheritSize ? "100%" : 14} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H6V1.33333H1.33333V10.6667H10.6667V6H12V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM4.46667 8.46667L3.53333 7.53333L9.73333 1.33333H7.33333V0H12V4.66667H10.6667V2.26667L4.46667 8.46667Z"  fill={props.fill ? props.fill:'black'}/>
	</svg>	
  );
}

export default URLIcon